import React, { useEffect } from "react";
import PersonalInfoPage from "../profile/patient/PersonalInfoPage";
import { useNavigate } from "react-router-dom";
import { PersonalInfoFormProps } from "../../types/profile";
import useAuthStore from "../../store/authStore";
import { isAdmin } from "../../utils/auth";

const PatientsProfile: React.FC<PersonalInfoFormProps> = ({ patientId }) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();

  useEffect(() => {
    if (!patientId) {
      if (isAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [patientId]);

  return <PersonalInfoPage patientId={patientId} />;
};

export default PatientsProfile;
