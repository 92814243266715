import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Button from "../ui/Button";
import { WhatsAppChatButtonProps } from "../../types/common";

const WhatsAppChatButton: React.FC<WhatsAppChatButtonProps> = ({
  phoneNumber,
  message,
}) => {
  // Create the WhatsApp URL
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message || "")}`;

  const handleClick = () => {
    console.log("clicked with url", url);
    // window.open(url, "_blank");
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null; // Prevent potential security issues
    }
  };

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Button
        variant="contained"
        color="success"
        className="success-button"
        // startIcon={<WhatsAppIcon />}
        onClick={handleClick}
      >
        Chat on WhatsApp
      </Button>
    </a>
  );
};

export default WhatsAppChatButton;
