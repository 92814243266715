import React, { useEffect, useState } from "react";
import { Box, Chip, TextField, Typography } from "@mui/material";
import { postConsultationSummary } from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";
import { DoctorsNoteProps } from "../../../types/appointments";

const DoctorsNote: React.FC<DoctorsNoteProps> = ({
  appointmentId,
  doctorNotes,
}) => {
  // props & state values
  const { notes, setNotes } = useAppointmentStore();
  const [newNote, setNewNote] = useState("");

  // callbacks & functions
  useEffect(() => {
    if (notes && notes.length === 0) {
      setNotes(doctorNotes?.doctorNotes);
    }
  }, []);

  const handleDelete = (noteToDelete: string) => {
    setNotes(notes.filter((note) => note !== noteToDelete));
    const notesArray = notes.filter((note) => note !== noteToDelete);
    const data = {
      appointmentId: appointmentId || doctorNotes?.appointmentId,
      clinicalNotes: notesArray || [],
      // medications: medicineData,
    };
    const params = {
      saveType: "CLINICALNOTES",
    };
    postConsultationSummary(data, params);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && newNote.trim() !== "") {
      setNotes([...notes, newNote.trim()]);
      const data = {
        appointmentId: appointmentId || doctorNotes?.appointmentId,
        clinicalNotes: [...notes, newNote.trim()],
        // medications: medicineData,
      };
      const params = {
        saveType: "CLINICALNOTES",
      };
      postConsultationSummary(data, params);
      setNewNote("");
    }
  };

  const handleInput = (event: any) => {
    setNewNote(event.target.value || "");
  };

  return (
    <Box
      sx={{
        paddingBottom: "25px",
        marginBottom: "19px",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
      }}
    >
      <Typography
        sx={{
          mb: 2,
          fontSize: "25px",
          lineHeight: "30px",
          fontFamily: "Roboto Condensed",
          color: "var(--neuro-button-bg-primary)",
        }}
      >
        Doctors note
      </Typography>

      <Box
        sx={{
          bgcolor: "var(--neuro-white-text)",
          border: "1px solid var(--neuro-light-grey_border)",
          paddingBlock: "21px",
          paddingInline: "23px",
          borderRadius: "20px",
          minHeight: "100px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {notes &&
            notes.length > 0 &&
            notes.map((note) => (
              <Chip
                key={note}
                label={note}
                onDelete={() => handleDelete(note)}
                sx={{
                  bgcolor: "var(--neuro-white-text)",
                  borderRadius: "10px",
                  paddingBlock: "12px",
                  paddingInline: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "14px",
                  border: "1px solid var(--neuro-light-grey_border)",
                  "& .MuiChip-label": {
                    paddingLeft: "0px",
                  },
                }}
              />
            ))}
        </Box>
        <TextField
          onChange={handleInput}
          // disabled={
          //   createSummaryLoading && createSummaryLoading.includes("notes")
          //     ? true
          //     : false
          // }
          onKeyDown={handleKeyPress}
          value={newNote}
          placeholder="Type here..."
          sx={{
            // minWidth: "100px",
            minHeight: "100px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            alignItems: "flex-start",
            height: "100px",
            width: "100%",
            "& .MuiInputBase-root": {
              //   paddingRight: "12px",
              alignItems: "flex-start",
              borderRadius: "50px",
              height: "100px",
              width: "100%",
            },
            "& .MuiInputBase-input": {
              alignItems: "flex-start",
              padding: "0px !important",
              height: "100px !important",
              width: "100%",
            },
            "& .MuiOutlinedInput-root": {
              alignItems: "flex-start",
              width: "100%",
              backgroundColor: "transparent",
              "& fieldset": {
                borderColor: "transparent", // default
              },
              "&:hover fieldset": {
                borderColor: "transparent", // hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // focus
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DoctorsNote;
