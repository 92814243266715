import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
  const token = localStorage.getItem("token"); // Check for token in localStorage

  if (!token) {
    // If no token, redirect to login page
    return <Navigate to="/" replace />;
  }

  // If token exists, allow access to the element
  return element;
};

export default PrivateRoute;
