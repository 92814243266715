import React, { useEffect, useState } from "react";

// third-party imports
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

// project imports
// import DailyMedication from "./DailyMedication";
// import Reminders from "../appointments/Appointments/Reminders";
import WelcomeCard from "./WelcomeCard";
import useAuthStore from "../../store/authStore";
import ContentCenter from "../shared/ContentCenter";
import SignedUpModal from "./SignedUpModal";
import useAppStore from "../../store/appStore";
import AllAppointments from "../appointments/Appointments/AllAppointments";
import { isPatient } from "../../utils/auth";
import AbhaCardDetails from "../profile/patient/AbhaCardDetails";

const PatientMonitor: React.FC = () => {
  // props & state values
  const { user, userRole } = useAuthStore();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [signedUpModalOpen, setSignedUpModalOpen] = useState<boolean>(false);

  console.log("in dashboard");

  const { isRedirectedFromSignUp, setIsRedirectedFromSignUp, isMenuCollapsed } =
    useAppStore();

  // callbacks & functions
  useEffect(() => {
    if (isRedirectedFromSignUp) {
      setSignedUpModalOpen(true);
    }
    return () => {
      setIsRedirectedFromSignUp(false);
    };
  }, [isRedirectedFromSignUp]);

  const handleCloseSignedUpModal = () => {
    setSignedUpModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
        }}
      >
        <Grid container spacing={2.5} sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={isPatient(userRole) ? (isMenuCollapsed ? 8 : 7) : 12}
          >
            <Box
              sx={{
                paddingTop: isMobile ? "18px" : "28px",
                paddingBottom: isMobile ? "18px" : "31px",
                paddingInline: isMobile ? "15px" : "20px",
                height: "100%",
              }}
            >
              {userRole !== "ADMIN" ? (
                <Grid container spacing={2.5}>
                  <Grid item xs={12}>
                    <WelcomeCard />
                  </Grid>

                  <Grid item container xs={12} spacing={2.5}>
                    <Grid item xs={12} xl={8}>
                      <AllAppointments from="dashboard" />
                    </Grid>
                    {/* <Grid item xs={12} xl={4} sx={{ marginTop: "21px" }}>
                  <DailyMedication />
                </Grid> */}
                  </Grid>
                </Grid>
              ) : (
                <ContentCenter>
                  <Typography className="no-data">
                    Work in progress. Coming soon.
                  </Typography>
                </ContentCenter>
              )}
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={4}>
          <Reminders />
        </Grid> */}
          {isPatient(userRole) && (
            <Grid item xs={12} md={isMenuCollapsed ? 4 : 5}>
              <AbhaCardDetails userId={user?.userId || ""} from="dashboard" />
            </Grid>
          )}
        </Grid>

        {/* showing modal if navigated from sign up page */}
        <SignedUpModal
          open={signedUpModalOpen}
          handleClose={handleCloseSignedUpModal}
        />
      </Box>
    </>
  );
};

export default PatientMonitor;
