import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { CommentModalProps } from "../../types/referrals";

const CommentModal: React.FC<CommentModalProps> = ({
  open,
  status,
  onClose,
}) => {
  const [comment, setComment] = useState<string>("");

  const handleClose = (save: boolean) => {
    if (save) {
      onClose(comment);
    } else {
      onClose(null);
    }
    setComment(""); // Reset comment after closing
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {status === "Accepted" ? "Accept Referral" : "Reject Referral"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Comments(If any)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="comments"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentModal;
