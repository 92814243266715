import React from "react";
import Form from "@rjsf/core";
import { DynamicFormProps } from "../../types/forms";

const DynamicForm = ({
  schema,
  uiSchema,
  validator,
  widgets,
  formData,
  onChange,
  onSubmit,
  templates,
  formContext,
  readOnly = false,
  ...props
}: DynamicFormProps) => {
  const handleChange = (event: any) => {
    // Only call onChange if not readOnly
    if (!readOnly && onChange) {
      onChange(event);
    }
  };

  const handleSubmit = (event: any) => {
    if (!readOnly && onSubmit) {
      onSubmit(event);
    }
  };
  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      widgets={widgets}
      onChange={handleChange}
      onSubmit={handleSubmit}
      formData={formData}
      templates={templates}
      formContext={formContext}
      readonly={readOnly}
      // onChange={onChange}
      // onSubmit={onSubmit}
      {...props}
    />
  );
};

export default DynamicForm;
