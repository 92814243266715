import React, { useState, useEffect } from "react";
import { fetchHospitals, deleteHospital } from "../../services/hospitalService";
import {
  Button,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditHospitalModal from "./AddEditHospitalModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Hospital } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import HospitalsList from "./HospitalsList";

const Hospitals: React.FC = () => {
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadHospitals();
  }, []);

  const loadHospitals = async () => {
    try {
      const response = await fetchHospitals();
      setHospitals(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching hospitals:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          minHeight: "100%",
          height: "100%",
          paddingInline: "20px",
          mb: 2,
        }}
      >
        <HospitalsList
          hospitals={hospitals}
          loading={loading}
          loadHospitals={loadHospitals}
        />
      </Box>
      <br></br>
    </>
  );
};

export default Hospitals;
