import React from "react";
import { Box, Typography } from "@mui/material";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { SignedUpModalProps } from "../../types/dashboard";

const SignedUpModal: React.FC<SignedUpModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      closeIcon
      onClose={handleClose}
      width={600} // Customize width if needed
      //   height={400}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Typography
          variant="h6"
          sx={{ textAlign: "center", fontWeight: "bold", marginBottom: "16px" }}
        >
          Thank you for registering with 22Neuro Clinic.
        </Typography>

        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "center",
            marginBottom: "32px",
          }}
        >
          Your request has been successfully submitted. Our team will reach out
          to you shortly to assist with booking your appointment.
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "center",
            marginBottom: "32px",
          }}
        >
          We appreciate your patience and look forward to serving you.
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          className="outlined-secondary-button"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            handleClose();
          }}
          sx={{
            textTransform: "none",
            width: 150,
            height: 48,
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default SignedUpModal;
