import React from "react";

function RightExtandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="24"
      fill="none"
      viewBox="0 0 13 24"
    >
      <path
        fill="#000"
        d="M.39 23.61c.52.52 1.363.52 1.883 0l8.848-8.835a3.986 3.986 0 00.002-5.641L2.373.39A1.333 1.333 0 00.49.39c-.52.519-.52 1.36 0 1.88l8.752 8.74c.52.52.52 1.361 0 1.88L.39 21.73c-.52.52-.52 1.361 0 1.88z"
      ></path>
    </svg>
  );
}

export default RightExtandIcon;
