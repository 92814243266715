import React, { useEffect, useState } from "react";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import CustomTabPanel from "./CustomTabPanel";
import Button from "../../ui/Button";
import useAuthStore from "../../../store/authStore";
import DownloadSvgIcon from "../../svg/DownloadIcon";
import Alert from "../../ui/Alert";
import Spinner from "../../ui/Spinner";
import Tooltip from "../../ui/Tooltip";
import { isDoctorOrAdmin, isPatient } from "../../../utils/auth";
import LoadingScreen from "../../shared/LoadingScreen";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import useDocumentStore from "../../../store/documentsStore";
import {
  downloadDocumentDoctorSummary,
  downloadDocumentPatientSummary,
  updateDoctorApproveStatus,
  updateDocumentFeedback,
} from "../../../services/documentsService";
import useAppStore from "../../../store/appStore";
import { ReportSummaryProps } from "../../../types/documents";
import { PATIENT } from "../../../utils/constants";
import TranscriptionViewer from "./TranscriptionViewer";
import PatientNotes from "./PatientNotes";
import SymptomAssessment from "./SymptomAssessment";
import PatientSummary from "./PatientSummary";
import DoctorSummary from "./DoctorSummary";
import DocuemtMetaData from "./DocuemtMetaData";
import DocumentFeedback from "./DocumentFeedback";

const ReportSummary: React.FC<ReportSummaryProps> = ({
  loading,
  metadata,
  doctorSummary,
  patientSummary,
  fileType,
  // fileName,
  id,
  isDoctorApproved,
  feedback,
  transcriptions,
  patientNote,
  symptomAssessment,
  from = "",
}) => {
  // props & state values
  console.log("fileType in sumary", fileType);
  const { showSnackbar } = useAppStore();
  const { user, userRole } = useAuthStore();
  const {
    reGenerateSummary,
    loadingReGenerateSummary,
    fetchDocumentDetails,
    updatedDoctorSummary,
    loadingDoctorSummary,
    errorDoctorSummary,
    cleanUpDoctorSummaryState,
    updatedPatientSummary,
    loadingPatientSummary,
    errorPatientSummary,
    cleanUpPatientSummaryState,
    updatedPatientNotes,
    loadingPatientNotes,
    errorPatientNotes,
    cleanUpPatientNotesState,
    updatedSymptomAssessment,
    loadingSymptomAssessment,
    errorSymptomAssessment,
    cleanUpSymptomAssessmentState,
    selectedDocumentId,
  } = useDocumentStore();

  const [value, setValue] = useState<number>(0);

  const [approveStatusLoading, setApproveStatusLoading] =
    useState<boolean>(false);

  const [downloadDoctorSummaryLoading, setDownloadDoctorSummaryLoading] =
    useState<boolean>(false);

  const [downloadPatientSummaryLoading, setDownloadPatientSummaryLoading] =
    useState<boolean>(false);

  const [doctorApproved, setDoctorApproved] =
    useState<boolean>(isDoctorApproved);

  const [_, setUpdatedFeedbackLoading] = useState<boolean>(false);

  const downloadPdfDisabled =
    (value === 0 &&
      (downloadDoctorSummaryLoading ||
        !doctorSummary ||
        doctorSummary === "")) ||
    (value === 1 &&
      (downloadPatientSummaryLoading ||
        !patientSummary ||
        patientSummary === ""));

  const showDownloadPdfIcon =
    (userRole === PATIENT && isDoctorApproved) ||
    (isDoctorOrAdmin(userRole) && (value === 0 || value === 1));

  // callbacks & functions
  const feebackUpdateHandler = async (id: string, feedback: string) => {
    setUpdatedFeedbackLoading(true);
    showSnackbar("Feedback is updating, please wait", "info");
    try {
      const response = await updateDocumentFeedback(id, feedback);
      if (response?.data) {
        showSnackbar("Feedback updated", "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
      }
      setUpdatedFeedbackLoading(false);
    } catch (error) {
      showSnackbar("Could not update feedback, please try again", "error");
      setUpdatedFeedbackLoading(false);
    }
  };

  const summaryStatusHandler = async (
    id: string,
    isDoctorApproved: boolean
  ) => {
    setApproveStatusLoading(true);
    const message = doctorApproved ? "Rejecting" : "Approving";
    showSnackbar(`${message} summary, please wait`, "info");
    try {
      const response = await updateDoctorApproveStatus(id, isDoctorApproved);
      if (response?.data) {
        const message = doctorApproved ? "Rejected" : "Approved";
        showSnackbar(`Summary ${message}`, "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
      }
      setApproveStatusLoading(false);
    } catch (error) {
      const message = doctorApproved ? "Reject" : "Approve";
      showSnackbar(`Could not ${message} summary, please try again`, "error");
      setApproveStatusLoading(false);
    }
  };

  const downloadHandler = (response: any) => {
    const contentDisposition = response?.headers["content-disposition"];
    const blobData = response?.data;
    const fileName = contentDisposition;
    const blob = blobData;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  const downloadDoctorSummaryPdf = async (id: string) => {
    setDownloadDoctorSummaryLoading(true);
    showSnackbar("Doctor summary is downloading, please wait", "info");
    try {
      const response = await downloadDocumentDoctorSummary(id);
      downloadHandler(response);

      showSnackbar("Doctor summary is downloaded", "success");
      setDownloadDoctorSummaryLoading(false);
    } catch (error) {
      showSnackbar(
        "Could not download doctor summary, please try again",
        "error"
      );
      setDownloadDoctorSummaryLoading(false);
    }
  };

  const downloadPatientSummaryPdf = async (id: string) => {
    setDownloadPatientSummaryLoading(true);
    showSnackbar("Patient summary is downloading, please wait", "info");
    try {
      const response = await downloadDocumentPatientSummary(id);
      downloadHandler(response);

      showSnackbar("Patient summary is downloaded", "success");
      setDownloadPatientSummaryLoading(false);
    } catch (error) {
      showSnackbar(
        "Could not download patient summary, please try again",
        "error"
      );
      setDownloadPatientSummaryLoading(false);
    }
  };

  useEffect(() => {
    setDoctorApproved(isDoctorApproved);
  }, [isDoctorApproved]);

  // side effetcs for doctor summary updation
  useEffect(() => {
    if (isDoctorOrAdmin(userRole)) {
      if (loadingDoctorSummary) {
        showSnackbar("Updating doctor summary", "info");
      }
      if (errorDoctorSummary) {
        showSnackbar(
          "Could not update doctor summary, please try again",
          "error"
        );
        cleanUpDoctorSummaryState();
      }
      if (updatedDoctorSummary) {
        showSnackbar("Doctor summary updated", "success");
        // fetchDocuments();
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
        cleanUpDoctorSummaryState();
      }
    }
  }, [
    selectedDocumentId,
    userRole,
    updatedDoctorSummary,
    loadingDoctorSummary,
    errorDoctorSummary,
  ]);

  // side effetcs for patient summary updation
  useEffect(() => {
    if (isDoctorOrAdmin(userRole)) {
      if (loadingPatientSummary) {
        showSnackbar("Updating patient summary", "info");
      }
      if (errorPatientSummary) {
        showSnackbar(
          "Could not update patient summary, please try again",
          "error"
        );
        cleanUpPatientSummaryState();
      }
      if (updatedPatientSummary) {
        showSnackbar("Patient summary updated", "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
        // fetchDocuments();
        cleanUpPatientSummaryState();
      }
    }
  }, [
    selectedDocumentId,
    userRole,
    updatedPatientSummary,
    loadingPatientSummary,
    errorPatientSummary,
  ]);

  // side effetcs for patient notes updation
  useEffect(() => {
    if (isDoctorOrAdmin(userRole)) {
      if (loadingPatientNotes) {
        showSnackbar("Updating patient notes", "info");
      }
      if (errorPatientNotes) {
        showSnackbar(
          "Could not update patient notes, please try again",
          "error"
        );
        cleanUpPatientNotesState();
      }
      if (updatedPatientNotes) {
        showSnackbar("Patient notes updated", "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
        // fetchDocuments();
        cleanUpPatientNotesState();
      }
    }
  }, [
    selectedDocumentId,
    userRole,
    updatedPatientNotes,
    loadingPatientNotes,
    errorPatientNotes,
  ]);

  // side effetcs for symptom assessment updation
  useEffect(() => {
    if (isDoctorOrAdmin(userRole)) {
      if (loadingSymptomAssessment) {
        showSnackbar("Updating symptom assessment", "info");
      }
      if (errorSymptomAssessment) {
        showSnackbar(
          "Could not update symptom assessment, please try again",
          "error"
        );
        cleanUpSymptomAssessmentState();
      }
      if (updatedSymptomAssessment) {
        showSnackbar("Symptom assessment updated", "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
        // fetchDocuments();
        cleanUpSymptomAssessmentState();
      }
    }
  }, [
    selectedDocumentId,
    userRole,
    updatedSymptomAssessment,
    loadingSymptomAssessment,
    errorSymptomAssessment,
  ]);

  const handleTabChange = (_: any, newValue: number) => {
    console.log("newValue in tab", newValue);
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // approve/reject patient summary
  const handleSummaryStatus = (satusValue: boolean) => {
    summaryStatusHandler(id, satusValue);
  };

  // doctor summary download as pdf
  const handleDownloadSummary = (id: string, value: number) => {
    if (value === 0) {
      downloadDoctorSummaryPdf(id);
    }
    if (value === 1) {
      downloadPatientSummaryPdf(id);
    }
  };

  const handleRegenerateSummary = (id: string) => {
    reGenerateSummary(id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "17px",
        paddingInline: "20px",
        borderRight:
          from === "conference"
            ? "none"
            : "1px solid var(--neuro-secondary_border)",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "19px",
          borderBottom: "1px dashed var(--neuro-secondary_border)",
          minHeight: "60px",
        }}
      >
        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "var(--neuro-black-text)",
            fontFamily: "Roboto Condensed",
          }}
        >
          Report Summary
        </Typography>

        {!loading && fileType !== "AUDIO" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {value === 1 && isDoctorOrAdmin(userRole) && (
              <Tooltip
                title={`${doctorApproved ? "Reject" : "Approve"} Patient Summary`}
                placement="top-start"
              >
                <Button
                  variant="contained"
                  className={`${doctorApproved ? "warning-button" : "success-button"}`}
                  size="small"
                  onClick={() => handleSummaryStatus(!doctorApproved)}
                  disabled={
                    approveStatusLoading ||
                    !patientSummary ||
                    patientSummary === ""
                  }
                >
                  {approveStatusLoading ? (
                    <Spinner size={23} />
                  ) : doctorApproved ? (
                    "Reject"
                  ) : (
                    "Approve"
                  )}
                </Button>
              </Tooltip>
            )}

            {user?.email === "venkatesh@racchabanda.com" && (
              <Tooltip title="Regenerate summary" placement="top">
                <IconButton
                  onClick={() => handleRegenerateSummary(id)}
                  disabled={loadingReGenerateSummary}
                >
                  <RestorePageIcon />
                </IconButton>
              </Tooltip>
            )}

            {showDownloadPdfIcon && (
              <Tooltip
                title={
                  value === 0
                    ? "Download doctor summary pdf"
                    : "Download patient summary pdf"
                }
                placement="top"
              >
                <IconButton
                  disabled={downloadPdfDisabled}
                  sx={{
                    "&.Mui-disabled > svg": {
                      opacity: 0.5,
                    },
                  }}
                  onClick={() => handleDownloadSummary(id, value)}
                >
                  <DownloadSvgIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "var(--neuro-white-text)",
          height: "calc(100% - 60px)",
        }}
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // overflow: "auto",
              position: "relative",
              height: "100%",
            }}
          >
            {/* tabs */}
            {isDoctorOrAdmin(userRole) && (
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "calc(100% - 50px)",
                  overflowX: "auto",
                  overflowY: "hidden",
                  height: "50px",
                  flexShrink: 0,
                }}
              >
                {fileType === "AUDIO" && (
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{ width: "max-content" }}
                  >
                    <Tab label="Transcription" {...a11yProps(0)} />
                    <Tab label="Patient Notes" {...a11yProps(1)} />
                    <Tab label="Symptom Assessment (ICMR)" {...a11yProps(2)} />
                  </Tabs>
                )}

                {fileType !== "AUDIO" && (
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{ width: "max-content" }}
                  >
                    <Tab label="Doctor Summary" {...a11yProps(0)} />
                    <Tab label="Patient Summary" {...a11yProps(1)} />
                    {metadata && metadata !== "" && (
                      <Tab label="Meta data" {...a11yProps(2)} />
                    )}
                    <Tab
                      label="Feedback"
                      {...a11yProps(metadata && metadata !== "" ? 3 : 2)}
                    />
                  </Tabs>
                )}
              </Box>
            )}

            {/* tabs content */}
            <Box
              sx={{
                flexGrow: 1,
                maxHeight: fileType === "AUDIO" ? "100%" : "calc(100% - 120px)",
                overflowY: "auto",
              }}
            >
              {isDoctorOrAdmin(userRole) && (
                <>
                  {/* Doctor Summary Tab */}
                  <CustomTabPanel value={value} index={0}>
                    {fileType !== "AUDIO" && (
                      <DoctorSummary summary={doctorSummary} id={id} />
                    )}

                    {fileType === "AUDIO" && (
                      <TranscriptionViewer data={transcriptions} />
                    )}
                  </CustomTabPanel>

                  {/* Patient Summary Tab */}
                  <CustomTabPanel value={value} index={1}>
                    {fileType !== "AUDIO" && (
                      <PatientSummary summary={patientSummary} id={id} />
                    )}

                    {fileType === "AUDIO" && (
                      <PatientNotes notes={patientNote} id={id} />
                    )}
                  </CustomTabPanel>

                  {/* Symptom Assessment (ICMR) tab for audio*/}
                  <CustomTabPanel value={value} index={2}>
                    {fileType === "AUDIO" && (
                      <SymptomAssessment
                        assessments={symptomAssessment}
                        id={id}
                      />
                    )}
                  </CustomTabPanel>

                  {/* Meta Data Tab */}
                  {metadata && metadata !== "" && fileType !== "AUDIO" && (
                    <CustomTabPanel value={value} index={2}>
                      <DocuemtMetaData metaData={metadata} />
                    </CustomTabPanel>
                  )}

                  {/* Feedback Tab */}
                  {fileType !== "AUDIO" && (
                    <CustomTabPanel
                      value={value}
                      index={metadata && metadata !== "" ? 3 : 2}
                    >
                      <DocumentFeedback
                        id={id}
                        feebackUpdateHandler={feebackUpdateHandler}
                        feedback={feedback}
                      />
                    </CustomTabPanel>
                  )}
                </>
              )}

              {isPatient(userRole) &&
                fileType !== "AUDIO" &&
                (isDoctorApproved ? (
                  <PatientSummary summary={patientSummary} id={id} isPatient />
                ) : (
                  <Alert
                    message="Not approved by doctor"
                    type="info"
                    sx={{ marginTop: "12px" }}
                  />
                ))}
            </Box>

            {/* summary generated using ai alert */}
            {isDoctorOrAdmin(userRole) &&
              (value === 0 || value === 1) &&
              fileType !== "AUDIO" && (
                <Box
                  sx={{
                    flexShrink: 0,
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1,
                    height: "70px",
                  }}
                >
                  <Alert
                    message="Content generated using AI. Please verify for accuracy"
                    type="info"
                    sx={{
                      height: "70px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </Box>
              )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReportSummary;
