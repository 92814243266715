import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import PatientsProfile from "./PatientsProfile";
import PatientDocuments from "./PatientDocuments";
import PatientForms from "./PatientForms";
import { capitalizeFirstLetter } from "../../utils/common";
import PatientAppointments from "./PatientAppointments";
import PatientAbhaDetails from "./PatientAbhaDetails";

const PatientTabs = ({
  selectedTab,
  selectedPatient,
  patientId,
  userId,
  patientName,
  patientEmail,
  patientContact,
  from = "",
}: {
  selectedTab: string;
  selectedPatient: any;
  patientId?: string;
  userId?: string;
  patientName?: string;
  patientEmail?: string;
  patientContact?: string;
  from?: string;
}) => {
  // props & state values
  const containerRef = useRef<HTMLDivElement | null>(null);
  console.log("selectedPatient in tabs", selectedPatient);
  console.log("selectedTab in tabs", selectedTab);

  // callbacks & functions
  useEffect(() => {
    // Scroll the container to the top when "Profile" tab is selected
    if (selectedTab === "Profile" && containerRef.current) {
      console.log("containerRef.current", containerRef.current);
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedTab]);

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          height: "calc(100% - 130px)",
          maxHeight: "calc(100% - 130px)",
          flexGrow: 1,
          overflow: "auto",
          padding: "10px",
        }}
      >
        {selectedTab === "Profile" && (
          <PatientsProfile patientId={patientId || selectedPatient?.id} />
        )}

        {selectedTab === "Documents" && (
          <PatientDocuments
            from={from}
            patientId={patientId || selectedPatient?.id}
          />
        )}

        {selectedTab === "Forms" && (
          <PatientForms
            patientName={
              patientName ||
              `${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""} ${capitalizeFirstLetter(selectedPatient?.user?.lastName || "") || ""}`
            }
            patientId={patientId || selectedPatient?.id}
          />
        )}

        {selectedTab === "Appointments" && (
          <PatientAppointments
            patientId={patientId || selectedPatient?.id}
            patientName={
              patientName ||
              `${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""} ${capitalizeFirstLetter(selectedPatient?.user?.lastName || "") || ""}`
            }
            patientEmail={patientEmail || selectedPatient?.user?.email || ""}
            patientContact={
              patientContact || selectedPatient?.user?.mobile || ""
            }
            from={from}
          />
        )}

        {selectedTab === "ABHA Details" && (
          <PatientAbhaDetails
            userId={userId || selectedPatient?.userId || ""}
          />
        )}
      </Box>
    </>
  );
};

export default PatientTabs;
