import * as React from "react";

interface VideoCallIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const VideoCallIcon = ({
  color = "black",
  height = 12,
  width = 18,
  ...props
}: VideoCallIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 4L15.5768 2.45392C16.3699 1.97803 16.7665 1.74009 17.0928 1.77051C17.3773 1.79703 17.6369 1.944 17.806 2.17433C18 2.43848 18 2.90095 18 3.8259V8.1741C18 9.099 18 9.5615 17.806 9.8257C17.6369 10.056 17.3773 10.203 17.0928 10.2295C16.7665 10.2599 16.3699 10.022 15.5768 9.5461L13 8M3.2 12H9.8C10.9201 12 11.4802 12 11.908 11.782C12.2843 11.5903 12.5903 11.2843 12.782 10.908C13 10.4802 13 9.9201 13 8.8V3.2C13 2.0799 13 1.51984 12.782 1.09202C12.5903 0.71569 12.2843 0.40973 11.908 0.21799C11.4802 -7.45058e-08 10.9201 0 9.8 0H3.2C2.0799 0 1.51984 -7.45058e-08 1.09202 0.21799C0.71569 0.40973 0.40973 0.71569 0.21799 1.09202C-7.45058e-08 1.51984 0 2.07989 0 3.2V8.8C0 9.9201 -7.45058e-08 10.4802 0.21799 10.908C0.40973 11.2843 0.71569 11.5903 1.09202 11.782C1.51984 12 2.07989 12 3.2 12Z"
      fill={color}
    />
  </svg>
);
export default VideoCallIcon;
