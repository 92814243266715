import { Box, Typography } from "@mui/material";
import React from "react";
import { PatientHeaderProps } from "../../../types/appointments";

const PatientHeader: React.FC<PatientHeaderProps> = ({ name }) => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-primary-seagreen)",
        height: "80px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "34px",
      }}
    >
      <Typography
        sx={{
          fontSize: "25px",
          fontWeight: 700,
          lineHeight: "30px",
          fontFamily: "Roboto Condensed",
          color: "var(--neuro-white-text)",
        }}
      >
        Patient Name: {name || ""}
      </Typography>
    </Box>
  );
};

export default PatientHeader;
