import React, { useEffect, useState } from "react";

// third-party imports
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Avatar,
  IconButton,
  Typography,
  Box,
  Popper,
  useMediaQuery,
  ClickAwayListener,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";

// project imports
import Button from "../ui/Button";
import DoYouKnowDisorderModal from "../appointments/NewAppointment/DoYouKnowDisorderModal";
import HeaderButton from "../ui/HeaderButton";
import useAuthStore from "../../store/authStore";
import {
  capitalizeFirstLetter,
  capitalizeWordsPreservingSpaces,
} from "../../utils/common";
import HelpSupportModal from "../shared/HelpSupportModal";
import Tooltip from "../ui/Tooltip";
import MenuIcon from "../svg/MenuIcon";
import useProfileStore from "../../store/profileStore";
import { isAdmin, isDoctor, isDoctorOrAdmin } from "../../utils/auth";
import useAppStore from "../../store/appStore";

const Header = () => {
  // props & state values
  const {
    profileDetails,
    getProfileDetails,
    userProfile,
    userFirstName,
    userLastName,
  } = useProfileStore();

  console.log("profileDetails in header", profileDetails);

  const { user, userRole, logout } = useAuthStore();

  const doctorId = user?.doctorId;

  const loggedInUserId = isDoctorOrAdmin(userRole) ? doctorId : user?.patientId;

  console.log("loggedInUserId", loggedInUserId);

  const { isMenuCollapsed, setIsMenuCollapsed, drawerOpen, setDrawerOpen } =
    useAppStore();

  const isMobile = useMediaQuery("(max-width:800px)");

  const [searchValue, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [disorderModalOpen, setDisorderModalOpen] = useState<boolean>(false);

  // const {logout} = useAuthStore()

  console.log("user", user);

  const isAppointmentPath = /^\/appointment\/[a-zA-Z0-9]{22,}$/.test(
    window?.location?.pathname
  );

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const profilePopperItems = [
    {
      text: "Personal Info",
      id: 1,
      lastItem: false,
    },
    // {
    //   text: "Payment History",
    //   id: 2,
    //   lastItem: false,
    // },
    {
      text: "Logout",
      id: 4,
      lastItem: true,
    },
  ];

  // callbacks & function values
  useEffect(() => {
    console.log("useEF 1");
    if (doctorId && isAdmin(userRole)) {
      getProfileDetails(doctorId, "ADMIN");
    } else if (doctorId && isDoctor(userRole)) {
      getProfileDetails(doctorId, "DOCTOR");
    } else {
      getProfileDetails();
    }
  }, [doctorId]);

  // useEffect(() => {
  //   console.log("useEF 2");

  //   if (profileDetails && profileDetails?.id === loggedInUserId) {
  //     console.log("useEF 2 in");

  //     console.log("profileDetails", profileDetails);
  //     const fetchedProfileImageUrl = profileDetails?.user?.profileImageUrl;
  //     if (fetchedProfileImageUrl) {
  //       setProfileUrl(fetchedProfileImageUrl);
  //       localStorage.setItem("profile_image", fetchedProfileImageUrl);
  //     }
  //   }
  // }, [profileDetails]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleProfilePopperItemClick = (item: string) => {
    if (item === "Logout") {
      setAnchorEl(null);
      logout();
      navigate("/");
      //window.location.reload();
    }
    if (item === "Personal Info") {
      setAnchorEl(null);
      navigate(`/profile/personal-info`, {
        state: {
          userId: user?.userId || "",
        },
      });
    }
  };

  const handleNewAppointment = () => {
    // setDisorderModalOpen(true);
    navigate("/specialties");
  };

  const handleCloseDisorderModal = () => {
    setDisorderModalOpen(false);
  };

  const handleMenuIconClick = () => {
    if (isMobile) {
      setDrawerOpen(true);
    } else {
      setIsMenuCollapsed(!isMenuCollapsed);
    }
  };

  const handleBack = () => {
    if (isAppointmentPath) {
      navigate("/appointments");
    } else {
      // if (!isMobile) {
      //   setIsMenuCollapsed((prev) => !prev);
      // }
      navigate("/documents");
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: isMobile ? 60 : 80,
          width: "100%",
          padding: "17px 20px 16px 20px",
          position: "sticky",
          top: 0,
          right: 0,
          background: isMobile
            ? "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(84, 184, 149, 1))"
            : "var(--neuro-white-text)",
          borderBottom: "1px solid var(--neuro-secondary_border)",
          zIndex: 1,
        }}
      >
        <Tooltip
          title={isMenuCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          placement="top"
        >
          <IconButton
            disableRipple
            sx={{
              marginRight: isMobile ? "10px" : "14px",
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={handleMenuIconClick}
          >
            {isMenuCollapsed ? (
              <MenuIcon
                color={
                  isMobile
                    ? "var(--neuro-white-text)"
                    : "var(--neuro-black-text)"
                }
              />
            ) : isMobile ? (
              <MenuIcon
                color={
                  isMobile
                    ? "var(--neuro-white-text)"
                    : "var(--neuro-black-text)"
                }
              />
            ) : (
              <ArrowBackIosNewIcon />
            )}
          </IconButton>
        </Tooltip>

        {(window?.location?.pathname === "/documents/view" ||
          isAppointmentPath) && (
          <Tooltip
            title={`Back to ${isAppointmentPath ? "appointments" : "documents"}`}
            placement="top"
          >
            <IconButton
              onClick={handleBack}
              disableRipple
              size="small"
              sx={{
                marginInline: isMobile ? "10px" : "14px",
                padding: 0,
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
            >
              <ArrowBackIcon
                sx={{
                  color: isMobile
                    ? "var(--neuro-white-text)"
                    : "var(--neuro-black-text)",
                }}
              />
            </IconButton>
          </Tooltip>
        )}

        {/* <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
          endIcon={<SearchIcon />}
        /> */}

        <Box flexGrow={1} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            marginRight: "39px",
          }}
        >
          {/*<HeaderButton startIcon={<LocalHospitalIcon />}> 
             Near by Clinics
          </HeaderButton>*/}
          {/* {userRole === "PATIENT" && (
            <HeaderButton
              onClick={handleNewAppointment}
              startIcon={<CalendarTodayIcon />}
            >
              New Appointment
            </HeaderButton>
          )} */}

          {/* <HelpSupportModal isButton={true} /> */}

          {/* Do you know your disorder modal */}
          <DoYouKnowDisorderModal
            open={disorderModalOpen}
            onClose={handleCloseDisorderModal}
            // width={700}
          />

          {/* <HeaderButton startIcon={<EmailIcon />}>Subscribe</HeaderButton> */}
        </Box>

        {/* will use later - commented for now */}
        {/* {isMobile && (
          <IconButton
            color="inherit"
            disableRipple
            sx={{
              marginRight: isMobile ? "22px" : "16px",
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            <AddIcon
              sx={{
                fontSize: "20px",
                color: isMobile
                  ? "var(--neuro-white-text)"
                  : "var(--neuro-black-text)",
              }}
            />
          </IconButton>
        )} */}

        {/* popper for add icon - have to make changes here */}
        {/* <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{
            marginTop: isMobile ? "11px !important" : "16px !important",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px 0px 20px 20px",
            zIndex: 199,
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              sx={{
                padding: "14px 18px 30px 16px",
                bgcolor: "rgba(255, 255, 255, 1)",
                borderRadius: "20px 0px 20px 20px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {profilePopperItems &&
                profilePopperItems.length > 0 &&
                profilePopperItems.map((item) => {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        paddingBottom: item?.lastItem ? 0 : "11px",
                        borderBottom: item?.lastItem
                          ? "none"
                          : "1px solid var(--neuro-secondary_border)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neuro-darkblue-text)",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "19.2px",
                          fontFamily: "Inter",
                          paddingLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleProfilePopperItemClick(item.text)}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </ClickAwayListener>
        </Popper> */}

        {/* will enable it later */}
        {/* <IconButton
          color="inherit"
          disableRipple
          sx={{
            marginRight: isMobile ? "22px" : "16px",
            padding: 0,
            backgroundColor: "transparent",
            "&:hover": { backgroundColor: "transparent" },
          }}
        >
          <NotificationsIcon
            sx={{
              fontSize: "20px",
              color: isMobile
                ? "var(--neuro-white-text)"
                : "var(--neuro-black-text)",
            }}
          />
        </IconButton> */}

        {!isMobile && (
          <Typography
            sx={{
              fontWeight: 700,
              color: isMobile
                ? "var(--neuro-white-text)"
                : "var(--neuro-black-text)",
            }}
          >
            {userRole === "DOCTOR" && "Dr."}{" "}
            {capitalizeWordsPreservingSpaces(userFirstName || "") || ""}{" "}
            {capitalizeWordsPreservingSpaces(userLastName || "") || ""}{" "}
            {/* {capitalizeWordsPreservingSpaces(user?.name || "") || ""}{" "} */}
            {userRole === "ADMIN" && "(Admin)"}
          </Typography>
        )}

        {!isMobile ? (
          <Button
            variant="outlined"
            className="outlined-secondary-button"
            sx={{
              width: 80,
              height: 46,
              marginLeft: isMobile ? 0 : "10px",
            }}
            onClick={handleClick}
            aria-describedby={id}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Avatar
                alt={
                  `${capitalizeWordsPreservingSpaces(user?.name || "")}` ||
                  "user-profile"
                }
                src={userProfile || undefined}
                sx={{ width: 38, height: 38 }}
              />
              <ExpandMoreIcon sx={{ color: "var(--neuro-darkgrey_border)" }} />
            </Box>
          </Button>
        ) : (
          <Box
            sx={{
              height: "36px",
              width: "36px",
              borderRadius: "50%",
              background: "var(--neuro-white-text)",
              border: "1px solid var(--neuro-button-bg-success)",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleClick}
            aria-describedby={id}
          >
            <Avatar
              alt={
                `${capitalizeWordsPreservingSpaces(user?.name || "")}` ||
                "user-profile"
              }
              src={userProfile || undefined}
              sx={{ width: 36, height: 36 }}
            />
          </Box>
        )}

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-end"
          sx={{
            marginTop: isMobile ? "11px !important" : "16px !important",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "20px 0px 20px 20px",
            zIndex: 199,
          }}
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box
              sx={{
                padding: "14px 18px 30px 16px",
                bgcolor: "rgba(255, 255, 255, 1)",
                borderRadius: "20px 0px 20px 20px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {profilePopperItems &&
                profilePopperItems.length > 0 &&
                profilePopperItems.map((item) => {
                  return (
                    <Box
                      key={item.id}
                      sx={{
                        paddingBottom: item?.lastItem ? 0 : "11px",
                        borderBottom: item?.lastItem
                          ? "none"
                          : "1px solid var(--neuro-secondary_border)",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neuro-darkblue-text)",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "19.2px",
                          fontFamily: "Inter",
                          paddingLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleProfilePopperItemClick(item.text)}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};

export default Header;
