import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import ComponentHeader from "../../shared/ComponentHeader";
import DocumentsSection from "./DocumentsSection";
import useAuthStore from "../../../store/authStore";
import Alert from "../../ui/Alert";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "../../svg/UploadIcon";
import UploadFileModal from "../view/UploadFileModal";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentsProps } from "../../../types/documents";

const Documents: React.FC<DocumentsProps> = ({ patientId }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { isMenuCollapsed, showSnackbar } = useAppStore();

  const [files, setFiles] = useState<File[]>([]);

  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);

  const { user, userRole } = useAuthStore();

  const categories = [
    { name: "OP Consultation", value: "OP Consultation" },
    { name: "Invoice", value: "Invoice" },
    { name: "Diagnostic Report", value: "Diagnostic Report" },
    { name: "Immunization Record", value: "Immunization Record" },
    { name: "Surgical Report", value: "Surgical Report" },
    { name: "Discharge Summary", value: "Discharge Summary" },
    { name: "Genetic Testing", value: "Genetic Testing" },
    { name: "Admission Summary", value: "Admission Summary" },
    { name: "Other", value: "Other" },
  ];

  const {
    documents,
    loadingDocuments,
    errorDocuments,
    fetchDocuments,
    loadingDeleteDocument,
    loadingUploadDocuments,
    errorUploadDocuments,
    uploadedDocuments,
    cleanUpUploadDocumentsState,
    cleanUpSelectedDocumentId,
  } = useDocumentStore();

  console.log("documents in index", documents);

  const alertMessage = loadingUploadDocuments ? (
    <Alert
      sx={{ width: "100%" }}
      message="Uploading documents, please wait"
      type="info"
    />
  ) : loadingDeleteDocument ? (
    <Alert
      sx={{ width: "100%" }}
      message="Deleting document, please wait"
      type="info"
    />
  ) : null;

  useEffect(() => {
    cleanUpSelectedDocumentId();
  }, []);

  useEffect(() => {
    if (patientId) {
      fetchDocuments(patientId);
    } else if (!patientId) {
      fetchDocuments();
    }
  }, [user?.userId, fetchDocuments]);

  useEffect(() => {
    if (loadingUploadDocuments) {
      // showSnackbar("Uploading documents, please wait", "info");
    } else if (errorUploadDocuments) {
      showSnackbar("Could not upload documents, please try again", "error");
      setFiles([]);
      cleanUpUploadDocumentsState();
    } else if (uploadedDocuments) {
      showSnackbar("Documents Uploaded", "success");
      setFiles([]);
      if (patientId) {
        fetchDocuments(patientId);
      } else if (!patientId) {
        fetchDocuments();
      }
      // fetchDocuments();
      cleanUpUploadDocumentsState();
    }
  }, [
    loadingUploadDocuments,
    errorUploadDocuments,
    uploadedDocuments,
    fetchDocuments,
  ]);

  const handleAddNew = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setOpenUploadModal(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        paddingBlock: patientId ? 0 : isMobile ? "10px" : "28px",
        paddingInline: patientId ? 0 : isMobile ? "15px" : "20px",
        height: "100%",
      }}
    >
      <ComponentHeader
        title={patientId ? "" : "Documents"}
        alert={alertMessage}
      >
        <Button
          variant="outlined"
          className="outlined-secondary-button"
          sx={{ width: 160, height: 46 }}
          startIcon={<UploadIcon />}
          // disabled={disabled}
          // onClick={handleClick}
          onClick={() => setOpenUploadModal(true)}
        >
          Upload
        </Button>
        {/* <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNew}
        >
          Add
        </Button> */}
      </ComponentHeader>

      <UploadFileModal
        open={openUploadModal}
        onClose={handleCancel}
        categories={categories}
        closeIcon
        patientId={patientId}
      />

      <DocumentsSection
        isMenuCollapsed={isMenuCollapsed}
        loading={loadingDocuments}
        error={errorDocuments}
        documents={documents}
        patientId={patientId}
      />
    </Box>
  );
};

export default Documents;
