import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Tabs, Tab, Grid } from "@mui/material";
import CustomTabPanel from "../documents/view/CustomTabPanel";
import { useParams } from "react-router-dom";
import { getPatientProfileApi } from "../../services/profileService";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { capitalizeFirstLetter } from "../../utils/common";

const GreenBorderBox = ({
  children,
}: {
  children: React.ReactElement | React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        borderLeft: "6px solid var(--neuro-button-bg-success)",
        height: "26px",
        paddingLeft: "7px",
      }}
    >
      {children}
    </Box>
  );
};

function PatientDetails() {
  // props & stat values
  const { id } = useParams();

  const styles = {
    textStyles: {
      fontFamily: "var(--neuro-font-family-roboto)",
      lineHeight: "120%",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [value, setValue] = useState(0);

  // callbacks & functions
  const getProfileDetails = async (id: string) => {
    try {
      const response = await getPatientProfileApi(id);
      setDetails(response.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProfileDetails(id);
    }
  }, [id]);

  function calculateAge(birthDateString: string): number {
    const birthDate = new Date(birthDateString);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  function calculateBirthYearFromAge(age: number): string {
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    return `01/01/${birthYear}`; // Assuming birthdate as January 1st for display
  }

  function formatDOB(dateString: string): string {
    const date = new Date(dateString);
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${month}/${day}/${year}`;
  }

  const getGender = (gender: string) => {
    switch (gender) {
      case "MALE":
        return "M";
      case "FEMALE":
        return "F";
      default:
        return "O";
    }
  };

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        padding: "29px 22px",
        background: "var(--neuro-bg-light-grey-secondary)",
      }}
    >
      <Typography
        sx={{
          fontSize: "var(--neuro-font-size-large-small)",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
        }}
      >
        View Details
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Doctor details tabs"
        sx={{ marginBottom: "12px" }}
      >
        <Tab label="Profile" />
        {/* <Tab label="Documents" /> */}
      </Tabs>

      <CustomTabPanel noPadding value={value} index={0}>
        {loading && <LoadingScreen />}

        {!loading && !details && (
          <ContentCenter>
            <Typography className="no-data">No details found</Typography>
          </ContentCenter>
        )}

        {!loading && details && (
          <Box
            sx={{
              display: "flex",
              backgroundColor: "var(--neuro-white-text)",
              borderRadius: "5px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "25px 29px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginRight: "95px",
                gap: "29px",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  width: 190,
                  height: 190,
                  border: "2px solid var(--neuro-button-bg-success)",
                  backgroundColor: "var(--neuro-secondary_border)",
                }}
                src={details?.user?.profileImageUrl || undefined}
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: "var(--neuro-font-size-micro-small)",
                    fontWeight: "var(--neuro-font-weight-medium)",
                    color: "var(--neuro-dark-grey-primary)",
                    fontFamily: "var(--neuro-font-family-inter)",
                  }}
                >
                  {details?.dateOfBirth && details?.dateOfBirth !== ""
                    ? `${calculateAge(details?.dateOfBirth)} y,` || ""
                    : details.age && details.age !== ""
                      ? `${details.age} y,` || ""
                      : ""}{" "}
                  {details?.gender && details?.gender !== ""
                    ? getGender(details?.gender)
                    : ""}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={3}>
              <Grid item container spacing={3.5} xs={12} sm={6}>
                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      First Name:{" "}
                      {capitalizeFirstLetter(details?.user?.firstName || "")}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Email: {details?.user?.email || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Height: {details?.height || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      City: {details?.city || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Zip code: {details?.zipCode || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>
              </Grid>

              <Grid item container spacing={3.5} xs={12} sm={6}>
                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Last Name:{" "}
                      {capitalizeFirstLetter(details?.user?.lastName || "")}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Mobile No: {details?.user?.mobile || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Date of Birth:{" "}
                      {details?.dateOfBirth && details?.dateOfBirth !== ""
                        ? formatDOB(details?.dateOfBirth) || ""
                        : details?.age && details?.age !== ""
                          ? calculateBirthYearFromAge(Number(details?.age)) ||
                            ""
                          : ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Weight: {details?.weight || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      State: {details?.state || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>

                <Grid item xs={12}>
                  <GreenBorderBox>
                    <Typography sx={styles.textStyles}>
                      Street address: {details?.streetAddress || ""}
                    </Typography>
                  </GreenBorderBox>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </CustomTabPanel>

      {/* <CustomTabPanel noPadding value={value} index={1}>
        <Typography sx={{ fontWeight: 500 }}>
          Documents content here...
        </Typography>
      </CustomTabPanel> */}
    </Box>
  );
}

export default PatientDetails;
