import { create } from "zustand";

// Define the interface for the combined store
interface AppStore {
  // States for loading
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;

  // States for layout
  isMenuCollapsed: boolean;
  setIsMenuCollapsed: (value: boolean) => void;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
  showHeader: boolean;
  setShowHeader: (value: boolean) => void;

  // States for modal and redirection
  isHelpModalOpen: boolean;
  setHelpModalOpen: (value: boolean) => void;
  isRedirectedFromSignUp: boolean;
  setIsRedirectedFromSignUp: (value: boolean) => void;

  // States for snackbar
  snackbarMessage: string;
  snackbarOpen: boolean;
  snackbarSeverity: "success" | "info" | "warning" | "error";
  showSnackbar: (
    message: string,
    severity?: "success" | "info" | "warning" | "error"
  ) => void;
  closeSnackbar: () => void;
}

// Create the Zustand store with all logic
const useAppStore = create<AppStore>((set) => ({
  // Initial states for app
  isLoading: false,
  setIsLoading: (value: boolean) => set({ isLoading: value }),

  // Initial states for layout
  isMenuCollapsed: false,
  setIsMenuCollapsed: (value: boolean) => set({ isMenuCollapsed: value }),
  drawerOpen: false,
  setDrawerOpen: (value: boolean) => set({ drawerOpen: value }),
  showSidebar: true,
  setShowSidebar: (value: boolean) => set({ showSidebar: value }),
  showHeader: true,
  setShowHeader: (value: boolean) => set({ showHeader: value }),

  // Initial states for help modal and redirection
  isHelpModalOpen: true,
  isRedirectedFromSignUp: false,
  setHelpModalOpen: (value: boolean) => set({ isHelpModalOpen: value }),
  setIsRedirectedFromSignUp: (value: boolean) =>
    set({ isRedirectedFromSignUp: value }),

  // Initial states for snackbar
  snackbarMessage: "",
  snackbarOpen: false,
  snackbarSeverity: "info",
  showSnackbar: (
    message: string,
    severity: "success" | "info" | "warning" | "error" = "info"
  ) =>
    set({
      snackbarMessage: message,
      snackbarOpen: true,
      snackbarSeverity: severity,
    }),
  closeSnackbar: () => set({ snackbarOpen: false }),
}));

export default useAppStore;
