import React, { useEffect, useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import ComponentHeader from "../../shared/ComponentHeader";
import DocumentsSection from "./DocumentsSection";
import useAuthStore from "../../../store/authStore";
import Alert from "../../ui/Alert";
import UploadIcon from "../../svg/UploadIcon";
import UploadFileModal from "../view/UploadFileModal";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentsProps } from "../../../types/documents";

const Documents: React.FC<DocumentsProps> = ({ patientId, from = "" }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const { isMenuCollapsed, showSnackbar } = useAppStore();
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const { user } = useAuthStore();

  const categories = [
    { name: "Prescription", value: "Prescription" },
    { name: "Billing Statement", value: "Billing Statement" },
    { name: "Medical Test Results", value: "Medical Test Results" },
    { name: "Vaccination Record", value: "Vaccination Record" },
    { name: "Surgery Summary Report", value: "Surgery Summary Report" },
    { name: "Hopsital Discharge Summary", value: "Hopsital Discharge Summary" },
    { name: "Genetic Test Report", value: "Genetic Test Report" },
    { name: "Hospital Admission Summary", value: "Hospital Admission Summary" },
    { name: "Additional Documents", value: "Additional Documents" },
  ];

  const {
    documents,
    loadingDocuments,
    errorDocuments,
    fetchDocuments,
    loadingDeleteDocument,
    loadingUploadDocuments,
    errorUploadDocuments,
    uploadedDocuments,
    cleanUpUploadDocumentsState,
    cleanUpSelectedDocumentId,
  } = useDocumentStore();

  const alertMessage = loadingUploadDocuments ? (
    <Alert
      sx={{ width: "100%" }}
      message="Uploading documents, please wait"
      type="info"
    />
  ) : loadingDeleteDocument ? (
    <Alert
      sx={{ width: "100%" }}
      message="Deleting document, please wait"
      type="info"
    />
  ) : null;

  useEffect(() => {
    cleanUpSelectedDocumentId();
  }, []);

  useEffect(() => {
    if (patientId) {
      fetchDocuments(patientId);
    } else if (!patientId) {
      fetchDocuments();
    }
  }, [user?.userId, fetchDocuments, patientId]);

  useEffect(() => {
    if (loadingUploadDocuments) {
      // showSnackbar("Uploading documents, please wait", "info");
    } else if (errorUploadDocuments) {
      showSnackbar("Could not upload documents, please try again", "error");
      cleanUpUploadDocumentsState();
    } else if (uploadedDocuments) {
      showSnackbar("Documents Uploaded", "success");
      if (patientId) {
        fetchDocuments(patientId);
      } else if (!patientId) {
        fetchDocuments();
      }
      // fetchDocuments();
      cleanUpUploadDocumentsState();
    }
  }, [
    loadingUploadDocuments,
    errorUploadDocuments,
    uploadedDocuments,
    fetchDocuments,
    patientId,
  ]);

  const handleCancel = () => {
    setOpenUploadModal(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        paddingBlock: patientId ? 0 : isMobile ? "10px" : "28px",
        paddingInline: patientId ? 0 : isMobile ? "15px" : "20px",
        height: "100%",
      }}
    >
      <ComponentHeader
        title={patientId ? "" : "Documents"}
        alert={alertMessage}
      >
        <Button
          variant="outlined"
          className="outlined-secondary-button"
          sx={{ width: 160, height: 46 }}
          startIcon={<UploadIcon />}
          onClick={() => setOpenUploadModal(true)}
        >
          Upload
        </Button>
      </ComponentHeader>

      <UploadFileModal
        open={openUploadModal}
        onClose={handleCancel}
        categories={categories}
        closeIcon
        patientId={patientId}
      />

      <DocumentsSection
        isMenuCollapsed={isMenuCollapsed}
        loading={loadingDocuments}
        error={errorDocuments}
        documents={documents}
        patientId={patientId}
        from={from}
      />
    </Box>
  );
};

export default Documents;
