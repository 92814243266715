import React, { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ViewerReportCard from "./ViewerReportCard";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import useAppStore from "../../../store/appStore";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentsProps } from "../../../types/documents";

const DocumentList: React.FC<DocumentsProps> = ({ patientId }) => {
  const { showSnackbar } = useAppStore();

  const {
    documentDeleted,
    loadingDeleteDocument,
    errorDeleteDocument,
    cleanUpDeleteDocumentState,
    documents,
    loadingDocuments,
    errorDocuments,
    fetchDocuments,
    setSelectedDocumentId,
  } = useDocumentStore();

  useEffect(() => {
    if (loadingDeleteDocument) {
      showSnackbar("Deleting document, please wait", "info");
    } else if (errorDeleteDocument) {
      showSnackbar("Could not delete document, please try again", "error");
      cleanUpDeleteDocumentState();
    } else if (documentDeleted) {
      showSnackbar("Document deleted", "success");
      if (patientId) {
        fetchDocuments(patientId);
      } else {
        fetchDocuments();
      }
      cleanUpDeleteDocumentState();
    }
  }, [loadingDeleteDocument, errorDeleteDocument, documentDeleted]);

  // Extracted styles to a variable
  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    paddingRight: 2.5,
    borderRight: "1px solid var(--neuro-secondary_border)",
    height: "100%",
    // overflowY: "auto",
  };

  const titleStyles = {
    fontSize: "25px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "var(--neuro-black-text)",
    fontFamily: "Roboto Condensed",
  };

  const listStyles = {
    display: "flex",
    flexDirection: "column",
    gap: 1.75,
    height: "calc(100% - 100px)",
    overflow: "auto",
  };

  return (
    <Box sx={containerStyles}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "19px",
          borderBottom: "1px dashed var(--neuro-secondary_border)",
          minHeight: "60px",
        }}
      >
        <Typography sx={titleStyles}>Uploaded Documents</Typography>
      </Box>
      <Box sx={listStyles}>
        <>
          {loadingDocuments && <LoadingScreen />}

          {errorDocuments && (
            <ContentCenter>
              <Typography className="no-data">Documents not found</Typography>
            </ContentCenter>
          )}

          {!loadingDocuments &&
            !errorDocuments &&
            documents &&
            documents.length === 0 && (
              <ContentCenter>
                <Typography className="no-data">Documents not found</Typography>
              </ContentCenter>
            )}

          {!loadingDocuments &&
            !errorDocuments &&
            documents &&
            documents.length > 0 &&
            documents.map((document) => (
              <ViewerReportCard
                key={document?.id}
                {...document}
                setSelectedDocumentId={setSelectedDocumentId}
                patientId={patientId}
              />
            ))}
        </>
      </Box>
    </Box>
  );
};

export default DocumentList;
