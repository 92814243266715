import { useEffect, useState } from "react";
import { Box, Typography, Avatar, Tabs, Tab, Grid } from "@mui/material";
import CustomTabPanel from "../documents/view/CustomTabPanel";
import { useParams } from "react-router-dom";
import { getDoctorProfileApi } from "../../services/profileService";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { capitalizeFirstLetter } from "../../utils/common";
import { specializationMapping } from "../../utils/appointments";

function DoctorDetails() {
  // props & stat values
  const { id } = useParams();

  const styles = {
    textStyles: {
      fontFamily: "var(--neuro-font-family-roboto)",
      lineHeight: "120%",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  const [value, setValue] = useState(0);
  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  console.log("doctor details", details);

  // callbacks & functions
  const getProfileDetails = async (id: string) => {
    try {
      const response = await getDoctorProfileApi(id);
      setDetails(response.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProfileDetails(id);
    }
  }, [id]);

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        padding: "29px 22px",
        background: "var(--neuro-bg-light-grey-secondary)",
      }}
    >
      <Typography
        sx={{
          fontSize: "var(--neuro-font-size-large-small)",
          fontFamily: "var(--neuro-font-family-roboto-condensed)",
        }}
      >
        View Details
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Doctor details tabs"
        sx={{ marginBottom: "12px" }}
      >
        <Tab label="General Details" />
        {/* <Tab label="Availability" />
        <Tab label="Certifications and Awards" /> */}
      </Tabs>

      <CustomTabPanel noPadding value={value} index={0}>
        {loading && <LoadingScreen />}

        {!loading && !details && (
          <ContentCenter>
            <Typography className="no-data">No details found</Typography>
          </ContentCenter>
        )}

        {!loading && details && (
          <Box
            sx={{
              display: "flex",
              backgroundColor: "var(--neuro-white-text)",
              borderRadius: "5px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "25px 29px",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "95px",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: 190,
                    height: 190,
                    border: "2px solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-secondary_border)",
                  }}
                  src={details?.user?.profileImageUrl || undefined}
                />
              </Box>

              <Grid container spacing={3}>
                <Grid item container spacing={3.5} xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">First Name:</span>{" "}
                      {capitalizeFirstLetter(details?.user?.firstName || "")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Email:</span>{" "}
                      {details?.user?.email || ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Clinc:</span>{" "}
                      {details?.defaultHospital?.name || ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Qualifications:</span>{" "}
                      {details?.qualifications || ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Spoken Languages:</span>{" "}
                      {details?.spokenLanguages &&
                      details?.spokenLanguages.length > 0
                        ? details?.spokenLanguages.map(
                            (language: string, index: number) => (
                              <span key={index}>
                                {language}
                                {index !== details?.spokenLanguages.length - 1
                                  ? ","
                                  : null}
                              </span>
                            )
                          )
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container spacing={3.5} xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Last Name:</span>
                      {capitalizeFirstLetter(details?.user?.lastName || "")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Mobile No:</span>{" "}
                      {details?.user?.mobile || ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Specialization:</span>{" "}
                      {details?.specializations &&
                      details?.specializations.length > 0
                        ? details?.specializations
                            .map(
                              (specialization: string) =>
                                specializationMapping[specialization] ||
                                specialization
                            )
                            .join(", ")
                        : ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">Years of Experience:</span>{" "}
                      {details?.yearsOfExperience || ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">
                        Online Consultation Fee:
                      </span>{" "}
                      {details?.onlineConsultationFee || ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={styles.textStyles}>
                      <span className="bold-text">
                        Inperson Consultation Fee:
                      </span>{" "}
                      {details?.inPersonConsultationFee || ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {details?.about && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">
                  About Dr.{" "}
                  {capitalizeFirstLetter(details?.user?.firstName || "")}{" "}
                  {capitalizeFirstLetter(details?.user?.lastName || "")}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {details?.about || ""}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </CustomTabPanel>

      {/* <CustomTabPanel noPadding value={value} index={1}>
        <Typography sx={{ fontWeight: 500 }}>
          Availability content here...
        </Typography>
      </CustomTabPanel>

      <CustomTabPanel noPadding value={value} index={2}>
        <Typography sx={{ fontWeight: 500 }}>
          Certifications and Awards content here...
        </Typography>
      </CustomTabPanel> */}
    </Box>
  );
}

export default DoctorDetails;
