import React, { useState } from "react";
import {
  deleteReferral,
  updateReferralStatus,
} from "../../services/referralService";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditReferralModal from "./AddEditReferralModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import CommentModal from "./CommentModal";
import { Referral } from "../../types/common";
import useAuthStore from "../../store/authStore"; // Assuming this is the correct path
import ComponentHeader from "../shared/ComponentHeader";
import ContentCenter from "../shared/ContentCenter";
import LoadingScreen from "../shared/LoadingScreen";
import { ReferralsListProps } from "../../types/referrals";
import { getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";

const ReferralsList: React.FC<ReferralsListProps> = ({
  referrals,
  loading,
  loadReferrals,
}) => {
  // props & state values
  console.log("referrals", referrals);
  const { userRole } = useAuthStore();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [commentStatus, setCommentStatus] = useState<"Accepted" | "Rejected">(
    "Accepted"
  );
  const [selectedReferral, setSelectedReferral] = useState<Referral | null>(
    null
  );
  const [deleteReferralId, setDeleteReferralId] = useState<string | null>(null);
  const [editReferral, setEditReferral] = useState<Referral | null>(null);
  const [activeTab, setActiveTab] = useState<
    "All" | "Pending" | "Accepted" | "Rejected"
  >("All");

  // callbacks & functions
  const handleAddNew = () => {
    setEditReferral(null);
    setDialogOpen(true);
  };

  const handleEdit = (referral: Referral) => {
    setEditReferral(referral);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteReferralId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteReferralId) {
      try {
        await deleteReferral(deleteReferralId);
        loadReferrals();
      } catch (error) {
        console.error("Error deleting referral:", error);
      }
    }
    setDeleteReferralId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadReferrals();
  };

  const handleTabChange = (
    _: any,
    newValue: "Pending" | "Accepted" | "Rejected"
  ) => {
    setActiveTab(newValue);
  };

  const handleCommentModalClose = async (comments: string | null) => {
    if (selectedReferral) {
      try {
        await updateReferralStatus(
          selectedReferral.id,
          commentStatus,
          comments
        );
        loadReferrals();
      } catch (error) {
        console.error(`Error updating referral to ${commentStatus}:`, error);
      }
    }
    setCommentModalOpen(false);
    setSelectedReferral(null);
  };

  const openCommentModal = (
    referral: Referral,
    status: "Accepted" | "Rejected"
  ) => {
    setSelectedReferral(referral);
    setCommentStatus(status);
    setCommentModalOpen(true);
  };

  const filteredReferrals = referrals?.filter(
    (referral) => referral.status === activeTab
  );

  const AllRefferalsTable = () => {
    return referrals?.length > 0 ? (
      <TableContainer component={Paper}>
        <Table>
          {
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Patient Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Mobile
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Address
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Urgency
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Referred To
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Referred By
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Registered On
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  {userRole === "ADMIN" &&
                  referrals.some((referral) => referral?.status === "Pending")
                    ? "Actions"
                    : "Comments"}
                </TableCell>
                {userRole === "ADMIN" &&
                  referrals.some(
                    (referral) => referral?.status === "Pending"
                  ) && (
                    <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                      Comments
                    </TableCell>
                  )}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {referrals?.map((referral) => (
              <TableRow key={referral.id} sx={{ height: "40px" }}>
                <TableCell>{`${referral.firstName || "-"} ${referral.lastName || ""}`}</TableCell>
                <TableCell>{referral.mobile || "-"}</TableCell>
                <TableCell>
                  {referral.city || "-"} {referral.state || ""}
                </TableCell>
                <TableCell>{referral.urgencyOfReferral || "-"}</TableCell>
                <TableCell>{`${referral.referralDoctorFirstName || "-"} ${referral.referralDoctorLastName || ""}`}</TableCell>
                <TableCell>
                  {referral?.doctor?.user?.firstName || "-"}{" "}
                  {referral?.doctor?.user?.lastName || ""}
                </TableCell>
                <TableCell>
                  {getFormattedDate(referral?.createdAt || "-")}
                </TableCell>
                <TableCell>
                  {userRole === "DOCTOR" && referral?.status === "Pending" && (
                    <Box display="flex" justifyContent="space-around">
                      <IconButton
                        onClick={() => handleEdit(referral)}
                        color="secondary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(referral.id)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}

                  {userRole === "DOCTOR" && referral?.status !== "Pending" && (
                    <>{referral?.comments || "-"}</>
                  )}

                  {userRole === "ADMIN" && referral?.status === "Pending" && (
                    <Box display="flex" gap={2.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="primary-button "
                        onClick={() => openCommentModal(referral, "Accepted")}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        className="warning-button"
                        onClick={() => openCommentModal(referral, "Rejected")}
                      >
                        Reject
                      </Button>
                    </Box>
                  )}

                  {userRole === "ADMIN" &&
                    referrals.some(
                      (referral) => referral?.status === "Pending"
                    ) &&
                    referral?.status !== "Pending" && <>-</>}

                  {userRole === "ADMIN" &&
                    !referrals.some(
                      (referral) => referral?.status === "Pending"
                    ) && <>{referral?.comments || "-"}</>}
                </TableCell>

                {userRole === "ADMIN" &&
                  referrals.some(
                    (referral) => referral?.status === "Pending"
                  ) && (
                    <TableCell>
                      {referral?.status === "Pending" ? (
                        <>-</>
                      ) : (
                        referral?.comments || "-"
                      )}
                    </TableCell>
                  )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <ContentCenter>
        <Typography className="no-data">No Referrals found</Typography>
      </ContentCenter>
    );
  };

  const FilteredRefferalsTable = () => {
    return filteredReferrals?.length > 0 ? (
      <TableContainer component={Paper}>
        <Table>
          {
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Patient Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Mobile
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Address
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Urgency
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Referred To
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Referred By
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Registered On
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  {userRole === "ADMIN" && activeTab === "Pending"
                    ? "Actions"
                    : "Comments"}
                </TableCell>
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {filteredReferrals?.map((referral) => (
              <TableRow key={referral.id} sx={{ height: "40px" }}>
                <TableCell>{`${referral.firstName || "-"} ${referral.lastName || ""}`}</TableCell>
                <TableCell>{referral.mobile || "-"}</TableCell>
                <TableCell>
                  {referral.city || "-"} {referral.state || ""}
                </TableCell>
                <TableCell>{referral.urgencyOfReferral || "-"}</TableCell>
                <TableCell>{`${referral.referralDoctorFirstName || "-"} ${referral.referralDoctorLastName || ""}`}</TableCell>
                <TableCell>
                  {referral?.doctor?.user?.firstName || "-"}{" "}
                  {referral?.doctor?.user?.lastName || ""}
                </TableCell>
                <TableCell>
                  {getFormattedDate(referral?.createdAt || "-")}
                </TableCell>
                <TableCell>
                  {userRole === "DOCTOR" && activeTab === "Pending" ? (
                    <Box display="flex" justifyContent="space-around">
                      <IconButton
                        onClick={() => handleEdit(referral)}
                        color="secondary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(referral.id)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : userRole === "ADMIN" && activeTab === "Pending" ? (
                    <Box display="flex" gap={2.5}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="primary-button "
                        onClick={() => openCommentModal(referral, "Accepted")}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        className="warning-button"
                        onClick={() => openCommentModal(referral, "Rejected")}
                      >
                        Reject
                      </Button>
                    </Box>
                  ) : (
                    <>{referral?.comments || "-"}</>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <ContentCenter>
        <Typography className="no-data">
          {activeTab === "Pending"
            ? "No Pending Referrals found"
            : activeTab === "Accepted"
              ? "No Accepted Referrals found"
              : "No Rejected Referrals found"}
        </Typography>
      </ContentCenter>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        minHeight: "100%",
        // paddingInline: "20px",
        // mb: 2,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        marginTop={"20px"}
      >
        <ComponentHeader title="Referral List" />
        {userRole === "DOCTOR" && (
          <Button
            variant="outlined"
            color="secondary"
            className="outlined-secondary-button"
            startIcon={<AddIcon />}
            onClick={handleAddNew}
          >
            Add New
          </Button>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ flexGrow: 1 }}>
          <Tab label="All" value="All" />
          <Tab label="Pending" value="Pending" />
          <Tab label="Accepted" value="Accepted" />
          <Tab label="Rejected" value="Rejected" />
        </Tabs>
      </Box>

      <Box sx={{ height: "calc(100% - 142px)" }}>
        {loading ? (
          <ContentCenter>
            <LoadingScreen />
          </ContentCenter>
        ) : activeTab === "All" ? (
          <AllRefferalsTable />
        ) : (
          <FilteredRefferalsTable />
        )}
      </Box>

      <AddEditReferralModal
        open={dialogOpen}
        onClose={handleCloseDialog}
        referral={editReferral}
      />

      <ConfirmDialog
        open={confirmOpen}
        title="Confirm Delete"
        message="Are you sure you want to delete this referral?"
        onClose={handleConfirmClose}
      />

      <CommentModal
        open={commentModalOpen}
        status={commentStatus}
        onClose={handleCommentModalClose}
      />
    </Box>
  );
};

export default ReferralsList;
