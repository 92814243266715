import { Box } from "@mui/material";
import React, { useState } from "react";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import { GeneralDetailsProps } from "../../../types/appointments";
import ComponentHeader from "../../shared/ComponentHeader";
import HorizontalTabs from "../../profile/patient/HorizontalTabs";
import PatientTabs from "../../patients/PatientTabs";
import useAppStore from "../../../store/appStore";

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ patientDetails }) => {
  // Props & state values
  const tabs = [
    "Profile",
    "Documents",
    "Appointments",
    "Forms",
    "ABHA Details",
  ];

  const [selectedTab, setSelectedTab] = useState<string>("Profile");
  const { setAppointmentConferenceView } = useAppStore();

  const selectedPatient = patientDetails;

  console.log("patientDetails", patientDetails);
  // callbacks & functions
  const onTabClick = (tab: string) => {
    if (tab === "Appointments") {
      setAppointmentConferenceView("List");
    }
    setSelectedTab(tab);
  };

  return (
    <Box
      sx={{
        paddingBottom: "19px",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
        marginBottom: "19px",
      }}
    >
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          height: "100%",
          paddingInline: "10px",
          paddingBlock: "15px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            width: "100%",
            overflowX: "auto",
          }}
        >
          <ComponentHeader
            title={`Patient / ${
              capitalizeWordsPreservingSpaces(patientDetails?.name || "") || ""
            } / ${selectedTab}`}
            isBreadcrumb
          />

          <HorizontalTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            onClick={onTabClick}
            from="meet"
          />
        </Box>

        <PatientTabs
          selectedPatient={selectedPatient}
          selectedTab={selectedTab}
          patientName={`${
            capitalizeWordsPreservingSpaces(patientDetails?.name || "") || ""
          }`}
          patientId={patientDetails?.id || ""}
          userId={patientDetails?.userId || ""}
          patientContact={patientDetails?.mobile || ""}
          patientEmail={patientDetails?.email || ""}
          from="meet"
        />
      </Box>
    </Box>
  );
};

export default GeneralDetails;
