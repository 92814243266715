import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";

import Input from "../ui/Input";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import OtpModal from "./OtpModal";
import { sendOtp, verifyOtp } from "../../services/authService";
import Checkbox from "../ui/Checkbox";
import axios from "axios";
import useAppStore from "../../store/appStore";
import { LoginFormInputs } from "../../types/auth";
import { loginSchema } from "../../validations/authValidation";

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      whatsappUpdates: false,
    },
  });

  const { showSnackbar } = useAppStore();

  const [loading, setLoading] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);

  const isMobile = useMediaQuery("(max-width:600px)");

  const whatsappUpdates = watch("whatsappUpdates");

  useEffect(() => {
    const savedWhatsappUpdates =
      localStorage.getItem("whatsappUpdates") === "true";
    setValue("whatsappUpdates", savedWhatsappUpdates);
  }, [setValue]);

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setLoading(true);
    try {
      if (!errors.whatsappUpdates) {
        localStorage.setItem(
          "whatsappUpdates",
          data.whatsappUpdates.toString()
        );
      }

      const lowerCaseEmail = data.emailOrPhone.toLowerCase();
      const payloadData = {
        emailOrPhone: lowerCaseEmail || "",
      };
      const result = await sendOtp(payloadData);

      setEmail(data.emailOrPhone);
      setOtpToken(result.otpToken);
      setOtpOpen(true);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        if (error?.response?.status === 403) {
          showSnackbar(
            "Your account has been deactivated. Please contact the administrator for assistance.",
            "error"
          );
        }
      } else {
        console.error("OTP request failed:", error);
      }
    }
    setLoading(false);
  };

  const onOtpSubmit = async (otp: string) => {
    setLoading(true);
    try {
      const result = await verifyOtp(email, otp, otpToken);
      login(result.accessToken, result.refreshToken);
      navigate("/dashboard");
      setOtpOpen(false);
    } catch (error: any) {
      console.error("OTP verification failed:", error.message);
    }
    setLoading(false);
  };

  const onCloseOtp = () => {
    setOtpOpen(false);
  };

  const loginFormStyles = {
    headingStyles: {
      fontWeight: 700,
      fontSize: "2rem",
      fontFamily: "Roboto Condensed",
      lineHeight: "2.8rem",
      color: isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      textAlign: isMobile ? "start" : "left",
      width: isMobile ? "100%" : "revert",
    },
    subHeadingStyles: {
      fontFamily: "Roboto Slab",
      fontWeight: 400,
      fontSize: "0.8rem",
      color: isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      textAlign: isMobile ? "start" : "left",
      marginTop: "0.5rem",
      width: isMobile ? "100%" : "revert",
    },
    greenLineSeperator: {
      width: "69px",
      height: "7px",
      backgroundColor: "var(--neuro-button-bg-success)",
      margin: isMobile ? "20px auto 20px" : "33px 0 40px",
    },
    whatsappSpan: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      fontFamily: "Roboto Condensed",
      color: "var(--neuro-button-bg-success) !important",
    },
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: isMobile ? 0 : 8.5,
          mb: isMobile ? 0 : 13.5,
          padding: 0,
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              height: "200px",
              width: "100%",
              background: "linear-gradient(135deg, #2581C4, #62B55A)",
              color: "white",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              marginBottom: "50px",
              justifyContent: "flex-end",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                paddingLeft: "25px",
                paddingBottom: "35px",
                // height: "100%",
              }}
            >
              <Typography sx={loginFormStyles.headingStyles}>
                Log In to
              </Typography>
              <Typography sx={loginFormStyles.headingStyles}>
                22Neuro Clinic
              </Typography>
              <Typography sx={loginFormStyles.subHeadingStyles}>
                Log In / Create account with 22Neuro Clinic
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Typography sx={loginFormStyles.headingStyles}>
              Log In / Create account with
            </Typography>
            <Typography sx={loginFormStyles.headingStyles}>
              22Neuro Clinic
            </Typography>
          </>
        )}

        {!isMobile && <Box sx={loginFormStyles.greenLineSeperator}></Box>}

        <Box sx={{ paddingInline: isMobile ? "20px" : 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginBottom: "9px",
            }}
          >
            <Input
              type="text"
              label="E-mail"
              className="input-primary"
              {...register("emailOrPhone")}
              error={!!errors.emailOrPhone}
              helperText={errors.emailOrPhone?.message}
            />
            <Typography
              sx={{
                color: "var(--neuro-darkgrey_border)",
                marginBottom: "23px",
              }}
            >
              An OTP will be sent to this email
            </Typography>
            <Checkbox
              label={
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontFamily: "Roboto Condensed",
                    color: "var(--neuro-black-text)",
                  }}
                >
                  Accept{" "}
                  <a
                    href="https://www.22neuro.com/terms-condition"
                    target="blank"
                    style={{ textDecoration: "underline" }}
                  >
                    terms and conditions
                  </a>{" "}
                </Typography>
              }
              className="checkbox-primary"
              checked={whatsappUpdates}
              {...register("whatsappUpdates")}
              error={!!errors.whatsappUpdates}
              helperText={errors.whatsappUpdates?.message}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={loading}
              className="success-button"
              sx={{
                mt: 5,
                mb: 6,
                textTransform: "none",
                width: isMobile ? "100%" : 200,
                height: 48,
              }}
            >
              {loading ? <Spinner /> : "Log In / Create Account"}
            </Button>
          </Box>
        </Box>
      </Box>

      <OtpModal
        email={email}
        otpToken={otpToken}
        open={otpOpen}
        onClose={onCloseOtp}
        width={650}
      />
    </>
  );
};

export default LoginForm;
