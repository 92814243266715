import { Box } from "@mui/material";
import React, { useState } from "react";
import Tab from "./Tab";

const FormsTabContent = () => {
  // props & state values
  const [selectedTab, setSelectedTab] = useState<string>("Patient");
  const tabs = ["Patient", "Doctor"];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "18px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          borderBottom: "1px solid var(--neuro-light-grey_border)",
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <React.Fragment key={index}>
              <Tab
                tab={tab}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default FormsTabContent;
