import axios from "axios";
import { RegistrationDataProps } from "../types/auth";

export const completeRegistration = async (
  registrationData: RegistrationDataProps
) => {
  try {
    const response = await axios.post(
      "/api/auth/complete-registration",
      registrationData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(
    //     error.response.data.message || "Unable to complete registration"
    //   );
    // }
    throw error;
  }
};
// Register a new user
export const register = async (userData: any) => {
  try {
    const response = await axios.post("/api/auth/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to register");
    }
    throw error;
  }
};

// Standard login with username and password (if needed)
export const login = async (userData: any) => {
  try {
    const response = await axios.post("/api/auth/login", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to login");
    }
    throw error;
  }
};

// Login by sending OTP to email or phone
export const sendOtp = async (data: any) => {
  try {
    const response = await axios.post("/api/auth/send-otp", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

// Verify OTP
export const verifyOtp = async (
  emailOrPhone: string,
  otp: string,
  otpToken: string,
  txnId?: any,
  userId?: any
) => {
  let data;

  if (txnId) {
    data = {
      emailOrMobileOrAadhaarOrAbhaNumber: emailOrPhone,
      otp,
      txnId,
      userId: userId,
    };
  } else {
    data = {
      emailOrMobileOrAadhaarOrAbhaNumber: emailOrPhone,
      otp,
      otpToken,
      userId: userId,
    };
  }
  const response = await axios.post("/api/auth/verify-otp", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data; // Return the data from the response
};

// Resend OTP
export const resendOtp = async (emailOrPhone: string) => {
  try {
    const response = await axios.post(
      "/api/auth/resend-otp",
      { emailOrMobileOrAadhaarOrAbhaNumber: emailOrPhone },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to resend OTP");
    // }
    throw error;
  }
};

// Refresh Token
export const refreshTokenAPI = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  return axios.post(`/api/token/refresh`, { token: refreshToken });
};

// send aadhar otp
export const sendAadharOtp = async (aadharNumber: any) => {
  try {
    const data = { aadhaar: aadharNumber };
    const response = await axios.post(
      "/api/abdm/send-otp/aadhar-linked-number",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

// verify aadhar otp
export const verifyAadharOtp = async (
  otp: string,
  txnId: string,
  mobileNumber: string,
  aadhaar: string,
  userId?: any
) => {
  const response = await axios.post(
    "/api/abdm/verify/create-abha",
    { otp, txnId, mobileNumber, aadhaar, userId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// send new-number otp
export const sendNewNumberOtp = async (txnId: string, mobileNumber: string) => {
  try {
    const response = await axios.post(
      "/api/abdm/send-otp/new-number",
      { txnId, mobileNumber },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

// verify new-number otp
export const verifyNewNumberOtp = async (txnId: string, otp: string) => {
  const response = await axios.post(
    "/api/abdm/verify-otp/new-number",
    { txnId, otp },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// get abha-address suggesstions
export const getAbhaAddressSuggestions = async (txnId: string) => {
  const response = await axios.get("/api/abdm/abha-address-suggestions", {
    headers: {
      "Content-Type": "application/json",
      transaction_id: txnId,
    },
  });
  return response.data; // Return the data from the response
};

// post abha-address
export const submitAbhaAddress = async (txnId: string, abhaAddress: string) => {
  const response = await axios.post(
    "/api/abdm/abha-address",
    { txnId, abhaAddress },
    {
      headers: {
        "Content-Type": "application/json",
        transaction_id: txnId,
      },
    }
  );
  return response.data; // Return the data from the response
};

// check abha for aadhar linked mobile
export const checkAbhaForMobile = async (mobile: string) => {
  const response = await axios.post(
    "/api/abdm/check-abha/aadhar-linked-number",
    { mobile },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// check abha for aadhar linked mobile
export const fetchAbhaDetails = async (userId: string) => {
  const response = await axios.get(`/api/abdm/abhaDetails/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data; // Return the data from the response
};

// login with abha or mobile or aadhar - abha apis
export const loginForAbha = async (value: string) => {
  const response = await axios.post(
    "/api/abdm/sendOtp",
    { mobileOrAadhaarOrAbhaNumber: value },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// verify otp for login with abha or mobile or aadhar - abha apis
export const verifyOtpForLoginAbha = async (
  value: string,
  txnId: string,
  otp: string
) => {
  const response = await axios.post(
    "/api/abdm/verifyOtp",
    { mobileOrAadhaarOrAbhaNumber: value, txnId, otp },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// Download abha card
// export const downloadAbha = async (data: any) => {
//   try {
//     const response = await axios.get("/api/abdm/download-abha-card", {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     // if (axios.isAxiosError(error) && error.response) {
//     //   throw new Error(error.response.data.message || "Unable to send OTP");
//     // }
//     throw error;
//   }
// };
