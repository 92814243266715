import { Box } from "@mui/material";
import React, { useState } from "react";
import ReportsTabContent from "./ReportsTabContent";
import Tab from "./Tab";
import FormsTabContent from "./FormsTabContent";
import { ReportsSectionProps } from "../../../types/appointments";

const ReportsSection: React.FC<ReportsSectionProps> = ({ reports }) => {
  // props & state values
  const [selectedTab, setSelectedTab] = useState<string>("Reports");
  const tabs = ["Reports", "Forms"];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        paddingBottom: "20px",
        marginBottom: "19px",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          borderBottom: "1px solid var(--neuro-light-grey_border)",
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <React.Fragment key={index}>
              <Tab
                tab={tab}
                selectedTab={selectedTab}
                disabled={tab === "Forms" ? true : false}
                setSelectedTab={setSelectedTab}
              />
            </React.Fragment>
          );
        })}
      </Box>

      {selectedTab === "Reports" && <ReportsTabContent reports={reports} />}

      {selectedTab === "Forms" && <FormsTabContent />}
    </Box>
  );
};

export default ReportsSection;
