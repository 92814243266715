import React from "react";
import Avatar from "@mui/material/Avatar";
import { UserAvatarProps } from "../../types/common";

const UserAvatar: React.FC<UserAvatarProps> = ({
  src,
  alt,
  sx,
  width = 70,
  height = 70,
}) => {
  return (
    <Avatar
      src={src || undefined} // Only render the src when it's valid
      alt={alt}
      sx={{
        width: width,
        height: height,
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        color: "var(--neuro-darkgrey_border)",
        border: "2px solid var(--neuro-button-bg-success)",
        ...sx,
      }}
    >
      {/* Fallback initials or text inside Avatar if image is missing */}
      {!src &&
        alt
          .split(" ")
          .map((word: any) => word[0])
          .join("")}
    </Avatar>
  );
};

export default UserAvatar;
