import { Box, Link, Typography } from "@mui/material";
import NationalHealthAuthorityLogo from "../../shared/NationalHealthAuthorityLogo";
import Button from "../../ui/Button";
import AadharModal from "../../auth/AadharModal";
import { useState } from "react";
import AddAbhaAccountModal from "./AddAbhaAccountModal";
import useAuthStore from "../../../store/authStore";
import { PATIENT } from "../../../utils/constants";

const NoAbhaDetails = () => {
  // Props & state values
  const [aadharModalOpen, setAadharModalOpen] = useState(false);
  const [addAbhaAccountModalOpen, setAddAbhaAccountModalOpen] = useState(false);
  const { userRole, user } = useAuthStore();

  // callbacks & functions
  const onCloseAadharModal = () => {
    setAadharModalOpen(false);
  };
  return (
    <Box
      sx={{
        width: "max-content",
        height: "max-content",
        display: "flex",
        flexDirection: "column",
        paddingBlock: "10px",
        paddingInline: "20px",
        border: "1px solid var(--neuro-secondary_border)",
        backgroundColor: "var(--neuro-white-text)",
        borderRadius: "10px",
        // alignItems: "center",
        // gap: "20px",
        // justifyContent: "center",
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "15px",
            lineHeight: "24px",
            letterSpacing: "0.5px",
            color: "#666666",
            width: "42%",
            textAlign: "center",
          }}
        >
          Ayushman Bharat Digital Mission
        </Typography>

        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#666666",
          }}
        >
          Approved By
        </Typography>
      </Box>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "14px",
            color: "#666666",
            width: "40%",
            textAlign: "center",
          }}
        >
          Creating India's Digital Health Ecosystem
        </Typography>

        <NationalHealthAuthorityLogo width={95} height={60} />
      </Box>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          className="success-gradient-button"
          sx={{ width: "max-content" }}
          disabled={userRole !== PATIENT}
          onClick={() => setAadharModalOpen(true)}
        >
          Create Account Using Aadhaar
        </Button>

        <Link
          sx={{
            width: "max-content",
            cursor: userRole !== PATIENT ? "not-allowed" : "pointer",
          }}
          className="universal-link"
          onClick={(event) => {
            if (userRole !== PATIENT) {
              event.stopPropagation();
            } else {
              setAddAbhaAccountModalOpen(true);
            }
          }}
        >
          Add Account Using Aadhaar
        </Link>
      </Box>

      {/* create abha account modal */}
      <AadharModal
        open={aadharModalOpen}
        onClose={onCloseAadharModal}
        userId={user?.userId}
      />

      {/* add abha account modal */}
      <AddAbhaAccountModal
        open={addAbhaAccountModalOpen}
        onClose={() => setAddAbhaAccountModalOpen(false)}
      />
    </Box>
  );
};

export default NoAbhaDetails;
