import React, { useMemo } from "react";

// third-party imports
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
} from "@mui/material";

// project imports
import HospitalMeetDetails from "./HospitalMeetDetails";
import Logo from "../../shared/Logo";
import OnlineMeetDetails from "./OnlineMeetDetails";
import useAuthStore from "../../../store/authStore";
import {
  calculateAge,
  getAppointmentStatus,
  getFullHospitalAddress,
  specializationMapping,
} from "../../../utils/appointments";
import { isAdmin, isDoctor, isPatient } from "../../../utils/auth";
import ComponentHeader from "../../shared/ComponentHeader";
import { capitalizeFirstLetter } from "../../../utils/common";
import { getFormattedDateTime } from "../../../utils/appointments";
import UserAvatar from "../../shared/UserAvatar";
import { CANCELLED, COMPLETED, ONLINE } from "../../../utils/constants";
import { AppointmentDetailsPropTypes } from "../../../types/appointments";

const AppointmentDetails: React.FC<AppointmentDetailsPropTypes> = ({
  appointmentDetails,
  patientId,
  from = "",
  usedIn = "",
}) => {
  // props & state values
  const {
    appointmentDate,
    appointmentCode,
    status,
    appointmentMode,
    patient,
    doctor,
    availableSlot,
    id,
  } = appointmentDetails;

  const formattedDate = useMemo(() => {
    if (!appointmentDate) return null;
    const DateTimeObject = getFormattedDateTime(appointmentDate);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    } else return "";
  }, [availableSlot]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { userRole } = useAuthStore();

  const patientName = `${capitalizeFirstLetter(appointmentDetails.patient?.user?.firstName || "")} ${capitalizeFirstLetter(appointmentDetails.patient?.user?.lastName || "")}`;

  const doctorName = `Dr. ${capitalizeFirstLetter(appointmentDetails.doctor?.user?.firstName || "")} ${capitalizeFirstLetter(appointmentDetails.doctor?.user?.lastName || "")}`;

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  // Common appointment status message
  const appointmentInfo = useMemo(() => {
    if (status === CANCELLED) return "Your appointment has been cancelled!";
    if (
      status === COMPLETED ||
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime) ===
        COMPLETED
    ) {
      return "Your appointment has been completed!";
    }
    return "Your appointment has been confirmed!";
  }, [status, availableSlot]);

  // Consultation message based on role and appointment status
  const consultationMessage = useMemo(() => {
    const isCompleted = status === COMPLETED || appointmentStatus === COMPLETED;
    const cancelledMsg = `Your consultation with ${isDoctor(userRole) ? patientName : doctorName} has been cancelled by you or by the ${isDoctor(userRole) ? "patient" : "doctor"}.`;
    const completedMsg = `Your consultation with ${isDoctor(userRole) ? patientName : doctorName} has been successfully completed.`;
    const scheduledMsg = isDoctor(userRole)
      ? `Your consultation with ${patientName} has been scheduled.`
      : `Your consultation with ${doctorName} has been scheduled.`;

    if (status === CANCELLED) return cancelledMsg;
    if (isCompleted)
      return `${completedMsg} ${isPatient(userRole) ? "The prescription has been sent." : "Thank you for your time."}`;
    return `${scheduledMsg} The meeting link will be shared with you 30 minutes before the consultation.`;
  }, [status, userRole, patientName, doctorName, availableSlot]);

  const userName =
    isAdmin(userRole) || isPatient(userRole) ? patientName : doctorName;

  const isPatientOrAdmin = isPatient(userRole) || isAdmin(userRole);

  return (
    <Box>
      {from !== "list" && (
        <>
          <ComponentHeader title="Appointment Details" />

          <Typography
            sx={{
              marginBottom: "12px",
              marginTop: "22px",
              color: "var(--neuro-black-text)",
              lineHeight: "120%",
            }}
          >
            {`${userName} ${appointmentInfo}`}
          </Typography>
        </>
      )}

      <Card
        sx={{
          padding:
            from === "list"
              ? "0px"
              : appointmentMode === "ONLINE"
                ? "25px 19px 200px 21px"
                : "25px 19px 24px 21px",
          borderRadius: from === "list" ? "0px 0px 12px 12px" : "12px",
          boxShadow: "none",
          border:
            from === "list"
              ? "none"
              : "1px solid var(--neuro-secondary_border)",
        }}
      >
        <CardContent sx={{ padding: "0px !important" }}>
          {from !== "list" && (
            <>
              <Grid container spacing={1.75} alignItems="center" sx={{ mb: 3 }}>
                <Grid item>
                  <UserAvatar
                    src={
                      isPatientOrAdmin
                        ? doctor?.user?.profileImageUrl
                        : patient?.user?.profileImageUrl || null
                    }
                    alt={
                      isPatientOrAdmin
                        ? `${capitalizeFirstLetter(doctor?.user?.firstName || "Doctor-profile")}` ||
                          "Doctor-profile"
                        : `${capitalizeFirstLetter(patient?.user?.firstName || "User-profile")}` ||
                          "User-profile"
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-micro)",
                      fontWeight: "var(--neuro-font-weight-semibold)",
                      fontFamily: "var(--neuro-font-family-inter)",
                      color: "var(--neuro-bg-darkblue-primary)",
                      lineHeight: "120%",
                    }}
                  >
                    {isPatientOrAdmin ? doctorName : patientName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-x-small)",
                      fontFamily: "var(--neuro-font-family-roboto-slab)",
                      color: "var(--neuro-button-bg-primary)",
                      lineHeight: "120%",
                    }}
                  >
                    {isPatientOrAdmin
                      ? doctor?.specializations &&
                        doctor?.specializations.length > 0
                        ? doctor?.specializations
                            .map(
                              (specialization: string) =>
                                specializationMapping[specialization] ||
                                specialization
                            )
                            .join(", ")
                        : ""
                      : `Gender: ${patient?.gender}` || ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "var(--neuro-font-size-x-small)",
                      fontFamily: "var(--neuro-font-family-roboto-slab)",
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                      marginTop: "12px",
                    }}
                  >
                    {isPatientOrAdmin
                      ? doctor?.yearsOfExperience
                        ? `${doctor?.yearsOfExperience} Years Experience`
                        : ""
                      : `Age: ${calculateAge(patient?.dateOfBirth || "")} Years`}
                  </Typography>
                </Grid>
                <Grid item>
                  {isPatientOrAdmin ? <Logo width={89} height={63} /> : ""}
                </Grid>
              </Grid>
            </>
          )}

          <Box
            sx={{
              borderTop:
                from !== "list"
                  ? "1px solid var(--neuro-secondary_border)"
                  : "none",
              borderBottom: "1px solid var(--neuro-secondary_border)",
              paddingTop: "16px",
              paddingLeft: "32px",
              paddingBottom: "8px",
              marginLeft: "-21px",
              marginRight: "-19px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    color: "var(--neuro-black-text)",
                    lineHeight: "120%",
                  }}
                >
                  Type:
                </Typography>
                <Typography
                  sx={{
                    color: "var(--neuro-black-text)",
                    lineHeight: "120%",
                    fontSize: "var(--neuro-font-size-medium-smallest)",
                    fontWeight: "var(--neuro-font-weight-bold)",
                    fontFamily: "var(--neuro-font-family-roboto-condensed)",
                  }}
                >
                  {appointmentMode
                    ? appointmentMode === ONLINE
                      ? "Online Consultation"
                      : "Clinic Visit"
                    : ""}{" "}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    paddingLeft: isMobile ? 0 : "28px",
                    borderLeft: isMobile
                      ? "none"
                      : "1px solid var(--neuro-secondary_border)",
                    marginBottom: isMobile ? 0 : "-8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                    }}
                  >
                    Appointment Date:
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                      lineHeight: "120%",
                      fontSize: "var(--neuro-font-size-medium-smallest)",
                      fontWeight: "var(--neuro-font-weight-bold)",
                      fontFamily: "var(--neuro-font-family-roboto-condensed)",
                    }}
                  >
                    {formattedDate ? formattedDate.date : ""}{" "}
                    {formattedDate ? formattedDate.time : ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              paddingLeft: appointmentMode === ONLINE ? "17px" : 0,
            }}
          >
            {appointmentMode === ONLINE ? (
              <OnlineMeetDetails
                appointmentCode={appointmentDetails?.appointmentCode}
                appointmentId={appointmentDetails?.id || ""}
                status={appointmentDetails?.status || ""}
                detailsInfo={consultationMessage || ""}
                availableSlot={appointmentDetails?.availableSlot}
                from={from}
                patientId={patientId}
                usedIn={usedIn}
              />
            ) : (
              <HospitalMeetDetails
                appointmentCode={appointmentCode}
                from={from}
                detailsInfo={consultationMessage || ""}
                appointmentId={id || ""}
                hospitalName={doctor?.defaultHospital?.name || ""}
                hospitalAddress={getFullHospitalAddress(
                  doctor?.defaultHospital
                )}
                phoneNumber={doctor?.defaultHospital?.contactNumber || ""}
                availableSlot={availableSlot}
                status={status || ""}
                patientId={patientId}
                usedIn={usedIn}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AppointmentDetails;
