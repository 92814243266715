import React from "react";
import { Box, Typography } from "@mui/material";
import { number } from "prop-types";
import { BadgeProps } from "../../types/ui";

const Badge: React.FC<BadgeProps> = ({
  label,
  top,
  width,
  left,
  color = "var(--neuro-white-text)",
  backgroundColor = "var(--neuro-secondary-light-error)",
}) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        color: color,
        padding: "2px 8px",
        display: "inline-block",
        position: "absolute",
        transform: "rotate(-45deg)",
        transformOrigin: "top left",
        top: top || 132,
        left: left || -81,
        height: "32px",
        width: width || "100%",
        textAlign: "center",
        zIndex: 1,
        overflow: "hidden",

        margin: 0,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: "1.2rem",
        lineHeight: 1.43,
        letterSpacing: "0.3em",
        verticalAlign: "middle",
        // margin: "auto",
      }}
    >
      <Typography variant="body2" component="span">
        {label}
      </Typography>
    </Box>
  );
};

export default Badge;
