import React, { useEffect, useState } from "react";

// third-party imports
import {
  Box,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useAppStore from "../../store/appStore";
import { SidebarMenuItemProps } from "../../types/ui";

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
  text,
  icon,
  route,
  selected = false,
  sx = {},
  hasSubmenus = false,
  submenus = [],
  initiallyOpen = false,
}) => {
  // props & state values
  const navigate = useNavigate();

  const { setDrawerOpen, drawerOpen } = useAppStore();

  const isMobile = useMediaQuery("(max-width:800px)");

  const [open, setOpen] = useState(initiallyOpen);

  // callbacks & functions
  const handleListItemClick = (route: string) => {
    navigate(`/${route}`);
    if (isMobile) {
      setDrawerOpen(!drawerOpen);
    }
  };

  useEffect(() => {
    if (initiallyOpen && hasSubmenus) {
      setOpen(true);
    }
  }, [initiallyOpen, hasSubmenus]);

  return (
    <>
      <ListItem
        button
        onClick={() => handleListItemClick(route)}
        sx={{
          color: selected
            ? "var(--neuro-white-text)"
            : "var(--neuro-black-text)",
          background: selected
            ? "linear-gradient(90deg, #54B895 0%, #62B55A 100%)"
            : "transparent",
          borderRadius: "100px",
          // margin: '5px 10px',
          "&:hover": {
            backgroundColor: selected ? "#3CB371" : "#f0f0f0",
          },
          padding: "5px 6px",
          width: "auto",
          marginBottom: "9px",
          marginInline: "20px",
          ...sx,
        }}
      >
        <Box display="flex" alignItems="center">
          <ListItemIcon
            sx={{
              color: selected
                ? "var(--neuro-black-text)"
                : "var(--neuro-white-text)",
              minWidth: "36px",
              height: "36px",
              width: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: selected
                ? "var(--neuro-white-text)"
                : "var(--neuro-bg-primary-seagreen)",
              borderRadius: "50%",
              padding: "11px",
              marginRight: selected ? "14px" : "12px",
              "&>svg": {
                width: "18px",
                height: "18px",
              },
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={text}
            sx={{
              "& .MuiTypography-root": {
                fontSize: "16px",
                fontWeight: selected ? "600" : "400",
              },
            }}
          />
        </Box>
        {hasSubmenus && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              if (hasSubmenus) {
                setOpen(!open);
              }
            }}
            sx={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              width: 40,
              height: 40,
              // "& >svg": {
              //   width: 20,
              //   height: 20,
              // },
            }}
          >
            {open ? (
              <KeyboardArrowDownIcon
                sx={{ fontSize: "24px", color: "black" }}
              />
            ) : (
              <KeyboardArrowRightIcon
                sx={{ fontSize: "24px", color: "black" }}
              />
            )}
          </IconButton>
        )}
      </ListItem>
      {hasSubmenus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {submenus.map((submenu, subIndex) => (
            <SidebarMenuItem
              key={subIndex}
              text={submenu.text}
              icon={submenu.icon}
              route={submenu.route}
              selected={submenu.selected}
              sx={{ paddingLeft: "32px" }}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default SidebarMenuItem;
