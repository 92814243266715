import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Logo from "../shared/Logo";
import Badge from "../ui/Badge";
import config from "../../config";
import HelpSupportModal from "../shared/HelpSupportModal";
import AssistantIcon from "../svg/AssistantIcon";

const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const authLayouStyles = {
    welcomeText: {
      fontWeight: 700,
      fontSize: "39px",
      fontFamily: "Roboto Condensed",
      lineHeight: "46.8px",
      color: "var(--neuro-bg-darkblue-primary)",
    },
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Grid container sx={{ height: "100vh" }}>
      {!isMobile && (
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            borderLeft: "60px solid var(--neuro-button-bg-success)",
            position: "relative",
            overflow: "hidden",
          }}
          className="bg-primary h-screen"
        >
          <Badge
            label={config?.VERSION || ""}
            top={86}
            left={-36}
            width={150}
          />

          {/* {!isMobile && ( */}
          <Box
            sx={{
              paddingInline: "77px",
              paddingTop: "68px",
              display: "flex",
              flexDirection: "column",
              // gap: "199px",
              gap: "109px",
              height: "100%",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "20px",
              }}
            > */}
            <Logo width={172} height={122} />
            {/* <HelpSupportModal /> */}
            {/* </Box> */}
            <Box
              sx={{
                paddingBlock: "20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                background: "var(--neuro-white-text)",
                borderRadius: "10px",
              }}
            >
              <AssistantIcon />
              <Typography
                sx={{
                  fontSize: "39px",
                  fontWeight: 700,
                  lineHeight: "46.8px",
                  fontFamily: "Roboto Condensed",
                  textAlign: "start",
                  marginTop: "19px",
                  marginBottom: "22px",
                  color: "var(--neuro-black-text)",
                }}
              >
                Need Assistance ?
              </Typography>
              <HelpSupportModal from="login" />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={authLayouStyles.welcomeText}>
                Welcome to
              </Typography>
              <Typography sx={authLayouStyles.welcomeText}>
                22Neuro Clinic
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "calc(100% - 77px)",
                marginBottom: "20px",
              }}
            >
              <HelpSupportModal />
            </Box> */}
          </Box>
          {/* // )} */}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={7}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="form-background"
      >
        {!isMobile ? (
          <Container maxWidth="sm">{children}</Container>
        ) : (
          <Box sx={{ height: "100%", width: "100%" }}>{children}</Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
