import React, { useState, useEffect } from "react";

// third-party imports
import { parseISO, format } from "date-fns";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";

// project imports
import DownExpandIcon from "../../svg/DownExpandIcon";
import Button from "../../ui/Button";
import RightExtandIcon from "../../svg/RightExtandIcon";
import LeftExtandIcon from "../../svg/LeftExtandIcon";
import {
  AppointmentSchedulerCalendarProps,
  DayProps,
} from "../../../types/appointments";

const Day: React.FC<DayProps> = ({ day, date, month, isActive, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: "20px",
        paddingBlock: "20px",
        paddingInline: "15px",
        marginRight: "20px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        },
      }}
    >
      <Typography
        sx={{
          color: isActive
            ? "var(--neuro-bg-primary-seagreen)"
            : "var(--neuro-darkgrey_border)",
          fontSize: "var(--neuro-font-size-micro)",
          lineHeight: "120%",
          fontFamily: "var(--neuro-font-family-inter)",
        }}
      >
        {day}
      </Typography>
      <Typography
        sx={{
          fontWeight: 700,
          color: isActive
            ? "var(--neuro-button-bg-success)"
            : "var(--neuro-black-text)",
          fontSize: "36px",
          lineHeight: "120%",
          fontFamily: "Inter",
        }}
      >
        {date}
      </Typography>
    </Box>
  );
};

const Calendar: React.FC<AppointmentSchedulerCalendarProps> = ({
  selectedDate,
  onDateSelect,
}) => {
  // props & state values
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [monthChangeCount, setMonthCountChange] = useState<number>(0);
  const [visibleDays, setVisibleDays] = useState<Date[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // callbacks & functions
  useEffect(() => {
    updateVisibleDays(currentMonth);
  }, [monthChangeCount]);

  // const getSelectedFormattedDateTime = (dateString: string) => {
  //   const date = new Date(dateString);

  //   const formattedDate = format(date, "dd MMMM yyyy");

  //   const formattedTime = format(date, "h:mma").toLowerCase();
  //   return {
  //     formattedDate,
  //     formattedTime,
  //   };
  // };

  // const SelectedFormattedDateTime = getSelectedFormattedDateTime(
  //   `${selectedDate}`
  // );

  // const getFormattedDateTime = (dateString: string) => {
  //   const date = parseISO(dateString);

  //   const formattedDate = format(date, "dd MMMM yyyy");
  //   const formattedTime = format(date, "h:mma").toLowerCase();
  //   return {

  //   }
  //  };

  // const FOrmattedDateTime = getFormattedDateTime("2024-08-10T15:00:00.000Z");

  const updateVisibleDays = (date: Date) => {
    const days: Date[] = [];
    const startDate = new Date(date);

    // Adjust startDate to the current day if it is in the past
    if (startDate < new Date()) {
      startDate.setDate(new Date().getDate());
    }

    for (let i = 0; i < 7; i++) {
      days.push(
        new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        )
      );
    }

    setVisibleDays(days);
    setCurrentMonth(days[3]); // Update current month for display purposes
  };

  const handlePrevWeek = () => {
    const newDate = new Date(visibleDays[0]);
    newDate?.setDate(newDate?.getDate() - 7);
    updateVisibleDays(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(visibleDays[6]);
    newDate?.setDate(newDate?.getDate() + 1);
    updateVisibleDays(newDate);
  };

  const handleMonthClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMonthClose = () => {
    setAnchorEl(null);
  };

  const handleMonthSelect = (month: number, year: number) => {
    setCurrentMonth(new Date(year, month, 1));
    setMonthCountChange(monthChangeCount + 1);
    handleMonthClose();
  };

  const handleBackToToday = () => {
    setCurrentMonth(new Date());
    setMonthCountChange(monthChangeCount + 1);
  };
  const getMonthOptions = () => {
    const now = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const monthIndex = (now.getMonth() + i) % 12;
      const year = now.getFullYear() + Math.floor((now.getMonth() + i) / 12);
      months.push({
        index: monthIndex,
        year: year,
        label: new Date(year, monthIndex, 1).toLocaleString("default", {
          month: "long",
        }),
      });
    }

    return months;
  };

  return (
    <Box sx={{ marginBottom: "32px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "18px",
        }}
      >
        <Button
          variant="outlined"
          id="basic-button"
          className="outlined-secondary-button"
          onClick={handleMonthClick}
          aria-controls={open ? "basic-menu" : undefined}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 160,
            height: 46,
          }}
        >
          {currentMonth?.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
          <DownExpandIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root": {
              width: 150,
              borderRadius: "10px",
              //   "&::-webkit-scrollbar": {
              //     width: "4px",
              //   },
              //   "&::-webkit-scrollbar-track": {
              //     "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
              //   },
              //   "&::-webkit-scrollbar-thumb": {
              //     backgroundColor: "red",
              //     // outline: '1px solid slategrey'
              //   },
            },
          }}
          onClose={handleMonthClose}
        >
          {getMonthOptions().map(({ index, year, label }) => (
            <MenuItem
              key={`${index}-${year}`}
              onClick={() => handleMonthSelect(index, year)}
            >
              {label} {year}
            </MenuItem>
          ))}
        </Menu>
        <Button
          variant="text"
          className="secondary-text-button"
          disableRipple
          onClick={handleBackToToday}
        >
          Back to Today
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: "var(--neuro-secondary_border)",
          backgroundColor: "var(--neuro-white-text)",
          paddingInline: "20px",
        }}
      >
        <IconButton
          disableRipple
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            marginRight: "10px",
            "&: hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handlePrevWeek}
        >
          <LeftExtandIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            overflowX: "auto",
            // gap: "20px",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {visibleDays.map((day, index) => (
            <React.Fragment key={index}>
              {index > 0 && day.getDate() === 1 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "10px",
                    marginLeft: "-20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "inline-block",
                      position: "relative",
                      backgroundColor: "var(--neuro-button-bg-primary)",
                      color: "var(--neuro-white-text)",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      paddingBlock: "3px",
                      "&:after": {
                        content: '""',
                        position: "absolute",
                        right: "-14px",
                        top: "-2px",
                        bottom: "0",
                        width: "0",
                        height: "0",
                        borderStyle: "solid",
                        borderWidth: "12px 0 12px 15px",
                        borderColor: `transparent transparent transparent var(--neuro-button-bg-primary)`,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--neuro-white-text)",
                        fontSize: "13px",
                        lineHeight: "120%",
                      }}
                    >
                      {new Date(
                        day?.getFullYear(),
                        day?.getMonth(),
                        1
                      ).toLocaleString("default", { month: "long" })}
                    </Typography>
                  </Box>
                  <Divider
                    orientation="vertical"
                    sx={{
                      backgroundColor: "var(--neuro-secondary_border)",
                      height: "83px",
                      color: "var(--neuro-secondary_border)",
                    }}
                  />
                </Box>
              )}
              <Day
                day={day
                  ?.toLocaleString("default", { weekday: "short" })
                  ?.toUpperCase()}
                date={day.getDate()?.toString()}
                month={day.toLocaleString("default", { month: "short" })}
                isActive={selectedDate?.toDateString() === day?.toDateString()}
                onClick={() => onDateSelect(day)}
              />
            </React.Fragment>
          ))}
        </Box>
        <IconButton
          disableRipple
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            "&: hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handleNextWeek}
        >
          <RightExtandIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Calendar;
