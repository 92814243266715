import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { CustomStepperProps } from "../../types/forms";

const CustomStepper = ({
  steps,
  activeStep,
  onStepChange,
  stepCompleted,
  readOnly = false,
}: CustomStepperProps) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "26px",
        marginBottom: "34px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: isMobile ? "15px" : "30px",
          overflowX: "auto",
        }}
      >
        {steps.map((step: any, index: number) => (
          <Box
            key={index}
            onClick={() => {
              // if (
              //   stepCompleted[index + 1] ||
              //   stepCompleted[index - 1] ||
              //   stepCompleted[index] ||
              //   index === activeStep
              // ) {
              //   onStepChange(index);
              // }
              if (
                readOnly ||
                index === activeStep ||
                stepCompleted[index + 1] ||
                stepCompleted[index - 1] ||
                stepCompleted[index]
              ) {
                onStepChange(index);
              }
            }}
            sx={{
              width: "100px",
              height: "10px",
              borderRadius: "2px",
              cursor:
                readOnly ||
                stepCompleted[index + 1] ||
                stepCompleted[index - 1] ||
                stepCompleted[index] ||
                index === activeStep
                  ? "pointer"
                  : "not-allowed",
              backgroundColor:
                index === activeStep
                  ? "var(--neuro-button-bg-success)"
                  : stepCompleted[index + 1] ||
                      stepCompleted[index - 1] ||
                      stepCompleted[index]
                    ? "var(--neuro-button-bg-secondary)"
                    : "var(--neuro-light-grey_border)",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CustomStepper;
