import React, { ChangeEvent, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Add as AddIcon } from "@mui/icons-material";
import TabletIcon from "../../svg/TabletIcon";
import InjectionIcon from "../../svg/InjectionIcon";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import OintmentsIcon from "../../svg/OintmentsIcon";
import CapsuleIcon from "../../svg/CapsuleIcon";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import MorningIcon from "../../svg/MorningIcon";
import AfternoonIcon from "../../svg/AfternoonIcon";
import NightIcon from "../../svg/NightIcon";
import useAppointmentStore from "../../../store/appointmentsStore";
import { MedicationProps, Medicine } from "../../../types/appointments";

const initialMedicine = {
  medication: "",
  medicationType: "",
  dosageAmount: "",
  dosageUnit: "",
  frequency: "",
  durationAmount: "",
  durationUnit: "",
};

const Medication: React.FC<MedicationProps> = ({ medications }) => {
  // props & state values
  const { medicines, setMedicines } = useAppointmentStore();

  const style = {
    fontSize: "14px",
    fontFamily: "Inter",
  };

  const medicationTypeSelectStyle = {
    bgcolor: "var(--neuro-login-form-bg-primary)",
    borderRadius: "10px",
    minWidth: "55px",
    width: "55px",
    height: "40px",
    maxHeight: "40px",
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        fontSize: "12px",
        display: "flex",
        paddingLeft: "8px",
      },
    // minWidth: "82px",
    // width: "82px",
    "& >.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
      {
        height: "17px",
        minHeight: "17px",
      },
    "& >.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "11.5px 14px 11.5px 7px",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      bgcolor: "var(--neuro-login-form-bg-primary)",
      "& fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
      "&:hover fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
    },
  };

  const frequencySelectStyle = {
    display: "flex",
    bgcolor: "var(--neuro-login-form-bg-primary)",
    borderRadius: "10px",
    minWidth: "105px",
    width: "105px",
    // minWidth: "160px",
    // width: "160px",
    height: "40px",
    maxHeight: "40px",
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        fontSize: "12px",
        display: "flex",
        paddingLeft: "8px",
      },
    "& .MuiOutlinedInput-root": {
      display: "flex",
      fontSize: "14px !important",
      bgcolor: "var(--neuro-login-form-bg-primary)",
      "& >.MuiSelect-outlined": {
        display: "flex",
      },
      "& fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
      "&:hover fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
    },
  };

  const durationUnitSelectStyle = {
    bgcolor: "var(--neuro-login-form-bg-primary)",
    borderRadius: "10px",
    border: "none none none initial",
    minWidth: "50px",
    width: "50px",
    maxWidth: "50px",
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        fontSize: "12px",
        display: "flex",
        paddingLeft: "8px",
      },
    // minWidth: "82px",
    // width: "82px",
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      bgcolor: "var(--neuro-login-form-bg-primary)",
      "& fieldset": {
        borderColor:
          "transparent transparent transparent var(--neuro-login-form-bg-primary) !important",
      },
      "&:hover fieldset": {
        borderColor:
          "transparent transparent transparent var(--neuro-login-form-bg-primary) !important",
      },
      "&.Mui-focused fieldset": {
        borderColor:
          "transparent transparent transparent var(--neuro-login-form-bg-primary) !important",
      },
    },
  };

  const dosageUnitSelectStyle = {
    bgcolor: "var(--neuro-login-form-bg-primary)",
    borderRadius: "10px",
    minWidth: "60px",
    width: "60px",
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        fontSize: "12px",
        display: "flex",
        paddingLeft: "8px",
      },
    // minWidth: "82px",
    // width: "82px",
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      bgcolor: "var(--neuro-login-form-bg-primary)",
      "& fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
      "&:hover fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important",
      },
    },
  };

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
  };

  const textFieldStyle = {
    minHeight: "40px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    height: "40px",
    width: "100%",
    fontSize: "12px",
    "& .MuiInputBase-root": {
      // paddingInline: "16px",
      paddingInline: "8px",
      alignItems: "flex-start",
      borderRadius: "10px",
      height: "40px",
      width: "100%",
      fontSize: "12px",
    },
    "& .MuiInputBase-input": {
      alignItems: "flex-start",
      padding: "0px !important",
      height: "40px !important",
      width: "100%",
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-root": {
      alignItems: "flex-start",
      width: "100%",
      backgroundColor: "var(--neuro-login-form-bg-primary)",
      fontSize: "12px",
      "& fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important", // default
      },
      "&:hover fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important", // hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--neuro-login-form-bg-primary) !important", // focus
      },
    },
  };

  // callbacks & functions
  useEffect(() => {
    if (medications && medications.length > 0) {
      setMedicines([...medications]);
    }
  }, [medications]);

  const handleInputChange = <K extends keyof Medicine>(
    index: number,
    field: K,
    value: any
  ) => {
    const newMedicines = [...medicines];

    newMedicines[index][field] = value;

    setMedicines(newMedicines);

    if (
      field === "medication" &&
      index === medicines.length - 1 &&
      value.trim() !== ""
    ) {
      handleAddMedicine();
    }
  };

  const handleAddMedicine = () => {
    setMedicines([...medicines, { ...initialMedicine }]);
  };

  const handleDeleteMedicine = (index: number) => {
    const newMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(newMedicines);
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      <Typography
        sx={{
          marginBottom: "16px",
          fontSize: "25px",
          lineHeight: "30px",
          fontFamily: "Roboto Condensed",
          color: "var(--neuro-button-bg-primary)",
        }}
      >
        Medication
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "18px",
          // width: "100%",
        }}
      >
        <Typography sx={{ minWidth: 150, maxWidth: 150, ...style }}>
          Medicine name
        </Typography>
        <Typography sx={{ minWidth: 55, maxWidth: 55, ...style }}>
          Type
        </Typography>
        <Typography sx={{ minWidth: 125, maxWidth: 125, ...style }}>
          Dosage
        </Typography>
        <Typography sx={{ minWidth: 105, maxWidth: 105, ...style }}>
          Frequency
        </Typography>
        <Typography sx={{ minWidth: 105, maxWidth: 105, ...style }}>
          Duration
        </Typography>
        <span style={{ minWidth: "34px" }}></span>
      </Box>

      {medicines &&
        medicines.length > 0 &&
        medicines.map((medicine, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginBottom: "15px",
              // overflowX: "auto",
              // width: "100%",
            }}
          >
            <TextField
              value={medicine.medication}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(index, "medication", e.target.value)
              }
              placeholder="Add medicine"
              variant="outlined"
              size="small"
              sx={{ minWidth: 150, maxWidth: 150, ...textFieldStyle }}
            />

            <Select
              value={medicine.medicationType}
              onChange={(e: any) =>
                handleInputChange(index, "medicationType", e.target.value)
              }
              // IconComponent={DownExpandIcon}
              displayEmpty
              variant="outlined"
              size="small"
              sx={medicationTypeSelectStyle}
            >
              <MenuItem value="TABLET" sx={menuItemStyle}>
                <TabletIcon height={17} width={17} />
              </MenuItem>
              <MenuItem value="INJECTION" sx={menuItemStyle}>
                <InjectionIcon height={17} width={17} />
              </MenuItem>
              <MenuItem value="SYRUP" sx={menuItemStyle}>
                <MedicationLiquidIcon
                  sx={{
                    fontSize: "17px",
                    height: "17px",
                    width: "17px",
                    color: "var(--neuro-bg-primary-seagreen)",
                  }}
                />
              </MenuItem>
              <MenuItem value="OINTMENT" sx={menuItemStyle}>
                <OintmentsIcon />
              </MenuItem>
              <MenuItem value="CAPSULE" sx={menuItemStyle}>
                <CapsuleIcon />
              </MenuItem>
            </Select>

            <Box
              sx={{
                border: "1px solid var(--neuro-secondary_border)",
                borderRadius: "10px",
                width: 125,
                maxWidth: 125,
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                type="number"
                value={medicine.dosageAmount}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, "dosageAmount", e.target.value)
                }
                // placeholder="Add medicine"
                variant="outlined"
                size="small"
                sx={{ minWidth: 65, maxWidth: 65, ...textFieldStyle }}
              />

              <Select
                value={String(medicine.dosageUnit)}
                onChange={(e: SelectChangeEvent<string>) =>
                  handleInputChange(index, "dosageUnit", e.target.value)
                }
                displayEmpty
                variant="outlined"
                size="small"
                sx={dosageUnitSelectStyle}
              >
                <MenuItem value="MG">MG</MenuItem>
                <MenuItem value="ML">ML</MenuItem>
                <MenuItem value="G">G</MenuItem>
              </Select>
            </Box>

            <Select
              value={medicine.frequency}
              onChange={(e: SelectChangeEvent<string>) =>
                handleInputChange(index, "frequency", e.target.value)
              }
              displayEmpty
              // renderValue={(selected) => selected.join(", ")}
              variant="outlined"
              size="small"
              sx={frequencySelectStyle}
            >
              <MenuItem
                value="ONCE_A_DAY_MORNING"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <MorningIcon />
              </MenuItem>
              <MenuItem
                value="ONCE_A_DAY_AFTERNOON"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <AfternoonIcon />
              </MenuItem>
              <MenuItem
                value="ONCE_A_DAY_NIGHT"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <NightIcon />
              </MenuItem>
              <MenuItem
                value="TWICE_A_DAY"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                  <MorningIcon />
                  <NightIcon />
                </Box>
              </MenuItem>
              <MenuItem
                value="THREE_TIMES_A_DAY"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                  <MorningIcon />
                  <AfternoonIcon />
                  <NightIcon />
                </Box>
              </MenuItem>
              <MenuItem
                value="FOUR_TIMES_A_DAY"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                  <MorningIcon />
                  <AfternoonIcon />
                  <Brightness6Icon
                    sx={{
                      fontSize: "15px",
                      color: "var(--neuro-button-bg-success)",
                    }}
                  />
                  <NightIcon />
                </Box>
              </MenuItem>
              <MenuItem
                value="AS_NEEDED"
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                As Needed
              </MenuItem>
            </Select>

            <Box
              sx={{
                border: "1px solid var(--neuro-secondary_border)",
                borderRadius: "10px",
                width: 105,
                maxWidth: 105,
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                type="number"
                value={medicine.durationAmount}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(index, "durationAmount", e.target.value)
                }
                // placeholder="Add medicine"
                variant="outlined"
                size="small"
                sx={{ minWidth: 55, maxWidth: 55, ...textFieldStyle }}
              />

              <Select
                value={String(medicine.durationUnit)}
                onChange={(e: SelectChangeEvent<string>) =>
                  handleInputChange(index, "durationUnit", e.target.value)
                }
                displayEmpty
                variant="outlined"
                size="small"
                sx={durationUnitSelectStyle}
              >
                <MenuItem value="DAYS">D</MenuItem>
                <MenuItem value="WEEKS">W</MenuItem>
                <MenuItem value="MONTHS">M</MenuItem>
              </Select>
            </Box>

            <IconButton
              onClick={() => handleDeleteMedicine(index)}
              size="small"
              sx={{ color: "var(--neuro-black-text)", width: 24, height: 24 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
        }}
      >
        <IconButton
          onClick={handleAddMedicine}
          size="small"
          sx={{ color: "#A7A7A7" }}
        >
          <AddIcon />
        </IconButton>
        <Typography variant="body2" sx={{ ml: 1 }}>
          Add medicine
        </Typography>
      </Box>
    </Box>
  );
};

export default Medication;
