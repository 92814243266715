import { Box, Dialog, IconButton, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import Tooltip from "../ui/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DrawerProps } from "../../types/common";

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Drawer: React.FC<DrawerProps> = ({
  open,
  children,
  onClose,
  headerText,
  width = "45vw",
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: { width },
          height: "100vh",
          marginLeft: "auto", // Align to the right
          maxWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
          backgroundColor: "var(--neuro-white-text)",
          overflow: "hidden",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* drawer header */}
        {headerText && (
          <Box
            sx={{
              background:
                "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(37, 129, 196, 1))",
              height: "60px",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <Tooltip title="Close this dialog" placement="top">
              <IconButton size="small" onClick={onClose}>
                <ArrowBackIcon sx={{ color: "var(--neuro-white-text)" }} />
              </IconButton>
            </Tooltip>
            <Typography
              sx={{
                fontSize: "25px",
                lineHeight: "30px",
                color: "var(--neuro-white-text)",
                fontFamily: "Roboto Condensed",
              }}
            >
              {headerText}
            </Typography>
          </Box>
        )}
        {children}
      </Box>
    </Dialog>
  );
};

export default Drawer;
