import React, { useEffect, useState, useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Box, Grid, Avatar, IconButton } from "@mui/material";
import Input from "../../ui/Input";
import useAuthStore from "../../../store/authStore";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import useProfileStore from "../../../store/profileStore";
import useAppStore from "../../../store/appStore";
import {
  AdminProfileFormInputs,
  PersonalInfoFormProps,
} from "../../../types/profile";
import { adminProfileSchema } from "../../../validations/profileValidation";

const AdminPersonalInfoPage: React.FC<PersonalInfoFormProps> = () => {
  // props & state values
  const {
    profileDetails,
    getProfileDetails,
    updateProfile,
    loadingUpdateProfile,
    errorUpdateProfile,
    updatedProfileDetails,
    cleanUpUpdateProfileState,
  } = useProfileStore();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm<AdminProfileFormInputs>({
    resolver: yupResolver(adminProfileSchema),
  });

  const [profileImagePreview, setProfileImagePreview] = useState<
    string | undefined
  >();
  const { user } = useAuthStore();

  const { showSnackbar } = useAppStore();
  const initialValues = useRef<AdminProfileFormInputs | null>(null);

  const doctorId = user?.doctorId;

  // callbaks & functions
  useEffect(() => {
    if (doctorId) {
      getProfileDetails(doctorId, "ADMIN");
    }
  }, [doctorId]);

  useEffect(() => {
    if (loadingUpdateProfile) {
      showSnackbar("Updating profile, please wait", "info");
    } else if (errorUpdateProfile) {
      showSnackbar("Could not update profile, please try again", "error");
      cleanUpUpdateProfileState();
    } else if (updatedProfileDetails && doctorId) {
      showSnackbar("Profile updated", "success");
      getProfileDetails(doctorId, "ADMIN");
      cleanUpUpdateProfileState();
    }
  }, [updatedProfileDetails, errorUpdateProfile, loadingUpdateProfile]);

  useEffect(() => {
    if (profileDetails) {
      const defaultValues = {
        firstName: profileDetails.user.firstName || "",
        lastName: profileDetails.user.lastName || "",
        mobile: profileDetails.user.mobile || "",
        email: profileDetails.user.email || "",
        profileImage: profileDetails.user.profileImageUrl || undefined,
      };
      reset(defaultValues);
      setProfileImagePreview(profileDetails.user.profileImageUrl || undefined);
      initialValues.current = defaultValues;
    }
  }, [profileDetails, reset]);

  const onSubmit: SubmitHandler<AdminProfileFormInputs> = async (data) => {
    console.log("data", data);
    // setLoading(true);
    // setLoading(false);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file && profileDetails) {
      setProfileImagePreview(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("profileUrl", file);
      formData.append("table", "USER");
      formData.append(
        "profileImageUrl",
        profileDetails.user.profileImageUrl || undefined
      );
      updateProfile(profileDetails.user.id, formData);
      showSnackbar(`Your profile updated successfully.`, "success");
    }
  };

  const handleRemoveImage = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setProfileImagePreview(undefined);
    setValue("profileImage", null);
    event.stopPropagation();
  };

  const onBlurHandler = async (event: any) => {
    let key = event?.target.name as keyof AdminProfileFormInputs;
    let value = event?.target.value as any;

    const isValid = await trigger(key);
    if (!isValid) return;
    const initialValue = initialValues.current
      ? initialValues.current[key]
      : undefined;

    if (key === ("spokenLanguages" as keyof AdminProfileFormInputs)) {
      value = event.target.value.split(",").map((s: string) => s.trim());
    }

    if (initialValue === value) return;

    // initialValues.current[key] =value;

    const table = profileDetails
      ? key in profileDetails
        ? "DOCTOR"
        : "USER"
      : null;
    const data = { [key]: value, table };
    if (value && profileDetails) {
      updateProfile(profileDetails.user.id, data);
    }
  };

  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        gap: "11px",
        height: "100%",
        width: "100%",
      }}
    >
      {/* <Typography
				sx={{
					fontWeight: 700,
					color: "var(--neuro-button-bg-success)",
					fontSize: "20px",
					fontFamily: "Roboto Condensed",
					lineHeight: "24px",
					marginBottom: "11px",
				}}
			>
				Personal Info (Doctor)
			</Typography> */}

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          margin: "0",
          background: "var(--neuro-white-text)",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid container rowGap={4} columnGap={2.75}>
          <Grid item container xs={10} spacing={2.75}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
              xs={12}
              md={9}
            >
              <Grid item xs={12} md={9}>
                <Input
                  type="text"
                  className="input-primary"
                  label="First name *"
                  {...register("firstName")}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  className="input-primary"
                  type="text"
                  label="Last name *"
                  {...register("lastName")}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: "relative",
                  width: "190px",
                  height: "190px",
                  border: "2px solid var(--neuro-button-bg-success)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                  boxSizing: "content-box",
                  overflow: "hidden",
                }}
              >
                <Avatar
                  src={profileImagePreview || ""}
                  sx={{
                    width: "190px",
                    height: "190px",
                    border: "2px solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    overflow: "hidden",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "30%",
                    backgroundColor: "var(--neuro-button-bg-success)",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {profileImagePreview ? (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 2px var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "7px",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "24px",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 2px var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "7px",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          fontSize: "24px",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="profile-image-upload"
                        type="file"
                        {...register("profileImage")}
                        onChange={(e) => {
                          register("profileImage").onChange(e);
                          handleImageChange(e);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                startAdornment={<Typography>+91</Typography>}
                type="text"
                className="input-primary"
                label="Phone number *"
                {...register("mobile")}
                error={!!errors?.mobile}
                helperText={errors?.mobile?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                disabled
                type="email"
                className="input-primary"
                label="E-mail *"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminPersonalInfoPage;
