import React from "react";
import {
  Box,
  IconButton,
  Modal as MuiModal,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalProps } from "../../types/ui";

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  width,
  height,
  closeIcon = false,
  ...props
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClose = (
    event: React.SyntheticEvent<any, Event>,
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    // Prevent closing on backdrop click or escape key
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    if (onClose) {
      // If onClose expects no arguments
      if (onClose.length === 0) {
        (onClose as () => void)();
      } else {
        // If onClose expects event and reason
        (
          onClose as (
            event: React.SyntheticEvent<any, Event>,
            reason: "backdropClick" | "escapeKeyDown"
          ) => void
        )(event, reason);
      }
    }
  };
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      {...props}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "var(--neuro-white-text)",
          width: width ? (isMobile ? "94%" : width) : 600,
          minHeight: 200,
          // maxHeight: height || 500,
          height: height || "max-content",
          display: "flex",
          paddingBlock: "20px",
          paddingInline: isMobile ? 0 : "20px",
          justifyContent: "center",
          borderRadius: "20px",
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "60px", // Thickness of the right border
            height: "100%",
            background:
              "linear-gradient(to bottom, rgba(98, 181, 90, 1), rgba(37, 129, 196, 1))",
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",

            "@media (max-width: 600px)": {
              width: "100%",
              height: "60px", // Thickness of the top border
              background:
                "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(37, 129, 196, 1))",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "0px",
              right: "auto",
              top: 0,
              left: 0,
            },
          }}
        />

        <>
          {closeIcon && (
            <IconButton
              onClick={() => onClose && (onClose as () => void)()}
              size="small"
              sx={{
                position: "absolute",
                top: isMobile ? 15 : 20,
                right: isMobile ? 15 : 13,
              }}
            >
              <CloseIcon
                sx={{
                  // position: "absolute",
                  // top: 20,
                  // right: 20,
                  // cursor: "pointer",
                  color: "var(--neuro-white-text)",
                  width: 24,
                  height: 24,
                }}
              />
            </IconButton>
          )}
          <Box
            sx={{
              width: "100%",
              marginRight: "60px",
              "@media (max-width: 600px)": {
                marginRight: 0,
                marginTop: "60px",
              },
              // maxHeight: 500,
              // overflow: "auto",
            }}
            onClick={(event) => event.stopPropagation()}
          >
            {children}
          </Box>
        </>
      </Box>
    </MuiModal>
  );
};

export default Modal;
