import React from "react";

// third-party imports
import { InputAdornment, TextField } from "@mui/material";
import { SearchInputProps } from "../../types/ui";

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  placeholder,
  onChange,
  endIcon,
  startIcon,
  fullWidth,
  width,
  sx,
}) => {
  return (
    <TextField
      placeholder={placeholder}
      fullWidth={fullWidth ? true : false}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{startIcon || null}</InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">{endIcon || null}</InputAdornment>
        ),
      }}
      sx={{
        height: "46px",
        width: width ? width : fullWidth ? "100%" : 300,
        "& .MuiInputBase-root": {
          paddingRight: "12px",
          borderRadius: "50px",
          height: "46px",
        },
        "& .MuiInputBase-input": {
          padding: "0px !important",
          height: "46px !important",
        },
        "& .MuiInputAdornment-positionStart": {
          color: "var(--neuro-black-text)",
          display: startIcon ? "flex" : "none",
        },
        "& .MuiInputAdornment-positionEnd": {
          color: "var(--neuro-black-text)",
          display: endIcon ? "flex" : "none",
        },
        "& .MuiOutlinedInput-root": {
          backgroundColor: "var(--neuro-white-text)",
          "& fieldset": {
            borderColor: "var(--neuro-secondary_border)", // default
          },
          "&:hover fieldset": {
            borderColor: "var(--neuro-secondary_border)", // hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "var(--neuro-secondary_border)", // focus
          },
        },
        ...sx,
      }}
    />
  );
};

export default SearchInput;
