export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWordsPreservingSpaces(string: string) {
  if (string === undefined || string.includes("undefined")) {
    return "";
  }
  if (string === "") {
    return "";
  }
  return string
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back together with spaces
}

export const getFormattedDate = (date: Date | string) => {
  if (!date || date === "") return "-";

  // Convert date to Date object if it's a string
  const dateObj = new Date(date);

  // Ensure the date is valid
  if (isNaN(dateObj.getTime())) return "-";

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
};
