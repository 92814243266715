import React, { useState, useEffect } from "react";
import { fetchReferrals } from "../../services/referralService";
import { Box } from "@mui/material";
import { Referral } from "../../types/common";
import ReferralsList from "./ReferralsList";

const Referrals: React.FC = () => {
  // props & state values
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  console.log("referrals", referrals);

  // callbacks & functions
  const loadReferrals = async () => {
    try {
      const response = await fetchReferrals();
      setReferrals(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadReferrals();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        minHeight: "100%",
        paddingInline: "20px",
        mb: 2,
        height: "100%",
      }}
    >
      <ReferralsList
        referrals={referrals}
        loading={loading}
        loadReferrals={loadReferrals}
      />
    </Box>
  );
};

export default Referrals;
