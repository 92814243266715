import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../ui/Button";
import Drawer from "../shared/Drawer";
import GeneralForm from "../forms/GeneralForm";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../../utils/common";
import FormFilledDetails from "../forms/FormFilledDetails";
import useAuthStore from "../../store/authStore";
import { isAdmin, isPatient } from "../../utils/auth";
import assignFormStore from "../../store/formStore";
import { fetchFormRequestsApi } from "../../services/formService";
import useAppStore from "../../store/appStore";
import { PatientFormsListProps } from "../../types/patients";

const PatientFormsList: React.FC<PatientFormsListProps> = ({
  patientId,
  patientName,
}) => {
  // props & state values
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  const [formAction, setFormAction] = useState<string | null>(null);

  const [formRequests, setFormRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { showSnackbar } = useAppStore();

  const { user, userRole } = useAuthStore();
  // let patientId = user?.patientId;

  const {
    formSubmitted,
    formAssigned,
    assignForm,
    submitLoading,
    submitError,
    submitFormResponse,
    cleanUpFormSubmittedState,
  } = assignFormStore();

  console.log("formRequests", formRequests);

  // callbacks & functions
  const loadFormRequests = async (id: string) => {
    try {
      const response = await fetchFormRequestsApi(id);
      setFormRequests(response?.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  const onSubmitForm = (responses: any, score: number) => {
    const data = {
      responses,
      score,
    };
    submitFormResponse(selectedForm?.id, data);
  };

  useEffect(() => {
    if (patientId) {
      loadFormRequests(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (formSubmitted && patientId) {
      loadFormRequests(patientId);
    }
  }, [formSubmitted, patientId]);

  useEffect(() => {
    if (formAssigned && patientId) {
      loadFormRequests(patientId);
    }
  }, [formAssigned, patientId]);

  useEffect(() => {
    if (submitError) {
      showSnackbar("Could not submit form, please try again", "error");
      cleanUpFormSubmittedState();
    }
    if (submitLoading) {
      showSnackbar("Form is submitting, please try again", "info");
    }

    if (formSubmitted) {
      showSnackbar("Form is submittied", "success");
    }
  }, [formSubmitted, submitLoading, submitError]);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedForm(null);
    setFormAction(null);
    cleanUpFormSubmittedState();
  };

  const formattedDate = (dateString: string | null) => {
    if (!dateString || dateString === "") {
      return "";
    }
    const formatedDate = format(new Date(dateString), "d MMMM yyyy");

    return formatedDate;
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {loading && <LoadingScreen />}

      {!loading && formRequests && formRequests.length === 0 && (
        <ContentCenter>
          <Typography className="no-data">Forms not found</Typography>
        </ContentCenter>
      )}

      {!loading && formRequests && formRequests.length > 0 && (
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Form Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Assigned By
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Assigned On
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Status
                </TableCell>
                {!isPatient(userRole) && (
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Score
                  </TableCell>
                )}
                <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formRequests?.map((form) => (
                <TableRow key={form?.id}>
                  <TableCell>{form?.form?.name}</TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(form?.assignedBy?.firstName || "") ||
                      ""}{" "}
                    {capitalizeFirstLetter(form?.assignedBy?.lastName || "") ||
                      ""}
                  </TableCell>
                  <TableCell>{formattedDate(form?.createdAt || "")}</TableCell>
                  <TableCell>{form?.status}</TableCell>
                  {!isPatient(userRole) && (
                    <TableCell>
                      {form?.responses?.isScoreBased
                        ? form?.score === 0
                          ? 0
                          : form?.score || "-"
                        : "-"}
                    </TableCell>
                  )}
                  <TableCell>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <>
                        {form?.status === "COMPLETED" && (
                          <>
                            <Button
                              variant="outlined"
                              size="small"
                              className="outlined-secondary-button"
                              onClick={() => {
                                setSelectedForm(form);
                                setFormAction("Edit");
                                setDrawerOpen(true);
                              }}
                            >
                              Edit
                            </Button>
                            <Box sx={{ height: "30px" }}>
                              <Typography
                                sx={{
                                  fontSize: "24px",
                                  lineHeight: "30px",
                                  color: "var(--neuro-secondary_border)",
                                }}
                              >
                                /
                              </Typography>
                            </Box>
                            <Button
                              variant="outlined"
                              size="small"
                              className="outlined-secondary-button"
                              onClick={() => {
                                setSelectedForm(form);
                                setFormAction("View");
                                setDrawerOpen(true);
                              }}
                            >
                              View
                            </Button>
                          </>
                        )}

                        {form?.status === "PENDING" && (
                          <Button
                            size="small"
                            variant="outlined"
                            className="outlined-secondary-button"
                            onClick={() => {
                              setSelectedForm(form);
                              setFormAction("Fill");
                              setDrawerOpen(true);
                            }}
                          >
                            Fill
                          </Button>
                        )}
                      </>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* view/edit form drawer */}
      <Drawer
        open={drawerOpen}
        width={isMobile ? "100vw" : "45vw"}
        headerText={`${patientName}`}
        onClose={() => {
          setSelectedForm(null);
          setFormAction(null);
          setDrawerOpen(false);
          cleanUpFormSubmittedState();
        }}
      >
        <Box
          sx={{
            paddingInline: "16px",
            paddingTop: "20px",
            height: "calc(100% - 60px)",
            overflowY: "auto",
          }}
        >
          <>
            {selectedForm && (
              <>
                {formSubmitted ? (
                  <FormFilledDetails
                    score={
                      selectedForm?.form?.isScoreBased &&
                      formSubmitted?.score !== null &&
                      formSubmitted?.score !== undefined
                        ? formSubmitted?.score
                        : null
                    }
                    patientName={patientName}
                    onClose={onCloseDrawer}
                  />
                ) : (
                  <GeneralForm
                    formSchema={
                      selectedForm?.status === "COMPLETED"
                        ? selectedForm?.responses
                        : selectedForm?.form?.questions || null
                    }
                    readOnly={formAction === "View" ? true : false}
                    onFinish={onSubmitForm}
                    loading={submitLoading}
                    patientName={patientName}
                    onClosing={
                      formAction === "View" ? onCloseDrawer : undefined
                    }
                    isEdit={formAction === "Edit" ? true : false}
                  />
                )}
              </>
            )}
          </>
        </Box>
      </Drawer>
    </Box>
  );
};

export default PatientFormsList;
