import React from "react";

// third-party imports
import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";

// project imports
import Logo from "../../shared/Logo";
import { NewAppointmentAppointmentDetails } from "../../../types/appointments";

const AppointmentDetails: React.FC<NewAppointmentAppointmentDetails> = ({
  meetType,
  handleMeetTypeChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "var(--neuro-white-text)",
        border: "1px solid var(--neuro-secondary_border)",
        borderRadius: "12px",
        padding: "25px 25px 25px 21px",
        marginBottom: "16px",
      }}
    >
      <Box sx={{ display: "flex", gap: "14px" }}>
        <Typography
          sx={{
            color: "var(--neuro-bg-darkblue-primary)",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "120%",
            fontFamily: "Inter",
          }}
        >
          No doctor selected
        </Typography>
      </Box>
      <RadioGroup value={meetType} onChange={handleMeetTypeChange} row>
        <FormControlLabel
          value="ONLINE"
          control={<Radio />}
          label="Online Meet"
        />
        <FormControlLabel
          value="IN_PERSON"
          control={<Radio />}
          label="Direct Meet"
        />
      </RadioGroup>
      <Logo width={89} height={63} />
    </Box>
  );
};

export default AppointmentDetails;
