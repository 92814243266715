import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import CustomTabPanel from "./CustomTabPanel";
import Button from "../../ui/Button";
import useAuthStore from "../../../store/authStore";
import RemirrorEditor from "./RemirrorEditor";
import DownloadSvgIcon from "../../svg/DownloadIcon";
import Alert from "../../ui/Alert";
import Spinner from "../../ui/Spinner";
import Tooltip from "../../ui/Tooltip";
import Input from "../../ui/Input";
import { isDoctorOrAdmin, isPatient } from "../../../utils/auth";
import LoadingScreen from "../../shared/LoadingScreen";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import useDocumentStore from "../../../store/documentsStore";
import {
  downloadDocumentDoctorSummary,
  downloadDocumentPatientSummary,
  updateDoctorApproveStatus,
  updateDocumentFeedback,
} from "../../../services/documentsService";
import useAppStore from "../../../store/appStore";
import { ReportSummaryProps } from "../../../types/documents";

const ReportSummary: React.FC<ReportSummaryProps> = ({
  loading,
  metadata,
  doctorSummary,
  patientSummary,
  fileName,
  id,
  isDoctorApproved,
  feedback,
  from = "",
}) => {
  // props & state values
  const { showSnackbar } = useAppStore();

  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<number>(0);
  const [summaryMetaData, setSummaryMetaData] = useState<any>(metadata);
  const [feedbackValue, setFeedbackValue] = useState<string>(
    feedback ? feedback : ""
  );
  const [approveStatusLoading, setApproveStatusLoading] =
    useState<boolean>(false);
  const [downloadDoctorSummaryLoading, setDownloadDoctorSummaryLoading] =
    useState<boolean>(false);
  const [downloadPatientSummaryLoading, setDownloadPatientSummaryLoading] =
    useState<boolean>(false);

  const [doctorApproved, setDoctorApproved] =
    useState<boolean>(isDoctorApproved);

  const [updatedFeedbackLoading, setUpdatedFeedbackLoading] =
    useState<boolean>(false);

  const { user, userRole } = useAuthStore();

  const {
    reGenerateSummary,
    loadingReGenerateSummary,
    fetchDocumentDetails,
    updatedDoctorSummary,
    loadingDoctorSummary,
    errorDoctorSummary,
    cleanUpDoctorSummaryState,
    updatedPatientSummary,
    loadingPatientSummary,
    errorPatientSummary,
    cleanUpPatientSummaryState,
    selectedDocumentId,
  } = useDocumentStore();

  const [doctorSummaryHtmlString, setDoctorSummaryHtmlString] =
    useState<string>(doctorSummary);

  const [patientSummaryHtmlString, setPatientSummaryHtmlString] =
    useState<string>(patientSummary);

  const [doctorSummaryContent, setDoctorSummaryContent] = useState<any>(null);
  const [patientSummaryContent, setPatientSummaryContent] = useState<any>(null);

  // callbacks & functions
  const feebackUpdateHandler = async (id: string, feedback: string) => {
    setUpdatedFeedbackLoading(true);
    showSnackbar("Feedback is updating, please wait", "info");
    try {
      const response = await updateDocumentFeedback(id, feedback);
      if (response?.data) {
        showSnackbar("Feedback updated", "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
      }
      setUpdatedFeedbackLoading(false);
    } catch (error) {
      showSnackbar("Could not update feedback, please try again", "error");
      setUpdatedFeedbackLoading(false);
    }
  };

  const summaryStatusHandler = async (
    id: string,
    isDoctorApproved: boolean
  ) => {
    setApproveStatusLoading(true);
    const message = doctorApproved ? "Rejecting" : "Approving";
    showSnackbar(`${message} summary, please wait`, "info");
    try {
      const response = await updateDoctorApproveStatus(id, isDoctorApproved);
      if (response?.data) {
        const message = doctorApproved ? "Rejected" : "Approved";
        showSnackbar(`Summary ${message}`, "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
      }
      setApproveStatusLoading(false);
    } catch (error) {
      const message = doctorApproved ? "Reject" : "Approve";
      showSnackbar(`Could not ${message} summary, please try again`, "error");
      setApproveStatusLoading(false);
    }
  };

  const downloadHandler = (response: any) => {
    const contentDisposition = response?.headers["content-disposition"];
    const blobData = response?.data;
    const fileName = contentDisposition;
    const blob = blobData;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  const downloadDoctorSummaryPdf = async (id: string) => {
    setDownloadDoctorSummaryLoading(true);
    showSnackbar("Doctor summary is downloading, please wait", "info");
    try {
      const response = await downloadDocumentDoctorSummary(id);
      downloadHandler(response);

      showSnackbar("Doctor summary is downloaded", "success");
      setDownloadDoctorSummaryLoading(false);
    } catch (error) {
      showSnackbar(
        "Could not download doctor summary, please try again",
        "error"
      );
      setDownloadDoctorSummaryLoading(false);
    }
  };

  const downloadPatientSummaryPdf = async (id: string) => {
    setDownloadPatientSummaryLoading(true);
    showSnackbar("Patient summary is downloading, please wait", "info");
    try {
      const response = await downloadDocumentPatientSummary(id);
      downloadHandler(response);

      showSnackbar("Patient summary is downloaded", "success");
      setDownloadPatientSummaryLoading(false);
    } catch (error) {
      showSnackbar(
        "Could not download patient summary, please try again",
        "error"
      );
      setDownloadPatientSummaryLoading(false);
    }
  };

  function extractBodyContent(htmlString: string) {
    const bodyContentMatch = htmlString?.match(
      /<body[^>]*>((.|[\n\r])*)<\/body>/im
    );
    return bodyContentMatch ? bodyContentMatch[1].trim() : "";
  }

  useEffect(() => {
    if (metadata && metadata !== "") {
      setSummaryMetaData(metadata);
    } else if (!metadata || metadata === "") {
      const metaDataContent = "<html><p>No Meta data</p></html>";
      setSummaryMetaData(metaDataContent);
    }
  }, [metadata]);

  useEffect(() => {
    setDoctorSummaryHtmlString(doctorSummary);
    setPatientSummaryHtmlString(patientSummary);
    setDoctorApproved(isDoctorApproved);
    setFeedbackValue(feedback ? feedback : "");
  }, [isDoctorApproved, feedback, doctorSummary, patientSummary]);

  useEffect(() => {
    if (doctorSummaryHtmlString && doctorSummaryHtmlString !== "") {
      const innerDoctorSummaryContent = extractBodyContent(
        doctorSummaryHtmlString
      );
      setDoctorSummaryContent(innerDoctorSummaryContent);
    } else if (!doctorSummaryHtmlString || doctorSummaryHtmlString === "") {
      const innerDoctorSummaryContent = "<body><p>No Doctor Summary</p></body>";
      setDoctorSummaryContent(innerDoctorSummaryContent);
    }
  }, [doctorSummaryHtmlString]);

  useEffect(() => {
    if (patientSummaryHtmlString && patientSummaryHtmlString !== "") {
      const innerPatientSummaryContent = extractBodyContent(
        patientSummaryHtmlString
      );
      setPatientSummaryContent(innerPatientSummaryContent);
    } else if (!patientSummaryHtmlString || patientSummaryHtmlString === "") {
      const innerPatientSummaryContent =
        "<body><p>No Patient Summary</p></body>";
      setPatientSummaryContent(innerPatientSummaryContent);
    }
  }, [patientSummaryHtmlString]);

  // side effetcs for doctor summary updation
  useEffect(() => {
    if (isDoctorOrAdmin(userRole)) {
      if (loadingDoctorSummary) {
        showSnackbar("Updating doctor summary", "info");
      }
      if (errorDoctorSummary) {
        showSnackbar(
          "Could not update doctor summary, please try again",
          "error"
        );
        cleanUpDoctorSummaryState();
      }
      if (updatedDoctorSummary) {
        showSnackbar("Doctor summary updated", "success");
        // fetchDocuments();
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
        cleanUpDoctorSummaryState();
      }
    }
  }, [
    selectedDocumentId,
    userRole,
    updatedDoctorSummary,
    loadingDoctorSummary,
    errorDoctorSummary,
  ]);

  // side effetcs for patient summary updation
  useEffect(() => {
    if (isDoctorOrAdmin(userRole)) {
      if (loadingPatientSummary) {
        showSnackbar("Updating patient summary", "info");
      }
      if (errorPatientSummary) {
        showSnackbar(
          "Could not update patient summary, please try again",
          "error"
        );
        cleanUpPatientSummaryState();
      }
      if (updatedPatientSummary) {
        showSnackbar("Patient summary updated", "success");
        if (selectedDocumentId) {
          fetchDocumentDetails(selectedDocumentId);
        }
        // fetchDocuments();
        cleanUpPatientSummaryState();
      }
    }
  }, [
    selectedDocumentId,
    userRole,
    updatedPatientSummary,
    loadingPatientSummary,
    errorPatientSummary,
  ]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSummayMetaData = (event: any) => {
    setSummaryMetaData(event.target.innerHTML);
  };

  const handleFeedbackChange = (event: any) => {
    setFeedbackValue(event.target.value);
  };

  // approve/reject patient summary
  const handleSummaryStatus = (satusValue: boolean) => {
    summaryStatusHandler(id, satusValue);
  };

  // feedback onBlur
  const handleFeebackBlur = () => {
    const checkvalue = feedback ? feedback : "";
    if (feedbackValue.trim() !== checkvalue) {
      const value = feedbackValue.trim();
      feebackUpdateHandler(id, value);
    }
  };

  // doctor summary download as pdf
  const handleDownloadSummary = (id: string, value: number) => {
    if (value === 0) {
      downloadDoctorSummaryPdf(id);
    }
    if (value === 1) {
      downloadPatientSummaryPdf(id);
    }
  };

  const handleRegenerateSummary = (id: string) => {
    reGenerateSummary(id);
  };

  // const scrollLeft = () => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollLeft = 200; // Adjust scroll value as needed
  //   }
  // };

  // const scrollRight = () => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollLeft += 200; // Adjust scroll value as needed
  //   }
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "17px",
        paddingInline: "20px",
        borderRight:
          from === "conference"
            ? "none"
            : "1px solid var(--neuro-secondary_border)",
        height: "100%",
        // overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "19px",
          borderBottom: "1px dashed var(--neuro-secondary_border)",
          minHeight: "60px",
        }}
      >
        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "var(--neuro-black-text)",
            fontFamily: "Roboto Condensed",
            // textAlign: { md: "center", xl: "" },
          }}
        >
          Report Summary
        </Typography>

        {!loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {value === 1 && isDoctorOrAdmin(userRole) && !doctorApproved && (
              <Tooltip title="Approve Patient Summary" placement="top-start">
                <Button
                  variant="contained"
                  className="success-button"
                  size="small"
                  onClick={() => handleSummaryStatus(true)}
                  disabled={
                    approveStatusLoading ||
                    !patientSummaryHtmlString ||
                    patientSummaryHtmlString === ""
                  }
                >
                  {approveStatusLoading ? <Spinner size={23} /> : "Approve"}
                </Button>
              </Tooltip>
            )}

            {value === 1 && isDoctorOrAdmin(userRole) && doctorApproved && (
              <Tooltip title="Reject Patient Summary" placement="top-start">
                <Button
                  variant="contained"
                  className="warning-button"
                  size="small"
                  onClick={() => handleSummaryStatus(false)}
                  disabled={
                    approveStatusLoading ||
                    !patientSummaryHtmlString ||
                    patientSummaryHtmlString === ""
                  }
                >
                  {approveStatusLoading ? <Spinner size={23} /> : "Reject"}
                </Button>
              </Tooltip>
            )}

            {user?.email === "venkatesh@racchabanda.com" && (
              <Tooltip title="Regenerate summary" placement="top">
                <IconButton
                  onClick={() => handleRegenerateSummary(id)}
                  disabled={loadingReGenerateSummary}
                >
                  <RestorePageIcon />
                </IconButton>
              </Tooltip>
            )}

            {((userRole === "PATIENT" && isDoctorApproved) ||
              (isDoctorOrAdmin(userRole) && (value === 0 || value === 1))) && (
              <Tooltip title="Download pdf" placement="top">
                <IconButton
                  disabled={
                    (value === 0 &&
                      (downloadDoctorSummaryLoading ||
                        !doctorSummaryHtmlString ||
                        doctorSummaryHtmlString === "")) ||
                    (value === 1 &&
                      (downloadPatientSummaryLoading ||
                        !patientSummaryHtmlString ||
                        patientSummaryHtmlString === ""))
                  }
                  sx={{
                    "&.Mui-disabled > svg": {
                      opacity: 0.5,
                    },
                  }}
                  onClick={() => handleDownloadSummary(id, value)}
                >
                  <DownloadSvgIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          // paddingInline: "20px",
          backgroundColor: "var(--neuro-white-text)",
          height: "calc(100% - 60px)",
        }}
      >
        {loading ? (
          <LoadingScreen />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // overflow: "auto",
              position: "relative",
              height: "100%",
            }}
          >
            {/* tabs */}
            {isDoctorOrAdmin(userRole) && (
              <Box
                // ref={containerRef}
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "calc(100% - 50px)",
                  overflowX: "auto",
                  overflowY: "hidden",
                  height: "50px",
                  flexShrink: 0,
                  // display: "flex",
                  // alignItems: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  sx={{ width: "max-content" }}
                >
                  <Tab label="Doctor Summary" {...a11yProps(0)} />
                  <Tab label="Patient Summary" {...a11yProps(1)} />
                  {metadata && metadata !== "" && (
                    <Tab label="Meta data" {...a11yProps(2)} />
                  )}
                  <Tab
                    label="Feedback"
                    {...a11yProps(metadata && metadata !== "" ? 3 : 2)}
                  />
                </Tabs>
              </Box>
            )}

            {/* tabs content */}
            <Box
              sx={{
                flexGrow: 1,
                maxHeight: "calc(100% - 120px)",
                overflowY: "auto",
              }}
            >
              {isDoctorOrAdmin(userRole) && (
                <>
                  {/* Doctor Summary Tab */}
                  <CustomTabPanel value={value} index={0}>
                    {doctorSummaryContent && (
                      <RemirrorEditor
                        htmlContent={doctorSummaryContent}
                        summaryType="doctor"
                        setDoctorSummaryHtmlString={setDoctorSummaryHtmlString}
                        documentId={id}
                      />
                    )}
                  </CustomTabPanel>

                  {/* Patient Summary Tab */}
                  <CustomTabPanel value={value} index={1}>
                    {patientSummaryContent && (
                      <RemirrorEditor
                        htmlContent={patientSummaryContent}
                        summaryType="patient"
                        setPatientSummaryHtmlString={
                          setPatientSummaryHtmlString
                        }
                        documentId={id}
                      />
                    )}
                  </CustomTabPanel>

                  {/* Meta Data Tab */}
                  {metadata && metadata !== "" && (
                    <CustomTabPanel value={value} index={2}>
                      {summaryMetaData && (
                        <div
                          style={{ cursor: "pointer" }}
                          contentEditable
                          dangerouslySetInnerHTML={{
                            __html: `<pre>${summaryMetaData}</pre>`,
                          }}
                          onBlur={handleSummayMetaData}
                        />
                      )}
                    </CustomTabPanel>
                  )}

                  {/* Feedback Tab */}
                  <CustomTabPanel
                    value={value}
                    index={metadata && metadata !== "" ? 3 : 2}
                  >
                    <Input
                      type="text"
                      value={feedbackValue}
                      label="Feedback on the summary to enhance its quality"
                      onChange={handleFeedbackChange}
                      onBlur={handleFeebackBlur}
                      fullWidth
                      multiline
                    />
                  </CustomTabPanel>
                </>
              )}

              {isPatient(userRole) &&
                patientSummaryContent &&
                (isDoctorApproved ? (
                  <RemirrorEditor
                    htmlContent={patientSummaryContent}
                    summaryType="patient"
                    documentId={id}
                    isEditable={false}
                  />
                ) : (
                  <Alert
                    message="Not approved by doctor"
                    type="info"
                    sx={{ marginTop: "12px" }}
                  />
                ))}
            </Box>

            {/* summary generated using ai alert */}
            {isDoctorOrAdmin(userRole) && (value === 0 || value === 1) && (
              <Box
                sx={{
                  flexShrink: 0,
                  position: "sticky",
                  bottom: 0,
                  // backgroundColor: "white" /* Adjust to match your theme */,
                  zIndex: 1,
                  height: "70px",
                }}
              >
                <Alert
                  message="Content generated using AI. Please verify for accuracy"
                  type="info"
                  sx={{ height: "70px", display: "flex", alignItems: "center" }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ReportSummary;
