import { Box, Typography } from "@mui/material";
import React from "react";
import LoadingScreen from "../shared/LoadingScreen";
import ContentCenter from "../shared/ContentCenter";
import { AllFormsProps } from "../../types/patients";

const AllForms: React.FC<AllFormsProps> = ({
  setSelectedForm,
  selectedForm,
  formsList,
  loading,
}) => {
  return (
    <Box sx={{ width: "100%", height: "calc(100% - 90px)" }}>
      <>
        {loading && <LoadingScreen />}

        {!loading && formsList && formsList.length === 0 && (
          <ContentCenter>
            <Typography className="no-data">Forms not found</Typography>
          </ContentCenter>
        )}

        {!loading &&
          formsList &&
          formsList?.length > 0 &&
          formsList?.map((form, index) => (
            <Box
              key={index}
              onClick={() => setSelectedForm(form)}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                cursor: "pointer",
                width: "100%",
                height: "45px",
                paddingLeft: "30px",
                marginBottom: "23px",
                fontSize: "18px",
                fontFamily: "Inter",
                fontWeight: form?.id === selectedForm?.id ? 600 : 400,
                color: "var(--neuro-black-text)",
                border: "1px solid var(--neuro-light-grey_border)",
                backgroundColor:
                  form?.id === selectedForm?.id
                    ? "var(--neuro-light-grey_border)"
                    : "var(--neuro-white-text)",
                "&:hover": {
                  backgroundColor: "var(--neuro-light-grey_border)",
                },
                "&:last-child": {
                  marginBottom: "0px",
                },
              }}
            >
              {form?.name || ""}
            </Box>
          ))}
      </>
    </Box>
  );
};

export default AllForms;
