import React, { useState, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { getTagFromKey } from "dwv";
import { DisplayItem, TagData, TagsTableProps } from "../../../types/documents";

const TagsTable: React.FC<TagsTableProps> = ({ data }) => {
  const [fullMetaData, setFullMetaData] = useState<TagData>(data);
  const [searchfor, setSearchfor] = useState<string>("");
  const [instanceNumber, setInstanceNumber] = useState<number>(0);
  const [instanceNumbers, setInstanceNumbers] = useState<number[]>([]);
  const [sliderMin, setSliderMin] = useState<number>(0);
  const [sliderMax, setSliderMax] = useState<number>(0);
  const [displayData, setDisplayData] = useState<DisplayItem[]>([]);

  useEffect(() => {
    setFullMetaData(data);
    initializeState(data);
  }, [data]);

  useEffect(() => {
    filterList(searchfor, instanceNumber);
  }, [instanceNumber, searchfor]);

  const initializeState = (fullMetaData: TagData) => {
    const instanceElement = fullMetaData["00200013"];
    if (typeof instanceElement !== "undefined") {
      let instanceNumberValue = instanceElement.value;
      if (typeof instanceNumberValue === "string") {
        instanceNumberValue = [instanceNumberValue];
      }
      const numbers = instanceNumberValue
        .map(Number)
        .sort((a: any, b: any) => a - b);
      setInstanceNumbers(numbers);
      setSliderMin(0);
      setSliderMax(numbers.length - 1);
      setInstanceNumber(numbers[0]);
      setDisplayData(getMetaArray(numbers[0]));
    }
  };

  const getMetaArray = (instanceNumber: number): DisplayItem[] => {
    let reducer;
    if (isDicomMeta(fullMetaData)) {
      reducer = getDicomTagReducer(fullMetaData, instanceNumber, "");
    } else {
      reducer = getTagReducer(fullMetaData);
    }
    const keys = Object.keys(fullMetaData);
    return keys.reduce(reducer, []);
  };

  const isDicomMeta = (meta: TagData): boolean => {
    return typeof meta["00020010"] !== "undefined";
  };

  const getTagReducer = (tagData: TagData) => {
    return function (accumulator: DisplayItem[], currentValue: string) {
      accumulator.push({
        name: currentValue,
        value: tagData[currentValue].value.toString(),
      });
      return accumulator;
    };
  };

  const getDicomTagReducer = (
    tagData: TagData,
    instanceNumber: number,
    prefix: string
  ) => {
    return (accumulator: DisplayItem[], currentValue: string) => {
      const tag = getTagFromKey(currentValue);
      let key = tag.getNameFromDictionary();
      if (typeof key === "undefined") {
        key = "x" + tag.getKey();
      }
      const name = key;
      const element = tagData[currentValue];
      let value: any = element.value;
      if (
        typeof value.slice === "undefined" &&
        typeof value[instanceNumber] !== "undefined"
      ) {
        value = value[instanceNumber];
      }
      if (name === "InstanceNumber") {
        value = instanceNumber;
      }
      if (element.vr === "SQ") {
        accumulator.push({
          name: (prefix ? prefix + " " : "") + name,
          value: "",
        });
        for (let i = 0; i < value.length; ++i) {
          const sqItems = value[i];
          const keys = Object.keys(sqItems);
          const res = keys.reduce(
            getDicomTagReducer(sqItems, instanceNumber, prefix + "[" + i + "]"),
            []
          );
          accumulator = accumulator.concat(res);
        }
      } else {
        if (element.vr[0] === "O" && value.length > 10) {
          value =
            value.slice(0, 10).toString() + "... (len:" + value.length + ")";
        }
        accumulator.push({
          name: (prefix ? prefix + " " : "") + name,
          value: value.toString(),
        });
      }
      return accumulator;
    };
  };

  const onSliderChange = (_: any, sliderValue: number | number[]) => {
    const instanceNumber = instanceNumbers[sliderValue as number];
    setInstanceNumber(instanceNumber);
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchfor(event.target.value);
  };

  const filterList = (search: string, instanceNumber: number) => {
    const searchLo = search.toLowerCase();
    const metaArray = getMetaArray(instanceNumber);
    const updatedList = metaArray.filter((item: any) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          let value = item[key];
          if (typeof value !== "undefined") {
            if (typeof value !== "string") {
              value = value.toString();
            }
            if (value.toLowerCase().indexOf(searchLo) !== -1) {
              return true;
            }
          }
        }
      }
      return false;
    });
    setDisplayData(updatedList);
  };

  return (
    <div style={{ padding: "10px", overflow: "hidden" }}>
      <Stack direction="row" spacing={2}>
        <TextField
          id="search"
          type="search"
          value={searchfor}
          sx={{ width: "45%" }}
          onChange={onSearch}
          margin="normal"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Box width={300} display="flex" alignItems="center">
          <Slider
            title="Instance number"
            sx={{ margin: "20px" }}
            marks
            min={sliderMin}
            max={sliderMax}
            onChange={onSliderChange}
          />
          <div title="Instance number">{instanceNumber}</div>
        </Box>
      </Stack>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Tag</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TagsTable;
