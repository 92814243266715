import React from "react";
import { Box } from "@mui/material";
import SidebarHeader from "./SidebarHeader";
import SidebarMenu from "./SidebarMenu";
import SidebarFooter from "./SidebarFooter";
import { SidebarContentProps } from "../../types/common";

const SidebarContent: React.FC<SidebarContentProps> = ({
  menuItems,
  isMenuCollapsed,
  drawerOpen = false,
}) => {
  return (
    <Box
      sx={{
        width: isMenuCollapsed ? (drawerOpen ? "300px" : 0) : "300px",
        minWidth: isMenuCollapsed ? (drawerOpen ? "300px" : 0) : "300px",
        transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
        backgroundColor: "var(--neuro-white-text)",
        height: "100vh",
        color: "var(--neuro-black-text)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "1px solid var(--neuro-secondary_border)",
        // overflow: "auto",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box>
        <SidebarHeader />
        <SidebarMenu menuItems={menuItems} />
      </Box>
      <SidebarFooter />
    </Box>
  );
};

export default SidebarContent;
