import * as React from "react";
const DownExpandIcon = (props: any) => (
  <svg
    width={19}
    height={10}
    viewBox="0 0 19 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.64274 7.09553C9.49549 7.24512 9.33485 7.26475 9.203 7.26475C9.07248 7.26475 8.85495 7.24512 8.7077 7.09553C6.7901 5.27103 2.81234 0.458062 2.44488 0.195817C1.90005 -0.192175 1.15243 0.0495024 0.71871 0.48985C0.290346 0.923653 0.171875 1.22797 0.171875 1.55239C0.171875 1.77349 6.36575 8.53498 6.9909 9.19971C7.59061 9.83873 7.78605 10 8.81211 10H9.58049C10.6046 10 10.7525 9.83873 11.3522 9.20018C11.978 8.53451 18.1719 1.77256 18.1719 1.55145C18.1719 1.2261 18.0534 0.922717 17.625 0.488914C17.1913 0.0485668 16.4437 -0.194045 15.8989 0.194881C15.5314 0.457127 11.5597 5.2701 9.64207 7.09413L9.64274 7.09553Z"
      fill="black"
    />
  </svg>
);
export default DownExpandIcon;
