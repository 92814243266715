import api from "./apiService";

export const updateProfileApi = (id: string, data: any) => {
  return api.put(`/api/profiles/${id}`, data);
};

export const getProfileApi = (id?: string, role?: string) => {
  let params;

  if (role === "ADMIN" || role === "DOCTOR") {
    params = {
      doctorId: id,
    };
  } else {
    params = {
      patientId: id,
    };
  }

  if (id) {
    return api.get(`/api/profiles`, { params });
  } else {
    return api.get(`/api/profiles`);
  }
};

export const getDoctorProfileApi = (id: string) => {
  return api.get(`/api/profiles/doctors/${id}`);
};

export const getPatientProfileApi = (id: string) => {
  return api.get(`/api/profiles/patients/${id}`);
};
