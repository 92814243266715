// project imports
import { Box, Typography } from "@mui/material";
import useAppointmentStore from "../../../store/appointmentsStore";
import { ONLINE } from "../../../utils/constants";
import { Tax } from "../../../types/common";

const AppointmentPayment = () => {
  const { selectedDoctor, selectedMeetingType } = useAppointmentStore();

  console.log("selectedDoctor in payment", selectedDoctor);

  const charges =
    selectedMeetingType === ONLINE
      ? selectedDoctor?.onlineConsultationFee || null
      : selectedDoctor?.inPersonConsultationFee || null;

  const taxes: Tax[] = selectedDoctor?.taxes;

  const calculateTotalAmount = () => {
    return taxes?.reduce((total, tax) => {
      if (tax.type === "PERCENTAGE") {
        return total + (charges * parseFloat(tax.value)) / 100 || null;
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  };

  const totalAmount = charges
    ? taxes && taxes?.length > 0
      ? calculateTotalAmount()
      : charges
    : null;

  return (
    <Box
      sx={{
        marginTop: "26px",
        marginBottom: "12px",
      }}
    >
      <Box
        sx={{ paddingInline: "20px", display: "flex", flexDirection: "column" }}
      >
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-medium-smallest)",
            lineHeight: "var(--neuro-line-height-extra-small)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            color: "var(--neuro-bg-darkblue-primary)",
          }}
        >
          Payment Summary
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography>Appointment Charge</Typography>
            <Typography sx={{ fontWeight: "var(--neuro-font-weight-bold)" }}>
              {charges || "-"}
            </Typography>
          </Box>
          {taxes?.map((tax) => {
            return (
              <Box
                key={tax.name}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <Typography>
                  {tax.type === "PERCENTAGE"
                    ? `${tax.name} (${tax.value}%)` || "-"
                    : tax.name || "-"}
                </Typography>
                <Typography
                  sx={{ fontWeight: "var(--neuro-font-weight-bold)" }}
                >
                  {tax.type === "PERCENTAGE"
                    ? charges
                      ? (Number(charges) * Number(tax.value)) / 100
                      : "-"
                    : Number(tax.value) || "-"}
                </Typography>
              </Box>
            );
          })}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Total Amount</Typography>
            <Typography sx={{ fontWeight: "var(--neuro-font-weight-bold)" }}>
              {totalAmount || "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            border: "1px dashed var(--neuro-secondary_border)",
          }}
        />
      </Box>
    </Box>
  );
};

export default AppointmentPayment;
