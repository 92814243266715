import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
      }}
    >
      <Typography
        sx={{
          fontSize: "31px",
          lineHeight: "120%",
          color: "var(--neuro-black-text)",
        }}
      >
        Page Not Found
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "120%",
          color: "var(--neuro-black-text)",
          marginBottom: "20px",
        }}
      >
        The page you are looking for does not exist.
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/dashboard"
        sx={{
          width: "150px",
          height: "46px",
        }}
      >
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default NotFound;
