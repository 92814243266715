import React, { useEffect, useState } from "react";

// third-party imports
import { Box, Grid } from "@mui/material";

// project imports
import AppointmentList from "./AppointmentList";
import Reminders from "./Reminders";
import AllAppointments from "./AllAppointments";

const Appointments = () => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
      }}
    >
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={12}>
          <AllAppointments />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Reminders />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Appointments;
