import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Spinner from "../../ui/Spinner";
import useAuthStore from "../../../store/authStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import useProfileStore from "../../../store/profileStore";
import { fetchHospitals } from "../../../services/hospitalService";
import { Hospital } from "../../../types/common";
import useAppStore from "../../../store/appStore";
import {
  DoctorProfileFormInputs,
  PersonalInfoFormProps,
} from "../../../types/profile";
import { doctorProfileSchema } from "../../../validations/profileValidation";

const DoctorPersonalInfoPage: React.FC<PersonalInfoFormProps> = () => {
  // props & state values
  const {
    profileDetails,
    getProfileDetails,
    updateProfile,
    errorUpdateProfile,
    loadingUpdateProfile,
    updatedProfileDetails,
    cleanUpUpdateProfileState,
  } = useProfileStore();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
    trigger,
  } = useForm<DoctorProfileFormInputs>({
    resolver: yupResolver(doctorProfileSchema),
  });

  const [loading, setLoading] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState<
    string | undefined
  >();
  const [hospitals, setHospitals] = useState<Hospital[]>([]);

  const { user } = useAuthStore();

  const { showSnackbar } = useAppStore();
  const initialValues = useRef<any>(null);

  const doctorId = user?.doctorId;

  // callbaks & functions
  const setTimeToZero = (date: Date): Date => {
    const newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);

    return newDate;
  };

  useEffect(() => {
    if (doctorId) {
      getProfileDetails(doctorId, "DOCTOR");
    }
  }, [doctorId]);

  useEffect(() => {
    if (loadingUpdateProfile) {
      showSnackbar("Updating profile, please wait", "info");
    } else if (errorUpdateProfile) {
      showSnackbar("Could not update profile, please try again", "error");
      cleanUpUpdateProfileState();
    } else if (updatedProfileDetails && doctorId) {
      showSnackbar("Profile updated", "success");
      getProfileDetails(doctorId, "DOCTOR");
      cleanUpUpdateProfileState();
    }
  }, [updatedProfileDetails, errorUpdateProfile, loadingUpdateProfile]);

  useEffect(() => {
    if (profileDetails) {
      const defaultValues = {
        firstName: profileDetails.user.firstName || "",
        lastName: profileDetails.user.lastName || "",
        mobile: profileDetails.user.mobile || "",
        email: profileDetails.user.email || "",
        profileImage: profileDetails.user.profileImageUrl || undefined,
        specializations: profileDetails?.specializations || [],
        acceptsInPerson: profileDetails.acceptsInPerson,
        onlineConsultationFee: profileDetails.onlineConsultationFee,
        inPersonConsultationFee: profileDetails.inPersonConsultationFee,
        yearsOfExperience: profileDetails.yearsOfExperience,
        spokenLanguages: profileDetails.spokenLanguages || [],
        experienceDetails: profileDetails.experienceDetails,
        publishedWorks: profileDetails.publishedWorks,
        qualifications: profileDetails.qualifications,
        defaultHospitalId: profileDetails.defaultHospitalId,
      };
      reset(defaultValues);
      setProfileImagePreview(
        profileDetails?.user?.profileImageUrl || undefined
      );
      initialValues.current = defaultValues;
    }
  }, [profileDetails, reset]);

  useEffect(() => {
    const loadHospitals = async () => {
      try {
        const response = await fetchHospitals();
        setHospitals(response.data);
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };
    loadHospitals();
  }, []);

  // useEffect(()=> {

  // },[])

  const specializations = [
    {
      id: "1",
      name: "NEUROLOGIST",
    },
    {
      id: "2",
      name: "PRIMARY_CARE",
    },
    {
      id: "3",
      name: "PEDIATRICS",
    },
  ];

  const onSubmit: SubmitHandler<DoctorProfileFormInputs> = async (data) => {
    // setLoading(true);
    // setLoading(false);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setProfileImagePreview(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("profileUrl", file);
      formData.append("table", "USER");
      formData.append(
        "profileImageUrl",
        profileDetails.user.profileImageUrl || undefined
      );
      updateProfile(profileDetails.user.id, formData);
    }
  };

  const handleRemoveImage = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setProfileImagePreview(undefined);
    setValue("profileImage", null);
    event.stopPropagation();
  };

  const onBlurHandler = async (event: any) => {
    let key = event?.target.name as keyof DoctorProfileFormInputs;
    let value = event?.target.value as any;
    const initialValue = initialValues.current
      ? initialValues.current[key]
      : undefined;

    if (key === "spokenLanguages") {
      if (initialValue.join(",") === value.replace(/,+$/, "").trim()) return;
      value = event.target.value.split(",").map((s: string) => s.trim());
    }
    const isValid = await trigger(key);
    if (!isValid) return;
    if (initialValue === value) return;

    const table = key in profileDetails ? "DOCTOR" : "USER";
    const data = { [key]: value, table };
    updateProfile(profileDetails.user.id, data);
  };

  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        gap: "11px",
        height: "100%",
        width: "100%",
      }}
    >
      {/* <Typography
				sx={{
					fontWeight: 700,
					color: "var(--neuro-button-bg-success)",
					fontSize: "20px",
					fontFamily: "Roboto Condensed",
					lineHeight: "24px",
					marginBottom: "11px",
				}}
			>
				Personal Info (Doctor)
			</Typography> */}

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          margin: "0 auto",
          background: "var(--neuro-white-text)",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid container rowGap={4} columnGap={2.75}>
          <Grid item container xs={10} spacing={2.75}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
              xs={12}
              md={9}
            >
              <Grid item xs={12} md={9}>
                <Input
                  type="text"
                  className="input-primary"
                  label="First name *"
                  {...register("firstName")}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  className="input-primary"
                  type="text"
                  label="Last name *"
                  {...register("lastName")}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: "relative",
                  width: "190px",
                  height: "190px",
                  border: "2px solid var(--neuro-button-bg-success)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                  boxSizing: "content-box",
                  overflow: "hidden",
                }}
              >
                <Avatar
                  src={profileImagePreview || ""}
                  sx={{
                    width: "190px",
                    height: "190px",
                    border: "2px solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    overflow: "hidden",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "30%",
                    backgroundColor: "var(--neuro-button-bg-success)",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {profileImagePreview ? (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 2px var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "7px",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "24px",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 2px var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "7px",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          fontSize: "24px",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="profile-image-upload"
                        type="file"
                        {...register("profileImage")}
                        onChange={(e) => {
                          register("profileImage").onChange(e);
                          handleImageChange(e);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                startAdornment={<Typography>+91</Typography>}
                type="text"
                className="input-primary"
                label="Phone number *"
                {...register("mobile")}
                error={!!errors?.mobile}
                helperText={errors?.mobile?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                disabled
                type="email"
                className="input-primary"
                label="E-mail *"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                className="input-primary"
                error={!!errors.email}
              >
                <p className="MuiTypography-root MuiTypography-body1 css-ynxoyj-MuiTypography-root">
                  Hospital *
                </p>
                <Select
                  {...register("defaultHospitalId")}
                  value={watch("defaultHospitalId") || ""}
                  fullWidth
                  onBlur={(e: any) => onBlurHandler(e)}
                  error={!!errors?.defaultHospitalId}
                >
                  {hospitals.map((hospital) => (
                    <MenuItem key={hospital.id} value={hospital.id}>
                      {hospital.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                className="input-primary"
                error={!!errors?.specializations}
              >
                <p className="MuiTypography-root MuiTypography-body1 css-ynxoyj-MuiTypography-root">
                  Specializations *
                </p>
                <Select
                  multiple
                  {...register("specializations")}
                  value={watch("specializations") || []}
                  fullWidth
                  onBlur={(e: any) => onBlurHandler(e)}
                >
                  {specializations.map((specialization) => (
                    <MenuItem
                      key={specialization.id}
                      value={specialization.name}
                    >
                      {specialization.name}
                    </MenuItem>
                  ))}
                </Select>

                {!!errors?.specializations && (
                  <FormHelperText>
                    {errors.specializations?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="number"
                className="input-primary"
                label="Online Consultation Fee *"
                {...register("onlineConsultationFee")}
                error={!!errors?.onlineConsultationFee}
                helperText={errors?.onlineConsultationFee?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="number"
                className="input-primary"
                label="Inperson Consultation Fee *"
                {...register("inPersonConsultationFee")}
                error={!!errors?.inPersonConsultationFee}
                helperText={errors?.inPersonConsultationFee?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Qualifications *"
                {...register("qualifications")}
                error={!!errors?.qualifications}
                helperText={errors?.qualifications?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Experience Details"
                {...register("experienceDetails")}
                error={!!errors?.experienceDetails}
                helperText={errors?.experienceDetails?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="number"
                className="input-primary"
                label="Years Of Experience *"
                {...register("yearsOfExperience")}
                error={!!errors?.yearsOfExperience}
                helperText={errors?.yearsOfExperience?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Published Works"
                {...register("publishedWorks")}
                error={!!errors?.publishedWorks}
                helperText={errors?.publishedWorks?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Spoken Languages (comma-separated) *"
                {...register("spokenLanguages")}
                onChange={(e) =>
                  setValue(
                    "spokenLanguages",
                    e.target.value.split(",").map((s) => s.trim())
                  )
                }
                onBlur={(e: any) => onBlurHandler(e)}
                error={!!errors?.spokenLanguages}
                helperText={errors?.spokenLanguages?.message}
                aria-describedby="spokenLanguages-helper-text"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DoctorPersonalInfoPage;
