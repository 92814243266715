import React, { useCallback, useEffect } from "react";

// third-party imports
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

// project imports
import useAppointmentStore from "../../../store/appointmentsStore";
import AppointmentDetailsButton from "./AppointmentDetailsButton";
import useAppStore from "../../../store/appStore";
import { OnlineMeetDetailsProps } from "../../../types/appointments";

const OnlineMeetDetails: React.FC<OnlineMeetDetailsProps> = ({
  appointmentCode,
  appointmentId,
  status,
  detailsInfo,
  availableSlot,
  patientId,
  usedIn = "",
  from = "",
}) => {
  // props & state values
  const navigate = useNavigate();

  const {
    cancelAppointmentInfo,
    errorCancelAppointment,
    cleanUpCancelAppointmentState,
    cleanUpAppointmentState,
  } = useAppointmentStore();

  const { showSnackbar } = useAppStore();

  // callbacks & functions
  const handleNavigation = useCallback(() => {
    if (patientId) {
      navigate(`/patients/${patientId}/appointments`);
    } else {
      if (usedIn === "dashboard") {
        navigate("/dashboard");
      } else {
        navigate("/appointments");
      }
    }
  }, [navigate, patientId, usedIn]);

  useEffect(() => {
    if (errorCancelAppointment) {
      showSnackbar("Could not cancel appointment, please try again", "error");
      cleanUpCancelAppointmentState();
    }
    if (cancelAppointmentInfo) {
      showSnackbar("Appointment cancelled", "success");
      handleNavigation();
      cleanUpAppointmentState();
      cleanUpCancelAppointmentState();
    }

    // clean up on unmount
    return () => {
      cleanUpAppointmentState();
      cleanUpCancelAppointmentState();
    };
  }, [cancelAppointmentInfo, errorCancelAppointment, handleNavigation]);

  return (
    <Box
      sx={{
        marginTop: "41px",
      }}
    >
      <Typography
        sx={{
          color: "var(--neuro-black-text)",
          lineHeight: "120%",
          fontSize: "var(--neuro-font-size-mini)",
          fontWeight: "var(--neuro-font-weight-bold)",
          fontFamily: "var(--neuro-font-family-inter)",
          marginBottom: "14px",
        }}
      >
        Meeting Details
      </Typography>
      <Typography
        sx={{
          color: "var(--neuro-black-text)",
          lineHeight: "var(--neuro-line-height-micro)",
          fontFamily: "var(--neuro-font-family-inter)",
          marginBottom: "25px",
        }}
      >
        {detailsInfo || ""}
      </Typography>

      <AppointmentDetailsButton
        appointmentCode={appointmentCode}
        id={appointmentId}
        status={status}
        availableSlot={availableSlot}
        meetType="Online"
      />
    </Box>
  );
};

export default OnlineMeetDetails;
