import React from "react";

// third-party imports
import { Box, Typography, Grid, useMediaQuery } from "@mui/material";

// project imports
// import DiaryWithBgIcon from "../svg/DiaryWithBgIcon";
// import useAuthStore from "../../store/authStore";

const WelcomeCard: React.FC = () => {
  // props & state values
  // const { user } = useAuthStore();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Grid container spacing={2.5} alignItems="center">
      <Grid item xs={12} md={12} xl={5}>
        <Box
          sx={{
            padding: isMobile ? "10px" : "25px 38px 40px 22px",
            borderRadius: "12px",
            background:
              "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(84, 184, 149, 1))",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.1)",
            height: isMobile ? "110px" : "120px",
          }}
        >
          <Typography
            sx={{
              fontSize: "25px",
              lineHeight: "22px",
              color: "var(--neuro-white-text)",
              fontFamily: "Roboto Condensed",
              marginBottom: "25px",
            }}
          >
            Welcome to 22Neuro Clinic!!{" "}
            {/* <span className="welcome-text-user">{user?.name || ""}</span> */}
          </Typography>
          <Typography
            sx={{
              lineHeight: "20px",
              color: "var(--neuro-white-text)",
              fontFamily: "Roboto Condensed",
            }}
          >
            Your Comprehensive Companion on Your Brain Health Journey.
          </Typography>
        </Box>
      </Grid>
      {/* <Grid item xs={12} md={8.5} xl={5}>
        <Box
          sx={{
            backgroundColor: "var(--neuro-white-text)",
            borderRadius: "12px",
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.1)",
            padding: "48px 39px 42px 35px",
            display: "flex",
            alignItems: "center",
            height: "150px",
          }}
        >
          <Grid container spacing={2}>
            {[
              { label: "BLOOD", value: "A+" },
              { label: "HEIGHT", value: "175cm" },
              { label: "WEIGHT", value: "45kg" },
              { label: "PRESSURE", value: "120/80" },
            ].map((stat, index) => (
              <Grid item xs={3} key={index}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{
                      marginBottom: "11px",
                      lineHeight: "20px",
                      color: "var(--neuro-black-text)",
                    }}
                  >
                    {stat.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "25px",
                      lineHeight: "120%",
                      fontFamily: "Roboto Condensed",
                      color: "var(--neuro-bg-primary-seagreen)",
                    }}
                  >
                    {stat.value}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={3.5} xl={2}>
        <Box
          sx={{
            paddingTop: "19px",
            paddingRight: "13px",
            paddingLeft: "16px",
            bgcolor: "var(--neuro-button-bg-success)",
            borderRadius: "12px",
            border: "1px dashed var(--neuro-white-text)",
            height: "150px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "25px",
              lineHeight: "30px",
              fontFamily: "Roboto Condensed",
              color: "var(--neuro-white-text)",
              width: "max-content",
            }}
          >
            Start writing
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "25px",
              lineHeight: "30px",
              fontFamily: "Roboto Condensed",
              color: "var(--neuro-white-text)",
              width: "max-content",
              zIndex: 1,
            }}
          >
            Diary
          </Typography>
          <Box
            sx={{
              position: "absolute",
              bottom: "-7px",
              right: "0px",
              zIndex: 0,
            }}
          >
            <DiaryWithBgIcon />
          </Box>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default WelcomeCard;
