import { Box } from "@mui/material";

function CustomTabPanel(props: any) {
  // state & props
  const { children, value, index, noPadding = false, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ overflow: "auto" }}
      {...other}
    >
      {value === index && <Box sx={{ p: noPadding ? 0 : 3 }}>{children}</Box>}
    </Box>
  );
}

export default CustomTabPanel;
