import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentHeader from "../../shared/ComponentHeader";
import HorizontalTabs from "./HorizontalTabs";
import PersonalInfoPage from "./PersonalInfoPage"; // Assuming these components exist
import PatientFormsList from "../../patients/PatientFormsList";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import DoctorPersonalInfoPage from "../doctor/DoctorPersonalInfoPage";
import AdminPersonalInfoPage from "../admin/AdminPersonalInfoPage";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";

const PatientProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userRole } = useAuthStore();

  const tabs =
    userRole === "DOCTOR" || userRole === "ADMIN"
      ? ["Personal Info"]
      : ["Personal Info", "Forms"];

  // Initialize selectedTab based on the URL
  const initialTab = location.pathname.includes("forms")
    ? "Forms"
    : "Personal Info";

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (tab === "Personal Info") {
      navigate(`/profile/personal-info`);
    }
    if (tab === "Forms") {
      navigate(`/profile/forms`);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("personal-info")) {
      setSelectedTab("Personal Info");
    } else if (location.pathname.includes("forms")) {
      setSelectedTab("Forms");
    }
  }, [location.pathname]);

  return (
    <Box
      sx={{
        paddingInline: "20px",
        paddingBlock: "28px",
        display: "flex",
        flexDirection: "column",
        gap: "22px",
        width: "100%",
        height: "100%",
      }}
    >
      <ComponentHeader title="Profile" />

      {/* Horizontal Tabs */}
      <Box sx={{ marginBottom: "16px" }}>
        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onClick={onTabClick}
        />
      </Box>

      {/* Main Content */}
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          {selectedTab === "Personal Info" &&
            (userRole === "DOCTOR" ? (
              <DoctorPersonalInfoPage />
            ) : userRole === "ADMIN" ? (
              <AdminPersonalInfoPage />
            ) : (
              <PersonalInfoPage />
            ))}
          {selectedTab === "Forms" && (
            <PatientFormsList
              patientId={user?.patientId}
              patientName={capitalizeWordsPreservingSpaces(user?.name || "")}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientProfile;
