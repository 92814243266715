import {
  Box,
  Dialog,
  IconButton,
  Typography,
  Slide,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppointmentCalendar from "./AppointmentCalendar";
import AppointmentTimeSlots from "./AppointmentTimeSlots";
import SelectedDoctorCard from "./SelectedDoctorCard";
import AppointmentPayment from "./AppointmentPayment";
import { TransitionProps } from "@mui/material/transitions";
import Tooltip from "../ui/Tooltip";
import PaymentFooter from "./PaymentFooter";
import useAppointmentStore from "../../store/appointmentsStore";
import { AppointmentDrawerProps } from "../../types/appointments";

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const AppointmentDrawer: React.FC<AppointmentDrawerProps> = ({
  headerText,
  patientId,
  patientName,
  patientEmail,
  patientContact,
}) => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:600px)");

  const { setAppointmentDrawer, appointmentDrawerOpen } = useAppointmentStore();

  // callbacks & functions
  useEffect(() => {
    // close the drawer on unmount
    return () => {
      setAppointmentDrawer(false);
    };
  }, []);

  return (
    <Dialog
      fullScreen
      open={appointmentDrawerOpen}
      // onClose={() => setAppointmentDrawer(false)}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: isMobile ? "100vw" : "45vw",
          height: "100vh",
          marginLeft: "auto", // Align to the right
          maxWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
          backgroundColor: "var(--neuro-white-text)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* drawer header */}
        <Box
          sx={{
            background:
              "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(37, 129, 196, 1))",
            height: "60px",
            minHeight: "60px",
            // maxHeight: "60px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            paddingLeft: "20px",
          }}
        >
          <Tooltip title="Close this dialog" placement="right">
            <IconButton
              size="small"
              onClick={() => setAppointmentDrawer(false)}
            >
              <ArrowBackIcon sx={{ color: "var(--neuro-white-text)" }} />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{
              fontSize: "var(--neuro-font-size-tiny)",
              lineHeight: "var(--neuro-line-height-micro)",
              color: "var(--neuro-white-text)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
            }}
          >
            {headerText}
          </Typography>
        </Box>

        <Box sx={{ height: "calc(100% - 120px)", overflowY: "auto" }}>
          {/* middle content */}
          <Box
            sx={{
              paddingInline: isMobile ? "15px" : "20px",
              paddingTop: "18px",
              paddingBottom: "12px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SelectedDoctorCard />

            <AppointmentCalendar />

            <AppointmentTimeSlots />

            {/* dashed line separator */}
            <Box
              sx={{
                width: "100%",
                height: "1px",
                border: "1px dashed var(--neuro-secondary_border)",
              }}
            />
          </Box>

          {/* payment section */}
          <AppointmentPayment />
        </Box>

        <Box
          sx={{
            flexShrink: 0,
            position: "sticky",
            bottom: 0,
            zIndex: 1,
            minHeight: "60px",
            width: "100%",
            height: "60px",
          }}
        >
          <PaymentFooter
            patientId={patientId}
            patientName={patientName}
            patientEmail={patientEmail}
            patientContact={patientContact}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default AppointmentDrawer;
