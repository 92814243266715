import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Modal from "../ui/Modal";
import Tooltip from "../ui/Tooltip";
import AssistantIcon from "../svg/AssistantIcon";
import Button from "../ui/Button";
import useAppStore from "../../store/appStore";
import WhatsAppChatButton from "./WhatsAppChatButton";
import { HelpSupportModalProps } from "../../types/common";

const HelpSupportModal: React.FC<HelpSupportModalProps> = ({
  from = "",
  isButton = false,
}) => {
  const { isHelpModalOpen } = useAppStore();

  const [open, setOpen] = useState<boolean>(false);

  // callbacks & functions
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isHelpModalOpen ? (
        <>
          {!isButton && (
            <Box
              sx={{
                // position: "fixed",
                zIndex: 100,
                bottom: 10,
                right: 10,
              }}
            >
              <Button
                className="warning-secondary-button"
                variant="contained"
                onClick={() => setOpen(true)}
                sx={{
                  width:
                    from === "sidebar"
                      ? "100%"
                      : from === "login"
                        ? "250px"
                        : "auto",
                }}
              >
                {from === "sidebar" || from === "login" ? (
                  "Click Here"
                ) : (
                  <Box
                    sx={{ display: "flex", gap: "6px", alignItems: "center" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          lineHeight: "16px",
                          textAlign: "end",
                        }}
                      >
                        Need
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "16px",
                        }}
                      >
                        Assistance
                      </Typography>
                    </Box>
                    <AssistantIcon
                      height={32}
                      width={32}
                      color="var(--neuro-white-text)"
                    />
                  </Box>
                )}
              </Button>
            </Box>
          )}

          {isButton && (
            <Button
              startIcon={<AssistantIcon height={24} width={24} />}
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              onClick={() => setOpen(true)}
            >
              Need Assistance?
            </Button>
          )}

          <Modal open={open} width={500} closeIcon={true} onClose={handleClose}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={(event) => event.stopPropagation()}
            >
              <Box sx={{ marginBottom: "16px" }}>
                <AssistantIcon />
              </Box>
              <Typography
                // className="form-heading"
                sx={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "25px",
                  fontFamily: "Roboto Condensed",
                  lineHeight: "30px",
                  marginBottom: "9px",
                }}
              >
                Need Assistance?
              </Typography>

              <Typography
                // className="form-heading"
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontFamily: "Roboto Condensed",
                  lineHeight: "24px",
                  marginBottom: "9px",
                }}
              >
                You are in the right place
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "44px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: 700,
                      fontSize: "25px",
                      fontFamily: "Roboto Condensed",
                      lineHeight: "30px",
                    }}
                  >
                    Call Us : +91 84119-11234
                  </Typography>

                  <WhatsAppChatButton phoneNumber="8411911234" />
                  {/* <Button
                    variant="contained"
                    color="success"
                    className="success-button"
                  >
                    Chat on WhatsApp
                  </Button> */}
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default HelpSupportModal;
