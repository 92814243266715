// third-party imports
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

//project imports
import Button from "../../ui/Button";
import Conference from "./Conference";
import DoctorsNote from "./DoctorsNote";
import GeneralDetails from "./GeneralDetails";
import Medication from "./Medication";
import PatientHeader from "./PatientHeader";
import ReportsSection from "./ReportsSection";
import { useEffect, useState } from "react";
import useAuthStore from "../../../store/authStore";
import { isDoctor } from "../../../utils/auth";
import LoadingScreen from "../../shared/LoadingScreen";
import ContentCenter from "../../shared/ContentCenter";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import useAppStore from "../../../store/appStore";
import {
  getConsultationSummary,
  postConsultationSummary,
} from "../../../services/appointmentsService";
import useAppointmentStore from "../../../store/appointmentsStore";

const Meet = () => {
  // props & state values
  const { appointmentId } = useParams();

  const { medicines } = useAppointmentStore();

  const {
    setHelpModalOpen,
    setIsMenuCollapsed,
    setShowHeader,
    setShowSidebar,
    showSnackbar,
  } = useAppStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [createSummaryLoading, setCreateSummaryLoading] =
    useState<boolean>(false);

  const [summaryDetails, setSummaryDetails] = useState<any>(null);

  const { userRole } = useAuthStore();

  // callbacks & functions
  const fetchConsultationSummary = async (id: string) => {
    try {
      const response = await getConsultationSummary(id);
      setSummaryDetails(response?.data || null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const createSummary = async (saveType: string, data: any) => {
    setCreateSummaryLoading(true);
    showSnackbar("Updating medications, please wait", "info");
    const params = {
      saveType,
    };
    try {
      const response = await postConsultationSummary(params, data);
      if (response?.data) {
        showSnackbar("Medications updated successfully", "success");
      }
      setCreateSummaryLoading(false);
    } catch (error) {
      showSnackbar("Could not update medications", "error");
      setCreateSummaryLoading(false);
    }
  };

  useEffect(() => {
    setIsMenuCollapsed(true);
    setShowHeader(false);
    setShowSidebar(false);
    setHelpModalOpen(false);

    return () => {
      setShowHeader(true);
      setShowSidebar(true);
      setHelpModalOpen(true);
    };
  }, []);

  useEffect(() => {
    if (appointmentId) {
      fetchConsultationSummary(appointmentId);
    }
  }, [appointmentId]);

  const handleCreateSummary = () => {
    const medicineData =
      medicines && medicines.length > 0
        ? medicines
            .filter((medicine) => {
              // Check that all required fields have values
              return (
                medicine.medication.trim() !== "" &&
                medicine.medicationType.trim() !== "" &&
                String(medicine.dosageAmount).trim() !== "" &&
                medicine.dosageUnit.trim() !== "" &&
                String(medicine.durationAmount).trim() !== "" &&
                medicine.durationUnit.trim() !== "" &&
                medicine.frequency.trim() !== ""
              );
            })
            .map((medicine) => ({
              ...medicine,
              dosageAmount: Number(medicine.dosageAmount),
              durationAmount: Number(medicine.durationAmount),
            }))
        : [];

    const data = {
      appointmentId: appointmentId,
      medications: medicineData,
    };

    if (appointmentId) {
      createSummary("MEDICATIONS", data);
    }
  };

  return (
    <Box
      sx={{
        // paddingBlock: "28px",
        // paddingInline: "20px",
        width: "100vw",
        height: "100vh", // Ensure it takes full viewport height
        overflow: "hidden", // Prevent any potential overflow
      }}
    >
      <Grid container sx={{ height: "100vh", overflow: "hidden" }}>
        <Grid
          item
          xs={12}
          // md={6}
          md={isDoctor(userRole) ? 6 : 12}
          sx={{ height: "100vh", overflow: "hidden" }} // Ensure full height
        >
          <Conference appointmentId={appointmentId || ""} />
        </Grid>

        {isDoctor(userRole) && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "var(--neuro-white-text)",
              borderLeft: "1px solid #000",
            }}
          >
            {loading && <LoadingScreen />}

            {!loading && !summaryDetails && (
              <ContentCenter>
                <Typography className="no-data">Details not found</Typography>
              </ContentCenter>
            )}

            {!loading && summaryDetails && (
              <>
                <PatientHeader
                  name={
                    capitalizeWordsPreservingSpaces(
                      summaryDetails?.patientGeneralDetails?.Name || ""
                    ) || ""
                  }
                />
                <Box
                  sx={{
                    paddingInline: "30px",
                    paddingTop: "18px",
                    paddingBottom: "100px",
                    height: "calc(100% - 80px)",
                    overflowY: "auto",
                  }}
                >
                  <GeneralDetails
                    patientDetails={summaryDetails?.patientGeneralDetails}
                  />
                  <ReportsSection reports={summaryDetails?.reports} />

                  <DoctorsNote
                    appointmentId={appointmentId}
                    doctorNotes={summaryDetails?.doctorNotes}
                  />

                  <Medication
                    appointmentId={appointmentId}
                    medications={summaryDetails?.prescription}
                  />

                  <Box
                    sx={{
                      marginTop: "100px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="primary-button"
                      onClick={handleCreateSummary}
                      disabled={createSummaryLoading}
                    >
                      Submit & send Prescription to Patient
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Meet;
