import React, { useState } from "react";
import { deleteHospital } from "../../services/hospitalService";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditHospitalModal from "./AddEditHospitalModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Hospital } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { HospitalsListProps } from "../../types/hospitals";
import { getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";

const HospitalsList: React.FC<HospitalsListProps> = ({
  hospitals,
  loading,
  loadHospitals,
}) => {
  console.log("hospitals", hospitals);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteHospitalId, setDeleteHospitalId] = useState<string | null>(null);
  const [editHospital, setEditHospital] = useState<Hospital | null>(null);

  const handleAddNew = () => {
    setEditHospital(null);
    setDialogOpen(true);
  };

  const handleEdit = (hospital: Hospital) => {
    setEditHospital(hospital);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteHospitalId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteHospitalId) {
      try {
        await deleteHospital(deleteHospitalId);
        loadHospitals();
      } catch (error) {
        console.error("Error deleting hospital:", error);
      }
    }
    setDeleteHospitalId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadHospitals();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          minHeight: "100%",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          marginTop={"15px"}
        >
          <ComponentHeader title="Clinic List" />

          <Button
            variant="outlined"
            color="secondary"
            className="outlined-secondary-button"
            startIcon={<AddIcon />}
            onClick={handleAddNew}
          >
            Add New
          </Button>
        </Box>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Clinic Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    City
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    State
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Country
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Contact Number
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Timezone
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Email
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Registered On
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hospitals?.map((hospital) => (
                  <TableRow key={hospital.id} sx={{ height: "40px" }}>
                    <TableCell>{hospital.name}</TableCell>
                    <TableCell>{hospital.city}</TableCell>
                    <TableCell>{hospital.state}</TableCell>
                    <TableCell>{hospital.country}</TableCell>
                    <TableCell>{hospital.contactNumber}</TableCell>
                    <TableCell>{hospital.timezone}</TableCell>
                    <TableCell>{hospital.email || "-"}</TableCell>
                    <TableCell>
                      {getFormattedDate(hospital?.createdAt || "-")}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-around">
                        <IconButton
                          onClick={() => handleEdit(hospital)}
                          color="secondary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(hospital.id)}
                          color="primary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <AddEditHospitalModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          hospital={editHospital}
          loadHospitals={loadHospitals}
        />
        <ConfirmDialog
          open={confirmOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this hospital?"
          onClose={handleConfirmClose}
        />
      </Box>
      <br></br>
    </>
  );
};

export default HospitalsList;
