import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Typography,
  Box,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  IconButton,
} from "@mui/material";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import useAuthStore from "../../../store/authStore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import useProfileStore from "../../../store/profileStore";
import { DateTime } from "luxon";
import useAppStore from "../../../store/appStore";
import {
  PatientProfileFormInputs,
  PersonalInfoFormProps,
} from "../../../types/profile";
import { patientProfileSchema } from "../../../validations/profileValidation";

const PersonalInfoPage: React.FC<PersonalInfoFormProps> = ({ patientId }) => {
  // props & state values
  const {
    profileDetails,
    getProfileDetails,
    updateProfile,
    loadingUpdateProfile,
    errorUpdateProfile,
    updatedProfileDetails,
    cleanUpUpdateProfileState,
  } = useProfileStore();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    reset,
    trigger,
    watch,
  } = useForm<PatientProfileFormInputs>({
    resolver: yupResolver(patientProfileSchema),
    defaultValues: {},
  });

  const [loading, setLoading] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState<
    string | undefined
  >();
  const { showSnackbar } = useAppStore();
  const initialValues = useRef<any>(null);

  const { user } = useAuthStore();

  // callbaks & functions
  const setTimeToZero = (date: Date): Date => {
    const newDate = new Date(date);

    newDate.setHours(0, 0, 0, 0);

    return newDate;
  };

  useEffect(() => {
    if (patientId) {
      getProfileDetails(patientId, "PATIENT");
    } else if (!patientId) {
      getProfileDetails();
    }
  }, []);

  useEffect(() => {
    if (loadingUpdateProfile) {
      showSnackbar("Updating profile, please wait", "info");
    } else if (errorUpdateProfile) {
      showSnackbar("Could not update profile, please try again", "error");
      cleanUpUpdateProfileState();
    } else if (updatedProfileDetails) {
      showSnackbar("Profile updated", "success");
      if (patientId) {
        getProfileDetails(patientId, "PATIENT");
      } else if (!patientId) {
        getProfileDetails();
      }
      cleanUpUpdateProfileState();
    }
  }, [updatedProfileDetails, errorUpdateProfile, loadingUpdateProfile]);

  useEffect(() => {
    if (profileDetails) {
      const dateOfBirth = profileDetails.dateOfBirth
        ? DateTime.fromISO(profileDetails.dateOfBirth)
        : null; // Handle null or invalid dates safely
      // const dateOfBirth = new Date(profileDetails.dateOfBirth);
      // const dateWithZeroTime = setTimeToZero(dateOfBirth);
      const defaultValues = {
        firstName: profileDetails.user.firstName || "",
        lastName: profileDetails.user.lastName || "",
        mobile: profileDetails.user.mobile || "",
        email: profileDetails.user.email || "",
        height: profileDetails.height || "",
        weight: profileDetails.weight || "",
        city: profileDetails.city || "",
        state: profileDetails.state || "",
        zipCode: profileDetails.zipCode || "",
        country: profileDetails.country || "",
        gender: profileDetails.gender || "",
        age: profileDetails.age || "",
        dateOfBirth: dateOfBirth,
        streetAddress: profileDetails.streetAddress || "",
        profileImage: profileDetails.user?.profileImageUrl || undefined,
      };
      reset(defaultValues);
      setProfileImagePreview(profileDetails.user.profileImageUrl);
      initialValues.current = defaultValues;
    }
  }, [profileDetails, reset]);

  // useEffect(()=> {

  // },[])

  const onSubmit: SubmitHandler<PatientProfileFormInputs> = async (data) => {
    // setLoading(true);
    // setLoading(false);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] as any;
    if (file) {
      setProfileImagePreview(URL.createObjectURL(file));
      const formData = new FormData();
      formData.append("profileUrl", file);
      formData.append("table", "USER");
      formData.append("profileImageUrl", profileDetails.user.profileImageUrl);
      await updateProfile(profileDetails.user.id, formData);
      showSnackbar(`Your profile updated successfully.`, "success");
    }
  };

  const handleRemoveImage = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setProfileImagePreview(undefined);
    setValue("profileImage", null);
    event.stopPropagation();
  };

  const onBlurHandler = async (event: any) => {
    const key = event?.target.name as keyof PatientProfileFormInputs;
    const value = event?.target.value as any;
    const table = key in profileDetails ? "PATIENT" : "USER";
    const data = { [key]: value, table };

    const isValid = await trigger(key);
    if (!isValid) return;

    const initialValue = initialValues.current
      ? initialValues.current[key]
      : undefined;
    if (initialValue === value) return;

    try {
      const updatedPatient = await updateProfile(profileDetails.user.id, data);
      showSnackbar(`Your profile updated successfully.`, "success");
      initialValues.current[key] = value;
    } catch (error: any) {
      showSnackbar(`${error.response?.data.message}`, "error");
      return;
    }
  };

  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        gap: "11px",
        height: "100%",
        width: "100%",
      }}
    >
      {/* <Typography
        sx={{
          fontWeight: 700,
          color: "var(--neuro-button-bg-success)",
          fontSize: "20px",
          fontFamily: "Roboto Condensed",
          lineHeight: "24px",
          marginBottom: "11px",
        }}
      >
        Personal Info
      </Typography> */}

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          margin: "0 auto",
          background: "var(--neuro-white-text)",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid container rowGap={1} columnGap={2.75}>
          <Grid item container xs={10} spacing={2.75}>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
              xs={12}
              md={9}
            >
              <Grid item xs={12} md={9}>
                <Input
                  type="text"
                  className="input-primary"
                  label="First name *"
                  required
                  {...register("firstName")}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  className="input-primary"
                  type="text"
                  label="Last name *"
                  {...register("lastName")}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Input
                  startAdornment={<Typography>+91</Typography>}
                  type="text"
                  className="input-primary"
                  label="Phone *"
                  {...register("mobile")}
                  error={!!errors?.mobile}
                  helperText={errors?.mobile?.message}
                  onBlur={(e: any) => onBlurHandler(e)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  position: "relative",
                  width: "190px",
                  height: "190px",
                  border: "2px solid var(--neuro-button-bg-success)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                  boxSizing: "content-box",
                  overflow: "hidden",
                }}
              >
                <Avatar
                  src={profileImagePreview || ""}
                  sx={{
                    width: "190px",
                    height: "190px",
                    border: "2px solid var(--neuro-button-bg-success)",
                    backgroundColor: "var(--neuro-bg-light-grey-secondary)",
                    overflow: "hidden",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "30%",
                    backgroundColor: "var(--neuro-button-bg-success)",
                    borderBottomLeftRadius: "50%",
                    borderBottomRightRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                  }}
                >
                  {profileImagePreview ? (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 2px var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "7px",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                      onClick={handleRemoveImage}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "24px",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      component="label"
                      sx={{
                        overflow: "hidden",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "var(--neuro-button-bg-success)",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // boxShadow: "0 0 0 2px var(--neuro-button-bg-success)",
                        position: "absolute",
                        bottom: "7px",
                        "&:hover": {
                          backgroundColor: "var(--neuro-button-bg-success)",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          fontSize: "24px",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="profile-image-upload"
                        type="file"
                        {...register("profileImage")}
                        onChange={(e) => {
                          register("profileImage").onChange(e);
                          handleImageChange(e);
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                disabled
                type="email"
                className="input-primary"
                label="E-mail *"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontFamily: "Roboto Condensed",
                  marginBottom: "6px",
                }}
              >
                Gender *
              </Typography>

              <Controller
                defaultValue="MALE"
                name="gender"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    row
                    {...field}
                    onBlur={(e: any) => onBlurHandler(e)}
                  >
                    <FormControlLabel
                      value="MALE"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="FEMALE"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                )}
              />
              {errors.gender && (
                <Typography color="error" variant="body2">
                  {errors.gender.message}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item container spacing={2.5} alignItems="flex-end">
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontFamily: "Roboto Condensed",
                  marginBottom: "6px",
                }}
              >
                Date of Birth Or Age*
              </Typography>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      value={field.value || null} // Ensure value is not undefined
                      onChange={(date) => {
                        field.onChange(date);
                        onBlurHandler({
                          target: {
                            name: "dateOfBirth",
                            value: date,
                          },
                        });
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              height: "40px",
                              borderRadius: "10px",
                              backgroundColor: "#f4f4f4",
                              borderColor: "#f4f4f4",
                              "& >fieldset": {
                                borderColor: "#f4f4f4",
                              },
                              "& >fieldset:hover": {
                                borderColor: "#f4f4f4",
                              },
                              "& >fieldset:active": {
                                borderColor: "#f4f4f4",
                              },
                              "& >fieldset:focus": {
                                borderColor: "#f4f4f4",
                              },
                              "& >.Mui-focoused.MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid #f4f4f4",
                                },
                              "& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root>.MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid #f4f4f4",
                                },
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiFormControl-root": {
                          width: "100%",
                        },
                      }}
                      // renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />

              {errors.dateOfBirth && (
                <Typography color="error" variant="body2">
                  {/* {errors.dateOfBirth.message} */}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                // className="input-secondary"
                className="input-primary"
                endAdornment={<Typography>Years</Typography>}
                sx={{
                  "& >.MuiInputBase-root": {
                    height: "40px",
                  },
                }}
                type="text"
                {...register("age")}
                error={!!errors.age}
                helperText={errors.age?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          {/* <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Roboto Condensed",
                  marginBottom: "6px",
                }}
              >
                Date of birth
              </Typography>
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DatePicker
                      value={field.value || null} // Ensure value is not undefined
                      onChange={(date) => {
                        field.onChange(date);
                        onBlurHandler({
                          target: { name: "dateOfBirth", value: date },
                        });
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          sx: {
                            backgroundColor:
                              "var(--neuro-input-bg-primary) !important",
                            "& .MuiInputBase-root": {
                              backgroundColor:
                                "var(--neuro-input-bg-primary) !important",
                            },
                            "& .MuiOutlinedInput-root": {
                              height: "46px",
                              borderRadius: "10px",
                              backgroundColor:
                                "var(--neuro-input-bg-primary) !important",
                            },
                          },
                        },
                      }}
                      sx={{
                        "& .MuiFormControl-root": {
                          width: "100%",
                          backgroundColor:
                            "var(--neuro-input-bg-primary) !important",
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors.dateOfBirth && (
                <Typography color="error" variant="body2">
                  {errors.dateOfBirth.message}
                </Typography>
              )}
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Input
                type="number"
                className="input-primary"
                label="age"
                {...register("age")}
                error={!!errors?.mobile}
                helperText={errors?.mobile?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid> */}

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Height"
                {...register("height")}
                error={!!errors.height}
                helperText={errors.height?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                className="input-primary"
                type="text"
                label="Weight"
                {...register("weight")}
                error={!!errors.weight}
                helperText={errors.weight?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Input
              type="text"
              // className="input-primary"
              label="Street address"
              {...register("streetAddress")}
              error={!!errors.streetAddress}
              helperText={errors.streetAddress?.message}
              className="input-secondary-textarea"
              rows={3}
              multiline
              onBlur={(e: any) => onBlurHandler(e)}
            />
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="City"
                {...register("city")}
                error={!!errors.city}
                helperText={errors.city?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="State"
                {...register("state")}
                error={!!errors.state}
                helperText={errors.state?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2.75}>
            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Zip code"
                {...register("zipCode")}
                error={!!errors?.zipCode}
                helperText={errors?.zipCode?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Input
                type="text"
                className="input-primary"
                label="Country"
                {...register("country")}
                error={!!errors.country}
                helperText={errors.country?.message}
                onBlur={(e: any) => onBlurHandler(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Box mt={7} sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <Button type="submit" className="dark-button" variant="contained">
              Edit
            </Button>
            <Button
              type="submit"
              className="success-button"
              variant="contained"
              color="success"
            >
              Save
            </Button>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default PersonalInfoPage;
