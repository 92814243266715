import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PendingIcon from "@mui/icons-material/Pending";
import { getFormattedDateTime } from "../../../utils/appointments";
import useDocumentStore from "../../../store/documentsStore";
import { ViewerReportCardProps } from "../../../types/documents";

const getStatusIcon = (isSummaryGenerated: boolean) => {
  if (isSummaryGenerated) {
    return <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />;
  } else {
    return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
  }
};

const FileTypeIcon: React.FC<{ fileType: string }> = ({ fileType }) => {
  const fileTypeIconMap: { [key: string]: JSX.Element } = {
    PDF: <PictureAsPdfIcon sx={iconStyles} />,
    DICOM: <NoteAddIcon sx={iconStyles} />,
  };

  return fileTypeIconMap[fileType] || <ImageIcon sx={iconStyles} />;
};

const iconStyles = {
  width: 32,
  height: 40,
  fill: "var(--neuro-svg-error)",
  color: "var(--neuro-svg-error)",
  display: "block",
};

const ViewerReportCard: React.FC<ViewerReportCardProps> = ({
  id,
  name,
  reportDate,
  isSummaryGenerated,
  status,
  fileType,
  createdAt,
  setSelectedDocumentId,
  onClick,
  patientId,
  from = "",
}) => {
  // props & state values
  const navigate = useNavigate();

  const reportDateObj = getFormattedDateTime(reportDate);
  const createdAtObj = getFormattedDateTime(createdAt);

  const { selectedDocumentId } = useDocumentStore();

  // callbacks & functions
  const handleCardClick = (id: string) => {
    if (setSelectedDocumentId) {
      setSelectedDocumentId(id);
    }
    if (from === "list" && !patientId) {
      navigate("/documents/view");
    }
    if (patientId) {
      navigate(`/patients/${patientId}/documents/view`);
    }
    if (from === "conference" && onClick) {
      onClick();
    }
    // navigate("/documents/view", { state: { documentId: id } });
  };

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "dd-MM-yyyy");
  };

  return (
    <Box
      sx={{
        backgroundColor:
          selectedDocumentId === id
            ? from === "list"
              ? "var(--neuro-white-text)"
              : "var(--neuro-bg-light-grey-primary)"
            : "var(--neuro-white-text)",
        border: "1px solid var(--neuro-secondary_border)",
        borderRadius: "10px",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={() => handleCardClick(id)}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <FileTypeIcon fileType={fileType} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "16.8px",
                fontFamily: "Roboto",
                color: "var(--neuro-bg-darkblue-primary)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: setSelectedDocumentId ? "120px" : "200px",
              }}
            >
              {name || ""}
            </Typography>
            {getStatusIcon(isSummaryGenerated)}
          </Box>
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "16px",
              fontFamily: "Roboto Slab",
            }}
          >
            {setSelectedDocumentId ? "Date" : "Report date"}:{" "}
            {reportDateObj
              ? reportDateObj.day +
                "-" +
                reportDateObj.monthNumber +
                "-" +
                reportDateObj.year
              : ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "16px",
              fontFamily: "Roboto Slab",
            }}
          >
            Upload At :{" "}
            {createdAtObj
              ? createdAtObj.day +
                "-" +
                createdAtObj.monthNumber +
                "-" +
                createdAtObj.year
              : ""}
          </Typography>
        </Box>
      </Box>

      {/* {
        <IconButton
          disabled={status === "DELETED"}
          onClick={(event) => handleDeleteDocumet(event, id)}
          sx={{
            "&.Mui-disabled > svg": {
              opacity: 0.5,
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      } */}

      {/* confirm modal */}
      {/* <ConfirmModal
        open={openConfirmModal}
        description="Are you sure, you want to delete this document"
        handleConfirm={() => handleConfirm(id)}
        handleCancel={handleCancel}
      /> */}
    </Box>
  );
};

export default ViewerReportCard;
