import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import Drawer from "../shared/Drawer";
import PatientFormsList from "./PatientFormsList";
import AllFormsList from "./AllFormsList";
import { useNavigate } from "react-router-dom";
import GeneralForm from "../forms/GeneralForm";
import assignFormStore from "../../store/formStore";
import FormFilledDetails from "../forms/FormFilledDetails";
import useAppStore from "../../store/appStore";
import { PatientFormsProps } from "../../types/common";
import { isAdmin } from "../../utils/auth";
import useAuthStore from "../../store/authStore";

const PatientForms: React.FC<PatientFormsProps> = ({
  patientId,
  patientName,
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<any>(null);
  const { showSnackbar } = useAppStore();
  const { userRole } = useAuthStore();

  const isMobile = useMediaQuery("(max-width:600px)");

  const [showFormList, setShowFormList] = useState<boolean>(true);

  const navigate = useNavigate();

  const {
    formSubmitted,
    formAssigned,
    assignForm,
    submitLoading,
    submitError,
    submitFormResponse,
    cleanUpFormSubmittedState,
  } = assignFormStore();

  // callbacks & functions
  const onSubmitForm = (responses: any, score: number) => {
    const data = {
      responses,
      score,
    };
    submitFormResponse(formAssigned?.id, data);
  };

  useEffect(() => {
    return () => {
      setSelectedForm(null);
      cleanUpFormSubmittedState();
    };
  }, []);

  useEffect(() => {
    if (!patientId) {
      if (isAdmin(userRole)) {
        navigate("/patients");
      } else {
        navigate("/appointments");
      }
    }
  }, [patientId]);

  useEffect(() => {
    if (submitError) {
      showSnackbar("Could not submit form, please try again", "error");
      cleanUpFormSubmittedState();
    }
    if (submitLoading) {
      showSnackbar("Form is submitting, please try again", "info");
    }

    if (formSubmitted) {
      showSnackbar("Form is submittied", "success");
    }
  }, [formSubmitted, submitLoading, submitError]);

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedForm(null);
    setShowFormList(true);
    cleanUpFormSubmittedState();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "100%",
        height: "100%",
      }}
    >
      <ComponentHeader title="">
        <Button
          color="primary"
          variant="contained"
          className="primary-button"
          onClick={() => setDrawerOpen(true)}
        >
          Add Form
        </Button>
      </ComponentHeader>

      <PatientFormsList
        patientId={patientId || null}
        patientName={patientName}
      />

      <Drawer
        open={drawerOpen}
        width={isMobile ? "100vw" : "45vw"}
        headerText={patientName}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedForm(null);
          setShowFormList(true);
          cleanUpFormSubmittedState();
        }}
      >
        <Box
          sx={{
            paddingInline: "16px",
            paddingTop: "20px",
            height: "calc(100% - 60px)",
            overflowY: "auto",
          }}
        >
          {showFormList && (
            <Box>
              <AllFormsList
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
              />
              <Box
                sx={{
                  marginBlock: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="success"
                  variant="contained"
                  className="success-button"
                  size="large"
                  disabled={!selectedForm}
                  sx={{ height: 42, marginRight: "10px" }}
                  onClick={() => {
                    const data = {
                      patientId: patientId,
                      formId: selectedForm?.id,
                    };
                    assignForm(data);
                    setDrawerOpen(false);
                    setSelectedForm(null);
                    setShowFormList(true);
                  }}
                >
                  Assign Form
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  className="success-button"
                  size="large"
                  disabled={!selectedForm}
                  sx={{ height: 42 }}
                  onClick={() => {
                    const data = {
                      patientId: patientId,
                      formId: selectedForm?.id,
                    };
                    assignForm(data);
                    setShowFormList(false);
                  }}
                >
                  Assign & Fill Form
                </Button>
              </Box>
            </Box>
          )}

          {!showFormList && selectedForm && (
            <>
              {formSubmitted ? (
                <FormFilledDetails
                  score={
                    selectedForm?.isScoreBased &&
                    formSubmitted?.score !== null &&
                    formSubmitted?.score !== undefined
                      ? formSubmitted?.score
                      : null
                  }
                  patientName={patientName}
                  onClose={onCloseDrawer}
                />
              ) : (
                <GeneralForm
                  formSchema={selectedForm?.questions}
                  onFinish={onSubmitForm}
                  loading={submitLoading}
                  patientName={patientName}
                  onClosing={onCloseDrawer}
                />
              )}
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default PatientForms;
