import React from "react";
import { Typography, Box, IconButton, useMediaQuery } from "@mui/material";
import Tooltip from "../ui/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ComponentHeaderProps } from "../../types/common";

const ComponentHeader: React.FC<ComponentHeaderProps> = ({
  title,
  alert,
  children,
  handleBack,
  page,
  sx,
  isBreadcrumb = false,
}) => {
  const getFlexValue = () => {
    if (!alert && !children) {
      return 1;
    }
    if (!alert && children) {
      return 0.5;
    }
    if (alert && !children) {
      return 0.5;
    } else {
      return 0.3;
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flex: getFlexValue(),
        }}
      >
        {page && (
          <Tooltip title={`Back to ${page}`} placement="top">
            <IconButton onClick={handleBack} size="small">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        )}

        <Typography
          sx={{
            // fontSize: isBreadcrumb ? "18px" : "31px",
            // fontWeight: isBreadcrumb ? 500 : 400,
            // lineHeight: isBreadcrumb ? "normal" : "37.2px",
            fontSize: isBreadcrumb ? "26px" : isMobile ? "25px" : "31px",
            fontWeight: 400,
            lineHeight: isMobile ? "30px" : "37.2px",
            fontFamily: "Roboto Condensed",
            color: isMobile
              ? "var(--neuro-darkblue-text)"
              : "var(--neuro-black-text)",
          }}
        >
          {title}
        </Typography>
      </Box>

      {alert && (
        <Box sx={{ flex: !children ? 0.5 : 0.4, textAlign: "center" }}>
          {alert}
        </Box>
      )}

      {children && (
        <Box
          sx={{
            flex: !alert ? 0.5 : 0.3,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default ComponentHeader;
