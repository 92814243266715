import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "../ui/Button";
import HelpSupportModal from "../shared/HelpSupportModal";
import AssistantIcon from "../svg/AssistantIcon";

const SidebarFooter: React.FC = () => {
  return (
    <Box
      bgcolor="var(--neuro-bg-darkblue-primary)"
      textAlign="center"
      sx={{
        borderRadius: "30px 30px 0px 0px",
        padding: "17px 20px 30px 20px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "var(--neuro-white-text)",
          borderRadius: "20px",
          height: "150px",
          marginBottom: "33px",
          // paddingLeft: "21px",
          // paddingTop: "28px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* <Typography
          sx={{
            fontSize: "25px",
            fontWeight: 700,
            lineHeight: "30px",
            fontFamily: "Roboto Condensed",
            textAlign: "start",
            color: "var(--neuro-black-text)",
            zIndex: 1,
          }}
        >
          {/* 22Neuro */}
        {/* Need Assistance */}
        {/* </Typography> */}
        {/* <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Roboto Condensed",
            textAlign: "start",
            color: "var(--neuro-black-text)",
            zIndex: 1,
          }}
        >
          {/* has providing you an */}
        {/* on below button for */}
        {/* </Typography> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <AssistantIcon height={60} width={60} />
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: 700,
              lineHeight: "30px",
              fontFamily: "Roboto Condensed",
              textAlign: "start",
              paddingLeft: "15px",
              color: "var(--neuro-black-text)",
              zIndex: 1,
            }}
          >
            {/* Excellent{" "} */}
            Need Assistance?
            {/* <span
            style={{
              fontSize: "20px",
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Roboto Condensed",
            }}
          >
            offers...
          </span> */}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            border: "1px dashed var(--neuro-grey_border)",
            backgroundColor: "var(--neuro-bg-light-grey)",
            width: "156px",
            height: "156px",
            bottom: "-27px",
            left: "-22px",
            borderRadius: "50%",
            opacity: 0.5,
          }}
        >
          <img
            src="/uprade-bg.png"
            alt="upgrade-logo"
            style={{
              position: "absolute",
              bottom: 4,
              left: 4,
              opacity: 1,
            }}
          />
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ borderRadius: "20px" }}
      >
        Upgrade Now
      </Button> */}
      <HelpSupportModal from="sidebar" />
    </Box>
  );
};

export default SidebarFooter;
