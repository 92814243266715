import { MeetTokenApiParams } from "../types/appointments";
import api from "./apiService";

export const getToken = (data: MeetTokenApiParams) => {
  return api.post(`/api/token/meet`, data);
};

export const getAllDoctors = (params: {
  specialization: string | undefined;
}) => {
  return api.get(`/api/doctors`, {
    params,
  });
};

export const getAppointments = (id?: string) => {
  if (id && id !== "") {
    const params = {
      patientId: id,
    };
    return api.get(`/api/appointments`, { params });
  }
  return api.get(`/api/appointments`);
};

export const getAppointmentDetails = (id: string) => {
  return api.get(`/api/appointments/${id}`);
};

export const getTimeSlotsForDoctor = (
  id: string,
  params: { meetingType: string; date: string }
) => {
  return api.get(`/api/slots/${id}`, {
    params,
  });
};

export const getTimeSlotsForDate = (params: {
  meetingType: string;
  date: string;
}) => {
  return api.get(`/api/slots`, {
    params,
  });
};

export const getDoctorsForDate = (params: {
  meetingType: string;
  startTime: string;
}) => {
  return api.get(`/api/slots/availableDoctors`, {
    params,
  });
};

export const getAppointmentConfirmationDetails = (appointmentId: string) => {
  return api.get(`/api/appointments/confirmation/${appointmentId}`);
};

export const cancelAppointmentBooking = (
  appointmentId: string,
  data: { status: string }
) => {
  return api.put(`/api/appointments/cancel/${appointmentId}`, data);
};

export const fetchPayLater = (params: { paymentType: string }, data: any) => {
  return api.post(`/api/appointments`, data, {
    params,
  });
};

export const failAppointmentBooking = (id: string) => {
  return api.put(`/api/appointments/fail/${id}`);
};

export const getConsultationSummary = (id: string) => {
  return api.get(`/api/consultationSummary/${id}`);
};

export const postConsultationSummary = (
  data: any,
  params?: { saveType: string }
) => {
  if (params) {
    return api.post(`/api/consultationSummary`, data, { params });
  } else {
    return api.post(`/api/consultationSummary`, data);
  }
};

// to update status of payment when paid offline - done by admin
export const offlinePayment = (id: string) => {
  return api.put(`/api/appointments/offlinePayment/${id}`);
};
