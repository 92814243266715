import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Documents from "../documents/list";
import ReportViewer from "../documents/view/ReportViewer";
import { DocumentsProps } from "../../types/documents";

const PatientDocuments: React.FC<DocumentsProps> = ({ patientId }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!patientId) {
      navigate("/patients");
    }
  }, [patientId]);

  return (
    <>
      {window?.location?.pathname.includes("view") ? (
        <ReportViewer patientId={patientId} />
      ) : (
        <Documents patientId={patientId} />
      )}
    </>
  );
};

export default PatientDocuments;
