import React from "react";
import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { CheckboxProps } from "../../types/ui";

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      onChange,
      label,
      name,
      sx,
      className,
      id,
      checked,
      error,
      helperText,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <FormControlLabel
          control={
            <MUICheckbox
              ref={ref}
              checked={checked}
              onChange={onChange}
              id={id}
              name={name}
              className={className}
              {...props}
            />
          }
          label={label}
          sx={{
            "& .MuiTypography-root": {
              fontSize: "20px",
              fontWeight: 400,
              fontFamily: "Roboto Condensed",
              color: "var(--neuro-black-text)",
              lineHeight: "19.2px",
            },
            ...sx,
          }}
        />
        {error && helperText && (
          <FormHelperText error>{helperText}</FormHelperText>
        )}
      </>
    );
  }
);

export default Checkbox;
