import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Modal from "../ui/Modal";
import OtpInput from "../ui/OtpInput";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import { resendOtp, verifyOtp } from "../../services/authService";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Signup from "./Signup"; // Import the Signup component
import Logo from "../shared/Logo";
import { OtpModalProps } from "../../types/auth";
import { isValidAadhar, isValidAbha } from "../../utils/common";
import useAppStore from "../../store/appStore";
import Input from "../ui/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Define validation schema for Yup
const schema = yup.object().shape({
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, "OTP must be 6 digits")
    .required("OTP is required"),
});

interface FormInputs {
  otp: string;
}

const OtpModal: React.FC<OtpModalProps> = ({
  open,
  onClose,
  width,
  closeIcon,
  otpToken,
  email,
  txnId,
  message,
  loggedInUserId,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const { showSnackbar } = useAppStore();
  const [otp, setOtp] = useState("");
  const aadharOrAbha = isValidAadhar(email) || isValidAbha(email);
  const [isAdharOrAbha, setIsAadharOrAbha] = useState(aadharOrAbha);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State to handle error message
  const [timer, setTimer] = useState(isAdharOrAbha ? 30 : 15); // Timer state
  const [isTimerActive, setIsTimerActive] = useState(false); // Timer activation state
  const [showSignUp, setShowSignUp] = useState(false); // State to handle whether to show the signup form
  const [userId, setUserId] = useState<string | null>(null); // State to store userId for signup

  const navigate = useNavigate();
  const numInputs = 6;
  const {
    login,
    //  setShowSignUpModal
  } = useAuthStore();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)

  useEffect(() => {
    const aadharOrAbha = isValidAadhar(email) || isValidAbha(email);
    setIsAadharOrAbha(aadharOrAbha);
    setTimer(isAdharOrAbha ? 30 : 15);
  }, [email]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (open && isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(interval!);
            setIsTimerActive(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [open, isTimerActive]);

  useEffect(() => {
    if (open) {
      // Reset states when modal opens
      setOtp("");
      setLoading(false);
      setResendLoading(false);
      setErrorMessage(null);
      setTimer(isAdharOrAbha ? 30 : 15);
      setIsTimerActive(true);
      setShowSignUp(false); // Reset signup form display
      setUserId(null); // Reset userId
    }
  }, [open]);

  const handleOtpSubmit = async () => {
    setLoading(true);
    setErrorMessage(null); // Reset the error message on each submit
    try {
      const result = await verifyOtp(email, otp, otpToken, txnId);
      console.log("result in otp submit", result);

      if (result.isSignUpRequired) {
        // If signup is required, show the signup modal - will uncomment 1st if showing in dashbaord and comment 2nd
        // setShowSignUpModal(true);
        setShowSignUp(true);

        console.log("result after verify otp", result);
        setUserId(result?.userId || undefined); // Store userId for completing registration

        // will uncomment if showing sign up in dashboard
        // login(result.accessToken, result.refreshToken);

        // will uncomment if showing in dashboard
        // if (onClose) {
        //   onClose();
        // }

        // will uncomment if showing signup in dashboard
        // navigate("/dashboard");
      } else {
        // Otherwise, proceed with login
        login(result.accessToken, result.refreshToken);
        navigate("/dashboard");
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        // Handle 401 error for invalid OTP
        if (error.response.status === 401) {
          setErrorMessage(
            error.response.data.message || "Invalid OTP. Please try again."
          );
        } else {
          console.error(
            "OTP verification failed:",
            error.response.data.message
          );
          setErrorMessage("An error occurred during OTP verification.");
        }
      } else {
        console.error("An unknown error occurred during OTP verification");
        setErrorMessage("An unknown error occurred. Please try again.");
      }
    }
    setLoading(false);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoading(true);
    setErrorMessage(null); // Reset the error message on each submit
    try {
      const result = await verifyOtp(
        email,
        data.otp,
        otpToken,
        txnId,
        loggedInUserId
      );
      console.log("result after verify otp", result);

      if (result.isSignUpRequired) {
        // If signup is required, show the signup modal in dashboard - will uncomment 1st and comment 2nd
        // setShowSignUpModal(true);
        setShowSignUp(true);

        console.log("result after verify otp", result);
        setUserId(result.userId); // Store userId for completing registration

        // for showing in login itself - will uncomment if showing in dashboard
        // login(result.accessToken, result.refreshToken);

        if (onClose) {
          onClose();
        }

        // will uncomment to show in dashboard
        // navigate("/dashboard");
      } else {
        // Otherwise, proceed with login
        if (result && result?.abhaAttached) {
          showSnackbar("Abha account linked", "success");
          if (onClose) {
            onClose();
          }
          window.location.reload();
        }
        console.log("result.accessToken in otp verify", result.accessToken);
        console.log("result.refreshToken in otp verify", result.refreshToken);
        login(result.accessToken, result.refreshToken);
        navigate("/dashboard");
        if (onClose) {
          onClose();
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        // Handle 401 error for invalid OTP
        if (error.response.status === 401) {
          setErrorMessage(
            error?.response?.data?.message || "Invalid OTP. Please try again."
          );
        } else {
          console.error(
            "OTP verification failed:",
            error?.response?.data?.message
          );
          setErrorMessage(
            error?.response?.data?.message ||
              "An error occurred during OTP verification."
          );
        }
      } else {
        console.error("An unknown error occurred during OTP verification");
        setErrorMessage(
          error?.response?.data?.message ||
            "An unknown error occurred. Please try again."
        );
      }
    }
    setLoading(false);
  };

  const handleResendOtp = async () => {
    setResendLoading(true);
    setErrorMessage(null); // Reset the error message on resend
    try {
      await resendOtp(email);
      setTimer(isAdharOrAbha ? 30 : 15); // Reset the timer to 15 seconds
      setIsTimerActive(true); // Reactivate the timer
    } catch (error: any) {
      console.log("error in resend", error?.response);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not re send otp, please try again",
        "error"
      );
    }
    setResendLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!showSignUp && event.key === "Enter" && otp.length === numInputs) {
      handleOtpSubmit();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      width={isMobile ? 320 : width ? (isAdharOrAbha ? 500 : width) : 540} // Adjust width
      // height={isMobile ? undefined : 450} // Adjust height
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: "20px",
        }}
        onKeyDown={isAdharOrAbha ? undefined : handleKeyDown}
        component={isAdharOrAbha ? "form" : "div"}
        onSubmit={isAdharOrAbha ? handleSubmit(onSubmit) : undefined}
      >
        {!showSignUp && (
          <>
            {!isAdharOrAbha && (
              <Box sx={{ marginBottom: "16px" }}>
                <Logo width={isMobile ? 80 : 100} />
              </Box>
            )}
            <Typography
              className="form-heading"
              sx={{
                textAlign: "center",
                // fontSize: isMobile ? "18px" : "24px",
                fontWeight: 700,
                fontSize: "39px",
                fontFamily: "Roboto Condensed",
                lineHeight: "46.8px",
              }}
            >
              {isAdharOrAbha ? "Aadhaar" : "Verify with OTP"}
            </Typography>
          </>
        )}

        {!showSignUp ? (
          <>
            <Typography
              sx={{
                fontSize: isMobile ? "14px" : "16px",
                fontWeight: isAdharOrAbha ? "bold" : 400,
                marginTop: "12px",
                marginBottom: isMobile ? "24px" : "32px",
                textAlign: "center",
              }}
            >
              {message && isAdharOrAbha
                ? message
                : "To ensure your security, please enter the One-Time Password (OTP) sent to your registered email / phone below."}
            </Typography>

            {!isAdharOrAbha && (
              <OtpInput value={otp} onChange={setOtp} numInputs={numInputs} />
            )}

            {isAdharOrAbha && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginTop: "16px",
                  marginBottom: errorMessage ? 0 : "16px",
                }}
              >
                <Input
                  type="text"
                  fullWidth
                  // className="input-secondary"
                  className="input-primary"
                  sx={{
                    "& >.MuiInputBase-root": {
                      height: "40px",
                    },
                  }}
                  label="Enter OTP*"
                  {...register("otp")}
                  error={!!errors.otp}
                  helperText={errors.otp?.message}
                />
              </Box>
            )}

            {/* Display error message */}
            {errorMessage && (
              <Typography
                color="error"
                sx={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  textAlign: "center",
                  fontSize: isMobile ? "12px" : "14px",
                }}
              >
                {errorMessage}
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                gap: "20px",
                alignItems: "center",
                mt: 4,
                mb: 2,
              }}
            >
              <Button
                type={isAdharOrAbha ? "submit" : "button"}
                variant="contained"
                color="success"
                disabled={
                  (isAdharOrAbha && loading) ||
                  (!isAdharOrAbha && (otp.length !== numInputs || loading))
                }
                className="success-button"
                onClick={isAdharOrAbha ? undefined : handleOtpSubmit}
                sx={{
                  textTransform: "none",
                  width: isMobile ? "100px" : 150,
                  height: isMobile ? "40px" : 48,
                  color: "var(--neuro-white-text)",
                }}
              >
                {loading ? <Spinner /> : "Submit"}
              </Button>
              <Button
                variant="text"
                color="secondary"
                disableRipple
                className="secondary-text-button"
                onClick={handleResendOtp}
                disabled={resendLoading || isTimerActive} // Disable while timer is active
                sx={{
                  textTransform: "none",
                  fontSize: isMobile ? "12px" : "14px",
                }}
              >
                {resendLoading ? (
                  <Spinner />
                ) : (
                  `Resend OTP ${isTimerActive ? `(${timer})` : ""}`
                )}
              </Button>
            </Box>

            {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="text"
                color="secondary"
                disableRipple
                className="secondary-text-button"
                onClick={handleResendOtp}
                disabled={resendLoading || isTimerActive} // Disable while timer is active
                sx={{
                  textTransform: "none",
                  fontSize: isMobile ? "12px" : "14px",
                }}
              >
                {resendLoading ? (
                  <Spinner />
                ) : (
                  `Resend OTP ${isTimerActive ? `(${timer})` : ""}`
                )}
              </Button>
            </Box> */}
          </>
        ) : (
          // Show signup form if required
          <Signup emailOrPhone={email} userId={userId!} />
        )}
      </Box>
    </Modal>
  );
};

export default OtpModal;
