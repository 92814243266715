import React from "react";

// third-party imports
import { Box, Typography, Switch } from "@mui/material";

// project imports
import DoctorGrid from "./NewAppointment/DoctorGrid";
import YourAvailability from "./NewAppointment/YourAvailability";
import SearchContainer from "./SearchContainer";
import AppointmentDrawer from "./AppointmentDrawer";
import { useNavigate, useParams } from "react-router-dom";
import ComponentHeader from "../shared/ComponentHeader";
import useAppointmentStore from "../../store/appointmentsStore";
import { SpecialityProps } from "../../types/appointments";
import { PatientAppointmentsProps } from "../../types/common";

const NewFlow: React.FC<PatientAppointmentsProps> = ({
  patientId,
  patientName,
  patientEmail,
  patientContact,
}) => {
  // props & state values
  const query = useParams();
  const navigate = useNavigate();

  const { yourAvailabilitySelected, setYourAvailabilitySelected } =
    useAppointmentStore();

  const [isNeurologistSelected, setIsNeurologistSelected] =
    React.useState(false);

  // callbacks & functions
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYourAvailabilitySelected(event.target.checked);
  };

  const handleBack = () => {
    navigate("/specialties");
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          padding: patientId ? 0 : "26px 20px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "28px",
            height: "100%",
          }}
        >
          {!patientId && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <ComponentHeader
                title="New Appointment"
                page="specialties"
                handleBack={handleBack}
              />
              {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: yourAvailabilitySelected
                    ? "var(--neuro-darkgrey_border)"
                    : "var(--neuro-black-text)",
                  textAlign: "right",
                  alignSelf: "stretch",
                  flexGrow: 1,
                  margin: "auto 0",
                  fontWeight: yourAvailabilitySelected ? 400 : 700,
                  lineHeight: "120%",
                  fontFamily:
                    "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
                }}
              >
                Doctor availability
              </Typography>

              <Switch
                checked={yourAvailabilitySelected}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />

              <Typography
                sx={{
                  color: yourAvailabilitySelected
                    ? "var(--neuro-black-text)"
                    : "var(--neuro-darkgrey_border)",
                  alignSelf: "stretch",
                  flexGrow: 1,
                  margin: "auto 0",
                  fontWeight: yourAvailabilitySelected ? 700 : 400,
                  lineHeight: "120%",
                  fontFamily:
                    "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
                }}
              >
                Your Availability
              </Typography>
            </Box> */}
            </Box>
          )}

          {/* filter header */}
          {/* <SearchContainer /> */}

          {!yourAvailabilitySelected && (
            <DoctorGrid
              isNeurologistSelected={isNeurologistSelected}
              patientId={patientId}
              selectedSpeciality={query.specialisation || ""}
            />
          )}

          {yourAvailabilitySelected && (
            <YourAvailability
              yourAvailabilitySelected={yourAvailabilitySelected}
              isNeurologistSelected={isNeurologistSelected}
            />
          )}
        </Box>
      </Box>
      <AppointmentDrawer
        headerText="Schedule Appointment"
        patientId={patientId}
        patientName={patientName}
        patientEmail={patientEmail}
        patientContact={patientContact}
      />
    </>
  );
};

export default NewFlow;
