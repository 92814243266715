import React, { useContext } from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import SidebarHeader from "./SidebarHeader";
import SidebarMenu from "./SidebarMenu";
import SidebarFooter from "./SidebarFooter";
import Badge from "../ui/Badge";
import config from "../../config";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { SidebarContentProps } from "../../types/common";

const SidebarContent: React.FC<SidebarContentProps> = ({
  menuItems,
  isMenuCollapsed,
  setDrawerOpen,
}) => {
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box
      sx={{
        width: isMenuCollapsed ? 0 : "300px",
        minWidth: isMenuCollapsed ? 0 : "300px",
        transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
        backgroundColor: "var(--neuro-white-text)",
        height: "100vh",
        color: "var(--neuro-black-text)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "1px solid var(--neuro-secondary_border)",
        overflow: "auto",
        position: "relative",
      }}
    >
      <Box>
        {!isMobile && <Badge label={config?.VERSION || ""} />}
        {isMobile && (
          <IconButton
            disableRipple
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              padding: 0,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "var(--neuro-white-text)",
                padding: 0,
              },
            }}
            onClick={() => setDrawerOpen(false)}
          >
            <ArrowBackIosNewIcon
              sx={{ fontSize: "24px", color: "var(--neuro-black-text)" }}
            />
          </IconButton>
        )}
        <SidebarHeader />
        <SidebarMenu menuItems={menuItems} />
      </Box>
      <SidebarFooter />
    </Box>
  );
};

export default SidebarContent;
