import React, { useState } from "react";

// third-party imports
import { Box, Typography, useMediaQuery } from "@mui/material";

// project imports
import { NewAppointmentListProps, Option } from "../../../types/appointments";
import ComponentHeader from "../../shared/ComponentHeader";
import TabAppointments from "./TabAppointments";
import FilterByAppointments from "./FilterByAppointments";

const NewAppointmentList: React.FC<NewAppointmentListProps> = ({
  appointments,
  loadingFetchAppointments,
  errorFetchAppointments,
  upcomingAppointments,
  ongoingAppointments,
  completedAppointments,
  cancelledAppointments,
  patientId,
  from = "",
}) => {
  // props & state values
  console.log("appointments in appoint list", appointments);

  const isMobile = useMediaQuery("(max-width:600px)");

  const options: Option[] = [
    { value: "All", label: "All" },
    { value: "Ongoing", label: "Ongoing" },
    { value: "Upcoming", label: "Upcoming" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
  ];

  const [selectedValue, setSelectedValue] = useState<string>("All");

  const getFilteredAppointments = () => {
    if (selectedValue === "All") {
      return appointments;
    } else if (selectedValue === "Ongoing") {
      return ongoingAppointments;
    } else if (selectedValue === "Upcoming") {
      return upcomingAppointments;
    } else if (selectedValue === "Completed") {
      return completedAppointments;
    } else if (selectedValue === "Cancelled") {
      return cancelledAppointments;
    } else {
      return appointments;
    }
  };

  return (
    <Box
      sx={{
        paddingLeft:
          from === "dashboard"
            ? isMobile
              ? 0
              : "16px"
            : !patientId
              ? isMobile
                ? "15px"
                : "20px"
              : 0,
        paddingTop:
          from === "dashboard"
            ? isMobile
              ? 0
              : "21px"
            : !patientId
              ? isMobile
                ? "18px"
                : "28px"
              : 0,
        paddingBottom:
          from === "dashboard"
            ? isMobile
              ? 0
              : "35px"
            : !patientId
              ? isMobile
                ? "18px"
                : "23px"
              : 0,
        paddingRight:
          from === "dashboard"
            ? isMobile
              ? 0
              : "17px"
            : !patientId
              ? isMobile
                ? "15px"
                : "20px"
              : 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!patientId && (
        <ComponentHeader
          title={from === "dashboard" ? "My Appointments" : "Appointments"}
          sx={{
            marginBottom: isMobile
              ? "18px"
              : from === "dashboard"
                ? "18px"
                : "30px",
          }}
        />
      )}

      {/* filter */}
      {from !== "dashboard" && (
        <Box
          sx={{
            marginBottom: "16px",
            padding: "10px",
            backgroundColor: "white",
            border: "1px soild #dcdcdc",
            borderRadius: "30px",
            width: "max-content",
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Typography>Filter by:</Typography>
          <FilterByAppointments
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            options={options}
            from={from}
          />
        </Box>
      )}

      {/* tabs content */}
      <TabAppointments
        appointments={getFilteredAppointments()}
        loadingFetchAppointments={loadingFetchAppointments}
        errorFetchAppointments={errorFetchAppointments}
        from={from}
        patientId={patientId}
      />
    </Box>
  );
};

export default NewAppointmentList;
