import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Box, Drawer, useMediaQuery, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useAuthStore from "../../store/authStore";
import SidebarContent from "./SidebarContent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FolderIcon from "@mui/icons-material/Folder";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MedicationIcon from "@mui/icons-material/Medication";
import PeopleIcon from "@mui/icons-material/People";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ShareIcon from "@mui/icons-material/Share";
import useAppStore from "../../store/appStore";

const NewSidebar = () => {
  const { isMenuCollapsed, drawerOpen, setDrawerOpen } = useAppStore();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:800px)");

  const menuItems = [
    {
      route: "dashboard",
      text: "Dashboard",
      icon: <HomeIcon />,
      selected: location.pathname.includes("dashboard"),
      roles: ["PATIENT", "DOCTOR", "ADMIN"],
    },
    {
      route: "appointments",
      text: "Appointments",
      icon: <CalendarTodayIcon />,
      selected:
        location.pathname.includes("appointments") ||
        location.pathname.includes("appointment") ||
        location.pathname.includes("meet") ||
        location.pathname.includes("specialties"),
      roles: ["PATIENT", "DOCTOR"],
    },
    {
      route: "documents",
      text: "Documents",
      icon: <FolderIcon />,
      selected:
        location.pathname.includes("documents") &&
        !location.pathname.includes("patients"),
      roles: ["PATIENT"],
    },
    {
      route: "referrals",
      text: "Referrals",
      icon: <ShareIcon />,
      selected: location.pathname.includes("referrals"),
      roles: ["DOCTOR", "ADMIN"],
    },
    {
      route: "patients",
      text: "Patients",
      icon: <PeopleIcon />,
      selected: location.pathname.includes("patients"),
      roles: ["ADMIN"],
      // submenus
      // [
      //       {
      //         route: "patients/documents",
      //         text: "Documents",
      //         icon: <FolderIcon />,
      //         selected: location.pathname.includes("patients/documents"),
      //         roles: ["ADMIN"],
      //       },
      //       {
      //         route: "patients/appointments",
      //         text: "Appointments",
      //         icon: <CalendarTodayIcon />,
      //         selected: location.pathname.includes("patients/appointments"),
      //         roles: ["ADMIN"],
      //       },
      //       {
      //         route: "patients/forms",
      //         text: "Forms",
      //         icon: <DynamicFormIcon />,
      //         selected: location.pathname.includes("patients/forms"),
      //         roles: ["ADMIN"],
      //       },
      //     ]
    },
    {
      route: "clinics",
      text: "Clinics",
      icon: <LocalHospitalIcon />,
      selected: location.pathname.includes("clinics"),
      roles: ["ADMIN"],
    },
    {
      route: "doctors",
      text: "Doctors",
      icon: <MedicationIcon />,
      selected: location.pathname.includes("doctors"),
      roles: ["ADMIN"],
    },
  ];

  const { userRole } = useAuthStore();
  const filteredMenuItems = menuItems.filter((item) =>
    item.roles.includes(userRole as string)
  );

  return (
    <Box>
      {isMobile ? (
        <Drawer
          anchor="left"
          open={drawerOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            "& .MuiDrawer-paper": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <SidebarContent
              menuItems={filteredMenuItems}
              isMenuCollapsed={isMenuCollapsed}
              setDrawerOpen={setDrawerOpen}
            />
            {!isMobile && (
              <IconButton
                disableRipple
                sx={{
                  marginRight: "14px",
                  border: "1px solid var(--neuro-secondary_border)",
                  width: "40px",
                  height: "40px",
                  padding: "10px",
                  backgroundColor: "var(--neuro-white-text)",
                  borderRadius: "0px 8px 8px 0px",
                  "&:hover": { backgroundColor: "var(--neuro-white-text)" },
                }}
                onClick={() => setDrawerOpen(false)}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
          </Box>
        </Drawer>
      ) : (
        <SidebarContent
          menuItems={filteredMenuItems}
          isMenuCollapsed={isMenuCollapsed}
        />
      )}
    </Box>
  );
};

export default NewSidebar;
