import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { isAdmin, isPatient } from "../../../utils/auth";
import {
  getAppointmentStatus,
  getFullHospitalAddress,
  getTimeDiffereForOngoing,
  handleOpenMap,
} from "../../../utils/appointments";
import Button from "../../ui/Button";
import LocationIcon from "../../svg/LocationIcon";
import VideoCallIcon from "../../svg/VideoCallIcon";
import RightExtandIcon from "../../svg/RightExtandIcon";
import api from "../../../services/apiService";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import PaymentIcon from "@mui/icons-material/Payment";
import PrescriptionButton from "./PrescriptionButton";
import useAppStore from "../../../store/appStore";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  CANCELLED,
  COMPLETED,
  IN_PERSON,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import { AppointmentCardIconsProps } from "../../../types/appointments";
import Tooltip from "../../ui/Tooltip";

const AppointmentCardButtons: React.FC<AppointmentCardIconsProps> = ({
  appointmentCode,
  id,
  userRole,
  appointmentMode,
  status,
  availableSlot,
  paymentStatus,
  doctor,
  patient,
  handleAppointmentClick,
  user,
  patientId,
}) => {
  // props & state values
  const maxWidth = useMediaQuery("(max-width:2000px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();
  const { fetchAppointments } = useAppointmentStore();

  const { showSnackbar } = useAppStore();

  const dateString = availableSlot?.startTime;

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const showPayButton =
    (isPatient(userRole) || isAdmin(userRole)) &&
    status !== COMPLETED &&
    status !== CANCELLED &&
    appointmentStatus !== COMPLETED &&
    paymentStatus &&
    paymentStatus === "PENDING";

  const showDirectionsButton =
    appointmentMode === IN_PERSON &&
    (appointmentStatus === ONGOING || appointmentStatus === UPCOMING) &&
    status !== CANCELLED;

  const showMeetButton =
    appointmentMode === ONLINE &&
    (appointmentStatus === UPCOMING || appointmentStatus === ONGOING) &&
    status !== CANCELLED &&
    status !== COMPLETED;

  const viewMapButtonDisabled =
    status === COMPLETED || appointmentStatus === COMPLETED;

  const totalAmount = useMemo(() => {
    const charges =
      appointmentMode === ONLINE
        ? doctor?.onlineConsultationFee || null
        : doctor?.inPersonConsultationFee || null;
    const gstValue = doctor?.taxes?.gst?.replace("%", "") || null;
    const gstAmount =
      charges && gstValue ? (Number(charges) * Number(gstValue)) / 100 : null;
    return gstAmount ? Number(charges) + gstAmount : null;
  }, [appointmentMode, doctor]);

  // callbacks & functions
  const handlePayment = useCallback(
    async (appointmentId: string, amount: number | null) => {
      try {
        const params = { paymentType: "PAYLATER" };

        const { data } = await api.post(
          `/api/payments/createOrder`,
          { appointmentId, amount },
          { params }
        );
        const { id, currency } = data;

        const options = {
          key: config.RAZORPAY_KEY_ID,
          amount,
          currency,
          name: config.APP_NAME,
          description: config.RAZORPAY_DESCRIPTION,
          order_id: id,
          handler: async (response: any) => {
            try {
              const result = await api.post(`/api/payments/verifySignature`, {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                appointmentId,
              });
              if (result.data.status === "success") {
                showSnackbar("Payment successful", "success");
                fetchAppointments(patientId || "");
              } else {
                showSnackbar(
                  "Payment not completed, please try again",
                  "error"
                );
              }
            } catch {
              showSnackbar("Payment not completed, please try again", "error");
            }
          },
          prefill: {
            name:
              `${patient?.user?.firstName} ${patient?.user?.lastName}` || "",
            email: patient?.user?.email || "",
            contact: patient?.user?.mobile || "",
          },
          theme: { color: "#3399cc" },
          modal: {
            ondismiss: () =>
              showSnackbar("Payment was cancelled by the user", "info"),
          },
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.on("payment.failed", (response: any) =>
          showSnackbar(
            `Payment failed. Reason: ${response.error.description}`,
            "error"
          )
        );
        rzp1.open();
      } catch (error) {
        console.error(error);
      }
    },
    [showSnackbar, fetchAppointments, patientId, patient, user]
  );

  const handleViewMap = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, doctor: any) => {
      event.stopPropagation();
      handleOpenMap(getFullHospitalAddress(doctor?.defaultHospital));
    },
    []
  );

  const handleMeetingJoin = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
      event.stopPropagation();
      navigate(`/meet/${id}`);
    },
    [navigate]
  );

  return (
    <Box
      sx={{
        display: "flex",
        gap: isMobile ? "20px" : "9px",
        width: isMobile ? "100%" : "initial",
        justifyContent: isMobile ? "center" : "flex-end",
        alignItems: "center",
      }}
    >
      {showPayButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            disabled={!totalAmount}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handlePayment(id, totalAmount);
            }}
          >
            <PaymentIcon
              sx={{
                fontSize: "var(--neuro-font-size-tiny-plus)",
                color: "var(--neuro-black-text)",
              }}
            />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            className="success-gradient-button"
            startIcon={
              <PaymentIcon
                sx={{
                  fontSize: "var(--neuro-font-size-tiny-plus)",
                  color: "var(--neuro-white-text)",
                }}
              />
            }
            sx={{
              textTransform: "none",
              height: 36,
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handlePayment(id, totalAmount);
            }}
            disabled={!totalAmount}
          >
            Pay
          </Button>
        ))}

      {showDirectionsButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            disabled={viewMapButtonDisabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleViewMap(event, doctor)
            }
          >
            <LocationIcon />
          </IconButton>
        ) : (
          <Button
            startIcon={<LocationIcon />}
            color="secondary"
            variant="outlined"
            className="outlined-secondary-button"
            sx={{
              fontSize: "var(--neuro-font-size-smaller-plus)",
              textTransform: "none",
              width: 130,
              height: 36,
              fontFamily: "var(--neuro-font-family-roboto)",
            }}
            disabled={viewMapButtonDisabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleViewMap(event, doctor)
            }
          >
            Directions
          </Button>
        ))}

      {showMeetButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            disabled={getTimeDiffereForOngoing(dateString) > 60}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (getTimeDiffereForOngoing(dateString) > 60) {
                event.stopPropagation();
              } else {
                if (paymentStatus === "PENDING") {
                  event.stopPropagation();
                } else {
                  handleMeetingJoin(event, appointmentCode);
                }
              }
            }}
          >
            <VideoCallIcon height={24} />
          </IconButton>
        ) : (
          <>
            {getTimeDiffereForOngoing(dateString) > 60 ? (
              <Button
                startIcon={<VideoCallIcon />}
                disabled={true}
                color="secondary"
                variant="outlined"
                className="outlined-secondary-button"
                sx={{
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  textTransform: "none",
                  width: 184,
                  height: 36,
                  fontFamily: "var(--neuro-font-family-roboto)",
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  if (getTimeDiffereForOngoing(dateString) > 60) {
                    event.stopPropagation();
                  } else {
                    if (paymentStatus === "PENDING") {
                      event.stopPropagation();
                    } else {
                      handleMeetingJoin(event, appointmentCode);
                    }
                  }
                }}
              >
                Meet with doctor
              </Button>
            ) : paymentStatus === "PENDING" ? (
              <Tooltip
                title={"Please complete the payment to meet with doctor"}
              >
                <Button
                  startIcon={<VideoCallIcon />}
                  disabled={
                    getTimeDiffereForOngoing(dateString) > 60 ||
                    isAdmin(userRole)
                  }
                  color="secondary"
                  variant="outlined"
                  className="outlined-secondary-button"
                  sx={{
                    fontSize: "var(--neuro-font-size-smaller-plus)",
                    textTransform: "none",
                    width: 184,
                    height: 36,
                    fontFamily: "var(--neuro-font-family-roboto)",
                  }}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    if (getTimeDiffereForOngoing(dateString) > 60) {
                      event.stopPropagation();
                    } else {
                      if (paymentStatus === "PENDING") {
                        event.stopPropagation();
                      } else {
                        handleMeetingJoin(event, appointmentCode);
                      }
                    }
                  }}
                >
                  Meet with doctor
                </Button>
              </Tooltip>
            ) : (
              <Button
                startIcon={<VideoCallIcon />}
                disabled={
                  getTimeDiffereForOngoing(dateString) > 60 || isAdmin(userRole)
                }
                color="secondary"
                variant="outlined"
                className="outlined-secondary-button"
                sx={{
                  fontSize: "var(--neuro-font-size-smaller-plus)",
                  textTransform: "none",
                  width: 184,
                  height: 36,
                  fontFamily: "var(--neuro-font-family-roboto)",
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  if (getTimeDiffereForOngoing(dateString) > 60) {
                    event.stopPropagation();
                  } else {
                    if (paymentStatus === "PENDING") {
                      event.stopPropagation();
                    } else {
                      handleMeetingJoin(event, appointmentCode);
                    }
                  }
                }}
              >
                Meet with doctor
              </Button>
            )}
          </>
        ))}

      <PrescriptionButton key={id} status={status} id={id} />

      {!isMobile && (
        <IconButton
          onClick={(event) => handleAppointmentClick(event, id)}
          sx={{
            marginLeft: maxWidth ? "20px" : "80px",
            width: 46,
            height: 46,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
          }}
        >
          <RightExtandIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default AppointmentCardButtons;
