import React, { useContext, useEffect, useState } from "react";

// third-party imports
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

// project imports
import AppointmentDetails from "./AppointmentDetails";
import Button from "../../ui/Button";
import Calendar from "./Calendar";
import Spinner from "../../ui/Spinner";
// import TimeSlots from "./TimeSlots";
import useAuthStore from "../../../store/authStore";

const AppointmentScheduler = () => {
  // props & state values
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || null;

  const { user } = useAuthStore();

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    data && data.selectedDoctorDate ? data.selectedDoctorDate : new Date()
  );
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [meetType, setMeetType] = useState<string>("ONLINE");

  const [appointmentDate, setAppointmentDate] = useState<any>(null);
  const [availableSlotId, setAvailableSlotId] = useState<string>("");

  const nextButtonIsDisabled =
    !selectedDate || !selectedTime || meetType === "";

  // callbacks & functions
  useEffect(() => {
    if (!data || !data.selectedDoctor) {
      navigate("/appointments");
    }
  }, [data]);

  const handleMeetTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeetType((event.target as HTMLInputElement).value);
  };

  const handleCreateAppointment = () => {
    const appointmenDetails = {
      patientId: user?.patientId as string,
      doctorId: data.selectedDoctor?.id as string,
      appointmentDate: appointmentDate,
      availableSlotId: availableSlotId,
      appointmentMode: meetType === "ONLINE" ? "ONLINE" : "IN_PERSON",
    };
    const urlData = {
      appointmenDetails: appointmenDetails,
      selectedDoctor: data.selectedDoctor || null,
    };
    navigate("/appointments/new-appointment/checkout", {
      state: urlData,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        paddingBlock: "28px",
        paddingLeft: "20px",
      }}
    >
      <Box
        sx={{
          width: "80%",
        }}
      >
        {data && data.selectedDoctor && (
          <>
            <Typography
              sx={{
                marginBottom: "29px",
                fontSize: "31px",
                lineHeight: "120%",
                color: "var(--neuro-black-text)",
              }}
            >
              New Appointment
            </Typography>

            <AppointmentDetails
              doctorProfile={undefined}
              doctorName={`${data.selectedDoctor?.firstName} ${data.selectedDoctor?.lastName}`}
              doctorSpeciality={data.selectedDoctor?.specializations[0]}
              doctorExperience={`${data.selectedDoctor?.yearsOfExperience} Years Experience`}
              meetType={meetType}
              handleMeetTypeChange={handleMeetTypeChange}
            />

            <Calendar
              selectedDate={selectedDate}
              onDateSelect={setSelectedDate}
            />

            {/* <TimeSlots
              selectedDate={selectedDate}
              meetType={meetType}
              selectedDoctor={data.selectedDoctor}
              selectedTime={selectedTime}
              onTimeSelect={setSelectedTime}
              setAppointmentDate={setAppointmentDate}
              setAvailableSlotId={setAvailableSlotId}
              availableSlotId={availableSlotId}
              selectedTimeSlotId={data.selectedDoctorSlotId}
            /> */}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "27px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "14px",
                  justifySelf: "flex-end",
                }}
              >
                <Button
                  sx={{ height: 46, width: 89 }}
                  className="outlined-secondary-button"
                  variant="contained"
                  onClick={handleBack}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  className="primary-button"
                  sx={{ width: 89, height: 46 }}
                  disabled={nextButtonIsDisabled}
                  onClick={handleCreateAppointment}
                >
                  Next
                  {/* {loading ? <Spinner /> : "Next"} */}
                </Button>
              </Box>
            </Box>
          </>
        )}
        {!data ||
          (!data.selectedDoctor && (
            <h4>Please select a doctor in previous page</h4>
          ))}
      </Box>
    </Box>
  );
};

export default AppointmentScheduler;
