import React, { useState, useRef, useEffect } from "react";
import ContentCenter from "../../shared/ContentCenter";
import { Box, Typography, IconButton } from "@mui/material";
import ViewerReportCard from "../../documents/view/ViewerReportCard";
import ReportsDrawer from "./ReportsDrawer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ReportsSectionProps } from "../../../types/appointments";

const ReportsTabContent: React.FC<ReportsSectionProps> = ({ reports }) => {
  // props & state values
  const [reportsDrawerOpen, setreportsDrawerOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isLeftDisabled, setIsLeftDisabled] = useState<boolean>(true);
  const [isRightDisabled, setIsRightDisabled] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null); // Ref for scrolling container

  console.log("reports inmeet", reports);

  // callbacks & functions
  const handleCardClick = (report: any) => {
    setSelectedReport(report);
    setreportsDrawerOpen(true);
  };

  // Check the scroll position to enable/disable buttons
  const checkScrollButtons = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setIsLeftDisabled(scrollLeft === 0); // Disable left button if at the start
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth); // Disable right button if at the end
    }
  };

  // Scroll functions
  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -250, // Adjust this value for more or less scroll per click
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 250, // Adjust this value for more or less scroll per click
        behavior: "smooth",
      });
    }
  };

  // Monitor scroll position to disable/enable buttons
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", checkScrollButtons);
      checkScrollButtons(); // Initial check when component mounts
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", checkScrollButtons);
      }
    };
  }, []);

  return (
    <>
      {(!reports ||
        reports.length === 0 ||
        (reports.length > 0 &&
          reports?.filter((report) => report?.status !== "DELETED").length ===
            0)) && (
        <ContentCenter>
          <Typography className="no-data">Reports not found</Typography>
        </ContentCenter>
      )}

      {reports &&
        reports.length > 0 &&
        reports?.filter((report) => report?.status !== "DELETED").length >
          0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust spacing between cards and buttons
              width: "100%",
            }}
          >
            {/* Left Scroll Button */}
            <IconButton
              disableRipple
              disabled={isLeftDisabled}
              sx={{
                padding: 0,
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={scrollLeft}
            >
              <ArrowBackIosIcon sx={{ fontSize: "24px" }} />
            </IconButton>

            <Box
              ref={scrollRef}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
                overflowX: "hidden", // Hide scroll bar
                scrollBehavior: "smooth", // Enable smooth scroll
                width: "100%",
              }}
            >
              {reports
                ?.filter((report) => report?.status !== "DELETED")
                ?.map((report, index) => (
                  <Box key={report.id} sx={{ minWidth: "max-content" }}>
                    <ViewerReportCard
                      id={report?.id}
                      name={report?.name}
                      reportDate={report?.reportDate}
                      isSummaryGenerated={report?.isSummaryGenerated}
                      status={report?.status}
                      fileType={report?.fileType}
                      createdAt={report?.createdAt}
                      from="conference"
                      onClick={() => handleCardClick(report)}
                    />
                  </Box>
                ))}
              <ReportsDrawer
                report={selectedReport}
                reportsDrawerOpen={reportsDrawerOpen}
                setreportsDrawerOpen={setreportsDrawerOpen}
              />
            </Box>

            {/* Right Scroll Button */}
            <IconButton
              disableRipple
              disabled={isRightDisabled}
              sx={{
                padding: 0,
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={scrollRight}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
        )}
    </>
  );
};

export default ReportsTabContent;
