import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Badge from "../ui/Badge";
import config from "../../config";

const SidebarHeader: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ position: "relative", height: "85px", maxHeight: "85px" }}
    >
      {!isMobile && <Badge label={config?.VERSION || ""} />}

      <img src="/22neuro-sidebar-logo.png" alt="22Neuro Clinic" width={120} />
    </Box>
  );
};

export default SidebarHeader;
