import * as React from "react";
const UploadIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#2581C4"
      d="M25.983 8.342C25.988 8.228 26 8.116 26 8a8 8 0 0 0-8-8c-3.11 0-5.8 1.779-7.123 4.371A4.989 4.989 0 0 0 9 4C6.47 4 4.401 5.885 4.068 8.324A5.997 5.997 0 0 0 6 20h18a5.998 5.998 0 0 0 1.983-11.658Zm-14.69 1.951 3-3a.997.997 0 0 1 1.414 0l3 3a.999.999 0 1 1-1.414 1.414L16 10.414V16a1 1 0 1 1-2 0v-5.586l-1.293 1.293a.999.999 0 1 1-1.414-1.414Z"
    />
  </svg>
);
export default UploadIcon;
