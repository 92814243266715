import { create } from "zustand";
import {
  getDocuments,
  removeDocument,
  generateDocumentSummary,
  getDocumentDetails,
  uploadFiles,
  updateDocumentMetaData,
  changeDoctorSummary,
  changePatientSummary,
  changeSymptomAssessment,
  changePatientNotes,
} from "../services/documentsService";

interface DocumentsStore {
  // States for fetching documents
  documents: any[];
  loadingDocuments: boolean;
  errorDocuments: string | null;
  fetchDocuments: (id?: string) => Promise<void>;

  // States for fetching single document details
  documentDetails: any;
  loadingDocumentDetails: boolean;
  errorDocumentDetails: string | null;
  fetchDocumentDetails: (id: string) => Promise<void>;
  cleanUpDocumentDetailsStore: () => void;

  // States for document selection
  selectedDocumentId: string | null;
  setSelectedDocumentId: (id: string | null) => void;
  cleanUpSelectedDocumentId: () => void;

  // States for document deletion
  documentDeleted: any;
  loadingDeleteDocument: boolean;
  errorDeleteDocument: string | null;
  deleteDocument: (id: string, patientId?: string) => Promise<void>;
  cleanUpDeleteDocumentState: () => void;

  // States for document summary regeneration
  reGeneratedSummaryData: any;
  loadingReGenerateSummary: boolean;
  errorReGenerateSummary: string | null;
  reGenerateSummary: (documentId: string) => Promise<void>;
  cleanUpReGenerateSummaryState: () => void;

  // States for uploading documents
  uploadedDocuments: any;
  loadingUploadDocuments: boolean;
  errorUploadDocuments: string | null;
  uploadDocuments: (formdata: FormData, id?: string) => Promise<void>;
  cleanUpUploadDocumentsState: () => void;

  // States for updating document metadata
  updatedMetadata: any;
  loadingMetadata: boolean;
  errorMetadata: string | null;
  updateMetadata: (id: string, metadata: string) => Promise<void>;
  cleanUpUpdateMetadataState: () => void;

  // States for updating doctor summary
  updatedDoctorSummary: any;
  loadingDoctorSummary: boolean;
  errorDoctorSummary: string | null;
  updateDoctorSummary: (id: string, doctorSummary: string) => Promise<void>;
  cleanUpDoctorSummaryState: () => void;

  // States for updating patient summary
  updatedPatientSummary: any;
  loadingPatientSummary: boolean;
  errorPatientSummary: string | null;
  updatePatientSummary: (id: string, patientSummary: string) => Promise<void>;
  cleanUpPatientSummaryState: () => void;

  // States for updating patient notes
  updatedPatientNotes: any;
  loadingPatientNotes: boolean;
  errorPatientNotes: string | null;
  updatePatientNotes: (id: string, patientNote: string) => Promise<void>;
  cleanUpPatientNotesState: () => void;

  // States for updating Symptom Assessment
  updatedSymptomAssessment: any;
  loadingSymptomAssessment: boolean;
  errorSymptomAssessment: string | null;
  updateSymptomAssessment: (
    id: string,
    symptomAssessment: string
  ) => Promise<void>;
  cleanUpSymptomAssessmentState: () => void;
}

const useDocumentStore = create<DocumentsStore>((set) => ({
  // Initial states for fetching documents
  documents: [],
  loadingDocuments: false,
  errorDocuments: null,
  fetchDocuments: async (id?: string) => {
    set({ loadingDocuments: true, errorDocuments: null });
    try {
      const response = await getDocuments(id);
      const documentsWithoutNull = response.data.filter(
        (document: any) => document !== null
      );
      set({ documents: documentsWithoutNull, loadingDocuments: false });
    } catch (error) {
      set({ errorDocuments: "Something went wrong!", loadingDocuments: false });
    }
  },

  // Initial states for fetching single document details
  documentDetails: null,
  loadingDocumentDetails: false,
  errorDocumentDetails: null,
  fetchDocumentDetails: async (id: string) => {
    set({ loadingDocumentDetails: true, errorDocumentDetails: null });
    try {
      const response = await getDocumentDetails(id);
      set({ documentDetails: response.data, loadingDocumentDetails: false });
    } catch (error) {
      set({
        errorDocumentDetails: "Something went wrong!",
        loadingDocumentDetails: false,
      });
    }
  },
  cleanUpDocumentDetailsStore: () => {
    set({
      documentDetails: null,
      loadingDocumentDetails: false,
      errorDocumentDetails: null,
    });
  },

  // Initial states for document selection
  selectedDocumentId: null,
  setSelectedDocumentId: (id: string | null) => set({ selectedDocumentId: id }),
  cleanUpSelectedDocumentId: () => set({ selectedDocumentId: null }),

  // Initial states for document deletion
  documentDeleted: null,
  loadingDeleteDocument: false,
  errorDeleteDocument: null,
  deleteDocument: async (id: string, patientId?: string) => {
    set({ loadingDeleteDocument: true, errorDeleteDocument: null });
    try {
      const response = await removeDocument(id, patientId);
      set({
        documentDeleted: response.data.message,
        loadingDeleteDocument: false,
      });
    } catch (error) {
      set({
        errorDeleteDocument: "Something went wrong!",
        loadingDeleteDocument: false,
      });
    }
  },
  cleanUpDeleteDocumentState: () => {
    set({
      documentDeleted: null,
      loadingDeleteDocument: false,
      errorDeleteDocument: null,
    });
  },

  // Initial states for regenerating document summary
  reGeneratedSummaryData: null,
  loadingReGenerateSummary: false,
  errorReGenerateSummary: null,
  reGenerateSummary: async (documentId: string) => {
    set({ loadingReGenerateSummary: true, errorReGenerateSummary: null });
    try {
      const response = await generateDocumentSummary(documentId);
      set({
        reGeneratedSummaryData: response.data,
        loadingReGenerateSummary: false,
      });
    } catch (error) {
      set({
        errorReGenerateSummary: "Failed to regenerate summary!",
        loadingReGenerateSummary: false,
      });
    }
  },
  cleanUpReGenerateSummaryState: () => {
    set({
      reGeneratedSummaryData: null,
      loadingReGenerateSummary: false,
      errorReGenerateSummary: null,
    });
  },

  // Initial states for uploading documents
  uploadedDocuments: null,
  loadingUploadDocuments: false,
  errorUploadDocuments: null,
  uploadDocuments: async (formdata: FormData, id?: string) => {
    set({ loadingUploadDocuments: true, errorUploadDocuments: null });
    try {
      const response = await uploadFiles(formdata, id);
      set({
        uploadedDocuments: response.data.createdDocuments,
        loadingUploadDocuments: false,
      });
    } catch (error) {
      set({
        errorUploadDocuments: "Something went wrong!",
        loadingUploadDocuments: false,
      });
    }
  },
  cleanUpUploadDocumentsState: () => {
    set({
      uploadedDocuments: null,
      errorUploadDocuments: null,
      loadingUploadDocuments: false,
    });
  },

  // Initial states for updating document metadata
  updatedMetadata: null,
  loadingMetadata: false,
  errorMetadata: null,
  updateMetadata: async (id: string, metadata: string) => {
    set({ loadingMetadata: true, errorMetadata: null });
    try {
      const response = await updateDocumentMetaData(id, metadata);
      set({ updatedMetadata: response.data, loadingMetadata: false });
    } catch (error) {
      set({ errorMetadata: "Something went wrong!", loadingMetadata: false });
    }
  },
  cleanUpUpdateMetadataState: () => {
    set({ updatedMetadata: null, loadingMetadata: false, errorMetadata: null });
  },

  // Initial states for updating doctor summary
  updatedDoctorSummary: null,
  loadingDoctorSummary: false,
  errorDoctorSummary: null,
  updateDoctorSummary: async (id: string, doctorSummary: string) => {
    set({ loadingDoctorSummary: true, errorDoctorSummary: null });
    try {
      const response = await changeDoctorSummary(id, doctorSummary);
      set({ updatedDoctorSummary: response.data, loadingDoctorSummary: false });
    } catch (error) {
      set({
        errorDoctorSummary: "Something went wrong!",
        loadingDoctorSummary: false,
      });
    }
  },
  cleanUpDoctorSummaryState: () => {
    set({
      updatedDoctorSummary: null,
      loadingDoctorSummary: false,
      errorDoctorSummary: null,
    });
  },

  // Initial states for updating patient summary
  updatedPatientSummary: null,
  loadingPatientSummary: false,
  errorPatientSummary: null,
  updatePatientSummary: async (id: string, patientSummary: string) => {
    set({ loadingPatientSummary: true, errorPatientSummary: null });
    try {
      const response = await changePatientSummary(id, patientSummary);
      set({
        updatedPatientSummary: response.data,
        loadingPatientSummary: false,
      });
    } catch (error) {
      set({
        errorPatientSummary: "Something went wrong!",
        loadingPatientSummary: false,
      });
    }
  },
  cleanUpPatientSummaryState: () => {
    set({
      updatedPatientSummary: null,
      loadingPatientSummary: false,
      errorPatientSummary: null,
    });
  },

  // Initial states for updating patient notes
  updatedPatientNotes: null,
  loadingPatientNotes: false,
  errorPatientNotes: null,
  updatePatientNotes: async (id: string, patientNote: string) => {
    set({ loadingPatientNotes: true, errorPatientNotes: null });
    try {
      const response = await changePatientNotes(id, patientNote);
      set({
        updatedPatientNotes: response.data,
        loadingPatientNotes: false,
      });
    } catch (error) {
      set({
        errorPatientNotes: "Something went wrong!",
        loadingPatientNotes: false,
      });
    }
  },
  cleanUpPatientNotesState: () => {
    set({
      updatedPatientNotes: null,
      loadingPatientNotes: false,
      errorPatientNotes: null,
    });
  },

  // Initial states for updating patient summary
  updatedSymptomAssessment: null,
  loadingSymptomAssessment: false,
  errorSymptomAssessment: null,
  updateSymptomAssessment: async (id: string, symptomAssessment: string) => {
    set({ loadingSymptomAssessment: true, errorSymptomAssessment: null });
    try {
      const response = await changeSymptomAssessment(id, symptomAssessment);
      set({
        updatedSymptomAssessment: response.data,
        loadingSymptomAssessment: false,
      });
    } catch (error) {
      set({
        errorSymptomAssessment: "Something went wrong!",
        loadingSymptomAssessment: false,
      });
    }
  },
  cleanUpSymptomAssessmentState: () => {
    set({
      updatedSymptomAssessment: null,
      loadingSymptomAssessment: false,
      errorSymptomAssessment: null,
    });
  },
}));

export default useDocumentStore;
