import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { isAdmin, isDoctor, isPatient } from "../../../utils/auth";
import {
  getAppointmentStatus,
  getFullHospitalAddress,
  getTimeDiffereForOngoing,
  handleOpenMap,
} from "../../../utils/appointments";
import Button from "../../ui/Button";
import LocationIcon from "../../svg/LocationIcon";
import VideoCallIcon from "../../svg/VideoCallIcon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import api from "../../../services/apiService";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import PaymentIcon from "@mui/icons-material/Payment";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import PaidIcon from "@mui/icons-material/Paid";
import PrescriptionButton from "./PrescriptionButton";
import useAppStore from "../../../store/appStore";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  CANCELLED,
  COMPLETED,
  IN_PERSON,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import { AppointmentCardIconsProps } from "../../../types/appointments";
import Tooltip from "../../ui/Tooltip";
import Spinner from "../../ui/Spinner";
import { offlinePayment } from "../../../services/appointmentsService";
import { Tax } from "../../../types/common";
import { formatMinutesToHHMM } from "../../../utils/common";

const AppointmentCardButtons: React.FC<AppointmentCardIconsProps> = ({
  appointmentCode,
  // appointmentDate,
  id,
  userRole,
  appointmentMode,
  status,
  availableSlot,
  paymentStatus,
  doctor,
  patient,
  handleAppointmentClick,
  user,
  patientId,
  shouldRenderDetails,
  prescription,
  // from = ""
}) => {
  // props & state values
  const maxWidth = useMediaQuery("(max-width:2000px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [payOfflineLoading, setPayOfflineLoading] = useState<boolean>(false);

  console.log("user in cardbtns", user);
  console.log("doctor in cardbtns", doctor);

  const styles = {
    detailsIconStyles: {
      fontSize: "36px",
      color: "var(--neuro-black-text)",
    },
    detailsIconButtonStyles: {
      marginLeft: maxWidth ? "20px" : "80px",
      width: 36,
      height: 36,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      border: "1px solid var(--neuro-secondary_border)",
    },
    meetButtonStyles: {
      fontSize: "var(--neuro-font-size-smaller-plus)",
      textTransform: "none",
      width: isDoctor(userRole) ? 190 : 184,
      height: 36,
      fontFamily: "var(--neuro-font-family-roboto)",
    },
  };

  const navigate = useNavigate();
  const { fetchAppointments } = useAppointmentStore();

  const { showSnackbar } = useAppStore();

  const dateString = availableSlot?.startTime;

  const meetButtonText = isDoctor(userRole)
    ? "Meet with patient"
    : "Meet with doctor";

  const isOngoingSoon = useMemo(
    () => getTimeDiffereForOngoing(dateString) <= config?.MEETING_ENABLE_TIME,
    [dateString]
  );

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const payButtonCondition =
    status !== COMPLETED &&
    status !== CANCELLED &&
    appointmentStatus !== COMPLETED &&
    paymentStatus &&
    paymentStatus === "PENDING";

  const showPayOfflineButton =
    isAdmin(userRole) && patientId && payButtonCondition;

  const showPayButton =
    (isPatient(userRole) || isAdmin(userRole)) && payButtonCondition;

  const showPayPendingDoctorButton =
    isDoctor(userRole) && payButtonCondition && appointmentMode === IN_PERSON;

  const showDirectionsButton =
    appointmentMode === IN_PERSON &&
    (appointmentStatus === ONGOING || appointmentStatus === UPCOMING) &&
    status !== CANCELLED;

  const showMeetButton =
    appointmentMode === ONLINE &&
    (appointmentStatus === UPCOMING || appointmentStatus === ONGOING) &&
    status !== CANCELLED &&
    status !== COMPLETED;

  const viewMapButtonDisabled =
    status === COMPLETED || appointmentStatus === COMPLETED;

  const charges =
    appointmentMode === ONLINE
      ? doctor?.onlineConsultationFee || null
      : doctor?.inPersonConsultationFee || null;

  const taxes: Tax[] = doctor?.taxes;

  const calculateTotalAmount = () => {
    return taxes?.reduce((total, tax) => {
      if (tax.type === "PERCENTAGE") {
        return total + (charges * parseFloat(tax.value)) / 100 || null;
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  };

  const totalAmount = charges
    ? taxes && taxes?.length > 0
      ? calculateTotalAmount()
      : charges
    : null;

  // callbacks & functions
  const handlePayment = useCallback(
    async (appointmentId: string, amount: number | null) => {
      try {
        const params = { paymentType: "PAYLATER" };

        const { data } = await api.post(
          `/api/payments/createOrder`,
          { appointmentId, amount },
          { params }
        );
        const { id, currency } = data;

        const options = {
          key: config.RAZORPAY_KEY_ID,
          amount,
          currency,
          name: config.APP_NAME,
          description: config.RAZORPAY_DESCRIPTION,
          order_id: id,
          handler: async (response: any) => {
            try {
              const result = await api.post(`/api/payments/verifySignature`, {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                appointmentId,
              });
              if (result.data.status === "success") {
                showSnackbar("Payment successful", "success");
                fetchAppointments(patientId || "");
              } else {
                showSnackbar(
                  "Payment not completed, please try again",
                  "error"
                );
              }
            } catch {
              showSnackbar("Payment not completed, please try again", "error");
            }
          },
          prefill: {
            name:
              `${patient?.user?.firstName} ${patient?.user?.lastName}` || "",
            email: patient?.user?.email || "",
            contact: patient?.user?.mobile || "",
          },
          theme: { color: "#3399cc" },
          modal: {
            ondismiss: () =>
              showSnackbar("Payment was cancelled by the user", "info"),
          },
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.on("payment.failed", (response: any) =>
          showSnackbar(
            `Payment failed. Reason: ${response.error.description}`,
            "error"
          )
        );
        rzp1.open();
      } catch (error) {
        console.error(error);
      }
    },
    [showSnackbar, fetchAppointments, patientId, patient, user]
  );

  const handleViewMap = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, doctor: any) => {
      event.stopPropagation();
      handleOpenMap(getFullHospitalAddress(doctor?.defaultHospital));
    },
    []
  );

  const handleMeetingJoin = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLElement>,
      id: string
    ) => {
      event.stopPropagation();
      navigate(`/meet/${id}`);
    },
    [navigate]
  );

  // offline payment by admin
  const handleOfflinePayment = async (id: string) => {
    try {
      setPayOfflineLoading(true);
      const result = await offlinePayment(id);
      if (result && result?.data) {
        setPayOfflineLoading(false);
        showSnackbar("Paid offline, successufully", "success");
        fetchAppointments(patientId || "");
      }
    } catch (error) {
      setPayOfflineLoading(false);
      showSnackbar("Could not complete pay offline, please try again", "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: isMobile ? "20px" : "9px",
        width: isMobile ? "100%" : "initial",
        justifyContent: isMobile ? "center" : "flex-end",
        alignItems: "center",
      }}
    >
      {showPayPendingDoctorButton &&
        (isMobile ? (
          <Tooltip
            title={isDoctor(userRole) ? "Payment not completed by patient" : ""}
          >
            <Box
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <span
                style={{ width: 0 }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              ></span>
              <IconButton
                disableRipple
                disabled={true}
                sx={{
                  padding: 0,
                  backgroundColor: "transparent",
                  "& :hover": {
                    padding: 0,
                    backgroundColor: "transparent",
                  },
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                }}
              >
                <CreditCardOffIcon
                  sx={{
                    fontSize: "var(--neuro-font-size-tiny-plus)",
                    color: "var(--neuro-black-text)",
                  }}
                />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip
            title={isDoctor(userRole) ? "Payment not completed by patient" : ""}
          >
            <Box
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <span
                style={{ width: 0 }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              ></span>
              <Button
                variant="contained"
                className="warning-button"
                startIcon={
                  <CreditCardOffIcon
                    sx={{
                      fontSize: "var(--neuro-font-size-tiny-plus)",
                      color: "var(--neuro-white-text)",
                    }}
                  />
                }
                sx={{
                  textTransform: "none",
                  height: 36,
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                }}
                disabled={true}
              >
                Payment pending
              </Button>
            </Box>
          </Tooltip>
        ))}

      {showPayButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            disabled={!totalAmount}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handlePayment(id, totalAmount);
            }}
          >
            <PaymentIcon
              sx={{
                fontSize: "var(--neuro-font-size-tiny-plus)",
                color: "var(--neuro-black-text)",
              }}
            />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            className="success-gradient-button"
            startIcon={
              <PaymentIcon
                sx={{
                  fontSize: "var(--neuro-font-size-tiny-plus)",
                  color: "var(--neuro-white-text)",
                }}
              />
            }
            sx={{
              textTransform: "none",
              height: 36,
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handlePayment(id, totalAmount);
            }}
            disabled={!totalAmount}
          >
            Pay
          </Button>
        ))}

      {showPayOfflineButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            disabled={!totalAmount || payOfflineLoading}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handleOfflinePayment(id);
            }}
          >
            {payOfflineLoading ? (
              <Spinner />
            ) : (
              <PaidIcon
                sx={{
                  fontSize: "var(--neuro-font-size-tiny-plus)",
                  color: "var(--neuro-black-text)",
                }}
              />
            )}
          </IconButton>
        ) : (
          <Button
            className="primary-button"
            variant="contained"
            startIcon={
              <PaidIcon
                sx={{
                  fontSize: "var(--neuro-font-size-tiny-plus)",
                  color: "var(--neuro-white-text)",
                }}
              />
            }
            sx={{
              textTransform: "none",
              height: 36,
            }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handleOfflinePayment(id);
            }}
            disabled={!totalAmount || payOfflineLoading}
          >
            {payOfflineLoading ? <Spinner /> : "Pay Offline"}
          </Button>
        ))}

      {showDirectionsButton &&
        (isMobile ? (
          <IconButton
            disableRipple
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& :hover": {
                padding: 0,
                backgroundColor: "transparent",
              },
            }}
            disabled={viewMapButtonDisabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleViewMap(event, doctor)
            }
          >
            <LocationIcon />
          </IconButton>
        ) : (
          <Button
            startIcon={<LocationIcon />}
            color="secondary"
            variant="outlined"
            className="outlined-secondary-button"
            sx={{
              fontSize: "var(--neuro-font-size-smaller-plus)",
              textTransform: "none",
              width: 130,
              height: 36,
              fontFamily: "var(--neuro-font-family-roboto)",
            }}
            disabled={viewMapButtonDisabled}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              handleViewMap(event, doctor)
            }
          >
            Directions
          </Button>
        ))}

      {showMeetButton &&
        (isMobile ? (
          <>
            {!isOngoingSoon ? (
              paymentStatus === "PENDING" ? (
                <Tooltip
                  title={
                    isDoctor(userRole) || isAdmin(userRole)
                      ? "Payment not completed by patient"
                      : "Please complete the payment to meet with doctor"
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <IconButton
                      disableRipple
                      sx={{
                        padding: 0,
                        backgroundColor: "transparent",
                        "& :hover": {
                          padding: 0,
                          backgroundColor: "transparent",
                        },
                      }}
                      disabled={true}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <VideoCallIcon height={24} />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`}
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <IconButton
                      disableRipple
                      sx={{
                        padding: 0,
                        backgroundColor: "transparent",
                        "& :hover": {
                          padding: 0,
                          backgroundColor: "transparent",
                        },
                      }}
                      disabled={true}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      <VideoCallIcon height={24} />
                    </IconButton>
                  </Box>
                </Tooltip>
              )
            ) : paymentStatus === "PENDING" ? (
              <Tooltip
                title={
                  isDoctor(userRole) || isAdmin(userRole)
                    ? "Payment not completed by patient"
                    : "Please complete the payment to meet with doctor"
                }
              >
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span
                    style={{ width: 0 }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  ></span>
                  <IconButton
                    disableRipple
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      "& :hover": {
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                    }}
                    disabled={true}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                    }}
                  >
                    <VideoCallIcon height={24} />
                  </IconButton>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  isAdmin(userRole) ||
                  (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ? "You are not a participant in this meet"
                    : ""
                }
              >
                <Box
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (
                      isAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ) {
                      event.stopPropagation();
                    } else {
                      handleMeetingJoin(event, appointmentCode);
                    }
                  }}
                >
                  {(isAdmin(userRole) ||
                    (isDoctor(userRole) && doctor?.id !== user?.doctorId)) && (
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                  )}
                  <IconButton
                    disableRipple
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      "& :hover": {
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                    }}
                    disabled={
                      isAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    }
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (
                        isAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ) {
                        event.stopPropagation();
                      } else {
                        handleMeetingJoin(event, appointmentCode);
                      }
                    }}
                  >
                    <VideoCallIcon height={24} />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {!isOngoingSoon ? (
              paymentStatus === "PENDING" ? (
                <Tooltip
                  title={
                    isDoctor(userRole) || isAdmin(userRole)
                      ? "Payment not completed by patient"
                      : "Please complete the payment to meet with doctor"
                  }
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={true}
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`You can join meet before ${formatMinutesToHHMM(config?.MEETING_ENABLE_TIME)} hours to meet time`}
                >
                  <Box
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                    <Button
                      startIcon={<VideoCallIcon />}
                      disabled={true}
                      color="secondary"
                      variant="outlined"
                      className="outlined-secondary-button"
                      sx={styles.meetButtonStyles}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                      }}
                    >
                      {meetButtonText}
                    </Button>
                  </Box>
                </Tooltip>
              )
            ) : paymentStatus === "PENDING" ? (
              <Tooltip
                title={
                  isDoctor(userRole) || isAdmin(userRole)
                    ? "Payment not completed by patient"
                    : "Please complete the payment to meet with doctor"
                }
              >
                <Box
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span
                    style={{ width: 0 }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  ></span>
                  <Button
                    startIcon={<VideoCallIcon />}
                    disabled={true}
                    color="secondary"
                    variant="outlined"
                    className="outlined-secondary-button"
                    sx={styles.meetButtonStyles}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                    }}
                  >
                    {meetButtonText}
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  isAdmin(userRole) ||
                  (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ? "You are not a participant in this meet"
                    : ""
                }
              >
                <Box
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (
                      isAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    ) {
                      event.stopPropagation();
                    } else {
                      handleMeetingJoin(event, appointmentCode);
                    }
                  }}
                >
                  {(isAdmin(userRole) ||
                    (isDoctor(userRole) && doctor?.id !== user?.doctorId)) && (
                    <span
                      style={{ width: 0 }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    ></span>
                  )}
                  <Button
                    startIcon={<VideoCallIcon />}
                    disabled={
                      isAdmin(userRole) ||
                      (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                    }
                    color="secondary"
                    variant="outlined"
                    className="outlined-secondary-button"
                    sx={styles.meetButtonStyles}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      if (
                        isAdmin(userRole) ||
                        (isDoctor(userRole) && doctor?.id !== user?.doctorId)
                      ) {
                        event.stopPropagation();
                      } else {
                        handleMeetingJoin(event, appointmentCode);
                      }
                    }}
                  >
                    {meetButtonText}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </>
        ))}

      <PrescriptionButton key={id} id={id} prescription={prescription} />

      {!isMobile && (
        <IconButton
          disableRipple
          onClick={(event) => handleAppointmentClick(event, id)}
          sx={styles.detailsIconButtonStyles}
        >
          {shouldRenderDetails ? (
            <KeyboardArrowDownIcon sx={styles.detailsIconStyles} />
          ) : (
            <KeyboardArrowRightIcon sx={styles.detailsIconStyles} />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default AppointmentCardButtons;
