import * as React from "react";
const DeleteIcon = (props: any) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.68555 11.6667V19.6667"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1846 11.6667V19.6667"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.43457 6.33374H23.4346"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.18457 6.33374H12.4346H20.6846V21.0004C20.6846 23.2096 18.8378 25.0004 16.5596 25.0004H8.30957C6.0314 25.0004 4.18457 23.2096 4.18457 21.0004V6.33374Z"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.31055 3.66667C8.31055 2.19391 9.54176 1 11.0605 1H13.8105C15.3294 1 16.5605 2.19391 16.5605 3.66667V6.33333H8.31055V3.66667Z"
      stroke="black"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DeleteIcon;
