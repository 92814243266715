import { Box } from "@mui/material";
import React from "react";
import { HorizontalTabsProps } from "../../../types/profile";

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  onClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        overflowX: "auto",
      }}
    >
      {tabs?.map((tab: string, index) => (
        <Box
          key={index}
          sx={{
            paddingBottom: selectedTab === tab ? "2px" : 0,
            borderBottom:
              selectedTab === tab
                ? "4px solid var(--neuro-button-bg-success)"
                : "none",
            height: "40px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            fontSize: "20px",
            lineHeight: "24px",
            fontStyle: "Roboto Condensed",
            color:
              selectedTab === tab
                ? "var(--neuro-black-text)"
                : "var(--neuro-darkgrey_border)",
            marginRight: selectedTab === tab ? "25px" : "29px",
            "&:last-child": { marginRight: 0 },
          }}
          onClick={() => onClick(tab)}
        >
          {tab}
        </Box>
      ))}
    </Box>
  );
};

export default HorizontalTabs;
