import { Grid } from "@mui/material";
import GeneralPhysicianIcon from "../../svg/GeneralPhysicianIcon";
import NeuroDoctorIcon from "../../svg/NeuroDoctorIcon";
import PediatricIcon from "../../svg/PediatricIcon";
import SpecialityCard from "./SpecialityCard";
import useAppStore from "../../../store/appStore";
import { SpecialityProps } from "../../../types/appointments";

const SpecialityList: React.FC<SpecialityProps> = ({ patientId }) => {
  const { isMenuCollapsed } = useAppStore();

  const specialties = [
    {
      id: 1,
      title: "General Physician",
      specialization: "Managing acute medical conditions",
      conditions: "Typhoid, Abdominal Pain, Migraine, Infections",
      icon: <GeneralPhysicianIcon />,
    },
    {
      id: 2,
      title: "Neurology",
      specialization: "Managing Issues of the nervous system",
      conditions: "Typhoid, Abdominal Pain, Migraine, Infections",
      icon: <NeuroDoctorIcon />,
    },
    // {
    //   id: 3,
    //   title: "Pediatrics",
    //   specialization: "Specialists to care and treat children",
    //   conditions: "Typhoid, Abdominal Pain, Migraine, Infections",
    //   icon: <PediatricIcon />,
    // },
  ];
  return (
    <Grid container spacing={2.5}>
      {specialties?.map((specialtiy) => (
        <Grid
          item
          xs={12}
          md={isMenuCollapsed ? 3 : 4}
          xl={isMenuCollapsed ? 12 / 5 : 3}
          key={specialtiy.id}
        >
          <SpecialityCard patientId={patientId} {...specialtiy} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SpecialityList;
