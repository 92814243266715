// @ts-nocheck

import React, { useEffect, useState } from "react";

// third-party imports
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { App, getDwvVersion, decoderScripts } from "dwv";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CloseIcon from "@mui/icons-material/Close";
import ContrastIcon from "@mui/icons-material/Contrast";
import StraightenIcon from "@mui/icons-material/Straighten";

// project imports
import TagsTable from "./TagsTable";
import { DicomThumbnailProps } from "../../../types/documents";

// Image decoders (for web workers)
decoderScripts["jpeg2000"] = `/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts["jpeg-lossless"] =
  `/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts["jpeg-baseline"] =
  `/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts["rle"] = `/assets/dwv/decoders/dwv/decode-rle.js`;

const TransitionUp = React.forwardRef((props: any, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

const DicomViewer: React.FC<DicomThumbnailProps> = ({ dicomUrl }) => {
  const [versions] = useState({
    dwv: getDwvVersion(),
    react: React.version,
  });
  const [tools] = useState({
    Scroll: {},
    ZoomAndPan: {},
    WindowLevel: {},
    Draw: {
      options: ["Ruler"],
    },
  });

  const [selectedTool, setSelectedTool] = useState<string>("Select Tool");
  const [loadProgress, setLoadProgress] = useState<number>(0);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [dwvApp, setDwvApp] = useState<App | null>(null);
  const [metaData, setMetaData] = useState<Record<string, any>>({});
  const [orientation, setOrientation] = useState<string | undefined>(undefined);
  const [showDicomTags, setShowDicomTags] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [feedbackValue, setFeedbackValue] = useState<string>("");

  useEffect(() => {
    setDwvApp(new App());
  }, [tools]);

  useEffect(() => {
    if (dwvApp) {
      dwvApp.init({
        dataViewConfigs: { "*": [{ divId: "layerGroup0" }] },
        tools,
      });

      if (dicomUrl) {
        try {
          // dwvApp.loadFiles(dicomUrl);
          dwvApp.loadURLs([dicomUrl]);
        } catch (error) {
          console.error("Error loading DICOM files:", error);
        }
      } else {
        console.error("dicomUrl is undefined or invalid.");
      }

      dwvApp.addEventListener("loadprogress", (event: any) => {
        setLoadProgress(event.loaded);
      });

      dwvApp.addEventListener("load", (event: any) => {
        setMetaData(dwvApp.getMetaData(event.dataid));
        setDataLoaded(true);
      });

      dwvApp.addEventListener("renderend", () => {
        const selectedTool = dwvApp.canScroll() ? "Scroll" : "ZoomAndPan";
        setSelectedTool(selectedTool);
        if (selectedTool === "Draw") {
          onChangeShape(tools.Draw.options[0]);
        }
      });

      dwvApp.addEventListener("loadend", (event: any) => {
        if (event.receivedLoadError) {
          setLoadProgress(0);
          alert("Received errors during load. Check log for details.");
        }
        if (event.receivedLoadAbort) {
          setLoadProgress(0);
          alert("Load was aborted.");
        }
      });

      window.addEventListener("resize", dwvApp.onResize);

      dwvApp.loadFromUri(window.location.href);
    }

    return () => {
      if (dwvApp) {
        window.removeEventListener("resize", dwvApp.onResize);
      }
    };
  }, [dwvApp, dicomUrl]);

  function toggleOrientation() {
    let newOrientation;
    if (orientation === "axial") {
      newOrientation = "coronal";
    } else if (orientation === "coronal") {
      newOrientation = "sagittal";
    } else if (orientation === "sagittal") {
      newOrientation = "axial";
    } else {
      newOrientation = "coronal"; // Default orientation
    }
    setOrientation(newOrientation);

    const config = {
      "*": [
        {
          divId: "layerGroup0",
          orientation: newOrientation,
        },
      ],
    };
    if (dwvApp) {
      dwvApp.setDataViewConfigs(config);
      const dataIds = dwvApp.getDataIds();
      dataIds.forEach((dataId: any) => {
        dwvApp.render(dataId);
      });
    }
  }

  const handleReset = () => {
    if (dwvApp) {
      dwvApp.resetLayout();
      // Additional reset logic if needed
    }
  };

  function canRunTool(tool: string): boolean {
    switch (tool) {
      case "Scroll":
        return dwvApp && dwvApp.canScroll();
      case "WindowLevel":
        return dwvApp && dwvApp.canWindowLevel();
      default:
        return true;
    }
  }

  function getToolIcon(tool: string): JSX.Element | null {
    switch (tool) {
      case "Scroll":
        return <MenuIcon />;
      case "ZoomAndPan":
        return <SearchIcon />;
      case "WindowLevel":
        return <ContrastIcon />;
      case "Draw":
        return <StraightenIcon />;
      default:
        return null;
    }
  }

  const toolsButtons = Object.keys(tools).map((tool) => (
    <ToggleButton
      key={tool}
      value={tool}
      title={tool}
      disabled={!dataLoaded || !canRunTool(tool)}
    >
      {getToolIcon(tool)}
    </ToggleButton>
  ));

  function onChangeShape(shape: string) {
    if (dwvApp) {
      dwvApp.setToolFeatures({ shapeName: shape });
    }
  }

  function onChangeTool(tool: string) {
    setSelectedTool(tool);
    if (dwvApp) {
      dwvApp.setTool(tool);
      if (tool === "Draw") {
        onChangeShape(tools.Draw.options[0]);
      }
    }
  }

  function handleToolChange(
    event: React.MouseEvent<HTMLElement>,
    newTool: string | null
  ) {
    if (newTool) {
      onChangeTool(newTool);
    }
  }

  function handleTagsDialogClose() {
    setShowDicomTags(false);
  }

  function handleTagsDialogOpen() {
    setShowDicomTags(true);
  }

  return (
    <div id="dwv">
      <Stack
        direction="row"
        spacing={1}
        justifyContent={"center"}
        padding={1}
        flexWrap="wrap"
      >
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={selectedTool}
          exclusive
          onChange={handleToolChange}
        >
          {toolsButtons}
        </ToggleButtonGroup>

        <ToggleButton
          size="small"
          value="reset"
          title="Reset"
          disabled={!dataLoaded}
          onClick={handleReset}
        >
          <RefreshIcon />
        </ToggleButton>

        <ToggleButton
          size="small"
          value="toggleOrientation"
          title="Toggle Orientation"
          disabled={!dataLoaded}
          onClick={toggleOrientation}
        >
          <CameraswitchIcon />
        </ToggleButton>

        <ToggleButton
          size="small"
          value="tags"
          title="Tags"
          disabled={!dataLoaded}
          onClick={handleTagsDialogOpen}
        >
          <LibraryBooksIcon />
        </ToggleButton>

        <Dialog
          open={showDicomTags}
          onClose={handleTagsDialogClose}
          TransitionComponent={TransitionUp}
        >
          <AppBar sx={{ position: "relative" }} position="sticky">
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={handleTagsDialogClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" sx={{ flex: 1 }}>
                DICOM Tags
              </Typography>
            </Toolbar>
          </AppBar>
          <TagsTable data={metaData} />
        </Dialog>
      </Stack>

      <div id="layerGroup0" className="layerGroup"></div>
    </div>
  );
};

export default DicomViewer;
