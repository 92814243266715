import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import Input from "../ui/Input";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import { sendOtp } from "../../services/authService";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for the datepicker
import useAuthStore from "../../store/authStore";
import { PatientEmailInputs, PatientEmailProps } from "../../types/patients";
import { patientEmailSchema } from "../../validations/patientValidation";
import axios from "axios";
import useAppStore from "../../store/appStore";

const PatientEmail: React.FC<PatientEmailProps> = ({
  setEmail,
  setOtpToken,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PatientEmailInputs>({
    resolver: yupResolver(patientEmailSchema),
  });

  const [loading, setLoading] = useState(false);

  const { user } = useAuthStore();
  const { showSnackbar } = useAppStore();

  const [isSameEmailError, setIsSameEmailError] = useState(false);

  const onSubmit: SubmitHandler<PatientEmailInputs> = async (data) => {
    setIsSameEmailError(false);
    setLoading(true);
    try {
      const payloadData = {
        emailOrMobileOrAadhaarOrAbhaNumber: data.email,
        createdBy: user?.userId || "",
        registrationStatus: "NEW",
      };

      const result = await sendOtp(payloadData);

      console.log("result insend otp", result);

      setEmail(data.email);
      setOtpToken(result.otpToken);

      //   login(result.accessToken, result.refreshToken);

      // Handle post-registration logic (e.g., redirect to dashboard or show success message)
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        console.log("error.response", error.response);
        if (
          error?.response?.data.message ===
          "User already exists with this email."
        ) {
          setIsSameEmailError(true);
        } else {
          showSnackbar(
            error?.response?.data.message ||
              "Could not sent otp, please try gain",
            "error"
          );
        }
      } else {
        console.error("OTP request failed:", error);
        showSnackbar(
          error?.response?.data.message ||
            "Could not sent otp, please try gain",
          "error"
        );
      }
    }
    setLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        mt: 4,
        mb: 4,
        margin: "0 auto",
      }}
    >
      <Box>
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <Input
              type="text"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "40px",
                },
              }}
              label="Email*"
              {...register("email")}
              error={!!errors.email || isSameEmailError}
              helperText={
                isSameEmailError
                  ? "Email already exists"
                  : errors.email?.message
              }
            />
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 5.5, mb: 7 }}>
          <Button
            type="submit"
            variant="contained"
            className="success-button"
            color="success"
            disabled={loading}
            sx={{ minWidth: 150, height: 40 }}
          >
            {loading ? <Spinner /> : "Get Otp"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PatientEmail;
