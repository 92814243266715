import { useEffect, useState } from "react";
import PatientTabs from "../../patients/PatientTabs";
import HorizontalTabs from "../../profile/patient/HorizontalTabs";
import ComponentHeader from "../../shared/ComponentHeader";
import { capitalizeFirstLetter } from "../../../utils/common";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAppStore from "../../../store/appStore";

const PatientInformation = () => {
  // Props & state values
  const navigate = useNavigate();
  const { selectedPatient } = useAppStore();
  const tabs = [
    "Profile",
    "Documents",
    "Appointments",
    "Forms",
    "ABHA Details",
  ];

  const [selectedTab, setSelectedTab] = useState<string>("Profile");

  // callbacks & functions
  function getInitialTab() {
    if (window.location.pathname.toLowerCase().includes("abha-details")) {
      return "ABHA Details";
    }
    const foundTab = tabs.find((tab) =>
      window.location.pathname.toLowerCase().includes(tab.toLowerCase())
    );
    if (window.location.pathname.toLowerCase().includes("forms")) {
      return "Forms";
    }
    return foundTab || "Profile";
  }

  useEffect(() => {
    const newTab = getInitialTab();
    setSelectedTab(newTab);
  }, [window.location.pathname]);

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (selectedPatient) {
      if (tab === "ABHA Details") {
        navigate(`/appointments/patient/${selectedPatient.id}/abha-details`);
      } else {
        navigate(
          `/appointments/patient/${selectedPatient.id}/${tab.toLowerCase()}`
        );
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingInline: "20px",
        paddingBlock: "15px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <ComponentHeader
          title={`Patient / ${capitalizeFirstLetter(
            selectedPatient?.user?.firstName || ""
          )} ${capitalizeFirstLetter(
            selectedPatient?.user?.lastName || ""
          )} / ${selectedTab}`}
          isBreadcrumb
        />

        <HorizontalTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onClick={onTabClick}
        />
      </Box>

      <PatientTabs
        selectedPatient={selectedPatient}
        selectedTab={selectedTab}
      />
    </Box>
  );
};

export default PatientInformation;
