import React from "react";

// project imports
import { Box, Typography } from "@mui/material";
import useAppointmentStore from "../../store/appointmentsStore";
import { ONLINE } from "../../utils/constants";

const AppointmentPayment = () => {
  const { selectedDoctor, selectedMeetingType } = useAppointmentStore();

  const charges =
    selectedMeetingType === ONLINE
      ? selectedDoctor?.onlineConsultationFee || null
      : selectedDoctor?.inPersonConsultationFee || null;

  const gstValue = selectedDoctor?.taxes?.gst.replace("%", "") || null;

  const gstAmount =
    charges && gstValue ? (Number(charges) * Number(gstValue)) / 100 : null;

  return (
    <Box
      sx={{
        marginTop: "26px",
        marginBottom: "12px",
      }}
    >
      <Box
        sx={{ paddingInline: "20px", display: "flex", flexDirection: "column" }}
      >
        <Typography
          sx={{
            fontSize: "var(--neuro-font-size-medium-smallest)",
            lineHeight: "var(--neuro-line-height-extra-small)",
            fontFamily: "var(--neuro-font-family-roboto-condensed)",
            color: "var(--neuro-bg-darkblue-primary)",
          }}
        >
          Payment Summary
        </Typography>
        <Box sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography>Appointment Charge</Typography>
            <Typography sx={{ fontWeight: "var(--neuro-font-weight-bold)" }}>
              {charges}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Typography>GST {selectedDoctor?.taxes?.gst || ""}</Typography>
            <Typography sx={{ fontWeight: "var(--neuro-font-weight-bold)" }}>
              {gstAmount}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            border: "1px dashed var(--neuro-secondary_border)",
          }}
        />
      </Box>
    </Box>
  );
};

export default AppointmentPayment;
