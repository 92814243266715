import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, useMediaQuery } from "@mui/material";

import DocumentList from "./DocumentList";
import OriginalReport from "./OriginalReport";
import ReportSummary from "./ReportSummary";
import useAuthStore from "../../../store/authStore";
import useDocumentStore from "../../../store/documentsStore";
import useAppStore from "../../../store/appStore";
import { DocumentsProps } from "../../../types/documents";

const ReportViewer: React.FC<DocumentsProps> = ({ patientId }) => {
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const isMobile = useMediaQuery("(max-width:800px)");

  const { isMenuCollapsed, setIsMenuCollapsed } = useAppStore();

  const [previousSidebarCollpased, setPreviousSidebarCollpased] =
    useState<boolean>(isMenuCollapsed ? true : false);

  const {
    documents,
    reGeneratedSummaryData,
    cleanUpReGenerateSummaryState,
    documentDetails,
    loadingDocumentDetails,
    errorDocumentDetails,
    fetchDocumentDetails,
    cleanUpDocumentDetailsStore,
    selectedDocumentId,
    setSelectedDocumentId,
  } = useDocumentStore();

  console.log("documents in report viewer", documents);

  useEffect(() => {
    setIsMenuCollapsed(true);

    return () => {
      cleanUpDocumentDetailsStore();
      setIsMenuCollapsed(previousSidebarCollpased);
    };
  }, []);

  useEffect(() => {
    if (selectedDocumentId) {
      fetchDocumentDetails(selectedDocumentId);
      cleanUpReGenerateSummaryState();
    }
  }, [selectedDocumentId, reGeneratedSummaryData]);

  useEffect(() => {
    if (documents && documents.length > 0 && selectedDocumentId) {
      const document = documents.filter((doc) => doc.id === selectedDocumentId);
      if (document.length === 0) {
        setSelectedDocumentId(documents[0]?.id);
      }
    }
  }, [documents, selectedDocumentId]);

  // redirect to documents page if no documents
  useEffect(() => {
    if (documents && documents.length === 0) {
      if (patientId) {
        navigate(`/patients/${patientId}/documents`);
      } else {
        navigate("/documents");
      }
    }
  }, [documents]);

  const handleBackToReports = () => {
    navigate("/documents");
  };

  return (
    <Box
      p={2.5}
      sx={{
        backgroundColor: "var(--neuro-white-text)",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={2}
        flexWrap="wrap"
        sx={{ height: "100%", overflow: "auto" }}
      >
        {userRole === "PATIENT" && (
          <>
            <Grid
              item
              xs={12}
              md={isMenuCollapsed ? 3.5 : 4}
              xl={3}
              sx={{
                height: isMobile ? "auto" : "100%",
                overflowY: "hidden",
              }}
            >
              <DocumentList patientId={patientId} />
            </Grid>
            <Grid
              item
              xs={12}
              md={isMenuCollapsed ? 8.5 : 8}
              xl={9}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <OriginalReport
                key={documentDetails?.id}
                loading={loadingDocumentDetails}
                error={Boolean(errorDocumentDetails)}
                fileType={documentDetails?.fileType}
                filePath={documentDetails?.filePath}
                fileName={documentDetails?.name}
                id={documentDetails?.id}
                status={documentDetails?.status}
                patientId={patientId}
              />
            </Grid>
          </>
        )}

        {userRole !== "PATIENT" && (
          <>
            <Grid
              item
              xs={12}
              md={isMenuCollapsed ? 2.5 : 3}
              xl={2}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <DocumentList />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              xl={5}
              sx={{
                height: "100%",
                overflowY: "hidden",
              }}
            >
              <OriginalReport
                loading={loadingDocumentDetails && !documentDetails}
                error={Boolean(errorDocumentDetails) && !documentDetails}
                fileType={documentDetails?.fileType}
                filePath={documentDetails?.filePath}
                fileName={documentDetails?.name}
                id={documentDetails?.id}
                status={documentDetails?.status}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={isMenuCollapsed ? 4.5 : 4}
              xl={5}
              sx={{ height: "100%", overflowY: "hidden" }}
            >
              <ReportSummary
                loading={loadingDocumentDetails}
                id={documentDetails?.id}
                metadata={documentDetails?.metadata}
                doctorSummary={documentDetails?.doctorSummary}
                patientSummary={documentDetails?.patientSummary}
                fileName={documentDetails?.name}
                isDoctorApproved={documentDetails?.isDoctorApproved}
                feedback={documentDetails?.feedback}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ReportViewer;
