import api from "./apiService"; // Assuming `api` is your Axios instance

export const fetchHospitals = () => {
  return api.get(`/api/hospitals`);
};

export const fetchHospitalById = (id: string) => {
  return api.get(`/api/hospitals/${id}`);
};

export const addHospital = (hospitalData: any) => {
  return api.post(`/api/hospitals`, hospitalData);
};

export const updateHospital = (id: string, hospitalData: any) => {
  return api.put(`/api/hospitals/${id}`, hospitalData);
};

export const deleteHospital = (id: string) => {
  return api.delete(`/api/hospitals/${id}`);
};
