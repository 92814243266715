export const CANCELLED = "CANCELLED";
export const UPCOMING = "UPCOMING";
export const ONGOING = "ONGOING";
export const COMPLETED = "COMPLETED";
export const ONLINE = "ONLINE";
export const IN_PERSON = "IN_PERSON";

export const OPTIONBASED = "OptionBased";
export const INPUTSUM = "InputSum";
export const PATIENTS = "patients";

export const PATIENT = "PATIENT";
export const ADMIN = "ADMIN";
export const DOCTOR = "DOCTOR";
