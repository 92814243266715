import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CircularProgressBar from "./CircularProgressBar";
import { differenceInYears, parseISO } from "date-fns";
import { capitalizeWordsPreservingSpaces } from "../../../utils/common";
import { GeneralDetailsProps } from "../../../types/appointments";

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ patientDetails }) => {
  console.log("patientDetails", patientDetails);
  // callbacks & functions
  const calculateAge = (dateString: string): number | string => {
    if (dateString === "" || dateString === undefined) {
      return "";
    }
    const birthDate = parseISO(dateString);

    const age = differenceInYears(new Date(), birthDate);

    return age;
  };

  return (
    <Box
      sx={{
        paddingBottom: "19px",
        borderBottom: "1px dashed var(--neuro-light-grey_border)",
        marginBottom: "19px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "27px",
            }}
          >
            <Typography
              sx={{
                fontSize: "25px",
                lineHeight: "30px",
                fontFamily: "Roboto Condensed",
                color: "var(--neuro-button-bg-primary)",
                cursor: "pointer",
              }}
              // onDoubleClick={}
            >
              General details
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "19px" }}>
              <Box
                sx={{
                  height: "25px",
                  paddingLeft: "5px",
                  borderLeft: "6px solid var(--neuro-button-bg-success)",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--neuro-black-text)",
                  }}
                >
                  Name :{" "}
                  {capitalizeWordsPreservingSpaces(
                    patientDetails?.Name || ""
                  ) || ""}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  rowGap: "19px",
                }}
              >
                <Box
                  sx={{
                    height: "25px",
                    paddingLeft: "5px",
                    borderLeft: "6px solid var(--neuro-button-bg-success)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                    }}
                  >
                    Height : {patientDetails?.Height || ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "25px",
                    paddingLeft: "5px",
                    borderLeft: "6px solid var(--neuro-button-bg-success)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                    }}
                  >
                    Gender : {patientDetails?.Gender}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "25px",
                    paddingLeft: "5px",
                    borderLeft: "6px solid var(--neuro-button-bg-success)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                    }}
                  >
                    Weight : {patientDetails?.Weight}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "25px",
                    paddingLeft: "5px",
                    borderLeft: "6px solid var(--neuro-button-bg-success)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--neuro-black-text)",
                    }}
                  >
                    Age :{" "}
                    {patientDetails?.dateOfBirth &&
                    patientDetails?.dateOfBirth !== ""
                      ? calculateAge(patientDetails?.dateOfBirth || "")
                      : patientDetails?.age || ""}{" "}
                    Years
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={4.5}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              border: "1px dashed var(--neuro-secondary_border)",
              borderRadius: "25px",
              padding: "18px",
              paddingInline: 0,
            }}
          >
            <CircularProgressBar value={63} />

            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                color: "var(--neuro-black-text)",
                fontFamily: "Roboto Slab",
              }}
            >
              Calculated from{" "}
              <span
                style={{
                  color: "var(--neuro-button-bg-primary)",
                  fontWeight: 700,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                test reports
              </span>
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default GeneralDetails;
