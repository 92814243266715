import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { PatientsListProps } from "../../types/patients";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../utils/common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";

const PatientsList: React.FC<PatientsListProps> = ({
  patients,
  selectedPatient,
  setSelectedPatient,
}) => {
  // props & state values
  const navigate = useNavigate();

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                First Name
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Last Name
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Mobile
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Email
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Gender
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Registered On
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Initial Appointment
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                Reasons for Appointment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients?.map((patient) => (
              <TableRow
                key={patient.id}
                sx={{
                  backgroundColor:
                    selectedPatient && selectedPatient?.id === patient?.id
                      ? "var(--neuro-bg-light-grey-secondary)"
                      : "var(--neuro-white-text)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedPatient(patient || null);
                  navigate(`/patients/${patient?.id}/profile`);
                }}
              >
                <TableCell>{patient.user?.firstName || "-"}</TableCell>
                <TableCell>{patient.user?.lastName || "-"}</TableCell>
                <TableCell>{patient.user?.mobile || "-"}</TableCell>
                <TableCell>{patient.user?.email || "-"}</TableCell>
                <TableCell>{patient?.gender || "-"}</TableCell>

                <TableCell sx={{ fontWeight: "bold" }}>
                  {getFormattedDate(patient?.createdAt || "-")}
                </TableCell>
                <TableCell>
                  {patient?.isFirstAppointmentCompleted ? (
                    <CheckCircleIcon
                      sx={{ color: "var(--neuro-button-bg-success)" }}
                    />
                  ) : (
                    <PendingIcon
                      sx={{ color: "var(--neuro-button-bg-primary)" }}
                    />
                  )}
                </TableCell>
                <TableCell>{patient?.reasonsForAppointments || "-"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PatientsList;
