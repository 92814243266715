import { useEffect, useState } from "react";

// third-party imports
import validator from "@rjsf/validator-ajv8";
import { Box, Typography, useMediaQuery } from "@mui/material";
import DynamicForm from "./DynamicForm";

// project imports
import Button from "../ui/Button";
import CustomStepper from "./CustomStepper";
import Spinner from "../ui/Spinner";
import FormFilledDetails from "./FormFilledDetails";
import { INPUTSUM, OPTIONBASED } from "../../utils/constants";
import {
  FormData,
  FormSchema,
  GeneralFormProps,
  Step,
} from "../../types/forms";

const GeneralForm = ({
  formSchema,
  disorder,
  onFinish,
  patientName,
  loading,
  onClosing,
  readOnly = false,
  isEdit = false,
  // loading = false,
}: GeneralFormProps) => {
  // props & state values
  const [_, setFormData] = useState<any>({});
  const [stepData, setStepData] = useState<any>(formSchema.formData || {});
  const [viewScore, setViewScore] = useState<any>(null);

  const [score, setScore] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const [__, setCompletedData] = useState<
    { step: string; data: any; score?: number }[]
  >([]);

  const [stepCompleted, setStepCompleted] = useState<boolean[]>(
    generateStepStatusArray(formSchema)
  );

  const isMobile = useMediaQuery("(max-width:600px)");
  const isScoreBased: boolean = formSchema.isScoreBased;
  const autoSubmit = formSchema.autoSubmit;
  const scoreCalculationLogic = formSchema?.scoreCalculationLogic; // "OptionBased" || "InputSum"

  // to show number of steps in stepper
  const steps =
    formSchema && formSchema.properties
      ? Object.keys(formSchema.properties)
      : [];

  // to find how many questions a step has
  const stepQuestions =
    formSchema && formSchema.properties
      ? Object.keys(
          formSchema.properties[`step${activeStep + 1}`]?.properties || {}
        ).length
      : 0;

  // to show or hide stepper
  const showSteps: boolean = steps.length > 1;

  // callbacks & functions
  useEffect(() => {
    return () => {
      setViewScore(null);
    };
  }, []);

  // useEffect(() => {
  //   if (isEdit) {
  //     if (scoreCalculationLogic && scoreCalculationLogic === INPUTSUM) {
  //       let total = 0;
  //       total = Object.keys(formSchema?.formData)?.reduce((sum, key) => {
  //         return sum + formSchema?.formData[key].answer;
  //       }, 0);
  //       setScore(total || 0);
  //     } else if (
  //       scoreCalculationLogic &&
  //       scoreCalculationLogic === OPTIONBASED
  //     ) {
  //       let totalScore = 0;

  //       totalScore = Object.keys(formSchema?.formData)?.reduce((sum, step) => {
  //         const userAnswer = formSchema?.formData[step].answer;
  //         const scoreValue = formSchema?.scores[step]?.answer[userAnswer] || 0;
  //         return sum + scoreValue;
  //       }, 0);
  //       setScore(totalScore || 0);
  //     }
  //   }
  // }, [isEdit, formSchema, scoreCalculationLogic]);

  useEffect(() => {
    if (isEdit) {
      const calculateScore = () => {
        if (scoreCalculationLogic && scoreCalculationLogic === INPUTSUM) {
          const total = Object.keys(formSchema?.formData || {}).reduce(
            (sum, key) => sum + formSchema?.formData[key].answer,
            0
          );
          setScore(total || 0);
        } else if (
          scoreCalculationLogic &&
          scoreCalculationLogic === OPTIONBASED
        ) {
          const totalScore = Object.keys(formSchema?.formData || {}).reduce(
            (sum, step) => {
              const userAnswer = formSchema?.formData[step].answer;
              return sum + (formSchema?.scores[step]?.answer[userAnswer] || 0);
            },
            0
          );
          setScore(totalScore);
        }
      };
      calculateScore();
    }
  }, [isEdit, formSchema, scoreCalculationLogic]);

  useEffect(() => {
    if (
      scoreCalculationLogic === OPTIONBASED &&
      !isEdit &&
      activeStep === steps.length - 1 &&
      stepCompleted[activeStep]
    ) {
      if (onFinish) {
        onFinish({ ...formSchema, formData: stepData }, score);
      }
    }
  }, [
    activeStep,
    stepCompleted,
    formSchema,
    onFinish,
    scoreCalculationLogic,
    score,
    steps?.length,
  ]);

  // useEffect(() => {
  //   if (formSchema) {
  //     setStepCompleted(generateStepStatusArray(formSchema));
  //   }
  // }, [formSchema]);

  // Function to check if all properties in a step are either `0` or `""`
  function checkStepValues(step: FormData): boolean {
    return Object.values(step).every(
      (value) => value !== null && value !== "" && value !== undefined
      // (value && value !== null) || value !== "" || value !== undefined
    );
  }

  // Function to generate an array of boolean values based on formSchema
  function generateStepStatusArray(formSchema: FormSchema): boolean[] {
    const stepKeys = Object.keys(formSchema.formData);

    return stepKeys.map((stepKey) => {
      const step = formSchema.formData[stepKey];

      // Check if the step is valid and not null or undefined
      return step !== null && step !== undefined
        ? checkStepValues(step)
        : false;
    });
  }

  // to calculate score for a scorebased MIDAS form
  function calculateTotalScoreForMidas(steps: Step): number {
    let totalScore = 0;

    for (const key in steps) {
      if (steps.hasOwnProperty(key)) {
        const answer = steps[key].answer;
        if (answer !== undefined) {
          totalScore += answer;
        }
      }
    }

    return totalScore;
  }

  // to calculate score for a scorebased form
  const calculateStepScore = (data: any, stepKey: string): number => {
    const stepScores = formSchema.scores[stepKey];
    let stepScore = 0;

    for (const key in data) {
      if (stepScores[key]) {
        if (typeof stepScores[key] === "object") {
          stepScore += stepScores[key][data[key]] || 0;
        } else {
          stepScore += stepScores[key].score;
        }
      }
    }
    return stepScore;
  };

  // next button handler
  const handleNext = ({ formData }: { formData: any }) => {
    if (readOnly) return;

    setStepData((prevData: any) => ({
      ...prevData,
      [`step${activeStep + 1}`]: formData,
    }));

    setFormData((prevData: any) => ({ ...prevData, ...formData }));

    if (scoreCalculationLogic === OPTIONBASED) {
      const stepScore = calculateStepScore(formData, `step${activeStep + 1}`);
      setScore((prevScore) => prevScore + stepScore);

      setCompletedData((prevData) => [
        ...prevData,
        { step: `step${activeStep + 1}`, data: formData, score: stepScore },
      ]);
    } else {
      setCompletedData((prevData) => [
        ...prevData,
        { step: `step${activeStep + 1}`, data: formData },
      ]);
    }

    // Mark current step as completed
    if (steps.length > 1) {
      const updatedStepCompleted = [...stepCompleted];
      updatedStepCompleted[activeStep] = true;
      setStepCompleted(updatedStepCompleted);
    }

    if (activeStep !== steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  // input change
  const handleChange = ({ formData }: any) => {
    setFormData((prevData: any) => ({ ...prevData, ...formData }));

    // if a step has more than one questions or form is MIDAS
    if (stepQuestions > 1 || scoreCalculationLogic === INPUTSUM) {
      setStepData((prevData: any) => ({
        ...prevData,
        [`step${activeStep + 1}`]: formData,
      }));

      // if form is MIDAS
      if (scoreCalculationLogic === INPUTSUM) {
        const stepScore = calculateTotalScoreForMidas({
          ...stepData,
          [`step${activeStep + 1}`]: formData,
        });
        setScore(stepScore);
      }
    }

    // Check if all required fields for the current step are filled
    if (steps.length > 1) {
      const currentStepSchema = formSchema.properties[`step${activeStep + 1}`];
      const requiredFields = currentStepSchema.required || [];

      const allFieldsFilled = requiredFields.every((field: string) => {
        const fieldValue = formData[field];
        return fieldValue !== undefined && fieldValue !== "";
      });

      // Update stepCompleted based on validation
      const updatedStepCompleted = [...stepCompleted];

      updatedStepCompleted[activeStep] = allFieldsFilled;
      setStepCompleted(updatedStepCompleted);
    }

    // if autosubmit set to true and step has only one question
    if (autoSubmit && stepQuestions === 1) {
      setTimeout(() => {
        handleNext({ formData });
      }, 350);
    }
  };

  // on submitting the form
  const handleSubmit = () => {
    if (readOnly) {
      return;
    }

    // we are submiting the form in useEffect for scoreCalculationLogic === OPTIONBASED forms
    if (
      activeStep ===
      steps.length - 1
      // scoreCalculationLogic !== OPTIONBASED
    ) {
      if (onFinish) {
        const reponse = {
          ...formSchema,
          formData: stepData,
        };
        onFinish(reponse, score);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  // back button handler
  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  //  to calculate core for view only - since no input interactions
  const calculateScoreForViewForms = () => {
    if (scoreCalculationLogic === INPUTSUM) {
      let total = 0;
      total = Object.keys(formSchema?.formData)?.reduce((sum, key) => {
        return sum + formSchema?.formData[key].answer;
      }, 0);
      setViewScore(total !== null && total !== undefined ? total : null);
    } else {
      let totalScore = 0;
      totalScore = Object.keys(formSchema?.formData)?.reduce((sum, step) => {
        const userAnswer = formSchema?.formData[step].answer;
        const scoreValue = formSchema?.scores[step]?.answer[userAnswer] || 0;
        return sum + scoreValue;
      }, 0);

      setViewScore(
        totalScore !== null && totalScore !== undefined ? totalScore : null
      );
    }
  };

  // to score for readOnly
  const handleViewScore = () => {
    // setViewScore(true);
    calculateScoreForViewForms();
  };

  // to show custom buttons in form - next, back and finish
  function SubmitButton() {
    if (readOnly) {
      return (
        <>
          {showSteps && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBlock: "20px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {activeStep !== 0 && (
                  <Button
                    variant="contained"
                    className="secondary-button"
                    color="secondary"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}

                {activeStep < steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="secondary-button"
                    onClick={handleNextStep}
                  >
                    Next
                  </Button>
                ) : isScoreBased ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="secondary-button"
                    onClick={handleViewScore}
                  >
                    View Score
                  </Button>
                ) : null}
              </Box>
            </Box>
          )}
          {!showSteps && isScoreBased && (
            <Button
              variant="contained"
              color="secondary"
              className="secondary-button"
              onClick={handleViewScore}
            >
              View Score
            </Button>
          )}
        </>
      );
    }

    return (
      <>
        {showSteps && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="secondary-button"
                  color="secondary"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}

              <Button
                variant="contained"
                color="secondary"
                className="secondary-button"
                type="submit"
                // disabled={isEmpty}
                disabled={!stepCompleted[activeStep] || loading}
              >
                {activeStep < steps.length - 1 ? (
                  "Next"
                ) : loading ? (
                  <Spinner />
                ) : (
                  "Finish"
                )}
              </Button>
            </Box>
          </Box>
        )}

        {!showSteps && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "20px",
            }}
          >
            <Button
              variant="contained"
              className="secondary-button"
              color="secondary"
              type="submit"
              disabled={loading}
            >
              {loading ? <Spinner /> : "Finish"}
            </Button>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      {viewScore === null && (
        <Box
          sx={{
            backgroundColor: "var(--neuro-login-form-bg-primary)",
            paddingBlock: isMobile ? "10px" : "24px",
            paddingInline: isMobile ? "15px" : "40px",
            borderRadius: "20px",
          }}
        >
          {disorder && (
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "30px",
                  lineHeight: "120%",
                  fontFamily: "Inter",
                  color: "var(--neuro-black-text)",
                }}
              >
                {disorder}
              </Typography>
            </Box>
          )}

          {showSteps && (
            <CustomStepper
              steps={steps}
              activeStep={activeStep}
              onStepChange={setActiveStep}
              stepCompleted={stepCompleted}
              readOnly={readOnly}
            />
          )}

          <DynamicForm
            schema={formSchema.properties[`step${activeStep + 1}`]}
            uiSchema={formSchema.uiSchema[`step${activeStep + 1}`]}
            formData={stepData[`step${activeStep + 1}`] || {}}
            validator={validator}
            onChange={handleChange}
            onSubmit={handleSubmit}
            templates={{
              ButtonTemplates: { SubmitButton },
            }}
            readOnly={readOnly}
          />
        </Box>
      )}

      {viewScore !== null && (
        <FormFilledDetails
          score={
            viewScore !== null && viewScore !== undefined ? viewScore : null
          }
          patientName={patientName}
          onClose={onClosing}
        />
      )}
    </>
  );
};

export default GeneralForm;
