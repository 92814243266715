import * as React from "react";
const BigBorderIcon = (props: any) => (
  <svg
    width={264}
    height={92}
    viewBox="0 0 264 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M141.5 1H13C6.37258 1 1 6.37258 1 13V79C1 85.6274 6.37258 91 13 91H264"
      stroke="#2581C4"
      strokeWidth={2}
    />
  </svg>
);
export default BigBorderIcon;
