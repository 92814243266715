import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import OtpInput from "../ui/OtpInput";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import { resendOtp, verifyOtp } from "../../services/authService";
import axios from "axios";
import Logo from "../shared/Logo";
import { PatientOtpProps } from "../../types/patients";

const PatientOtp: React.FC<PatientOtpProps> = ({
  otpToken,
  email,
  setIsSignUpRequired,
}) => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State to handle error message
  const [timer, setTimer] = useState(15); // Timer state
  const [isTimerActive, setIsTimerActive] = useState(false); // Timer activation state
  const [showSignUp, _] = useState(false); // State to handle whether to show the signup form

  const numInputs = 6;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(interval!);
            setIsTimerActive(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive]);

  useEffect(() => {
    // Reset states when modal opens
    setOtp("");
    setLoading(false);
    setResendLoading(false);
    setErrorMessage(null);
    setTimer(15);
    setIsTimerActive(true);
    setIsSignUpRequired(false); // Reset userId
  }, []);

  const handleOtpSubmit = async () => {
    setLoading(true);
    setErrorMessage(null); // Reset the error message on each submit
    try {
      const result = await verifyOtp(email, otp, otpToken);

      console.log("result on verigy", result);

      if (result.isSignUpRequired) {
        setIsSignUpRequired(true);
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        // Handle 401 error for invalid OTP
        if (error.response.status === 401) {
          setErrorMessage(
            error.response.data.message || "Invalid OTP. Please try again."
          );
        } else {
          console.error(
            "OTP verification failed:",
            error.response.data.message
          );
          setErrorMessage("An error occurred during OTP verification.");
        }
      } else {
        console.error("An unknown error occurred during OTP verification");
        setErrorMessage("An unknown error occurred. Please try again.");
      }
    }
    setLoading(false);
  };

  const handleResendOtp = async () => {
    setResendLoading(true);
    setErrorMessage(null); // Reset the error message on resend
    try {
      await resendOtp(email);
      setTimer(15); // Reset the timer to 15 seconds
      setIsTimerActive(true); // Reactivate the timer
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Resend OTP failed:", error.response.data.message);
      } else {
        console.error("An unknown error occurred during resend OTP");
      }
    }
    setResendLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!showSignUp && event.key === "Enter" && otp.length === numInputs) {
      handleOtpSubmit();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingInline: "20px",
      }}
      onKeyDown={handleKeyDown}
    >
      <>
        <Box sx={{ marginBottom: "16px" }}>
          <Logo width={isMobile ? 80 : 100} />
        </Box>
        <Typography
          className="form-heading"
          sx={{ textAlign: "center", fontSize: isMobile ? "18px" : "24px" }}
        >
          Verify with OTP
        </Typography>
      </>

      <>
        <Typography
          sx={{
            fontSize: isMobile ? "14px" : "16px",
            fontWeight: 400,
            marginTop: "12px",
            marginBottom: isMobile ? "24px" : "32px",
            textAlign: "center",
          }}
        >
          To ensure your security, please enter the One-Time Password (OTP) sent
          to your registered email below.
        </Typography>

        <OtpInput value={otp} onChange={setOtp} numInputs={numInputs} />

        {/* Display error message */}
        {errorMessage && (
          <Typography
            color="error"
            sx={{
              marginTop: "16px",
              marginBottom: "16px",
              textAlign: "center",
              fontSize: isMobile ? "12px" : "14px",
            }}
          >
            {errorMessage}
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="success"
            disabled={otp.length !== numInputs || loading}
            className="success-button"
            onClick={handleOtpSubmit}
            sx={{
              mt: 4,
              mb: 5.5,
              textTransform: "none",
              width: isMobile ? "100px" : 150,
              height: isMobile ? "40px" : 48,
              color: "var(--neuro-white-text)",
            }}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="text"
            color="secondary"
            disableRipple
            className="secondary-text-button"
            onClick={handleResendOtp}
            disabled={resendLoading || isTimerActive} // Disable while timer is active
            sx={{
              textTransform: "none",
              fontSize: isMobile ? "12px" : "14px",
            }}
          >
            {resendLoading ? (
              <Spinner />
            ) : (
              `Resend OTP ${isTimerActive ? `(${timer})` : ""}`
            )}
          </Button>
        </Box>
      </>
    </Box>
  );
};

export default PatientOtp;
