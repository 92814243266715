import * as React from "react";
const MenuIcon = (props: any) => {
  const { color = "var(--neuro-black-text)" } = props;
  return (
    <svg
      width={24}
      height={19}
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4286 2H2M22 9.5L2 9.5M13.4286 17L2 17"
        stroke={color}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MenuIcon;
