import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Formik, Form } from "formik";
import { addHospital, updateHospital } from "../../services/hospitalService";
import { Hospital } from "../../types/common";
import useAppStore from "../../store/appStore";
import { hospitalValidationSchema } from "../../validations/hospitalValidation";
import { AddEditHospitalModalProps } from "../../types/hospitals";

const AddEditHospitalModal: React.FC<AddEditHospitalModalProps> = ({
  open,
  onClose,
  hospital,
  loadHospitals,
}) => {
  const initialFormData: Hospital = {
    id: "",
    name: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    contactNumber: "",
    operatingHours: "",
    latitude: 0,
    longitude: 0,
    timezone: "",
    currency: "",
    website: null,
    email: null,
    status: true,
  };

  const [_, setFormData] = useState(initialFormData);

  const { showSnackbar } = useAppStore();

  useEffect(() => {
    if (hospital) {
      setFormData({
        id: hospital.id,
        name: hospital.name,
        streetAddress: hospital.streetAddress,
        city: hospital.city,
        state: hospital.state,
        zipCode: hospital.zipCode,
        country: hospital.country,
        contactNumber: hospital.contactNumber,
        operatingHours: hospital.operatingHours || "",
        latitude: hospital.latitude,
        longitude: hospital.longitude,
        timezone: hospital.timezone,
        currency: hospital.currency,
        website: hospital.website || "",
        email: hospital.email || "",
        status: hospital.status,
      });
    } else {
      setFormData(initialFormData); // Reset form data when adding a new hospital
    }
  }, [hospital]);

  const handleSubmit = async (values: typeof initialFormData) => {
    try {
      if (hospital) {
        const hospitalId = hospital.id;
        await updateHospital(hospitalId, values);
        loadHospitals();
        showSnackbar("Clinic Updated Successfully", "success");
      } else {
        delete (values as any).id;
        await addHospital(values);
        loadHospitals();
        showSnackbar("New Clinic Created Successfully", "success");
      }
      onClose();
    } catch (error) {
      const message = hospital
        ? "Could not update clinic, please try again"
        : "Could not add clinic, please try again";
      showSnackbar(message, "error");
      console.error("Error saving hospital:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{hospital ? "Edit Clinic" : "Add New Clinic"}</DialogTitle>
      <Formik
        initialValues={hospital || initialFormData}
        validationSchema={hospitalValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form>
            <DialogContent>
              <TextField
                label="Name"
                name="name"
                required
                value={values.name}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              <TextField
                label="Street Address"
                name="streetAddress"
                required
                value={values.streetAddress}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.streetAddress && !!errors.streetAddress}
                helperText={touched.streetAddress && errors.streetAddress}
              />
              <TextField
                label="City"
                name="city"
                required
                value={values.city}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.city && !!errors.city}
                helperText={touched.city && errors.city}
              />
              <TextField
                label="State"
                name="state"
                required
                value={values.state}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.state && !!errors.state}
                helperText={touched.state && errors.state}
              />
              <TextField
                label="Zip Code"
                name="zipCode"
                required
                value={values.zipCode}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.zipCode && !!errors.zipCode}
                helperText={touched.zipCode && errors.zipCode}
              />
              <TextField
                label="Country"
                name="country"
                required
                value={values.country}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.country && !!errors.country}
                helperText={touched.country && errors.country}
              />
              <TextField
                label="Contact Number"
                name="contactNumber"
                required
                value={values.contactNumber}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.contactNumber && !!errors.contactNumber}
                helperText={touched.contactNumber && errors.contactNumber}
              />
              <TextField
                label="Operating Hours"
                name="operatingHours"
                value={values.operatingHours}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.operatingHours && !!errors.operatingHours}
                helperText={touched.operatingHours && errors.operatingHours}
              />
              <TextField
                label="Latitude"
                name="latitude"
                type="number"
                required
                value={values.latitude}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.latitude && !!errors.latitude}
                helperText={touched.latitude && errors.latitude}
              />
              <TextField
                label="Longitude"
                name="longitude"
                type="number"
                required
                value={values.longitude}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.longitude && !!errors.longitude}
                helperText={touched.longitude && errors.longitude}
              />
              <TextField
                label="Timezone"
                name="timezone"
                value={values.timezone}
                onChange={handleChange}
                fullWidth
                required
                margin="dense"
                error={touched.timezone && !!errors.timezone}
                helperText={touched.timezone && errors.timezone}
              />
              <TextField
                label="Currency"
                name="currency"
                value={values.currency}
                onChange={handleChange}
                fullWidth
                required
                margin="dense"
                error={touched.currency && !!errors.currency}
                helperText={touched.currency && errors.currency}
              />
              <TextField
                label="Website"
                name="website"
                value={values.website}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.website && !!errors.website}
                helperText={touched.website && errors.website}
              />
              <TextField
                label="Email"
                name="email"
                required
                value={values.email}
                onChange={handleChange}
                fullWidth
                margin="dense"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditHospitalModal;
