import React, { useEffect, useState } from "react";

// third-party imports
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

// project imports
import AppointmentDetails from "./AppointmentDetails";
import AppointmentSummary from "./AppointmentSummary";
import ContentCenter from "../../shared/ContentCenter";
import LoadingScreen from "../../shared/LoadingScreen";
import { getAppointmentDetails } from "../../../services/appointmentsService";
import { AppointmentConfirmationProps } from "../../../types/appointments";

const AppointmentConfirmation: React.FC<AppointmentConfirmationProps> = ({
  patientId,
}) => {
  // props & state values
  const theme = useTheme();
  const query = useParams();

  console.log("query in details", query);

  const [loading, setLoading] = useState<boolean>(true);
  const [appointmenDetails, setAppointmenDetails] = useState(null);

  const navigate = useNavigate();
  const isUpXl = useMediaQuery(theme.breakpoints.up("xl"));

  // callbacks & function
  const loadAppointmentDetails = async (id: string) => {
    try {
      const response = await getAppointmentDetails(id);
      setAppointmenDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query && query.id) {
      loadAppointmentDetails(query.id);
    } else if (!query || !query.id) {
      navigate("/appointments");
    }
  }, [query]);

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        paddingInline: patientId ? 0 : "20px",
        paddingTop: patientId ? 0 : "20px",
        paddingBottom: patientId ? 0 : "78px",
        height: "100%",
        width: "100%",
      }}
    >
      {query && query.id && (
        <>
          {loading && <LoadingScreen />}

          {!loading && !appointmenDetails && (
            <ContentCenter>
              <Typography className="no-data">Details not found</Typography>
            </ContentCenter>
          )}

          {!loading && appointmenDetails && (
            <Grid container spacing={isUpXl ? (patientId ? 2.5 : 12.5) : 2.5}>
              <Grid item xs={12} md={8}>
                <AppointmentDetails
                  patientId={patientId}
                  appointmentDetails={appointmenDetails}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <AppointmentSummary appointmentDetails={appointmenDetails} />
              </Grid>
            </Grid>
          )}
        </>
      )}

      {!query ||
        (!query.id && (
          <ContentCenter>
            <Typography className="no-data">
              Invalid appointment link. Please verify the link or contact
              support for assistance.
            </Typography>
          </ContentCenter>
        ))}
    </Box>
  );
};

export default AppointmentConfirmation;
