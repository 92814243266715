import React, { useEffect } from "react";
import PersonalInfoPage from "../profile/patient/PersonalInfoPage";
import { useNavigate } from "react-router-dom";
import { PersonalInfoFormProps } from "../../types/profile";

const PatientsProfile: React.FC<PersonalInfoFormProps> = ({ patientId }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!patientId) {
      navigate("/patients");
    }
  }, [patientId]);

  return <PersonalInfoPage patientId={patientId} />;
};

export default PatientsProfile;
