import React, { useState } from "react";
import { deleteDoctor } from "../../services/doctorService";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditDoctorModal from "./AddEditDoctorModal";
import ConfirmDialog from "../shared/ConfirmDialog";
import { Doctor } from "../../types/common";
import ComponentHeader from "../shared/ComponentHeader";
import { DoctorsListProps } from "../../types/doctors";
import { getFormattedDate } from "../../utils/common";
import Button from "../ui/Button";

const DoctorsList: React.FC<DoctorsListProps> = ({
  loading,
  doctors,
  loadDoctors,
}) => {
  // props & state values
  console.log("doctors in list", doctors);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteDoctorId, setDeleteDoctorId] = useState<string | null>(null);
  const [editDoctor, setEditDoctor] = useState<Doctor | null>(null);

  // callbacks & functions
  const handleAddNew = () => {
    setEditDoctor(null);
    setDialogOpen(true);
  };

  const handleEdit = (doctor: Doctor) => {
    setEditDoctor(doctor);
    setDialogOpen(true);
  };

  const handleDelete = async (id: string) => {
    setDeleteDoctorId(id);
    setConfirmOpen(true);
  };

  const handleConfirmClose = async (confirmed: boolean) => {
    setConfirmOpen(false);
    if (confirmed && deleteDoctorId) {
      try {
        await deleteDoctor(deleteDoctorId);
        loadDoctors();
      } catch (error) {
        console.error("Error deleting doctor:", error);
      }
    }
    setDeleteDoctorId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    loadDoctors();
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "var(--neuro-bg-light-grey-secondary)",
          minHeight: "100%",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          marginTop={"15px"}
        >
          <ComponentHeader title="Doctor List" />
          <Button
            variant="outlined"
            color="secondary"
            className="outlined-secondary-button"
            startIcon={<AddIcon />}
            onClick={handleAddNew}
          >
            Add New
          </Button>
        </Box>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    First Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Last Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Specializations
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Years of Experience
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Languages
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Clinic
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Registered On
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {doctors?.map((doctor) => (
                  <TableRow key={doctor.id}>
                    <TableCell>{doctor.firstName}</TableCell>
                    <TableCell>{doctor.lastName}</TableCell>
                    <TableCell>{doctor.specializations.join(", ")}</TableCell>
                    <TableCell>{doctor.yearsOfExperience}</TableCell>
                    <TableCell>{doctor.spokenLanguages.join(", ")}</TableCell>
                    <TableCell>{doctor?.hospital?.name || "-"}</TableCell>
                    <TableCell>
                      {getFormattedDate(doctor?.createdAt || "-")}
                    </TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="space-around">
                        <IconButton
                          onClick={() => handleEdit(doctor)}
                          color="secondary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(doctor.id)}
                          color="primary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <AddEditDoctorModal
          open={dialogOpen}
          onClose={handleCloseDialog}
          doctor={editDoctor}
          loadDoctors={loadDoctors}
        />
        <ConfirmDialog
          open={confirmOpen}
          title="Confirm Delete"
          message="Are you sure you want to delete this doctor?"
          onClose={handleConfirmClose}
        />
      </Box>
      <br></br>
    </>
  );
};

export default DoctorsList;
