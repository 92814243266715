import React from "react";
import { Grid } from "@mui/material";
import ViewerReportCard from "../view/ViewerReportCard";
import useDocumentStore from "../../../store/documentsStore";
import { DocumentGridProps } from "../../../types/documents";

const DocumentGrid: React.FC<DocumentGridProps> = ({
  documents,
  isMenuCollapsed,
  patientId,
  from = "",
}) => {
  const { setSelectedDocumentId } = useDocumentStore();
  return (
    <Grid container spacing={3}>
      {documents.map((document) => (
        <Grid
          item
          xs={12}
          md={isMenuCollapsed ? 3 : 4}
          xl={3}
          key={document.id}
        >
          <ViewerReportCard
            {...document}
            from="list"
            setSelectedDocumentId={setSelectedDocumentId}
            patientId={patientId}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DocumentGrid;
