import React, { useEffect, useState } from "react";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  AllAppointmentsProps,
  AppointmentCardProps,
} from "../../../types/appointments";
import {
  getAppointmentStatus,
  // sortAppointments,
  sortAppoointmentsByDateTime,
} from "../../../utils/appointments";
// import AppointmentList from "./AppointmentList";
import {
  CANCELLED,
  COMPLETED,
  ONGOING,
  UPCOMING,
} from "../../../utils/constants";
import NewAppointmentList from "./NewAppointmentList";

const AllAppointments: React.FC<AllAppointmentsProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const {
    appointments,
    loadingFetchAppointments,
    errorFetchAppointments,
    fetchAppointments,
    cleanUpAppointments,
  } = useAppointmentStore();

  const [sortedAppointments, setSortedAppointments] = useState<
    AppointmentCardProps[]
  >([]);

  const [upcomingAppointments, setUpcomingAppointments] = useState<
    AppointmentCardProps[]
  >([]);

  const [ongoingAppointments, setOngoingAppointments] = useState<
    AppointmentCardProps[]
  >([]);

  const [completedAppointments, setCompletedAppointments] = useState<
    AppointmentCardProps[]
  >([]);

  const [cancelledAppointments, setCancelledAppointments] = useState<
    AppointmentCardProps[]
  >([]);

  // callbacks & functions
  useEffect(() => {
    if (patientId) {
      fetchAppointments(patientId);
    } else {
      fetchAppointments();
    }

    return () => {
      cleanUpAppointments();
    };
  }, []);

  const getUpcomingAppointments = (appointments: AppointmentCardProps[]) => {
    return (
      appointments?.filter(
        (appointment) =>
          getAppointmentStatus(
            appointment?.availableSlot?.startTime,
            appointment?.availableSlot?.endTime
          ) === UPCOMING
      ) || []
    );
  };

  const getOngoingAppointments = (appointments: AppointmentCardProps[]) => {
    return (
      appointments?.filter(
        (appointment) =>
          getAppointmentStatus(
            appointment?.availableSlot?.startTime,
            appointment?.availableSlot?.endTime
          ) === ONGOING
      ) || []
    );
  };

  const getCompletedAppointments = (appointments: AppointmentCardProps[]) => {
    return (
      appointments?.filter(
        (appointment) =>
          getAppointmentStatus(
            appointment?.availableSlot?.startTime,
            appointment?.availableSlot?.endTime
          ) === COMPLETED || appointment.status === COMPLETED
      ) || []
    );
  };

  const getCancelledAppointments = (appointments: AppointmentCardProps[]) => {
    return (
      appointments?.filter((appointment) => appointment.status === CANCELLED) ||
      []
    );
  };

  const sortAppointmentsHandler = (appointments: any) => {
    // const sortedByOrderAppointments = sortAppointments(appointments);
    const sortedByOrderAppointments = sortAppoointmentsByDateTime(appointments);
    setSortedAppointments(sortedByOrderAppointments);
    setUpcomingAppointments(getUpcomingAppointments(appointments));
    setOngoingAppointments(getOngoingAppointments(appointments));
    setCompletedAppointments(getCompletedAppointments(appointments));
    setCancelledAppointments(getCancelledAppointments(appointments));
  };

  useEffect(() => {
    if (appointments && appointments.length > 0) {
      if (from !== "dashboard") {
        sortAppointmentsHandler(appointments);
      } else if (from === "dashboard") {
        const appointmentsArry: AppointmentCardProps[] = [];
        appointments?.forEach((appointment) => {
          if (
            appointment?.availableSlot?.startTime &&
            appointment?.availableSlot?.endTime
          ) {
            const result = getAppointmentStatus(
              appointment.availableSlot.startTime,
              appointment.availableSlot.endTime
            );

            if (result !== "COMPLETED" && appointment?.status !== "COMPLETED") {
              appointmentsArry.push(appointment);
            }
          } else {
            console.warn(
              "Appointment does not have valid availableSlot",
              appointment
            );
          }
        });

        if (appointmentsArry.length > 0) {
          sortAppointmentsHandler(appointmentsArry);
        }
      }
    }
  }, [appointments]);

  return (
    // <AppointmentList
    //   appointments={sortedAppointments}
    //   upcomingAppointments={upcomingAppointments}
    //   ongoingAppointments={ongoingAppointments}
    //   completedAppointments={completedAppointments}
    //   loadingFetchAppointments={loadingFetchAppointments}
    //   errorFetchAppointments={errorFetchAppointments}
    //   from={from}
    //   patientId={patientId}
    // />
    <NewAppointmentList
      appointments={sortedAppointments}
      upcomingAppointments={upcomingAppointments}
      ongoingAppointments={ongoingAppointments}
      completedAppointments={completedAppointments}
      cancelledAppointments={cancelledAppointments}
      loadingFetchAppointments={loadingFetchAppointments}
      errorFetchAppointments={errorFetchAppointments}
      from={from}
      patientId={patientId}
    />
  );
};

export default AllAppointments;
