import React, { useEffect } from "react";
import AppointmentList from "../appointments/Appointments/AppointmentList";
import { Box } from "@mui/material";
import ComponentHeader from "../shared/ComponentHeader";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import AppointmentConfirmation from "../appointments/AppointmentConfirmation";
import Speciality from "../appointments/Speciality";
import NewFlow from "../appointments/NewFlow";
import { DocumentsProps } from "../../types/documents";
import { PatientAppointmentsProps } from "../../types/common";
import AllAppointments from "../appointments/Appointments/AllAppointments";

const PatientAppointments: React.FC<PatientAppointmentsProps> = ({
  patientId,
  patientName,
  patientEmail,
  patientContact,
}) => {
  const navigate = useNavigate();

  const path = window.location.pathname;

  const handleBookAppointment = () => {
    navigate(`/patients/${patientId}/appointments/book`);
  };

  useEffect(() => {
    if (!patientId) {
      navigate("/patients");
    }
  }, [patientId]);

  return (
    <>
      {path.includes("details") ? (
        <AppointmentConfirmation patientId={patientId} />
      ) : //  : path.includes("specialties") && !path.match(/specialties\/[^/]+$/) ? (
      //   <Speciality patientId={patientId} />
      // )
      //  : path.match(/specialties\/[^/]+$/) ? (
      //   <NewFlow
      //     patientId={patientId}
      //     patientName={patientName}
      //     patientEmail={patientEmail}
      //     patientContact={patientContact}
      //   />
      // )
      path.includes("book") ? (
        <NewFlow
          patientId={patientId}
          patientName={patientName}
          patientEmail={patientEmail}
          patientContact={patientContact}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            height: "100%",
          }}
        >
          <ComponentHeader title="">
            <Button
              color="primary"
              variant="contained"
              className="primary-button"
              onClick={handleBookAppointment}
            >
              Book Appointment
            </Button>
          </ComponentHeader>
          <AllAppointments patientId={patientId} />
        </Box>
      )}
    </>
  );
};

export default PatientAppointments;
