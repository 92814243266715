import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { addReferral, updateReferral } from "../../services/referralService";
import { Referral } from "../../types/common";
import { referralValidationSchema } from "../../validations/referralValidation";
import { AddEditReferralModalProps } from "../../types/referrals";
import useAppStore from "../../store/appStore";

const AddEditReferralModal: React.FC<AddEditReferralModalProps> = ({
  open,
  onClose,
  referral,
}) => {
  const initialFormData: Referral = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    dateOfBirth: null,
    gender: "", // Default empty value
    streetAddress: "",
    height: "",
    weight: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    doctorId: "",
    symptoms: "",
    urgencyOfReferral: "Routine", // Default value
    reasonForReferral: "", // Default empty value
    currentMedications: "",
    allergies: "",
    relevantMedicalConditions: "",
    previousTreatments: "",
    referralDoctorFirstName: "",
    referralDoctorLastName: "",
    referralDoctorMobile: "",
    referralDoctorEmail: "",
    notes: "",
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  const { showSnackbar } = useAppStore();

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (open && referral) {
      // Update the form data when the modal opens and a referral is passed in
      setFormData({
        id: referral.id,
        firstName: referral.firstName,
        lastName: referral.lastName,
        email: referral.email || "",
        mobile: referral.mobile,
        dateOfBirth: referral.dateOfBirth || null,
        gender: referral.gender || "",
        streetAddress: referral.streetAddress || "",
        height: referral.height || "",
        weight: referral.weight || "",
        city: referral.city || "",
        state: referral.state || "",
        zipCode: referral.zipCode || "",
        country: referral.country || "",
        doctorId: referral.doctorId,
        symptoms: referral.symptoms || "",
        urgencyOfReferral: referral.urgencyOfReferral || "Routine",
        reasonForReferral: referral.reasonForReferral || "",
        currentMedications: referral.currentMedications || "",
        allergies: referral.allergies || "",
        relevantMedicalConditions: referral.relevantMedicalConditions || "",
        previousTreatments: referral.previousTreatments || "",
        referralDoctorFirstName: referral.referralDoctorFirstName || "",
        referralDoctorLastName: referral.referralDoctorLastName || "",
        referralDoctorMobile: referral.referralDoctorMobile || "",
        referralDoctorEmail: referral.referralDoctorEmail || "",
        notes: referral.notes || "",
        createdAt: referral.createdAt,
        updatedAt: referral.updatedAt,
      });
    } else if (open && !referral) {
      // Reset to initial form data when adding a new referral
      setFormData(initialFormData);
    }
  }, [open, referral]);

  const handleSubmit = async (values: typeof initialFormData) => {
    try {
      if (referral) {
        const referralId = referral.id;
        await updateReferral(referralId, values);
      } else {
        await addReferral(values);
      }
      onClose();
    } catch (error) {
      const message = referral
        ? "Could not update referral, please try again"
        : "Could not add referral, please try again";
      showSnackbar(message, "error");
      console.error("Error saving referral:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {referral ? "Edit Referral" : "Add New Referral"}
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={formData}
        validationSchema={referralValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form>
            <DialogContent>
              {/* Patient Information Section */}
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Patient Information
                </Typography>
                <TextField
                  label="First Name"
                  name="firstName"
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  required
                  value={values.lastName}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                />
                <TextField
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  label="Mobile"
                  name="mobile"
                  required
                  value={values.mobile}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.mobile && !!errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                />
                <TextField
                  label="Date of Birth"
                  name="dateOfBirth"
                  type="date"
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  error={touched.dateOfBirth && !!errors.dateOfBirth}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                />
                <TextField
                  label="Gender"
                  name="gender"
                  required
                  value={values.gender}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  error={touched.gender && !!errors.gender}
                  helperText={touched.gender && errors.gender}
                >
                  <option value=""></option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </TextField>
                <TextField
                  label="Street Address"
                  name="streetAddress"
                  value={values.streetAddress}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.streetAddress && !!errors.streetAddress}
                  helperText={touched.streetAddress && errors.streetAddress}
                />
                <TextField
                  label="City"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                />
                <TextField
                  label="State"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.state && !!errors.state}
                  helperText={touched.state && errors.state}
                />
                <TextField
                  label="Zip Code"
                  name="zipCode"
                  value={values.zipCode}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.zipCode && !!errors.zipCode}
                  helperText={touched.zipCode && errors.zipCode}
                />
                <TextField
                  label="Country"
                  name="country"
                  value={values.country}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={touched.country && !!errors.country}
                  helperText={touched.country && errors.country}
                />
                <TextField
                  label="Symptoms"
                  name="symptoms"
                  value={values.symptoms}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  multiline
                  rows={3}
                  error={touched.symptoms && !!errors.symptoms}
                  helperText={touched.symptoms && errors.symptoms}
                />
                <TextField
                  label="Current Medications"
                  name="currentMedications"
                  value={values.currentMedications}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  multiline
                  rows={3}
                  error={
                    touched.currentMedications && !!errors.currentMedications
                  }
                  helperText={
                    touched.currentMedications && errors.currentMedications
                  }
                />
                <TextField
                  label="Allergies"
                  name="allergies"
                  value={values.allergies}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  multiline
                  rows={3}
                  error={touched.allergies && !!errors.allergies}
                  helperText={touched.allergies && errors.allergies}
                />
                <TextField
                  label="Relevant Medical Conditions"
                  name="relevantMedicalConditions"
                  value={values.relevantMedicalConditions}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  multiline
                  rows={3}
                  error={
                    touched.relevantMedicalConditions &&
                    !!errors.relevantMedicalConditions
                  }
                  helperText={
                    touched.relevantMedicalConditions &&
                    errors.relevantMedicalConditions
                  }
                />
                <TextField
                  label="Previous Treatments"
                  name="previousTreatments"
                  value={values.previousTreatments}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  multiline
                  rows={3}
                  error={
                    touched.previousTreatments && !!errors.previousTreatments
                  }
                  helperText={
                    touched.previousTreatments && errors.previousTreatments
                  }
                />
              </Box>

              {/* Doctor Information Section */}
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Doctor Information
                </Typography>
                <TextField
                  label="Doctor First Name"
                  name="referralDoctorFirstName"
                  value={values.referralDoctorFirstName}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={
                    touched.referralDoctorFirstName &&
                    !!errors.referralDoctorFirstName
                  }
                  helperText={
                    touched.referralDoctorFirstName &&
                    errors.referralDoctorFirstName
                  }
                />
                <TextField
                  label="Doctor Last Name"
                  name="referralDoctorLastName"
                  value={values.referralDoctorLastName}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={
                    touched.referralDoctorLastName &&
                    !!errors.referralDoctorLastName
                  }
                  helperText={
                    touched.referralDoctorLastName &&
                    errors.referralDoctorLastName
                  }
                />
                <TextField
                  label="Doctor Mobile"
                  name="referralDoctorMobile"
                  value={values.referralDoctorMobile}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={
                    touched.referralDoctorMobile &&
                    !!errors.referralDoctorMobile
                  }
                  helperText={
                    touched.referralDoctorMobile && errors.referralDoctorMobile
                  }
                />
                <TextField
                  label="Doctor Email"
                  name="referralDoctorEmail"
                  value={values.referralDoctorEmail}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  error={
                    touched.referralDoctorEmail && !!errors.referralDoctorEmail
                  }
                  helperText={
                    touched.referralDoctorEmail && errors.referralDoctorEmail
                  }
                />
                <TextField
                  label="Reason for Referral"
                  name="reasonForReferral"
                  value={values.reasonForReferral}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  error={
                    touched.reasonForReferral && !!errors.reasonForReferral
                  }
                  helperText={
                    touched.reasonForReferral && errors.reasonForReferral
                  }
                >
                  <option value=""></option>
                  <option value="HeadacheOrMigrane">Headache or Migrane</option>
                  <option value="SeizuresOrEpilepsy">
                    Seizures or Epilepsy
                  </option>
                  <option value="MovementDisorders">Movement Disorders</option>
                  <option value="Neuropathy">Neuropathy</option>
                  <option value="CognitiveIssues">Cognitive Issues</option>
                  <option value="Others">Others</option>
                </TextField>
                <TextField
                  label="Urgency of Referral"
                  name="urgencyOfReferral"
                  value={values.urgencyOfReferral}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  select
                  SelectProps={{
                    native: true,
                  }}
                  error={
                    touched.urgencyOfReferral && !!errors.urgencyOfReferral
                  }
                  helperText={
                    touched.urgencyOfReferral && errors.urgencyOfReferral
                  }
                >
                  <option value="Routine">Routine</option>
                  <option value="Urgent">Urgent</option>
                  <option value="Emergency">Emergency</option>
                </TextField>
                <TextField
                  label="Notes"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  fullWidth
                  margin="dense"
                  multiline
                  rows={4}
                  error={touched.notes && !!errors.notes}
                  helperText={touched.notes && errors.notes}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddEditReferralModal;
