import React, { useEffect, useState } from "react";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  AllAppointmentsProps,
  AppointmentCardProps,
} from "../../../types/appointments";
import {
  getAppointmentStatus,
  sortAppointments,
} from "../../../utils/appointments";
import AppointmentList from "./AppointmentList";

const AllAppointments: React.FC<AllAppointmentsProps> = ({
  patientId,
  from = "",
}) => {
  // props & state values
  const {
    appointments,
    loadingFetchAppointments,
    errorFetchAppointments,
    fetchAppointments,
    cleanUpAppointments,
  } = useAppointmentStore();

  const [sortedAppointments, setSortedAppointments] = useState<
    AppointmentCardProps[]
  >([]);

  // callbacks & functions
  useEffect(() => {
    if (patientId) {
      fetchAppointments(patientId);
    } else {
      fetchAppointments();
    }

    return () => {
      cleanUpAppointments();
    };
  }, []);

  const sortAppointmentsHandler = (appointments: any) => {
    const sortedByOrderAppointments = sortAppointments(appointments);
    setSortedAppointments(sortedByOrderAppointments);
  };

  useEffect(() => {
    if (appointments && appointments.length > 0 && from !== "dashboard") {
      sortAppointmentsHandler(appointments);
    } else if (
      appointments &&
      appointments.length > 0 &&
      from === "dashboard"
    ) {
      const appointmentsArry: AppointmentCardProps[] = [];
      appointments?.forEach((appointment) => {
        if (
          appointment?.availableSlot?.startTime &&
          appointment?.availableSlot?.endTime
        ) {
          const result = getAppointmentStatus(
            appointment.availableSlot.startTime,
            appointment.availableSlot.endTime
          );

          if (result !== "COMPLETED" && appointment?.status !== "COMPLETED") {
            appointmentsArry.push(appointment);
          }
        } else {
          console.warn(
            "Appointment does not have valid availableSlot",
            appointment
          );
        }
      });

      if (appointmentsArry.length > 0) {
        sortAppointmentsHandler(appointmentsArry);
      }
    }
  }, [appointments]);

  return (
    <AppointmentList
      appointments={sortedAppointments}
      loadingFetchAppointments={loadingFetchAppointments}
      errorFetchAppointments={errorFetchAppointments}
      from={from}
      patientId={patientId}
    />
  );
};

export default AllAppointments;
