import React from "react";

// third-party imports
import {
  Box,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  Avatar,
} from "@mui/material";

// project imports
import Logo from "../../shared/Logo";
import { AppointmentSchedulerDetails } from "../../../types/appointments";

const AppointmentDetails: React.FC<AppointmentSchedulerDetails> = ({
  doctorProfile,
  doctorName,
  doctorSpeciality,
  doctorExperience,
  meetType,
  handleMeetTypeChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "var(--neuro-white-text)",
        border: "1px solid var(--neuro-secondary_border)",
        borderRadius: "12px",
        padding: "25px 25px 25px 21px",
        marginBottom: "16px",
      }}
    >
      <Box sx={{ display: "flex", gap: "14px" }}>
        <Avatar
          src={doctorProfile}
          alt="doctor-profile"
          sx={{
            width: 70,
            height: 70,
            backgroundColor: "var(--neuro-bg-light-grey-secondary)",
            color: "var(--neuro-darkgrey_border)",
            border: "2px solid var(--neuro-button-bg-success)",
          }}
        />
        <Box
          sx={{ display: "flex", flexDirection: "column", marginTop: "2px" }}
        >
          <Typography
            sx={{
              color: "var(--neuro-bg-darkblue-primary)",
              fontSize: "var(--neuro-font-size-micro)",
              fontWeight: "var(--neuro-font-weight-semibold)",
              lineHeight: "120%",
              fontFamily: "var(--neuro-font-family-inter)",
            }}
          >
            {doctorName}
          </Typography>
          <Typography
            sx={{
              color: "var(--neuro-button-bg-primary)",
              fontSize: "var(--neuro-font-size-x-small)",
              lineHeight: "120%",
              fontFamily: "var(--neuro-font-family-roboto-slab)",
            }}
          >
            {doctorSpeciality === "PRIMARY_CARE"
              ? "General Physician"
              : doctorSpeciality.charAt(0).toUpperCase() +
                doctorSpeciality.slice(1).toLowerCase()}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginTop: "12px",
              color: "var(--neuro-black-text)",
              fontSize: "var(--neuro-font-size-x-small)",
              lineHeight: "120%",
              fontFamily: "var(--neuro-font-family-roboto-slab)",
            }}
          >
            {doctorExperience}
          </Typography>
        </Box>
      </Box>
      <RadioGroup value={meetType} onChange={handleMeetTypeChange} row>
        <FormControlLabel
          value="ONLINE"
          control={<Radio />}
          label="Online Meet"
        />
        <FormControlLabel
          value="IN_PERSON"
          control={<Radio />}
          label="Direct Meet"
        />
      </RadioGroup>
      <Logo width={89} height={63} />
    </Box>
  );
};

export default AppointmentDetails;
