import React, { useState, useEffect } from "react";
import { fetchPatients } from "../../services/patientService";
import { Box, Typography } from "@mui/material";
import { Patient } from "../../types/common";
import { useLocation, useNavigate } from "react-router-dom";
import ComponentHeader from "../shared/ComponentHeader";
import HorizontalTabs from "../profile/patient/HorizontalTabs";
import { capitalizeFirstLetter } from "../../utils/common";
// import PatientsProfile from "./PatientsProfile";
// import PatientDocuments from "./PatientDocuments";
// import PatientForms from "./PatientForms";
// import PatientAppointments from "./PatientAppointments";
import AddIcon from "@mui/icons-material/Add";
import PatientSignUpModal from "./PatientSignUpModal";
import Button from "../ui/Button";
import LoadingScreen from "../shared/LoadingScreen";
import PatientsList from "./PatientsList";
import ContentCenter from "../shared/ContentCenter";
// import PatientAbhaDetails from "./PatientAbhaDetails";
import PatientTabs from "./PatientTabs";

const Patients: React.FC = () => {
  // props & state values
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedPatient, setSelectedPatient] = useState<any>(null);

  console.log("selectedPatient", selectedPatient);

  const [addNewPatientModalOpen, setAddNewPatientModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  console.log("patients", patients);

  const tabs = [
    "Profile",
    "Documents",
    "Appointments",
    "Forms",
    "ABHA Details",
  ];

  // const containerRef = useRef<HTMLDivElement | null>(null);

  // const [selectedTab, setSelectedTab] = useState<string>(getInitialTab());
  const [selectedTab, setSelectedTab] = useState<string>("Profile");

  const showPatientsList = location?.pathname === "/patients";

  // callbacks & functions
  function getInitialTab() {
    if (location.pathname.toLowerCase().includes("abha-details")) {
      return "ABHA Details";
    }
    const foundTab = tabs.find((tab) =>
      location.pathname.toLowerCase().includes(tab.toLowerCase())
    );
    return foundTab || "Profile";
  }

  useEffect(() => {
    const newTab = getInitialTab();
    setSelectedTab(newTab);
  }, [location.pathname]);

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (selectedPatient) {
      if (tab === "ABHA Details") {
        navigate(`/patients/${selectedPatient.id}/abha-details`);
      } else {
        navigate(`/patients/${selectedPatient.id}/${tab.toLowerCase()}`);
      }
    }
  };

  // useEffect(() => {
  //   // Scroll the container to the top when "Profile" tab is selected
  //   if (selectedTab === "Profile" && containerRef.current) {
  //     console.log("containerRef.current", containerRef.current);
  //     containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  //   }
  // }, [selectedTab]);

  useEffect(() => {
    return () => {
      setSelectedPatient(null);
    };
  }, []);

  useEffect(() => {
    loadPatients();
  }, [location?.pathname]);

  const loadPatients = async () => {
    try {
      const response = await fetchPatients();
      setPatients(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    setAddNewPatientModalOpen(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingInline: "20px",
        paddingBlock: "15px",
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {showPatientsList && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ComponentHeader title="Patient List" />

            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </Box>
        )}

        {!showPatientsList && (
          <ComponentHeader
            title={`Patient / ${capitalizeFirstLetter(
              selectedPatient?.user?.firstName || ""
            )} ${capitalizeFirstLetter(
              selectedPatient?.user?.lastName || ""
            )} / ${selectedTab}`}
            isBreadcrumb
          />
        )}

        {!showPatientsList && (
          <HorizontalTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            onClick={onTabClick}
          />
        )}
      </Box>

      {!showPatientsList && (
        <PatientTabs
          selectedPatient={selectedPatient}
          selectedTab={selectedTab}
        />
        // <Box
        //   ref={containerRef}
        //   sx={{
        //     height: "calc(100% - 130px)",
        //     maxHeight: "calc(100% - 130px)",
        //     flexGrow: 1,
        //     overflow: "auto",
        //     padding: "10px",
        //   }}
        // >
        //   {selectedTab === "Profile" && (
        //     <PatientsProfile patientId={selectedPatient?.id} />
        //   )}

        //   {selectedTab === "Documents" && (
        //     <PatientDocuments patientId={selectedPatient?.id} />
        //   )}

        //   {selectedTab === "Forms" && (
        //     <PatientForms
        //       patientName={`${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""} ${capitalizeFirstLetter(selectedPatient?.user?.lastName || "") || ""}`}
        //       patientId={selectedPatient?.id}
        //     />
        //   )}

        //   {selectedTab === "Appointments" && (
        //     <PatientAppointments
        //       patientId={selectedPatient?.id}
        //       patientName={`${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""} ${capitalizeFirstLetter(selectedPatient?.user?.lastName || "") || ""}`}
        //       patientEmail={selectedPatient?.user?.email || ""}
        //       patientContact={selectedPatient?.user?.mobile || ""}
        //     />
        //   )}

        //   {selectedTab === "ABHA Details" && (
        //     <PatientAbhaDetails userId={selectedPatient?.userId || ""} />
        //   )}
        // </Box>
      )}

      {showPatientsList && (
        <Box sx={{ width: "100%", height: "100%" }}>
          <>
            {loading && <LoadingScreen />}

            {!loading && patients && patients.length === 0 && (
              <ContentCenter>
                <Typography className="no-data">No patients found</Typography>
              </ContentCenter>
            )}

            {!loading && patients && patients.length > 0 && (
              <PatientsList
                patients={patients}
                selectedPatient={selectedPatient}
                setSelectedPatient={setSelectedPatient}
              />
            )}

            {/* new patient add modal */}
            <PatientSignUpModal
              open={addNewPatientModalOpen}
              onClose={() => setAddNewPatientModalOpen(false)}
              loadPatients={loadPatients}
            />
          </>
        </Box>
      )}
    </Box>
  );
};

export default Patients;
