import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Box, useMediaQuery } from "@mui/material";

import Input from "../ui/Input";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import OtpModal from "./OtpModal";
import { sendOtp } from "../../services/authService";
import Checkbox from "../ui/Checkbox";
import useAppStore from "../../store/appStore";
import { LoginFormInputs } from "../../types/auth";
import { loginSchema } from "../../validations/authValidation";
import AadharModal from "./AadharModal";
import NationalHealthAuthorityLogo from "../shared/NationalHealthAuthorityLogo";

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      whatsappUpdates: false,
    },
  });

  const { showSnackbar } = useAppStore();

  const [loading, setLoading] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [aadharModalOpen, setAadharModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<any>(null);
  const [otpToken, setOtpToken] = useState("");
  const [txnId, setTxnId] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const whatsappUpdates = watch("whatsappUpdates");

  useEffect(() => {
    const savedWhatsappUpdates =
      localStorage.getItem("whatsappUpdates") === "true";
    setValue("whatsappUpdates", savedWhatsappUpdates);
  }, [setValue]);

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) => {
    setLoading(true);
    setTxnId(null);
    try {
      if (!errors.whatsappUpdates) {
        localStorage.setItem(
          "whatsappUpdates",
          data.whatsappUpdates.toString()
        );
      }

      const lowerCaseEmail = data.emailOrPhone.toLowerCase();
      const payloadData = {
        emailOrMobileOrAadhaarOrAbhaNumber: lowerCaseEmail || "",
      };
      const result = await sendOtp(payloadData);

      if (result?.message) {
        setMessage(result?.message);
      }

      setEmail(data.emailOrPhone);
      setOtpToken(result.otpToken);
      if (result.txnId) {
        setTxnId(result.txnId);
      }
      setOtpOpen(true);
    } catch (error: any) {
      showSnackbar(
        error?.response?.data?.message ||
          "Could not send OTP, Please try again",
        "error"
      );
    }
    setLoading(false);
  };

  const onCloseOtp = () => {
    setOtpOpen(false);
  };

  const onCloseAadharModal = () => {
    setAadharModalOpen(false);
  };

  const loginFormStyles = {
    headingStyles: {
      fontWeight: "var(--neuro-font-weight-bold)",
      fontSize: "var(--neuro-font-size-large-medium)",
      fontFamily: "var(--neuro-font-family-roboto-condensed)",
      lineHeight: "120%",
      color: isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      textAlign: isMobile ? "start" : "left",
      width: isMobile ? "100%" : "revert",
    },
    subHeadingStyles: {
      fontFamily: "var(--neuro-font-family-roboto-slab)",
      fontSize: "var(--neuro-font-size-x-small)",
      color: isMobile ? "var(--neuro-white-text)" : "var(--neuro-black-text)",
      textAlign: isMobile ? "start" : "left",
      marginTop: "5px",
      width: isMobile ? "100%" : "revert",
      lineHeight: "120%",
    },
    greenLineSeperator: {
      width: "69px",
      height: "7px",
      backgroundColor: "var(--neuro-button-bg-success)",
      margin: isMobile ? "20px auto 20px" : "33px 0 40px",
    },
    whatsappSpan: {
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "24px",
      fontFamily: "Roboto Condensed",
      color: "var(--neuro-button-bg-success) !important",
    },
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: isMobile ? 0 : 8.5,
          mb: isMobile ? 0 : 13.5,
          padding: 0,
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              height: "200px",
              width: "100%",
              background: "linear-gradient(135deg, #2581C4, #62B55A)",
              color: "white",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              marginBottom: "50px",
              justifyContent: "flex-end",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                paddingLeft: "25px",
                paddingBottom: "35px",
                // height: "100%",
              }}
            >
              <Typography sx={loginFormStyles.headingStyles}>
                Log In to
              </Typography>
              <Typography sx={loginFormStyles.headingStyles}>
                22Neuro Clinic
              </Typography>
              <Typography sx={loginFormStyles.subHeadingStyles}>
                Log In / Create account with 22Neuro Clinic
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Typography sx={loginFormStyles.headingStyles}>
              Log In / Create account with
            </Typography>
            <Typography sx={loginFormStyles.headingStyles}>
              22Neuro Clinic
            </Typography>
          </>
        )}

        {!isMobile && <Box sx={loginFormStyles.greenLineSeperator}></Box>}

        <Box sx={{ paddingInline: isMobile ? "20px" : 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginBottom: "9px",
            }}
          >
            <Input
              type="text"
              // label="E-mail / Phone / Aadhaar / ABHA *"
              label="E-mail / Aadhaar / ABHA *"
              className="input-primary"
              {...register("emailOrPhone")}
              error={!!errors.emailOrPhone}
              helperText={errors.emailOrPhone?.message}
            />
            <Typography
              sx={{
                color: "var(--neuro-darkgrey_border)",
                marginBottom: "23px",
              }}
            >
              An OTP will be sent to this email / phone
            </Typography>
            <Checkbox
              label={
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontFamily: "Roboto Condensed",
                    color: "var(--neuro-black-text)",
                  }}
                >
                  Accept{" "}
                  <a
                    href="https://www.22neuro.com/terms-condition"
                    target="blank"
                    style={{ textDecoration: "underline" }}
                  >
                    terms and conditions
                  </a>{" "}
                </Typography>
              }
              className="checkbox-primary"
              checked={whatsappUpdates}
              {...register("whatsappUpdates")}
              error={!!errors.whatsappUpdates}
              helperText={errors.whatsappUpdates?.message}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
              justifyContent: "space-between",
              mt: 5,
              mb: 6,
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={loading}
              className="success-button"
              sx={{
                // mt: 5,
                // mb: 6,
                textTransform: "none",
                width: isMobile ? "100%" : 200,
                height: 48,
              }}
            >
              {loading ? <Spinner /> : "Log In / Create Account"}
            </Button>
            {/* <Link
              className="universal-link"
              onClick={() => setAadharModalOpen(true)}
            >
              Create Account Using Aadhaar
            </Link> */}

            <Button
              variant="outlined"
              className="outlined-primary-button"
              startIcon={
                <NationalHealthAuthorityLogo width={100} height={35} />
              }
              onClick={() => setAadharModalOpen(true)}
            >
              Create Account Using Aadhaar
            </Button>
          </Box>
        </Box>
      </Box>

      <OtpModal
        email={email}
        otpToken={otpToken}
        open={otpOpen}
        onClose={onCloseOtp}
        width={650}
        txnId={txnId}
        message={message}
      />

      <AadharModal open={aadharModalOpen} onClose={onCloseAadharModal} />
    </>
  );
};

export default LoginForm;
