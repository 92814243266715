const AssistantIcon = (props: any) => {
  const { height = 100, width = 100, color = "#62B55A" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M63.0054 97.6744V87.7086H47.1881C40.6962 87.1947 34.5252 84.8205 29.5001 80.9022C24.475 76.984 20.8355 71.7088 19.0685 65.783L17.5747 63.1247H14.4992C13.2122 62.9647 12.0281 62.3756 11.1599 61.4643C10.3687 60.5176 9.93561 59.3485 9.92969 58.1418C9.93277 57.268 10.1749 56.41 10.6327 55.6504C11.089 54.8863 11.7575 54.2544 12.5659 53.8231L15.2021 52.4113V42.9437C15.3101 42.2464 15.5785 41.5807 15.9885 40.9925C16.3984 40.4042 16.9398 39.9075 17.5747 39.5386C18.0611 39.0848 18.6375 38.7264 19.2709 38.4845C19.9043 38.2418 20.5821 38.1208 21.2654 38.1268H73.9892C74.6885 38.1035 75.3854 38.2162 76.0361 38.4589C76.6869 38.7009 77.2777 39.0668 77.7723 39.5348C78.2669 40.0022 78.6546 40.5604 78.9108 41.1758C79.1669 41.7911 79.2861 42.4493 79.2615 43.1097V68.025H57.2935C56.7949 66.7567 55.9451 65.638 54.833 64.7859C53.7631 63.9084 52.4538 63.3328 51.0545 63.1247C49.6265 62.9624 48.1781 63.1337 46.8365 63.6236C45.5141 64.127 44.3604 64.9595 43.4974 66.0317C42.6568 67.0986 42.1403 68.3623 42.0035 69.6862C41.8118 71.0161 42.0252 72.37 42.6186 73.5894C43.1976 74.8389 44.1429 75.905 45.3427 76.6624C46.5038 77.4453 47.8735 77.9059 49.297 77.9915H89.4554C92.2446 77.9697 94.9138 76.9126 96.8861 75.0485C98.8584 73.1837 99.9769 70.6614 100 68.025V58.0592C100.002 56.7421 99.7223 55.4377 99.1792 54.2251C98.6354 53.0117 97.8385 51.9147 96.8369 50.9996C95.8769 50.0702 94.7285 49.3331 93.46 48.8335C92.1915 48.3338 90.8292 48.0821 89.4554 48.0926V38.1268C88.9592 27.8579 84.2946 18.1649 76.43 11.0637C68.5661 3.96265 58.1084 0 47.232 0C36.3556 0 25.8978 3.96265 18.0338 11.0637C10.1697 18.1649 5.50461 27.8579 5.00877 38.1268V47.8439C2.01277 50.4451 0.214692 54.053 0 57.8931C0.019 60.894 1.06739 63.8077 2.98769 66.1977C4.814 68.5163 7.31908 70.2775 10.1933 71.264C12.9566 78.6001 17.9327 85.0105 24.5071 89.7042C31.0815 94.3971 38.9663 97.168 47.1881 97.6744H63.0054Z"
        fill={color}
      />
    </svg>
  );
};
export default AssistantIcon;
