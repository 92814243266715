import { useEffect } from "react";

// third-party imports
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

// project imports
import AppointmentDetails from "./AppointmentDetails";
import PaymentSummary from "./PaymentSummary";

const Checkout = () => {
  // props & state values
  const navigate = useNavigate();
  const location = useLocation();
  const urlData = location.state || null;

  // callbacks & functions
  useEffect(() => {
    if (!urlData || !urlData.selectedDoctor) {
      navigate("/appointments");
    }
  }, [urlData]);

  const getTimeSlotInFormat = (dateString: string) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const utcDate = new Date();
    utcDate.setHours(hours, minutes);

    const formattedTime = format(utcDate, "h:mma").toLowerCase();
    return formattedTime;
  };

  const appointmentDateInFormat =
    urlData && urlData.appointmenDetails
      ? format(
          new Date(urlData?.appointmenDetails?.appointmentDate),
          "dd MMM yyyy"
        )
      : "";

  const appointmentTimeInFormat =
    urlData && urlData.appointmenDetails
      ? getTimeSlotInFormat(urlData.appointmenDetails?.appointmentDate)
      : "";

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        display: "flex",
        gap: "20px",
        "@media (max-width: 991px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          {urlData && urlData.selectedDoctor && urlData.appointmenDetails && (
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                margin: "27px 20px 0",
                "@media (max-width: 991px)": {
                  flexDirection: "column",
                  marginRight: "10px",
                },
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  "@media (max-width: 991px)": {
                    width: "100%",
                  },
                }}
              >
                <Box
                  sx={{
                    color: "var(--neuro-black-text)",
                    font: "31px/120% Roboto Condensed, -apple-system, Roboto, Helvetica, sans-serif",
                  }}
                >
                  Checkout
                </Box>
                <Box
                  sx={{
                    color: "var(--neuro-black-text)",
                    marginTop: "30px",
                    font: "16px/120% Roboto, sans-serif",
                  }}
                >
                  You'll receive a confirmation mail shortly!
                </Box>
                <AppointmentDetails
                  appointmentType={urlData.appointmenDetails?.appointmentMode}
                  appointmentDate={`${appointmentDateInFormat}, ${appointmentTimeInFormat}`}
                  doctorName={`${urlData.selectedDoctor?.firstName} ${urlData.selectedDoctor?.lastName}`}
                  doctorSpeciality={urlData.selectedDoctor?.specialization}
                  doctorExperience={`${urlData.selectedDoctor?.yearsOfExperience} Years Experience`}
                />
              </Box>
              <PaymentSummary
                selectedDoctor={urlData.selectedDoctor}
                appointmentType={urlData.appointmenDetails?.appointmentMode}
                appointmentDate={urlData?.appointmenDetails?.appointmentDate}
                availableSlotId={urlData?.appointmenDetails?.availableSlotId}
              />
            </Box>
          )}

          {(!urlData ||
            !urlData.selectedDoctor ||
            !urlData?.appointmenDetails) && (
            <h4>Please select a doctor in new-appointment page</h4>
          )}
        </>
      </Box>
    </Box>
  );
};

export default Checkout;
