import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ImageIcon from "@mui/icons-material/Image";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import PendingIcon from "@mui/icons-material/Pending";
import { getFormattedDateTime } from "../../../utils/appointments";
import useDocumentStore from "../../../store/documentsStore";
import { ViewerReportCardProps } from "../../../types/documents";
import useAuthStore from "../../../store/authStore";
import { PATIENT } from "../../../utils/constants";
import { isAdmin } from "../../../utils/auth";
import useAppStore from "../../../store/appStore";

// const getStatusIcon = (
//   fileType: string,
//   isSummaryGenerated?: boolean,
//   isTranscriptionsGenerated?: boolean
// ) =>
//   isSummaryGenerated && fileType !== "AUDIO" ? (
//     <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />
//   ) : (
//     <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />
//   );

const getStatusIcon = (
  fileType: string,
  isSummaryGenerated?: boolean,
  isTranscriptionsGenerated?: boolean
) => {
  if (isSummaryGenerated && fileType !== "AUDIO") {
    return <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />;
  } else if (!isSummaryGenerated && fileType !== "AUDIO") {
    return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
  } else if (isTranscriptionsGenerated && fileType === "AUDIO") {
    return <CheckCircleIcon sx={{ color: "var(--neuro-button-bg-success)" }} />;
  } else {
    return <PendingIcon sx={{ color: "var(--neuro-button-bg-primary)" }} />;
  }
};

const FileTypeIcon: React.FC<{ fileType: string }> = ({ fileType }) => {
  const iconStyles = {
    width: 32,
    height: 40,
    fill: "var(--neuro-svg-error)",
    color: "var(--neuro-svg-error)",
    display: "block",
  };

  const fileTypeIconMap: { [key: string]: JSX.Element } = {
    PDF: <PictureAsPdfIcon sx={iconStyles} />,
    DICOM: <NoteAddIcon sx={iconStyles} />,
    AUDIO: <AudioFileIcon sx={iconStyles} />,
    ZIP: <FolderZipIcon sx={iconStyles} />,
  };

  return fileTypeIconMap[fileType] || <ImageIcon sx={iconStyles} />;
};

const ViewerReportCard: React.FC<ViewerReportCardProps> = ({
  id,
  name,
  reportDate,
  isSummaryGenerated,
  isTranscriptionsGenerated,
  fileType,
  createdAt,
  setSelectedDocumentId,
  onClick,
  patientId,
  isFromMeet = false,
  from = "",
}) => {
  // props & state values
  const navigate = useNavigate();
  const { selectedDocumentId } = useDocumentStore();
  const { userRole } = useAuthStore();
  const { setDocumentsConferenceView } = useAppStore();

  const reportDateObj = getFormattedDateTime(reportDate);
  const createdAtObj = getFormattedDateTime(createdAt);

  const styles = {
    wrapperStyles: {
      backgroundColor:
        selectedDocumentId === id
          ? from === "list"
            ? "var(--neuro-white-text)"
            : "var(--neuro-bg-light-grey-primary)"
          : "var(--neuro-white-text)",
      border: "1px solid var(--neuro-secondary_border)",
      borderRadius: "10px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    nameStyles: {
      fontSize: "16px",
      lineHeight: "16.8px",
      fontFamily: "Roboto",
      color: "var(--neuro-bg-darkblue-primary)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: setSelectedDocumentId ? "120px" : "200px",
    },
    reportDateStyles: {
      fontSize: "13px",
      lineHeight: "16px",
      fontFamily: "Roboto Slab",
    },
  };

  // callbacks & functions
  const handleCardClick = (id: string) => {
    if (setSelectedDocumentId) {
      setSelectedDocumentId(id);
    }
    if (from === "list" && !patientId) {
      navigate("/documents/view");
    }
    if (patientId) {
      if (isFromMeet) {
        setDocumentsConferenceView("Details");
      } else {
        if (isAdmin(userRole)) {
          navigate(`/patients/${patientId}/documents/view`);
        } else {
          navigate(`/appointments/patient/${patientId}/documents/view`);
        }
      }
    }
    if (from === "conference" && onClick) {
      onClick();
    }
  };

  return (
    <Box sx={styles?.wrapperStyles} onClick={() => handleCardClick(id)}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <FileTypeIcon fileType={fileType} />

        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Typography sx={styles?.nameStyles}>{name || ""}</Typography>

            {userRole !== PATIENT &&
              getStatusIcon(
                fileType,
                isSummaryGenerated,
                isTranscriptionsGenerated
              )}
          </Box>

          <Typography sx={styles?.reportDateStyles}>
            {setSelectedDocumentId ? "Date" : "Report date"}:{" "}
            {reportDateObj
              ? `${reportDateObj.day}-${reportDateObj.monthNumber}-${reportDateObj.year}`
              : ""}
          </Typography>

          <Typography sx={styles?.reportDateStyles}>
            Upload At :{" "}
            {createdAtObj
              ? `${createdAtObj.day}-${createdAtObj.monthNumber}-${createdAtObj.year}`
              : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewerReportCard;
