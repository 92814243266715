import { Typography } from "@mui/material";
import { isAdmin, isPatient } from "../../../utils/auth";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";

const DoctorName = ({ id, name }: { id: string; name: string }) => {
  // props & state value
  const navigate = useNavigate();
  const { userRole } = useAuthStore();
  const isPatientOrAdmin = isPatient(userRole) || isAdmin(userRole);

  return (
    <Typography
      sx={{
        marginTop: "12px",
        fontWeight: "var(--neuro-font-weight-bold)",
        color: "var(--neuro-bg-darkblue-primary)",
        fontFamily: "var(--neuro-font-family-roboto)",
        lineHeight: "var(--neuro-line-height-tiny-plus)",
        "&:hover": {
          color: "var(--neuro-button-bg-primary)",
        },
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (isPatientOrAdmin) {
          navigate(`/doctors/${id || ""}/details`);
        }
      }}
    >
      {name}
    </Typography>
  );
};

export default DoctorName;
