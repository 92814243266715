import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ReactOtpInput from "react-otp-input";
import { OtpInputProps } from "../../types/ui";

const OtpInput: React.FC<OtpInputProps> = ({
  value,
  numInputs,
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine the gap based on whether it's a mobile device or not
  const gap = isMobile ? "2px" : "12px";

  return (
    <ReactOtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      shouldAutoFocus
      containerStyle={{
        display: "flex",
        justifyContent: "center",
        gap: gap, // Apply the determined gap value
      }}
      inputStyle={{
        width: "40px", // Width of each input box
        height: "50px", // Height of each input box
        borderRadius: "8px", // Rounded corners for the input box
        fontSize: "16px", // Font size inside the input box
        border: "1px solid #ced4da", // Border color for the input box
        textAlign: "center", // Center the text inside each input box
        color: "#495057", // Text color inside the input box
        backgroundColor: "#f8f9fa", // Background color for the input box
        outline: "none", // Remove default focus outline
      }}
      renderInput={(inputProps) => <input {...inputProps} />}
      {...props}
    />
  );
};

export default OtpInput;
