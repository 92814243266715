import React from "react";

// third-party imports
import { Box, CssBaseline, useMediaQuery } from "@mui/material";

// project imports
import Header from "./Header";
import NewSidebar from "../sidebar/NewSidebar";
import { Outlet } from "react-router-dom";
import useAppStore from "../../store/appStore";
import LoadingScreen from "../shared/LoadingScreen";

const MainLayout: React.FC<{
  children?: React.ReactNode;
  backgroundColor?: string;
}> = ({ backgroundColor }) => {
  // props & state values
  const { isMenuCollapsed, showHeader, showSidebar, isLoading } = useAppStore();
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: backgroundColor
          ? backgroundColor
          : "var(--neuro-bg-light-grey-secondary)",
        overflow: "hidden",
      }}
    >
      <CssBaseline />
      {/* <Sidebar /> */}
      {showSidebar && <NewSidebar />}
      <Box sx={{ flexGrow: 1 }}>
        {showHeader && <Header />}
        <Box
          sx={{
            height: isMobile
              ? showHeader
                ? "calc(100vh - 60px)"
                : "100vh"
              : showHeader
                ? "calc(100vh - 80px)"
                : "100vh",
            overflow: "auto",
            position: "relative",
            width:
              isMenuCollapsed || isMobile || !showSidebar
                ? "100vw"
                : "calc(100vw - 300px)",
          }}
        >
          <Outlet />
          {isLoading && (
            <LoadingScreen
              sx={{ position: "absolue", top: "50%", left: "50%" }}
            />
          )}
          {/* {children} */}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
