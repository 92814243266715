import React, { useEffect, useState } from "react";

// third-party imports
import { Box, Typography, useMediaQuery } from "@mui/material";

// project imports
import AppointmentCard from "./AppointmentCard";
import ContentCenter from "../../shared/ContentCenter";
import LoadingScreen from "../../shared/LoadingScreen";
import { AppointmentListProps } from "../../../types/appointments";
import ComponentHeader from "../../shared/ComponentHeader";

const AppointmentList: React.FC<AppointmentListProps> = ({
  appointments,
  loadingFetchAppointments,
  errorFetchAppointments,
  patientId,
  from = "",
}) => {
  // props & state values
  console.log("appointments in appoint list", appointments);

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        paddingLeft:
          from === "dashboard"
            ? isMobile
              ? 0
              : "16px"
            : !patientId
              ? isMobile
                ? "15px"
                : "20px"
              : 0,
        paddingTop:
          from === "dashboard"
            ? isMobile
              ? 0
              : "21px"
            : !patientId
              ? isMobile
                ? "18px"
                : "28px"
              : 0,
        paddingBottom:
          from === "dashboard"
            ? isMobile
              ? 0
              : "35px"
            : !patientId
              ? isMobile
                ? "18px"
                : "23px"
              : 0,
        paddingRight:
          from === "dashboard"
            ? isMobile
              ? 0
              : "17px"
            : !patientId
              ? isMobile
                ? "15px"
                : "20px"
              : 0,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!patientId && (
        <ComponentHeader
          title={from === "dashboard" ? "My Appointments" : "Appointments"}
          sx={{
            marginBottom: isMobile
              ? "18px"
              : from === "dashboard"
                ? "18px"
                : "30px",
          }}
        />
      )}

      {/* add in root level */}
      {loadingFetchAppointments && <LoadingScreen />}

      {/* no need to check error state */}
      {errorFetchAppointments && (
        <ContentCenter>
          <Typography className="no-data">Appointments not found</Typography>
        </ContentCenter>
      )}

      {!loadingFetchAppointments &&
        !errorFetchAppointments &&
        appointments &&
        appointments.length === 0 && (
          <ContentCenter>
            <Typography className="no-data">Appointments not found</Typography>
          </ContentCenter>
        )}

      {!loadingFetchAppointments &&
        !errorFetchAppointments &&
        appointments &&
        appointments.length > 0 &&
        appointments.map((appointment, index) => (
          <AppointmentCard
            key={appointment.id}
            {...appointment}
            sortedAppointments={appointments}
            patientId={patientId}
            usedIn={from}
          />
        ))}
    </Box>
  );
};

export default AppointmentList;
