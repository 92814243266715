import React, { useState, useRef, ChangeEvent } from "react";
import { Box, Typography, TextField, Autocomplete } from "@mui/material";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import Spinner from "../../ui/Spinner";
import UploadIcon from "../../svg/UploadIcon";
import UploadDocumentIcon from "../../svg/UploadDocumentIcon";
import useDocumentStore from "../../../store/documentsStore";
import { Category, UploadFileModalProps } from "../../../types/documents";

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  onClose,
  width,
  closeIcon,
  categories,
  patientId,
  ...props
}) => {
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { uploadDocuments, loadingUploadDocuments } = useDocumentStore();

  const uploadFiles = (files: File[]) => {
    console.log("files", files);
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    const value = selectedCategory?.value;
    formData.append("documentCategory", value as string);
    if (patientId) {
      uploadDocuments(formData, patientId);
    }
    if (!patientId) {
      uploadDocuments(formData);
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files) {
      const selectedFiles = Array.from(event.target.files || []);
      uploadFiles(selectedFiles);

      if (!selectedCategory) return;

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      setSelectedCategory(null);

      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon={closeIcon}
      width={width ? width : 540}
      {...props}
    >
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingInline: "40px",
        }}
      >
        <Typography
          className="form-heading"
          sx={{ textAlign: "center", fontWeight: 600, fontSize: 25 }}
        >
          Upload Document
        </Typography>
        <Box sx={{ mt: 4 }}>
          <UploadDocumentIcon />
        </Box>

        <Box sx={{ width: "100%", mt: 2 }}>
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option.name}
            value={selectedCategory}
            onChange={(_, newValue) => setSelectedCategory(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Category"
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    textAlign: "center", // Center align the placeholder text
                  },
                  "& .MuiInputLabel-root": {
                    textAlign: "center", // Center align the label text
                  },
                }}
              />
            )}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".jpeg,.jpg,.png,.pdf"
            disabled={!selectedCategory || loadingUploadDocuments}
          />
          <label htmlFor="file-upload">
            <Button
              variant="outlined"
              className="outlined-secondary-button"
              sx={{ width: 160, height: 46 }}
              startIcon={<UploadIcon />}
              disabled={!selectedCategory || loadingUploadDocuments}
              onClick={() => fileInputRef.current?.click()}
            >
              {loadingUploadDocuments ? <Spinner /> : "Upload"}
            </Button>
          </label>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadFileModal;
