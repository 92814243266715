import { Box, Grid } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Button from "../../ui/Button";
import VideoCallIcon from "../../svg/VideoCallIcon";
import ConfirmModal from "../../shared/ConfirmModal";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  getAppointmentStatus,
  getTimeDiffereForOngoing,
  handleOpenMap,
} from "../../../utils/appointments";
import { useNavigate } from "react-router-dom";
import Spinner from "../../ui/Spinner";
import LocationIcon from "../../svg/LocationIcon";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useAppStore from "../../../store/appStore";
import {
  CANCELLED,
  COMPLETED,
  ONGOING,
  UPCOMING,
} from "../../../utils/constants";
import { AppointmentDetailsButtonProps } from "../../../types/appointments";

const AppointmentDetailsButton: React.FC<AppointmentDetailsButtonProps> = ({
  appointmentCode,
  id,
  status,
  availableSlot,
  meetType,
  hospitalAddress,
}) => {
  // props & state values
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const { showSnackbar } = useAppStore();
  const navigate = useNavigate();

  const textToCopy = `https://22neuro.continuous.engineering/meet/${appointmentCode}`;

  const { cancelAppointment, loadingCancelAppointment } = useAppointmentStore();

  // Memoize appointment status and time difference to avoid multiple calculations
  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot?.startTime, availableSlot?.endTime]
  );

  const isOngoingSoon = useMemo(
    () => getTimeDiffereForOngoing(availableSlot?.startTime) <= 60,
    [availableSlot?.startTime]
  );

  const isMeetingAllowed = useMemo(
    () => [UPCOMING, ONGOING].includes(appointmentStatus),
    [appointmentStatus]
  );

  const isCompleted = useMemo(
    () => status === COMPLETED || appointmentStatus === COMPLETED,
    [status, appointmentStatus]
  );

  const isCancelledOrCompleted = useMemo(
    () => status === CANCELLED || isCompleted,
    [status, isCompleted]
  );

  // callbacks & functions
  const handleConfirm = useCallback(() => {
    if (!id || id === "") {
      showSnackbar("Could not cancel appointment, please try again", "error");
      return;
    }
    cancelAppointment(id);
  }, [id, cancelAppointment, showSnackbar]);

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleMeetingJoin = useCallback(() => {
    navigate(`/meet/${appointmentCode}`);
  }, [navigate, appointmentCode]);

  const handleCopyClick = useCallback(() => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => showSnackbar("Link copied", "success"))
      .catch(() =>
        showSnackbar("Could not copy link, please try again", "info")
      );
  }, [textToCopy, showSnackbar]);

  return (
    <>
      {meetType === "Online" && (
        <Grid container spacing={2} sx={{ paddingLeft: "11px" }}>
          {isMeetingAllowed && !isCancelledOrCompleted && (
            <>
              <Grid item>
                <Button
                  variant="contained"
                  className="warning-button"
                  startIcon={<VideoCallIcon />}
                  sx={{ width: 184, height: 36 }}
                  onClick={handleMeetingJoin}
                  disabled={!isOngoingSoon}
                >
                  Meet with doctor
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className="warning-button"
                  sx={{
                    height: 36,
                  }}
                  onClick={handleCopyClick}
                  startIcon={
                    <ContentCopyIcon
                      sx={{ fontSize: "var(--neuro-font-size-micro)" }}
                    />
                  }
                >
                  Copy Invitation
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={
                    <NoMeetingRoomIcon
                      sx={{ fontSize: "var(--neuro-font-size-micro)" }}
                    />
                  }
                  className="outlined-secondary-button"
                  sx={{
                    height: 36,
                  }}
                  onClick={() => setOpenConfirmModal(true)}
                  disabled={loadingCancelAppointment}
                >
                  {loadingCancelAppointment ? <Spinner /> : "Cancel Meeting"}
                </Button>
              </Grid>
            </>
          )}

          <ConfirmModal
            open={openConfirmModal}
            description="Are you sure, you want to cancel this appointment"
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        </Grid>
      )}

      {meetType === "Clinic" && (
        <Box
          sx={{
            marginTop: "48px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Button
            className="outlined-secondary-button"
            startIcon={<LocationIcon />}
            color="secondary"
            variant="outlined"
            sx={{ width: 130, height: 36 }}
            onClick={() => handleOpenMap(hospitalAddress || "")}
          >
            Directions
          </Button>

          {!isCancelledOrCompleted && (
            <Button
              variant="outlined"
              startIcon={
                <NoMeetingRoomIcon
                  sx={{ fontSize: "var(--neuro-font-size-micro)" }}
                />
              }
              className="outlined-secondary-button"
              sx={{
                height: 36,
              }}
              onClick={() => setOpenConfirmModal(true)}
              disabled={loadingCancelAppointment}
            >
              {loadingCancelAppointment ? <Spinner /> : "Cancel Meeting"}
            </Button>
          )}

          <ConfirmModal
            open={openConfirmModal}
            description="Are you sure, you want to cancel this appointment"
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        </Box>
      )}
    </>
  );
};

export default AppointmentDetailsButton;
