import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Remirror, useRemirror, EditorComponent } from "@remirror/react";
import { htmlToProsemirrorNode } from "remirror";
import { DOMSerializer, Schema } from "prosemirror-model";
import { schema } from "prosemirror-schema-basic";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import useDocumentStore from "../../../store/documentsStore";
import { RemirrorEditorProps } from "../../../types/documents";

const customSchema = new Schema({
  nodes: schema.spec.nodes,
  marks: schema.spec.marks,
});

const RemirrorEditor: React.FC<RemirrorEditorProps> = ({
  htmlContent,
  summaryType,
  setDoctorSummaryHtmlString,
  setPatientSummaryHtmlString,
  documentId,
  isEditable = true,
}) => {
  const [htmlStringContent, setHtmlStringContent] =
    useState<string>(htmlContent);

  const { updateDoctorSummary, updatePatientSummary } = useDocumentStore();

  useEffect(() => {
    setHtmlStringContent(htmlContent);
  }, [htmlContent]);

  const { manager, state } = useRemirror({
    extensions: () => [],
    content: htmlToProsemirrorNode({
      content: htmlContent,
      schema: customSchema,
    }),
    selection: "end",
    stringHandler: "html",
  });

  // Effect to update editor state when htmlContent changes
  useEffect(() => {
    const { view } = manager as { view: EditorView };
    const newState = EditorState.create({
      doc: htmlToProsemirrorNode({
        content: htmlContent,
        schema: customSchema,
      }),
      schema: customSchema,
      plugins: state.plugins,
    });
    view.updateState(newState);
  }, [htmlContent, manager, state.plugins]);

  // Effect to handle editor editability
  useEffect(() => {
    const { view } = manager as { view: EditorView };
    view.setProps({ editable: () => isEditable });
  }, [manager, isEditable]);

  function normalizeHtml(htmlString: string): string {
    // Create a DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Normalize the content
    const normalizeNode = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        // Trim text nodes
        node.textContent = node.textContent?.trim() || "";
      }

      // Recursively normalize child nodes
      node.childNodes.forEach(normalizeNode);
    };

    // Normalize the document body
    normalizeNode(doc.body);

    // Remove any empty tags
    const removeEmptyTags = (node: HTMLElement) => {
      const childNodes = Array.from(node.childNodes);

      for (const child of childNodes) {
        if (child.nodeType === Node.ELEMENT_NODE) {
          removeEmptyTags(child as HTMLElement);

          // Remove empty elements
          if (
            !child.textContent?.trim() &&
            !(child as HTMLElement).childElementCount
          ) {
            node.removeChild(child);
          }
        }
      }
    };

    removeEmptyTags(doc.body);

    // Return the normalized HTML
    return doc.body.innerHTML;
  }

  // Handler to call API on blur
  const handleBlur = useCallback(() => {
    const { view } = manager;
    const doc = view.state.doc;
    const serializer = DOMSerializer.fromSchema(customSchema);
    const html = serializer.serializeFragment(doc.content);

    // Optionally, convert the serialized HTML to a string
    let htmlString = new XMLSerializer().serializeToString(html);
    // Remove xmlns attributes
    htmlString = htmlString.replace(/xmlns="[^"]*"/g, "");

    const normalizedHtml = normalizeHtml(htmlString);
    const normalizedPreviousHtml = normalizeHtml(htmlStringContent);

    // Wrap HTML string with <body> tags
    // const wrappedHtmlString = `<body>${htmlString}</body>`;
    const wrappedHtmlString = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${summaryType === "patient" ? "Patient" : "Doctor"} Medical Summary</title>
        </head>
        <body>
            ${htmlString}
        </body>
        </html>
    `.trim();

    if (normalizedHtml !== normalizedPreviousHtml) {
      if (summaryType === "patient") {
        setPatientSummaryHtmlString(wrappedHtmlString);
        updatePatientSummary(documentId, wrappedHtmlString);
      }
      if (summaryType === "doctor") {
        setDoctorSummaryHtmlString(wrappedHtmlString);
        updateDoctorSummary(documentId, wrappedHtmlString);
      }
    }
  }, [
    manager,
    summaryType,
    setDoctorSummaryHtmlString,
    setPatientSummaryHtmlString,
  ]);

  useEffect(() => {
    const { view } = manager as { view: EditorView };

    const onBlur = () => handleBlur();

    // Attach the blur event listener
    view.dom.addEventListener("blur", onBlur);

    // Clean up the event listener on component unmount
    return () => {
      view.dom.removeEventListener("blur", onBlur);
    };
  }, [manager, handleBlur]);

  return (
    <Remirror key={htmlStringContent} manager={manager} initialContent={state}>
      <EditorComponent />
    </Remirror>
  );
};

export default RemirrorEditor;
