import { Box, Typography } from "@mui/material";
import React from "react";
import { ConferenceTabProps } from "../../../types/appointments";

const Tab: React.FC<ConferenceTabProps> = ({
  selectedTab,
  tab,
  setSelectedTab,
  disabled = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border:
          selectedTab === tab
            ? "1px solid var(--neuro-button-bg-primary)"
            : "1px solid var(--neuro-secondary_border)",
        borderBottom: "none",
        height: "47px",
        borderRadius: "12px 12px 0px 0px",
        paddingInline: "30px",
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor:
          selectedTab === tab
            ? "var(--neuro-button-bg-primary)"
            : "var(--neuro-bg-light-grey-secondary)",
      }}
      onClick={() => {
        if (setSelectedTab && !disabled) {
          setSelectedTab(tab);
        }
      }}
    >
      <Typography
        sx={{
          fontWeight: selectedTab === tab ? 700 : 400,
          color:
            selectedTab === tab
              ? "var(--neuro-white-text)"
              : "var(--neuro-black-text)",
        }}
      >
        {tab}
      </Typography>
    </Box>
  );
};

export default Tab;
