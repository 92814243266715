import { Box, IconButton } from "@mui/material";
import Input from "../../ui/Input";
import { useEffect, useState } from "react";
import { fetchAbhaDetails } from "../../../services/authService";
import LoadingScreen from "../../shared/LoadingScreen";
import NoAbhaDetails from "./NoAbhaDetails";
import ComponentHeader from "../../shared/ComponentHeader";
import Tooltip from "../../ui/Tooltip";
import DownloadSvgIcon from "../../svg/DownloadIcon";
import useAppStore from "../../../store/appStore";
import useAuthStore from "../../../store/authStore";

const AbhaCardDetails = ({
  userId,
  from = "",
}: {
  userId: string;
  from?: string;
}) => {
  // props & state values
  const [abhaDetails, setAbhaDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const { showSnackbar } = useAppStore();

  const { user } = useAuthStore();

  // callbacks & functions
  const loadAbhaDetails = async () => {
    try {
      const result = await fetchAbhaDetails(userId || "");
      console.log("result in abha details", result);
      if (result && result?.abhaCardUrl) {
        setAbhaDetails(result);
      }
      setLoading(false);
    } catch (error) {
      console.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    setAbhaDetails(null);
    setLoading(true);
    loadAbhaDetails();
  }, [user]);

  const handleAbhaCardDownload = async () => {
    if (abhaDetails?.abhaCardUrl) {
      try {
        setLoadingDownload(true);
        // Fetch the file to ensure it exists and create a Blob URL if needed
        const response = await fetch(abhaDetails?.abhaCardUrl);

        if (!response.ok) {
          throw new Error("File not found");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          abhaDetails?.abhaCardUrl.split("/").pop() || "download"
        ); // Set the filename
        document.body.appendChild(link);

        link.click();

        // Clean up by revoking the Blob URL and removing the element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        setLoadingDownload(false);
      } catch (error) {
        console.error("Download error:", error);
        setLoadingDownload(false);
        showSnackbar("Could not download the file, please try again", "info");
      }
    } else {
      showSnackbar("Could not download the file, please try again", "info");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: from === "dashboard" ? "100%" : "80%",
        backgroundColor: "var(--neuro-white-text)",
        borderRadius: "10px",
        // height: "100%",
        p: "20px",
        width: from === "dashboard" ? "100%" : "80%",
        height:
          from === "dashboard" ? "100%" : loading ? "100%" : "max-content",
      }}
    >
      <ComponentHeader
        title="ABHA Details"
        sx={{
          marginBottom: "18px",
        }}
      >
        <Tooltip title="Download ABHA Card" placement="top">
          <IconButton
            disabled={loadingDownload || !abhaDetails}
            sx={{
              "&.Mui-disabled > svg": {
                opacity: 0.5,
              },
            }}
            onClick={handleAbhaCardDownload}
          >
            <DownloadSvgIcon />
          </IconButton>
        </Tooltip>
      </ComponentHeader>

      {loading && <LoadingScreen />}

      {!loading && !abhaDetails && <NoAbhaDetails />}

      {!loading && abhaDetails && (
        <Box
          sx={{
            padding: 0,
            backgroundColor: "var(--neuro-white-text)",
            width: "100%",
            display: "flex",
            alignItems: from === "dashboard" ? "normal" : "center",
            flexDirection: from === "dashboard" ? "column" : "row",
          }}
        >
          <Box
            sx={{
              width: from === "dashboard" ? "370px" : "400px",
              height: "400px",
              flexShrink: 0,
              marginBottom: from === "dashboard" ? "10px" : 0,
            }}
          >
            <Box
              component="img"
              src={abhaDetails?.abhaCardUrl || undefined}
              alt="abha-card"
              sx={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure the image covers the full width
            />
          </Box>

          {from !== "dashboard" && (
            <Box
              sx={{
                height: "120px",
                width: "1px",
                backgroundColor: "var(--neuro-secondary_border)",
                marginInline: "40px",
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: from === "dashboard" ? 2.5 : 4.5,
            }}
          >
            <Input
              type="text"
              className="input-primary"
              label="ABHA Number"
              fullWidth
              value={abhaDetails?.abhaNumber || ""}
              readOnly
            />
            <Input
              type="text"
              className="input-primary"
              label="ABHA Address"
              fullWidth
              value={abhaDetails?.abhaAddress || ""}
              readOnly
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AbhaCardDetails;
