import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { fetchPatients } from "../../services/patientService";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Patient } from "../../types/common";
import { useLocation, useNavigate } from "react-router-dom";
import ComponentHeader from "../shared/ComponentHeader";
import HorizontalTabs from "../profile/patient/HorizontalTabs";
import { capitalizeFirstLetter, getFormattedDate } from "../../utils/common";
import PatientsProfile from "./PatientsProfile";
import PatientDocuments from "./PatientDocuments";
import PatientForms from "./PatientForms";
import PatientAppointments from "./PatientAppointments";
import AddIcon from "@mui/icons-material/Add";
import PatientSignUpModal from "./PatientSignUpModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import Button from "../ui/Button";

const Patients: React.FC = () => {
  // props & state values
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedPatient, setSelectedPatient] = useState<any>(null);

  const [addNewPatientModalOpen, setAddNewPatientModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  console.log("patients", patients);

  const tabs = ["Profile", "Documents", "Appointments", "Forms"];

  const containerRef = useRef<HTMLDivElement | null>(null);

  // const [selectedTab, setSelectedTab] = useState<string>(getInitialTab());
  const [selectedTab, setSelectedTab] = useState<string>("Profile");

  const showPatientsList = location?.pathname === "/patients";

  // callbacks & functions
  function getInitialTab() {
    const foundTab = tabs.find((tab) =>
      location.pathname.toLowerCase().includes(tab.toLowerCase())
    );
    return foundTab || "Profile";
  }

  useEffect(() => {
    const newTab = getInitialTab();
    setSelectedTab(newTab);
  }, [location.pathname]);

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
    if (selectedPatient) {
      navigate(`/patients/${selectedPatient.id}/${tab.toLowerCase()}`);
    }
  };

  useEffect(() => {
    // Scroll the container to the top when "Profile" tab is selected
    if (selectedTab === "Profile" && containerRef.current) {
      console.log("containerRef.current", containerRef.current);
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedTab]);

  useEffect(() => {
    return () => {
      setSelectedPatient(null);
    };
  }, []);

  useEffect(() => {
    loadPatients();
  }, [location?.pathname]);

  const loadPatients = async () => {
    try {
      const response = await fetchPatients();
      setPatients(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching patients:", error);
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    setAddNewPatientModalOpen(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        height: "100%",
        paddingInline: "20px",
        paddingBlock: "15px",
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {showPatientsList ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ComponentHeader title="Patient List" />

            <Button
              variant="outlined"
              color="secondary"
              className="outlined-secondary-button"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
            >
              Add New
            </Button>
          </Box>
        ) : (
          <ComponentHeader
            title={`Patient / ${capitalizeFirstLetter(
              selectedPatient?.user?.firstName || ""
            )} ${capitalizeFirstLetter(
              selectedPatient?.user?.lastName || ""
            )} / ${selectedTab}`}
            isBreadcrumb
          />
        )}

        {!showPatientsList && (
          <HorizontalTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            onClick={onTabClick}
          />
        )}
      </Box>

      {!showPatientsList ? (
        <Box
          ref={containerRef}
          sx={{
            height: "calc(100% - 130px)",
            maxHeight: "calc(100% - 130px)",
            flexGrow: 1,
            overflow: "auto",
            padding: "10px",
          }}
        >
          {selectedTab === "Profile" && (
            <PatientsProfile patientId={selectedPatient?.id} />
          )}
          {selectedTab === "Documents" && (
            <PatientDocuments patientId={selectedPatient?.id} />
          )}
          {selectedTab === "Forms" && (
            <PatientForms
              patientName={`${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""} ${capitalizeFirstLetter(selectedPatient?.user?.lastName || "") || ""}`}
              patientId={selectedPatient?.id}
            />
          )}
          {selectedTab === "Appointments" && (
            <PatientAppointments
              patientId={selectedPatient?.id}
              patientName={`${capitalizeFirstLetter(selectedPatient?.user?.firstName || "") || ""} ${capitalizeFirstLetter(selectedPatient?.user?.lastName || "") || ""}`}
              patientEmail={selectedPatient?.user?.email || ""}
              patientContact={selectedPatient?.user?.mobile || ""}
            />
          )}
        </Box>
      ) : (
        <Box>
          <>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          First Name
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Last Name
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Mobile
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Email
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Gender
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Registered On
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Initial Appointment
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold", color: "#333" }}>
                          Reasons for Appointment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patients.map((patient) => (
                        <TableRow
                          key={patient.id}
                          sx={{
                            backgroundColor:
                              selectedPatient &&
                              selectedPatient?.id === patient?.id
                                ? "var(--neuro-bg-light-grey-secondary)"
                                : "var(--neuro-white-text)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedPatient(patient || null);
                            navigate(`/patients/${patient?.id}/profile`);
                          }}
                        >
                          <TableCell>{patient.user?.firstName}</TableCell>
                          <TableCell>{patient.user?.lastName}</TableCell>
                          <TableCell>{patient.user?.mobile}</TableCell>
                          <TableCell>{patient.user?.email}</TableCell>
                          <TableCell>{patient?.gender}</TableCell>

                          <TableCell sx={{ fontWeight: "bold" }}>
                            {getFormattedDate(patient?.createdAt || "")}
                          </TableCell>
                          <TableCell>
                            {patient?.isFirstAppointmentCompleted ? (
                              <CheckCircleIcon
                                sx={{ color: "var(--neuro-button-bg-success)" }}
                              />
                            ) : (
                              <PendingIcon
                                sx={{ color: "var(--neuro-button-bg-primary)" }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {patient.reasonsForAppointments}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            <PatientSignUpModal
              open={addNewPatientModalOpen}
              onClose={() => setAddNewPatientModalOpen(false)}
              loadPatients={loadPatients}
            />
          </>
        </Box>
      )}
    </Box>
  );
};

export default Patients;
