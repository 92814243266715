import React from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { FilterByAppointmentsProps } from "../../../types/appointments";

const FilterByAppointments: React.FC<FilterByAppointmentsProps> = ({
  options,
  selectedValue,
  setSelectedValue,
  // from = "",
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <RadioGroup
      value={selectedValue}
      onChange={handleChange}
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export default FilterByAppointments;
