import React from "react";
import {
  Avatar,
  Box,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import PatientIcon from "../../svg/PatientIcon";
import useAuthStore from "../../../store/authStore";
import NewDoctorIcon from "../../svg/NewDoctorIcon";
import WcIcon from "@mui/icons-material/Wc";
import CareNavigatorIcon from "../../svg/CareNavigatorIcon";

interface TranscriptionData {
  roles: { [key: string]: string };
  transcription: { role: string; dialogue: string }[] | any[];
}

interface TranscriptionProps {
  data: TranscriptionData | null;
}

const TranscriptionViewer: React.FC<TranscriptionProps> = ({ data }) => {
  const { userRole } = useAuthStore();

  const iconForRole = (role: string) => {
    if (role === "DOCTOR") {
      return <NewDoctorIcon color="var(--neuro-white-text)" />;
    } else if (role === "PATIENT") {
      return <PatientIcon color="var(--neuro-white-text)" />;
    } else if (role === "CARENAVIGATOR") {
      return (
        <WcIcon
          sx={{
            fontSize: "30px",
            color: "var(--neuro-white-text)",
          }}
        />
      );
    } else {
      return <CareNavigatorIcon color="var(--neuro-white-text)" />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <>
        {(!data || !data.transcription || data.transcription.length === 0) && (
          <p>No transcription</p>
        )}

        {data &&
          data.transcription &&
          data.transcription.length > 0 &&
          data.transcription.map((entry, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              marginBottom={2}
              alignSelf={entry.role === userRole ? "flex-end" : "flex-start"}
              borderRadius="10px"
              padding={1.25}
              maxWidth="75%"
              border="1px solid var(--neuro-secondary_border)"
            >
              <ListItemAvatar
                sx={{
                  backgroundColor: "var(--neuro-button-bg-success)",
                  height: 40,
                  width: 40,
                  borderRadius: "50%",
                  minWidth: 40,
                  marginRight: "12px",
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "var(--neuro-button-bg-success)",
                  }}
                >
                  {iconForRole(entry.role || "")}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    {data.roles[entry.role]} {`(${entry.role.toLowerCase()})`}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2">{entry.dialogue}</Typography>
                }
              />
            </Box>
          ))}
      </>
    </Box>
  );
};

export default TranscriptionViewer;
