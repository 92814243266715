import React, { useCallback, useEffect, useMemo, useState } from "react";

// third-party imports
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";

// project imports
import AppointmentDetails from "../AppointmentConfirmation/AppointmentDetails";
import useAppointmentStore from "../../../store/appointmentsStore";
import { useNavigate } from "react-router-dom";
import {
  getAppointmentStatus,
  getFormattedDateTime,
  getStatusColor,
  specializationMapping,
} from "../../../utils/appointments";
import useAuthStore from "../../../store/authStore";
import { isAdmin, isDoctor, isPatient } from "../../../utils/auth";
import AppointmentCardButtons from "./AppointmentCardButtons";
import { capitalizeFirstLetter } from "../../../utils/common";
import RightExtandIcon from "../../svg/RightExtandIcon";
import {
  CANCELLED,
  COMPLETED,
  ONGOING,
  ONLINE,
  UPCOMING,
} from "../../../utils/constants";
import { AppointmentCardProps } from "../../../types/appointments";

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointmentCode,
  id,
  availableSlot,
  doctor,
  prescription,
  status,
  patient,
  paymentStatus,
  appointmentMode,
  sortedAppointments,
  patientId,
  usedIn = "",
  from = "",
}) => {
  // props & state values
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const [appointmentDetails, setAppointmentDetails] = useState<any>(null);

  const { user, userRole } = useAuthStore();

  const isPatientOrAdmin = isPatient(userRole) || isAdmin(userRole);
  const doctorName = `Dr. ${capitalizeFirstLetter(doctor?.user?.firstName || "")} ${capitalizeFirstLetter(doctor?.user?.lastName || "")}`;
  const appointementPatientName = `${capitalizeFirstLetter(patient?.user?.firstName || "")} ${capitalizeFirstLetter(patient?.user?.lastName || "")}`;

  const {
    cancelAppointmentInfo,
    fetchAppointments,
    selectedAppointmentId,
    setSelectedAppointmentId,
  } = useAppointmentStore();

  const shouldRenderDetails =
    selectedAppointmentId === id &&
    appointmentDetails &&
    appointmentDetails?.id === selectedAppointmentId;

  // callbacks & functions
  useEffect(() => {
    if (cancelAppointmentInfo) {
      if (patientId) {
        fetchAppointments(patientId);
      } else {
        fetchAppointments();
      }
    }
    return () => {
      setSelectedAppointmentId(null);
    };
  }, [cancelAppointmentInfo]);

  // Handle icon button click
  const handleAppointmentClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (selectedAppointmentId === id) {
        setSelectedAppointmentId(null);
        setAppointmentDetails(null);
      } else {
        setSelectedAppointmentId(id);
        const details = sortedAppointments?.find((appt) => appt.id === id);
        setAppointmentDetails(details || null);
      }
    },
    [selectedAppointmentId, id, sortedAppointments, setSelectedAppointmentId]
  );

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const isCompletedOrCancelled = useMemo(
    () =>
      [CANCELLED, COMPLETED].includes(status) ||
      appointmentStatus === COMPLETED,
    [status, appointmentStatus]
  );

  const cardStyles = useMemo(() => {
    const background = isCompletedOrCancelled
      ? "#f4f4f4"
      : "var(--neuro-white-text)";
    const borderLeft = isCompletedOrCancelled
      ? "1px solid var(--neuro-secondary_border)"
      : appointmentStatus === UPCOMING
        ? "4px solid var(--neuro-button-bg-primary)"
        : appointmentStatus === ONGOING
          ? "4px solid var(--neuro-button-bg-success)"
          : "1px solid var(--neuro-secondary_border)";

    return {
      background,
      borderLeft,
      opacity: isCompletedOrCancelled ? 0.9 : 1,
    };
  }, [status, appointmentStatus]);

  // Handle card click
  const handleCardClick = useCallback(() => {
    const path = patientId
      ? `/patients/${patientId}/appointments/${id}/details`
      : `/appointment/${id}`;
    navigate(path);
  }, [navigate, id, patientId]);

  const doctorSpecialisations = useCallback(() => {
    const { specializations } = doctor || {};
    if (!specializations?.length) return "";
    const mappedSpecializations = specializations?.map(
      (specialization: string) =>
        specializationMapping[specialization] || specialization
    );
    return isMobile
      ? specializations.length > 1
        ? `${mappedSpecializations[0]}...`
        : mappedSpecializations[0]
      : mappedSpecializations.join(", ");
  }, [doctor, isMobile]);

  const formattedDate = useMemo(() => {
    if (!availableSlot?.startTime) return null;
    const DateTimeObject = getFormattedDateTime(availableSlot.startTime);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    } else return "";
  }, [availableSlot]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: isMobile ? "16px" : "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          background: cardStyles.background,
          border: "1px solid var(--neuro-secondary_border)",
          borderLeft: cardStyles.borderLeft,
          borderRadius:
            selectedAppointmentId === id &&
            appointmentDetails &&
            appointmentDetails.id === selectedAppointmentId
              ? "12px 12px 0px 0px"
              : "12px",
          padding: isMobile ? "12px 14px" : "10px 26px 8px 28px",
          cursor: "pointer",
        }}
        onClick={handleCardClick}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: isMobile ? "space-between" : "normal",
            alignItems: isMobile ? "center" : "normal",
            width: isMobile ? "100%" : "initial",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              width: "120px",
            }}
          >
            <Typography
              sx={{
                paddingBlock: "2px",
                borderRadius: "4px",
                backgroundColor:
                  status === CANCELLED
                    ? "var(--neuro-grey-variant1)"
                    : status === COMPLETED
                      ? "var(--neuro-grey-variant1)"
                      : getStatusColor(appointmentStatus),
                color: "var(--neuro-white-text)",
                opacity: cardStyles.opacity,
                fontSize: "var(--neuro-font-size-x-small)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
                height: "20px",
                width: "120px",
                textAlign: "center",
              }}
            >
              {status === CANCELLED
                ? CANCELLED
                : status === COMPLETED
                  ? COMPLETED
                  : appointmentStatus || ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-x-small)",
                marginTop: "10px",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
                color: "var(--neuro-darkgrey_border)",
                width: "max-content",
              }}
            >
              {formattedDate ? formattedDate?.date || "" : ""}
            </Typography>

            <Typography
              sx={{
                marginTop: "2px",
                fontSize: "var(--neuro-font-size-x-small)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
                color: "var(--neuro-darkgrey_border)",
                textAlign: "center",
              }}
            >
              {formattedDate ? formattedDate?.time || "" : ""}
            </Typography>
          </Box>

          {!isMobile && (
            <Box
              sx={{
                backgroundColor: "var(--neuro-secondary_border)",
                width: "1px",
                height: "81.5px",
                marginTop: "-10px",
                marginBottom: "-8px",
                marginRight: "61px",
                marginLeft: "51px",
                "@media (max-width: 1400px)": {
                  marginLeft: "10px",
                  marginRight: "10px",
                },
              }}
            />
          )}

          <Box>
            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-x-small)",
                color: "var(--neuro-black-text)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "120%",
              }}
            >
              {appointmentMode
                ? appointmentMode === ONLINE
                  ? "Online Consultation"
                  : "Clinic Visit"
                : ""}{" "}
            </Typography>

            <Typography
              sx={{
                marginTop: "12px",
                fontWeight: "var(--neuro-font-weight-bold)",
                color: "var(--neuro-bg-darkblue-primary)",
                fontFamily: "var(--neuro-font-family-roboto)",
                lineHeight: "var(--neuro-line-height-tiny-plus)",
              }}
            >
              {isPatientOrAdmin
                ? doctorName
                : isDoctor(userRole)
                  ? appointementPatientName
                  : ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "var(--neuro-font-size-x-small)",
                color: "var(--neuro-darkgrey_border)",
                fontFamily: "var(--neuro-font-family-roboto-slab)",
                lineHeight: "var(--neuro-line-height-tiniest-plus)",
                marginTop: "1px",
              }}
            >
              {isPatientOrAdmin ? doctorSpecialisations() : ""}
            </Typography>
          </Box>

          {isMobile && (
            <IconButton
              onClick={(event) => handleAppointmentClick(event)}
              sx={{
                // marginLeft: maxWidth ? "20px" : "80px",
                width: 46,
                height: 46,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <RightExtandIcon />
            </IconButton>
          )}
        </Box>

        {isMobile && (
          <Box
            sx={{
              backgroundColor: "var(--neuro-secondary_border)",
              width: "100%",
              height: "1px",
              marginBlock: "10px",
            }}
          />
        )}

        {/* Card Buttons */}
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            height: isMobile ? "24px" : "46px",
          }}
        >
          <AppointmentCardButtons
            appointmentCode={appointmentCode}
            id={id}
            userRole={userRole}
            appointmentMode={appointmentMode}
            status={status}
            availableSlot={availableSlot}
            paymentStatus={paymentStatus}
            doctor={doctor}
            patient={patient}
            handleAppointmentClick={handleAppointmentClick}
            user={user}
            patientId={patientId}
          />
        </Box>
      </Box>

      {shouldRenderDetails && (
        <>
          <Box
            sx={{
              backgroundColor: "var(--neuro-white-text)",
              border: "1px solid var(--neuro-secondary_border)",
              borderRadius: "0px 0px 12px 12px",
              padding: "41px 26px 41px 28px",
            }}
          >
            <AppointmentDetails
              from="list"
              appointmentDetails={appointmentDetails}
              patientId={patientId}
              usedIn={usedIn}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AppointmentCard;
