import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import Spinner from "../ui/Spinner";
import useAuthStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import { AadharOtpModalProps } from "../../types/auth";
import { sendAadharOtp, verifyAadharOtp } from "../../services/authService";
import useAppStore from "../../store/appStore";
import Input from "../ui/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
// import axios from "axios";

// Define validation schema for Yup
const schema = yup.object().shape({
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, "OTP must be 6 digits")
    .required("OTP is required"),
});

interface FormInputs {
  mobile: string;
  otp: string;
}

const AadharOtpModal: React.FC<AadharOtpModalProps> = ({
  open,
  onClose,
  width,
  closeIcon,
  otpToken,
  aadharNumber,
  message,
  setOtpToken,
  setMessage,
  userId,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [timer, setTimer] = useState(30); // Timer state
  const [isTimerActive, setIsTimerActive] = useState(false); // Timer activation state

  const navigate = useNavigate();
  const { login } = useAuthStore();
  const { showSnackbar, setIsRedirectedFromSignUp } = useAppStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen is small (mobile)

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (open && isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(interval!);
            setIsTimerActive(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [open, isTimerActive]);

  useEffect(() => {
    if (open) {
      // Reset states when modal opens
      setLoading(false);
      setResendLoading(false);
      setTimer(30);
      setIsTimerActive(true);
    }
  }, [open]);

  const handleResendOtp = async () => {
    setResendLoading(true);

    try {
      setTimer(30); // Reset the timer to 15 seconds
      setIsTimerActive(true); // Reactivate the timer
      const result = await sendAadharOtp(aadharNumber || "");
      console.log("result in send otp aadhaar", result);
      setMessage(result?.message || "");
      setOtpToken(result?.txnId || "");
      console.log("result in send aahar otp", result);
    } catch (error: any) {
      console.log("error", error);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not complete process, please try again",
        "error"
      );
    }

    setResendLoading(false);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    setLoading(true);
    // Form submission logic here
    try {
      const result = await verifyAadharOtp(
        data.otp,
        otpToken,
        data.mobile,
        aadharNumber,
        userId
      );
      console.log("result in verify aahar otp", result);
      if (result && result?.accessToken && result?.refreshToken) {
        showSnackbar("Abha account created", "success");
        login(result?.accessToken, result?.refreshToken);
        navigate("/dashboard");
        setIsRedirectedFromSignUp(true);
      }
      if (result && result?.abhaAttached) {
        showSnackbar("Abha account created and linked", "success");
        onClose();
        window.location.reload();
      }
    } catch (error: any) {
      console.log("error in verify otp", error);
      showSnackbar(
        error?.response?.data?.message ||
          "Could not create abha account, please try again",
        "error"
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
        width={isMobile ? 320 : 500} // Adjust width
        // height={isMobile ? undefined : 450} // Adjust height
        {...props}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingInline: "20px",
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            className="form-heading"
            sx={{
              fontWeight: 700,
              fontSize: "var(--neuro-font-size-large-medium)",
              fontFamily: "var(--neuro-font-family-roboto-condensed)",
              lineHeight: "var(--neuro-line-height-medium)",
            }}
          >
            Aadhaar
          </Typography>

          <Typography
            sx={{
              fontSize: isMobile ? "14px" : "16px",
              fontWeight: "bold",
              marginTop: "12px",
              marginBottom: isMobile ? "24px" : "10px",
              textAlign: "center",
            }}
          >
            {message}
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginTop: "16px",
              marginBottom: "16px",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Input
              type="text"
              fullWidth
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "40px",
                },
              }}
              label="Enter OTP*"
              {...register("otp")}
              error={!!errors.otp}
              helperText={errors.otp?.message}
            />
            <Input
              type="text"
              fullWidth
              // className="input-secondary"
              className="input-primary"
              sx={{
                "& >.MuiInputBase-root": {
                  height: "40px",
                },
              }}
              label="Enter Aadhaar Registered Mobile Number*"
              {...register("mobile")}
              error={!!errors.mobile}
              helperText={errors.mobile?.message}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              mt: 4,
              mb: 2,
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={loading}
              className="success-button"
              // onClick={handleSubmitNew}
              sx={{
                textTransform: "none",
                width: isMobile ? "100px" : 150,
                height: isMobile ? "40px" : 48,
                color: "var(--neuro-white-text)",
              }}
            >
              {loading ? <Spinner /> : "Verify OTP"}
            </Button>

            <Button
              variant="text"
              color="secondary"
              disableRipple
              className="secondary-text-button"
              onClick={handleResendOtp}
              disabled={resendLoading || isTimerActive} // Disable while timer is active
              sx={{
                textTransform: "none",
                fontSize: isMobile ? "12px" : "14px",
              }}
            >
              {resendLoading ? (
                <Spinner />
              ) : (
                `Resend OTP ${isTimerActive ? `(${timer})` : ""}`
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AadharOtpModal;
