import { Box, Dialog, IconButton, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import Tooltip from "../../ui/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tab from "./Tab";
import OriginalReport from "../../documents/view/OriginalReport";
import ReportSummary from "../../documents/view/ReportSummary";
import { ReportsDrawerProps } from "../../../types/appointments";

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ReportsDrawer: React.FC<ReportsDrawerProps> = ({
  reportsDrawerOpen,
  setreportsDrawerOpen,
  report,
}) => {
  // props & state values
  const [selectedTab, setSelectedTab] = useState<string>("Original Report");
  const tabs = ["Original Report", "Report Summary"];

  return (
    <Dialog
      fullScreen
      open={reportsDrawerOpen}
      // onClose={() => setAppointmentDrawer(false)}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: "50vw",
          height: "100vh",
          marginLeft: "auto", // Align to the right
          maxWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
          backgroundColor: "var(--neuro-white-text)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            background:
              "linear-gradient(to right, rgba(98, 181, 90, 1), rgba(37, 129, 196, 1))",
            height: "60px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            paddingLeft: "20px",
          }}
        >
          <Tooltip title="Close this dialog" placement="right">
            <IconButton
              size="small"
              onClick={() => setreportsDrawerOpen(false)}
            >
              <ArrowBackIcon sx={{ color: "var(--neuro-white-text)" }} />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{
              fontSize: "25px",
              lineHeight: "30px",
              color: "var(--neuro-white-text)",
              fontFamily: "Roboto Condensed",
            }}
          >
            Reports Viewer
          </Typography>
        </Box>

        {/* middle content */}
        <Box
          sx={{
            height: "calc(100% - 60px)",
            overflowY: "auto",
            paddingInline: "20px",
            paddingTop: "18px",
            paddingBottom: "12px",
            display: "flex",
            flexDirection: "column",
            // gap: "16px",
          }}
        >
          {/* tabs   */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid var(--neuro-light-grey_border)",
            }}
          >
            {tabs.map((tab, index) => {
              return (
                <React.Fragment key={index}>
                  <Tab
                    tab={tab}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                  />
                </React.Fragment>
              );
            })}
          </Box>

          {/* tabs content */}
          {selectedTab === "Original Report" && (
            <OriginalReport
              fileType={report?.fileType || ""}
              filePath={report?.filePath || ""}
              fileName={report?.name || ""}
              id={report?.id || ""}
              status={report?.status || ""}
              from="conference"
            />
          )}
          {selectedTab === "Report Summary" && (
            <ReportSummary
              id={report?.id}
              metadata={report?.metadata}
              doctorSummary={report?.doctorSummary}
              patientSummary={report?.patientSummary}
              fileName={report?.name}
              isDoctorApproved={report?.isDoctorApproved}
              feedback={report?.feedback}
              from="conference"
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ReportsDrawer;
