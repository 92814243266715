import axios from "axios";
import { RegistrationDataProps } from "../types/auth";

export const completeRegistration = async (
  registrationData: RegistrationDataProps
) => {
  try {
    const response = await axios.post(
      "/api/auth/complete-registration",
      registrationData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(
    //     error.response.data.message || "Unable to complete registration"
    //   );
    // }
    throw error;
  }
};
// Register a new user
export const register = async (userData: any) => {
  try {
    const response = await axios.post("/api/auth/register", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to register");
    }
    throw error;
  }
};

// Standard login with username and password (if needed)
export const login = async (userData: any) => {
  try {
    const response = await axios.post("/api/auth/login", userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to login");
    }
    throw error;
  }
};

// Login by sending OTP to email or phone
export const sendOtp = async (data: any) => {
  try {
    const response = await axios.post("/api/auth/send-otp", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   throw new Error(error.response.data.message || "Unable to send OTP");
    // }
    throw error;
  }
};

// Verify OTP
export const verifyOtp = async (
  emailOrPhone: string,
  otp: string,
  otpToken: string
) => {
  const response = await axios.post(
    "/api/auth/verify-otp",
    { emailOrPhone, otp, otpToken },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data; // Return the data from the response
};

// Resend OTP
export const resendOtp = async (emailOrPhone: string) => {
  try {
    const response = await axios.post(
      "/api/auth/resend-otp",
      { emailOrPhone },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || "Unable to resend OTP");
    }
    throw error;
  }
};

// Refresh Token
export const refreshTokenAPI = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  return axios.post(`/api/token/refresh`, { token: refreshToken });
};
