import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SpecialityCardProps } from "../../../types/appointments";

const SpecialityCard: React.FC<SpecialityCardProps> = ({
  id,
  title,
  icon,
  specialization,
  conditions,
  patientId,
}) => {
  // props & state values
  const navigate = useNavigate();

  // callbacks & functions
  const handleCardClick = (speciality: string) => {
    if (speciality === "General Physician") {
      if (patientId) {
        navigate(
          `/patients/${patientId}/appointments/specialties/primary_care`
        );
      } else {
        navigate(`/specialties/primary_care`);
      }
    } else if (speciality === "Neurology") {
      if (patientId) {
        navigate(`/patients/${patientId}/appointments/specialties/neurology`);
      } else {
        navigate(`/specialties/neurology`);
      }
    } else {
      if (patientId) {
        navigate(`/patients/${patientId}/appointments/specialties/pediatrics`);
      } else {
        navigate(`/specialties/pediatrics`);
      }
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "12px",
        // maxWidth: "250px",
        textAlign: "center",
        backgroundColor: "var(--neuro-white-text)",
        border: "1px solid var(--neuro-secondary_border)",
        paddingBottom: "21px",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => handleCardClick(title)}
    >
      <Box
        sx={{
          borderRadius: "12px 0 0 0",
          borderTop: "2px solid var(--neuro-button-bg-success)",
          borderLeft: "2px solid var(--neuro-button-bg-success)",
          width: "63px",
          height: "140px",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "29px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              fontFamily:
                "'Roboto Condensed', -apple-system, Roboto, Helvetica, sans-serif",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: 1.2,
              marginBottom: "50px",
            }}
          >
            {title}
          </Typography>
          {icon}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "22px",
          padding: "0 34px",
        }}
      >
        <Typography
          sx={{
            color: "var(--neuro-button-bg-success)",
            fontFamily: "Roboto, -apple-system, Roboto, Helvetica, sans-serif",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "19px",
          }}
        >
          {specialization}
        </Typography>
        {/* <Typography
          sx={{
            color: "var(--neuro-black-text)",
            marginTop: "6px",
            fontFamily:
              "'Roboto Slab', -apple-system, Roboto, Helvetica, sans-serif",
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "16px",
          }}
        >
          {conditions}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default SpecialityCard;
