import React from "react";

// third-party imports
import { Box, Typography, useMediaQuery } from "@mui/material";

// project imports
import Button from "../ui/Button";
import CheckedIcon from "../svg/CheckedIcon";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/authStore";
import { isPatient } from "../../utils/auth";
import { capitalizeWordsPreservingSpaces } from "../../utils/common";
import { FormFilledDetailsProps } from "../../types/forms";

const FormFilledDetails = ({
  score,
  patientName,
  onClose,
}: FormFilledDetailsProps) => {
  // props & state values
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const { userRole } = useAuthStore();

  // callbacks & functions
  const getTextContent = () => {
    const baseStyle = { fontSize: "18px", fontWeight: 500 };
    if (isPatient(userRole)) {
      return (
        <>
          <Typography sx={baseStyle}>
            Thank you for submitting your information. Your details have been
            successfully saved.
          </Typography>
          <Typography sx={baseStyle}>
            Our team will review the information you provided, and if any
            further actions are required, we will contact you shortly.
          </Typography>
        </>
      );
    }
    return (
      <Typography sx={baseStyle}>
        The form has been successfully submitted on behalf of{" "}
        <span style={{ fontWeight: 700 }}>
          {capitalizeWordsPreservingSpaces(patientName || "")}
        </span>
        . The patient's information has been saved.
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: isMobile ? "10px 15px" : "24px",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--neuro-button-bg-secondary)",
            borderRadius: "50%",
            minWidth: isMobile ? "100px" : "150px",
            minHeight: isMobile ? "100px" : "150px",
            maxWidth: isMobile ? "100px" : "150px",
            maxHeight: isMobile ? "100px" : "150px",
            padding: "10px",
          }}
        >
          <CheckedIcon
            width={isMobile ? 60 : 100}
            height={isMobile ? 60 : 100}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
            alignItems: "center",
          }}
        >
          {getTextContent() || null}
        </Box>

        {score !== null && score !== undefined && !isPatient(userRole) && (
          <Typography
            sx={{
              fontSize: "30px",
              fontFamily: "Inter",
              marginTop: "32px",
              textAlign: isMobile ? "start" : "center",
            }}
          >
            Based on the responses, the patient's score is:{" "}
            <span
              style={{ fontSize: "30px", fontFamily: "Inter", fontWeight: 700 }}
            >
              {typeof score === "number" ? score : ""}.
            </span>
          </Typography>
        )}

        <Box
          sx={{
            marginBlock: isMobile ? "30px" : "54px",
            width: "50%",
            borderBottom: "1px solid var(--neuro-button-bg-secondary)",
          }}
        ></Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "23px",
          }}
        >
          <Button
            variant="outlined"
            className="outlined-secondary-button"
            color="secondary"
            size="large"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "44px",
            marginBottom: "20px",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontFamily: "Inter" }}>
            Any issue?{" "}
            <span>
              {" "}
              <a
                // href="#"
                style={{
                  color: "#000",
                  marginInline: "6px",
                  textDecoration: "underline",
                }}
              >
                Click Here
              </a>
            </span>
            reach out to our technical team.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FormFilledDetails;
