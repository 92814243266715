import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import Logo from "../../shared/Logo";
import BigBorderIcon from "../../svg/BigBorderIcon";
import { specializationMapping } from "../../../utils/appointments";
import { capitalizeFirstLetter } from "../../../utils/common";
import UserAvatar from "../../shared/UserAvatar";
import useAppointmentStore from "../../../store/appointmentsStore";

const SelectedDoctorCard = () => {
  // props & state values
  const isMobile = useMediaQuery("(max-width:600px)");
  const isBelow400 = useMediaQuery("(max-width:400px)");

  const { selectedDoctor } = useAppointmentStore();

  const styles = {
    containerStyles: {
      borderRadius: "12px",
      backgroundColor: "var(--neuro-white-text)",
      display: "flex",
      paddingRight: isMobile ? (isBelow400 ? "10px" : "14px") : "19px",
      paddingLeft: "11px",
      gap: isMobile ? 0 : "20px",
      flexWrap: "wrap",
      justifyContent: "space-between",
      border: "1px solid var(--neuro-secondary_border)",
      position: "relative",
      alignItems: "center",
      maxHeight: "92px",
      minHeight: "92px",
      marginBottom: "16px",
    },
    detailsStyles: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      margin: "auto 0",
      paddingTop: "4px",
      paddingBottom: "6px",
    },
  };

  return (
    <Box sx={styles?.containerStyles}>
      <Box sx={{ position: "absolute", top: 0, left: 0, bottom: 0 }}>
        <BigBorderIcon />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "14px",
        }}
      >
        <UserAvatar
          src={selectedDoctor?.profileUrl || null}
          alt={
            `${capitalizeFirstLetter(selectedDoctor?.firstName || "") || ""}` ||
            "Doctor-profile"
          }
        />

        <Box sx={styles?.detailsStyles}>
          <Typography
            sx={{
              fontFamily: "Roboto Condensed",
              color: "var(--neuro-bg-darkblue-primary)",
              lineHeight: "120%",
            }}
          >
            Dr. {capitalizeFirstLetter(selectedDoctor?.firstName || "") || ""}{" "}
            {capitalizeFirstLetter(selectedDoctor?.lastName || "") || ""}
          </Typography>
          <Typography
            sx={{
              fontFamily: "var(--neuro-font-family-roboto-slab)",
              fontSize: "var(--neuro-font-size-tiniest)",
              color: "var(--neuro-button-bg-primary)",
              marginTop: "3px",
            }}
          >
            {selectedDoctor?.specializations &&
            selectedDoctor?.specializations.length > 0
              ? selectedDoctor?.specializations
                  .map(
                    (specialization: string) =>
                      specializationMapping[specialization] || specialization
                  )
                  .join(", ")
              : ""}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: 0.75 }}
          >
            <LocationOnIcon
              sx={{
                fontSize: "var(--neuro-font-size-extra-small)",
                color: "var(--neuro-bg-darkblue-primary)",
              }}
            />
            <Typography
              sx={{
                color: "var(--neuro-bg-darkblue-primary)",
                fontSize: "var(--neuro-font-size-smallest-x)",
                lineHeight: "120%",
              }}
            >
              {selectedDoctor?.hospital?.streetAddress || ""},{" "}
              {selectedDoctor?.hospital?.city || ""}
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Logo height={63} width={isBelow400 ? 63 : 89} />
    </Box>
  );
};

export default SelectedDoctorCard;
