import React, { useContext, useEffect } from "react";

// third-party imports
import { useNavigate } from "react-router-dom";
import { Avatar, Box, IconButton, Typography } from "@mui/material";

// project imports
import RightExtandIcon from "../../svg/RightExtandIcon";
import useAppointmentStore from "../../../store/appointmentsStore";
import { NewAppointmentDoctorCardProps } from "../../../types/appointments";

const DoctorCard: React.FC<NewAppointmentDoctorCardProps> = (props) => {
  // props & state values
  const {
    doctor,
    selectedSpeciality,
    slotId = "",
    selectedDate = null,
    yourAvailabilitySelected = false,
  } = props;

  const {
    selectedDoctor,
    selectedDoctorSlotId,
    selectedDoctorDate,
    setSelectedDoctor,
    setSelectedDoctorSlotId,
    setSelecteDoctordDate,
  } = useAppointmentStore();

  const navigate = useNavigate();

  // callbacks & functions
  useEffect(() => {
    if (selectedDoctor) {
      const data = yourAvailabilitySelected
        ? {
            selectedDoctor: selectedDoctor,
            selectedDoctorSlotId: selectedDoctorSlotId,
            selectedDoctorDate: selectedDoctorDate,
          }
        : { selectedDoctor: selectedDoctor };
      navigate("/appointments/new-appointment/appointment-scheduler", {
        state: data,
      });
    }
    // state cleanup
    return () => {
      setSelectedDoctorSlotId("");
      setSelecteDoctordDate(null);
      setSelectedDoctor(null);
    };
  }, [selectedDoctor]);

  const getDoctorSpecialization = (specializations: any[]) => {
    if (selectedSpeciality) {
      return selectedSpeciality;
    } else {
      if (specializations.includes("PRIMARY_CARE")) {
        return "General Physician";
      } else {
        return (
          specializations
            .filter(
              (specialization: string) => specialization === "NEUROLOGIST"
            )[0]
            .charAt(0)
            .toUpperCase() +
          specializations
            .filter(
              (specialization: string) => specialization === "NEUROLOGIST"
            )[0]
            .slice(1)
            .toLowerCase()
        );
      }
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor: "var(--neuro-white-text)",
        display: "flex",
        flexGrow: 1,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "15px",
        paddingLeft: "15px",
        paddingRight: "20px",
        cursor: "pointer",
      }}
      onClick={() => {
        if (slotId !== "") {
          setSelectedDoctorSlotId(slotId);
        }
        if (selectedDate) {
          setSelecteDoctordDate(selectedDate);
        }
        setSelectedDoctor({
          ...doctor,
        });
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
        <Avatar
          src={doctor?.profileUrl !== "" ? doctor?.profileUrl : undefined}
          alt="doctor-profile"
          sx={{
            width: 70,
            height: 70,
            backgroundColor: "var(--neuro-bg-light-grey-secondary)",
            color: "var(--neuro-darkgrey_border)",
            border: "2px solid var(--neuro-button-bg-success)",
          }}
        />
        <Box
          sx={{
            alignSelf: "flex-start",
            display: "flex",
            marginTop: "2px",
            flexDirection: "column",
            gap: "12px",
            flexGrow: 1,
            flexBasis: 0,
            width: "fit-content",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: "var(--neuro-bg-darkblue-primary)",
                font: "600 18px/120% Inter, sans-serif",
              }}
            >
              {doctor?.firstName || ""} {doctor?.lastName || ""}
            </Typography>
            <Typography
              sx={{
                color: "var(--neuro-button-bg-primary)",
                font: "400 13px/120% Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif",
              }}
            >
              {getDoctorSpecialization(doctor?.specializations) || ""}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "var(--neuro-black-text)",
              font: "400 13px/120% Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif",
            }}
          >
            {doctor?.yearsOfExperience || ""} Years Experience
          </Typography>
        </Box>
      </Box>
      <IconButton
        disableRipple
        sx={{
          padding: 0,
          backgroundColor: "transparent",
          "&:hover": { backgroundColor: "transparent" },
        }}
        // onClick={() =>
        //   setSelectedDoctor({
        //     ...props,
        //   })
        // }
      >
        <RightExtandIcon />
      </IconButton>
    </Box>
  );
};

export default DoctorCard;
