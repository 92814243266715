import React from "react";
import { Box } from "@mui/material";

const SidebarHeader: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src="/22neuro-sidebar-logo.png" alt="22Neuro Clinic" width={120} />
    </Box>
  );
};

export default SidebarHeader;
